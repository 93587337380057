import { Button, IconButton, Link } from "@mui/material"
import React, { useEffect, useState } from "react"
import Enterdetails from "../../Popupdelete/enterDetails/Enterdetails"
import styles from "./Faqlist.module.css"
import homeImage from "../../../../../constant"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchFaqData,
  handleAddFaq,
  deleteFaq,
  editFaq,
} from "../../../../../State/api/superadminApi"
import { AlertsSuccess, Warnnoti } from "../../../../../common/alert/Alert"
import { fetchUsertypes } from "../../../../../State/api/commonapi"
import CircularLoader from "../../../../../common/loader/Loader"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { IoIosArrowBack } from "react-icons/io";
import { FiTrash2, FiEdit3 } from "react-icons/fi";

const datas = [
  {
    id: 1,
    heading: "What is web flow and why is it best website builder?",
    description:
      "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.",
  },
  {
    id: 2,
    heading: "Is framing available for all works?",
    description:
      "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.",
  },
  {
    id: 3,
    heading: "What is web flow and why is it best website builder?",
    description:
      "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.",
  },
  {
    id: 4,
    heading: "What is your favorite template from BRIX Templates?",
    description:
      "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.",
  },
  {
    id: 5,
    heading: "What is web flow and why is it best website builder?",
    description:
      "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.",
  },
  {
    id: 6,
    heading: "How do you clone a template from the Showcase?",
    description:
      "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.",
  },
  {
    id: 7,
    heading: "What is web flow and why is it best website builder?",
    description:
      "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.",
  },
  {
    id: 8,
    heading: "How do you clone a template from the Showcase?",
    description:
      "Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.",
  },
]
export default function Faqlist(props) {
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [data, setData] = useState({ offset: 0 })
  const [editdata, setEditdata] = useState()
  const dispatch = useDispatch()
  const faqlistdata = useSelector((state) => state.faqlistdata)
  const loader = useSelector((state) => state.loader)
  const location = useLocation()
  const { name } = location.state || {}
  const { id } = useParams()
  const user_type_id = id
  const navigate = useNavigate()
  // const usertype = useSelector((state) => state.usertype.data);
  
  const handlePopup = () => {
    setOpen(true)
  }
  // useEffect(() => {
  //     dispatch(fetchUsertypes());
  // }, [])
  const fetchData = () => {
    dispatch(fetchFaqData(user_type_id, {}))
  }
  useEffect(() => {
    fetchData()
  }, [])
  const handleAddfaq = (dt) => {
    dispatch(handleAddFaq(dt, setOpen, fetchData))
  }
  const handleEditPopup = (dt) => {
    setEditdata(dt)
    setEdit(true)
  }
  const handleEditfaq = (id, dt) => {
    dispatch(
      editFaq(
        id,
        {
          question: dt.question,
          answer: dt.answer,
          user_type_id: user_type_id,
          faq_type: dt.faq_type,
        },
        setEdit,
        fetchData
      )
    )
  }
  const handleDelete = (id) => {
    Warnnoti("Are you sure?", () => {
      dispatch(deleteFaq(id, fetchData))
    });
   
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      <div className={styles.components}>
        {open ? (
          <Enterdetails
            handleAddfaq={handleAddfaq}
            poptype="faquestion"
            open={open}
            setOpen={setOpen}
          />
        ) : (
          ""
        )}
        {edit ? (
          <Enterdetails
            editdata={editdata}
            handleEditfaq={handleEditfaq}
            poptype="faquestion"
            open={edit}
            setOpen={setEdit}
          />
        ) : (
          ""
        )}
        <div className={styles.back_button}>
    <Link onClick={() => navigate("/faq")} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      </div>
         
        <div className={styles.fqalist_header}>
          <div className={styles.main_heading}> For {name}</div>
          <button
          className="action_btn"
            onClick={handlePopup}
          >
            Create
          </button>
        </div>
        {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
          <div>
            {faqlistdata.data &&
              faqlistdata.data.map((val, i) => (
                <div key={ValidityState.id}>
                  <div className={styles.card}>
                    <div className={styles.details}>
                      <h4>{i + 1}.</h4>
                      <div>
                        <h2>{val.question}</h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              val && val.answer && val.answer.length > 100
                                ? val.answer.substring(0, 100) + "..."
                                : val && val.answer
                                ? val.answer
                                : "",
                          }}
                        ></p>
                      </div>
                    </div>
                    <div className={styles.action_div}>
                      <IconButton onClick={() => handleEditPopup(val)}>
                      <FiEdit3 className={styles.editicon} />
                        
                      </IconButton>
                      <IconButton onClick={() => handleDelete(val._id)}>
                      <FiTrash2 className={styles.deleicon}/>
                        
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}
