import React, { useState, useRef, useEffect } from "react";
import styles from "./Home.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import homeImage from "../../constant";
import { useDispatch } from "react-redux";
import { AddMailSubscription } from "../../State/api/userApi";
import { AlertsSuccess, Errornotify } from "../alert/Alert";
import { RiFileList2Line, RiUserSettingsLine, RiImageAddLine, RiPriceTag2Line  } from "react-icons/ri";

const data = [
  {
    id: 1,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  {
    id: 2,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  {
    id: 3,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
];
const faq_data = [
  {
    id: 1,
    question: "Is registration open to all individuals wanting to sell on Art Smiley?",
    answer: "Yes, individuals can sell on Art Smiley by simply creating an account. This opportunity is available to all artists looking to market their creations to a wider audience. Art Smiley supports artists of all levels, from beginners to established professionals, allowing them to showcase and sell their artwork."
  },
  {
    id: 2,
    question: "How to Sign Up as an Artist on Art Smiley?",
    answer: "To join Art Smiley as an artist, start by visiting the platform's Artist Application registration page. Fill out the required fields with your accurate information and any relevant details about your artistic practice. After submitting your application, it will be reviewed by the Art Smiley curatorial team. Once approved, you will receive a confirmation email that allows you to create your password. This will grant you access to your artist profile, where you can upload and sell your artwork."
  },
  {
    id: 3,
    question: "What is the expected timeframe for the application review process?",
    answer: "The review process for your artist application typically takes 3 to 4 weeks. You’ll be notified as soon as the review is complete, along with guidance on the next steps to begin showcasing your work."
  },
  {
    id: 4,
    question: "How do I submit my artwork for upload?",
    answer: "First, you need to login to your dashboard and select the upload arts section and start submitting the artwork with all details."
  },
  {
    id: 5,
    question: "What types of creative works are accepted for sale on Art Smiley?",
    answer: "You can sell art on Art Smiley including various artistic creations, such as all types of paintings, drawings, photography, digital art, sculptures, open, and limited edition prints. Unique handmade crafts or mixed media pieces are also permitted."
  },
  {
    id: 6,
    question: "What are the necessary steps to sell my artwork through Art Smiley?",
    answer: "To sell art on Art Smiley, First, create an artist account on the platform. After your account approval, begin uploading high-quality images of your artwork along with detailed descriptions. Set your prices based on your experience and market trends. Lastly, familiarize yourself with Art Smiley’s <a href='shipping-policy'>shipping,</a> and <a href='return-policy'>return</a> policies to ensure the prompt delivery of the artwork. Once your artwork will be approved by the curator then it will be available for sale on the platform."
  },
  {
    id: 7,
    question: "How does the payout system work for artists on Art Smiley?",
    answer: "As an Art Smiley artist, you can receive payments for your artwork through various options. Funds will be transferred to your bank account within 3 to 4 weeks after the successful delivery of your artwork. You can choose to receive your payment via bank account or PayPal, depending on your preference."
  },
  {
    id: 8,
    question: "How does the shipping procedure work?",
    answer: "Upon the sale of your artwork, you will receive an email with all the details. Login to your dashboard, accept the artwork order and  then schedule a pickup date with our courier service. Download soft copies of the shipping labels from your dashboard, print and stick them over the package. The buyer covers shipping charges, and your responsibility is limited to packaging your artwork according to the guidelines specified by Art Smiley. To know more about the packaging instructions, click this link [package guidelines link]."
  },
  {
    id: 9,
    question: "What are the best packaging methods for ensuring the safe transport of different kinds of artwork?",
    answer: (<>
    '<p>When packaging artwork, it’s essential to choose the right method to protect each piece. </p>
    <ul className={styles.faq}>
      <li>Flat pack boxes are perfect for small sized framed prints, paintings, and photography.</li>
      <li>Tube packaging works well for rolled-up paintings, as its cylindrical shape prevents crushing of artwork. </li>
      <li>For fragile or large pieces like sculptures or big-sized framed artwork, custom wooden crates provide the highest level of protection.</li>
      <li>All artworks must be wrapped with bubble wrap or foam cushioning to prevent surface scratches or impact damage. </li>
    </ul>
    <p>You can check out the detailed instructions with our packaging guidelines[packaging guidelines link].</p>
    '
    </>)
  },
  {
    id: 10,
    question: "How are commissions calculated when selling through Art Smiley?",
    answer: (
      <>
      <p>Art Smiley employs a commission structure that varies based on the type of transaction:</p>
      <ul className={styles.faq}>
        <li><span>For Sales:</span> Art Smiley charges a commission of 40% on each artwork sold through the platform, allowing artists to retain 60% of the sale price.</li>
        <li><span>For Rentals:</span> When renting out artwork, Art Smiley applies a 50% commission, with the remaining 50% going to the artist for the duration of the rental period.</li>
        <li><span>For Print Sales:</span> Art Smiley is responsible for printing open edition prints and takes a 40% commission on all art print sales. Artists are responsible for printing limited edition prints.</li>
      </ul>
      </>
    )
  },
  {
    id: 11,
    question: "How Does Artwork Renting Work on Art Smiley?",
    answer: (
      <>
      <p>Art Smiley offers a unique artwork rental service, allowing clients to rent pieces for a specified period. Artists  can opt for artwork renting by specifying the price of the artwork on a monthly basis. This helps the buyers to explore the artwork and try for a short period. They can purchase the artwork with a 30% rental amount credited for each month.</p>
      <p>The artist receives a portion of the rental fee, with Art Smiley taking a 50% commission. This option provides artists with an additional revenue stream while giving customers the flexibility to enjoy art.</p>
      </>
    )
  },
  {
    id: 12,
    question: "How will I know when my painting is sold?",
    answer: "Once your painting is sold on Art Smiley, you will receive a confirmation email. Login to the dashboard and accept the order. After that, you must schedule a pick up date and securely pack your painting by rolling it, wrapping it in bubble wrap, and placing it in a sturdy plastic or cardboard tube. Seal the ends of the tube with waterproof material for protection. Flat pack boxes are ideal for protecting framed paintings, prints, and photographs from bending and creasing during transit. After the painting is shipped, you can track its status through your Art Smiley account. Once the buyer receives the artwork and the 14-days return window has passed, your payment will be processed within 3 to 4 weeks."
  },
  {
    id: 13,
    question: "How Do I Determine the Price for My Original Works?",
    answer: "When setting the price for your original creations to sell art on Art Smiley, it's important to take your personal expectations into account. Make sure that your pricing reflects the platform's commission rate, which is currently 40%. This means you should incorporate this commission into your pricing strategy to ensure that your potential earnings meet your financial objectives."
  },
  {
    id: 14,
    question: "What are limited edition prints?",
    answer: "Limited edition prints are artworks produced by the artists in a restricted number, meaning only a specific quantity of prints will be made. These prints are typically signed and numbered by the artist, which adds to their value and appeal. The limited nature of these prints creates a sense of exclusivity, making them more desirable to collectors and art enthusiasts."
  },
  {
    id: 15,
    question: "What Are Open Edition Prints?",
    answer: "If the artist chooses an artwork to sell as open edition prints, it will be printed by Art Smiley.  Artists must provide a high quality image of the artwork for printing. Open edition prints refer to art reproductions that do not have a predetermined limit on the number of copies produced. Unlike limited edition prints, which are produced in a finite quantity, open edition prints can be created continuously without restriction. This allows for greater accessibility and affordability, making the artwork available to a broader audience."
  }
];

const service_data = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Platform Exclusivity",
    des: "Artists cannot sell their artwork on any other platform, ensuring the uniqueness and exclusivity of their pieces.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "First Preference for Exhibitions",
    des: "Exclusive artists are given priority in showcasing their artwork in our physical and virtual exhibitions, gaining maximum visibility.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "Priority in Auctions",
    des: "Get first preference to feature your work in high-profile auctions, increasing exposure to collectors and buyers.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "Increased Artwork Value",
    des: "The exclusivity of selling only through Art Smiley enhances the value and rarity of your artwork.",
  },
  {
    id: 5,
    src: "/img/service_artist_1",
    heading: "Artist-Centric Focus",
    des: "We manage the business aspects, allowing you to concentrate on creating and perfecting your art.",
  },
  {
    id: 6,
    src: "/img/service_artist_1",
    heading: "Exclusive Promotions",
    des: "Your artwork will be exclusively promoted on WhatsApp, email marketing, and social media platforms, reaching a broader audience of art enthusiasts.",
  },
  {
    id: 7,
    src: "/img/service_artist_1",
    heading: "Priority in Art Smiley Projects",
    des: "Exclusive artists are given first preference in collaborating on Art Smiley internal projects, ensuring maximum exposure and opportunities.",
  },
];
const feature_data = [
  {
    id: 1,
    heading: "Managing Your Artist Profile",
    des: "Access and update your profile at any time. Easily edit your information, artwork listings, and portfolio details. The dashboard allows for full control and easy management of your online presence.",
  },
  {
    id: 2,
    heading: "Uploading Arts",
    des: "Easily upload and manage your artworks directly from the dashboard. Organize your portfolio and keep it fresh by adding new pieces. Showcase your best work in an intuitive platform for maximum visibility.",
  },
  {
    id: 3,
    heading: "Exhibitions",
    des: "Manage your participation in Art Smiley exhibitions and stay informed about upcoming events. Increase your exposure by connecting with art collectors and expanding your professional network.",
  },
  {
    id: 4,
    heading: "Auctions",
    des: "Participate in exclusive auctions to sell your artwork. The Auction section allows you to manage bids and view potential buyers. Auctioning your art increases its value and creates a sense of urgency among collectors.",
  },
  {
    id: 5,
    heading: "Buyer's Offers",
    des: "Review and manage offers from interested buyers. The Buyer’s Offers gives you direct insights into buyer interests and allows you to respond quickly. Stay proactive in engaging with potential collectors.",
  },
  {
    id: 6,
    heading: "Art Smiley's Offers",
    des: "Check out the offers for your artwork curated by Art Smiley's team. You can choose the artworks for available offers to attract art collectors. It includes special promotions, gallery features, and exhibition offers.",
  },
  {
    id: 7,
    heading: "Recreate Request",
    des: "Receive and respond to requests for art reproductions. Manage recreations of sold pieces and communicate with interested buyers. Ensure consistency and quality in reproduced works for customer satisfaction.",
  },
  {
    id: 8,
    heading: "Quote Requests",
    des: "Handle requests for custom art quotes. The quote requests helps you manage inquiries related to commissions. Provide potential buyers with pricing and timelines for personalized art projects.",
  },
  {
    id: 9,
    heading: "Generate Dispute Feature",
    des: "Art Smiley values transparency and fairness. Should any conflict arise with an order, please use the Dispute feature. This structured approach to conflict management protects both you and the buyer, ensuring quick resolutions to any issues.",
  },
  {
    id: 10,
    heading: "Art Smiley News",
    des: "Stay updated with the latest blogs, events, newsletters, exhibitions, offers, auctions, etc. From new artist opportunities to art market insights, the news section keeps you informed about everything happening in the art world.",
  },
];
const exclusive_data = [
  {
    id: 1,
    src: "/img/artcollections",
    heading: "Expand Your Reach with Exhibitions and Auctions",
    des: "As a registered artist, you can sell art on Art Smiley and receive priority access to virtual and physical exhibitions, enhancing your global visibility. You’ll also gain exclusive opportunities to participate in auctions, connecting with dedicated collectors to elevate your artistic career.",
  },
  {
    id: 2,
    src: "/img/artcollections",
    heading: "Art Community",
    des: "Become a part of our thriving art community, where you can sell your work alongside other talented artists. Gain exclusive access to the latest trends and insights through features in blogs, magazines, catalogs, and artist interviews, helping you boost your visibility and enrich your artistic journey.",
  },
  {
    id: 3,
    src: "/img/artcollections",
    heading: "Featured  and Exclusive Artist",
    des: "Art Smiley assesses and categorizes featured artists based on their experience and profile, ensuring that each artist receives appropriate support and opportunities tailored to their unique career stage. As an artist, when you sell art on Art Smiley, your artworks will be showcased to a wider audience, helping attract potential buyers. We highlight your exclusive artist profile, enhancing your presence in the art world and connecting you with collectors and art enthusiasts.",
   },
  {
    id: 4,
    src: "/img/artcollections",
    heading: "Most Popular and Recommended Artworks",
    des: "At Art Smiley, we showcase the most popular and recommended artworks to celebrate exceptional talent within our community. These artworks are carefully curated based on popularity and artistic merit, ensuring they resonate with collectors and art lovers. This curated spotlight not only elevates the artists' profiles but also enhances their presence in the art market.",
   },
];
const subscription_data = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Public Profile",
    des: "Showcase your artwork to the world and become a permanent part of the Art Smiley community.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Personalized Dashboard",
    des: "Easily manage your portfolio, track the status of your artworks, exhibitions, virtual auctions, orders, customer requests, and client offers.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "SSL Security",
    des: "Protect your personal and sensitive information with SSL encryption.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "24/7 Customer Support",
    des: "Get round-the-clock assistance whenever you need it.",
  },
  {
    id: 5,
    src: "/img/service_artist_1",
    heading: "Upload up to 20 Works",
    des: "Start sharing your creations with up to 20 artwork uploads.",
  },
  {
    id: 6,
    src: "/img/service_artist_1",
    heading: "Analytics",
    des: "Monitor views, likes, follows, and reviews to track your audience engagement.",
  },
];
const standard_subscription = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Upload up to 200 Works",
    des: "Broaden your artwork display and reach a wider audience.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Homepage Feature for Your Artwork",
    des: "Enjoy heightened exposure with a one-time feature of your artwork on the Art Smiley homepage.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "Participation in Virtual Events",
    des: "Network with fellow artists and collectors through one-time participation with up to three works in any Art Smiley, including virtual exhibitions or auctions.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "Exclusive Marketing Opportunities",
    des: "Take advantage of one-time promotions for selected artworks on Art Smiley's social media channels (Facebook, Instagram, Twitter).",
  },
  {
    id: 5,
    src: "/img/service_artist_1",
    heading: "Insights on Pricing and Marketing",
    des: "Receive valuable support and insights to make strategic decisions regarding your art pricing and marketing efforts.",
  },
];
const premium_subscription = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Upload up to 500 Works",
    des: "Present your full collection of creative artworks and expand your portfolio.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Highlighted Artist Profile on Homepage",
    des: "Stand out in the artistic community with a one-time feature of your profile on the Art Smiley homepage.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "Enhanced Participation in Virtual Events",
    des: "Gain access to exclusive networking opportunities with two-time participation, featuring up to three works in any Art Smiley events, including virtual exhibitions or auctions.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "Broadened Marketing Support",
    des: "Reach a wider audience with two exclusive promotions of your artworks across all Art Smiley social media channels (Facebook, Twitter, Instagram, GMB, LinkedIn, Pinterest, Tumblr).",
  },
  {
    id: 5,
    src: "/img/service_artist_1",
    heading: "Video Production for YouTube & Social Media",
    des: "Visually showcase your artistry with a video created by the Art Smiley team or your own one-minute video shared on our YouTube channel and other social media platforms.",
  },
  {
    id: 6,
    src: "/img/service_artist_1",
    heading: "Exclusive Exhibition Invitations",
    des: "Enjoy discounted rates and first refusal opportunities for all Art Smiley-associated physical or virtual exhibitions.",
  },
  {
    id: 7,
    src: "/img/service_artist_1",
    heading: "Featured Blog Posts",
    des: "Share your artistic insights and experiences by having your work featured in Art Smiley's blogs.",
  },
  {
    id: 8,
    src: "/img/service_artist_1",
    heading: "Solo Online Exhibition with Annual Plan",
    des: "Highlight your artwork in a dedicated virtual showcase if you choose the one-year Premium Subscription.",
  },
];
const why_choose_data = [
  {
    id: 1,
    heading: "Reach a Wider Audience",
    des: "Tap into a global network of art enthusiasts, corporate buyers, and interior designers actively seeking unique art pieces when you sell art on Art Smiley. Expand your visibility through a platform that celebrates diverse artistic styles and mediums.",
  },
  {
    id: 2,
    heading: "Build Your Artistic Brand",
    des: "Establish a professional online presence with a customizable artist profile highlighting your story and portfolio. Gain recognition through Art Smiley’s curated features and artist spotlights.",
  },
  {
    id: 3,
    heading: "Expand Your Creative Potential",
    des: "Participate in physical and virtual exhibitions, auctions, and community events hosted by Art Smiley, gaining exposure and networking opportunities.",
  },
  {
    id: 4,
    heading: "Fair & Reliable Earnings",
    des: "Keep more of your earnings with competitive commission rates and transparent payment systems. Receive timely payouts with no unexpected deductions or delays.",
  },
  {
    id: 5,
    heading: "Smart Artist Dashboard ",
    des: "Efficiently manage your portfolio with our intuitive dashboard. Keep track of key metrics such as total arts, orders, inquiries, exhibitions, auctions, likes, followers, and earnings – all in one place!",
  },
  {
    id: 6,
    heading: "Hassle-Free Logistics",
    des: "Selling art should be about creativity, not complications. Art Smiley handles shipping, and delivery, ensuring your artwork reaches buyers safely. Our secure payment system guarantees that transactions are smooth between artists and buyers.",
  }
];

const reachheight_data = [
  {
    id: 1,
    src: "/img/curatorsignup",
    heading: "Worldwide Recognition",
    des: "<p>Present your creations to a global audience, fostering connections with collectors everywhere.</p>",
  },
  {
    id: 2,
    src: "/img/curator-recommended",
    heading: "Artistic Independence",
    des: "<p>Enjoy complete control over your pricing and creative choices, preserving your artistic integrity.</p>",
  },
  {
    id: 3,
    src: "/img/curator-earn",
    heading: "Dedicated Guidance",
    des: "<p>Access customized support tailored to elevate your artistic journey and business success.</p>",
  },
  {
    id: 4,
    src: "/img/curator-earn",
    heading: "Artist Classification",
    des: `
    <p>At Art Smiley, we assess artists and categorize them into emerging, experienced, acclaimed, and masters based on their experience and achievements.</p>
    `,  
  },
];
const reach_data =[
  {
    id: 1,
    icon: <RiFileList2Line />,
    heading: "Artist Application",
    des: "<p>Begin your journey by applying to join the Art Smiley platform. Once your application is reviewed and approved, you'll receive a confirmation email to create a password and then you can unlock access to all features and services.</p>",
  },
  {
    id: 2,
    icon:<RiUserSettingsLine />,
    heading: "User-Friendly Dashboard",
    des: "<p>Efficiently manage your portfolio with our intuitive dashboard. Keep track of key metrics such as total arts, orders, inquiries, exhibitions, auctions, likes, followers, and earnings – all in one place!</p>",
  },
  {
    id: 3,
    icon:<RiPriceTag2Line />,
    heading: "Promoting and Selling Your Artwork",
    des: "<p>Art Smiley's marketing efforts will get your artwork seen by broad audience. Participate in exhibitions and auctions, leverage social media promotion, and access a global audience of collectors.</p>",
  },
  {
    id: 4,
    icon:<RiImageAddLine />,
    heading: "Artwork Upload",
    des: "<p>Access your personalized dashboard and start submitting the artworks with all details. After the approval of the artwork, it will be available for sale. In this way, Art Smiley helps to attract a wide range of collectors and art enthusiasts.</p>",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Home(props) {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSignupButton = () => {
    // const applied_profile = localStorage.getItem("applied_profile");
    // const user_type = localStorage.getItem("usertype");
    
    // if (applied_profile === "true" && user_type === "aritst") {
    //   Errornotify("You Already Apply for Artist");
    // }
    // if (applied_profile === "true" && user_type === "affiliate") {
    //   Errornotify("You Already Apply for Artist");
    // } else {
    //   navigate("/artsmiley/applynow");
    // }
    navigate("/artist/applynow")
  };
  const handleChangeSubscription = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    dispatch(AddMailSubscription({ email: email }, "st", setEmail));
  };

  const [exclusiveExpanded, setExclusiveExpanded] = React.useState('excpanel0'); // Default to the first panel

const handleExclusiveChange = (panel) => (event, isExpanded) => {
  setExclusiveExpanded(isExpanded ? panel : false);
};

const [subscriptionExpanded, setSubscriptionExpanded] = React.useState("excpanel0"); // Default to the first panel open
const handleSubscriptionChange = (panel) => (event, isExpanded) => {
  setSubscriptionExpanded(isExpanded ? panel : false);
};

const [substandardscriptionExpanded, setStandardsubscriptionExpanded] = React.useState("excpanel0"); // Default to the first panel open
const handleStandardsubscriptionChange = (panel) => (event, isExpanded) => {
  setStandardsubscriptionExpanded(isExpanded ? panel : false);
};

const [subpremiumscriptionExpanded, setPremiumsubscriptionExpanded] = React.useState("excpanel0"); // Default to the first panel open
const handlePremiumsubscriptionChange = (panel) => (event, isExpanded) => {
  setPremiumsubscriptionExpanded(isExpanded ? panel : false);
};



const [expandedIndex, setExpandedIndex] = useState(null);
const [isOverflowing, setIsOverflowing] = useState(false);

const contentRef = useRef(null);

useEffect(() => {
  if (contentRef.current) {
    const isContentOverflowing =
      contentRef.current.scrollHeight > contentRef.current.clientHeight;
    setIsOverflowing(isContentOverflowing);
  }
}, []);

const toggleReadMore = (index) => {
  setExpandedIndex(expandedIndex === index ? null : index); // Toggle state
};

const handleNavigate = (route) => {
  if (route.includes("https")) {
    window.open(route, "_blank");
  } else {
   // console.log(route,'route')
    if(route === "/search") {
      navigate(`/user${route}`, {state: {itemType: "print"}});
      window.scrollTo({ top: 0, behavior: "smooth" });
    }else {
      navigate(`/user${route}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
  return (
    <>
      <AlertsSuccess />
      {/* <Header />
            <Navbar /> */}
      <React.Fragment>
        <CssBaseline />
        <section className={styles.hero_main}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Unlock The Power Of Global Art Exposure</h1>
              <button
              onClick={handleSignupButton}
              variant="contained"
              className="apply_ltbtn"
            >
             Apply Now
            </button>
            </div>
</Container>
        </section>
        <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Join Our Creative Artist Community</h3>
          <p className={styles.withor}>Step into the Spotlight with Art Smiley Artist Network by completing the Artist Application. Become a part of Art Smiley and showcase your art to a broader audience, expanding your reach and gaining recognition.</p>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <div className={styles.stboxh}>
                <div className={styles.stvob}>
                  <div className={styles.sticon}>
                  <img
                className={styles.img_fluid}
                src={"/img/curatorsignup.svg"}
                alt="img"
              />  </div>
                  <h4>Global Presence</h4>
                  <div className={styles.stcont}>
                    <p>Expand your global presence by showcasing your artwork to collectors worldwide, with opportunities for promotion through both physical and virtual exhibitions, as well as online auctions.</p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
            <div className={styles.stboxh}>
                <div className={styles.stvob}>
                <div className={styles.sticon}>
                  <img
                className={styles.img_fluid}
                src={"/img/curator-recommended.svg"}
                alt="img"
              />  </div>
                  <h4>Active Marketing</h4>
                  <div className={styles.stcont}>
                   <p>We actively promote your work through blogs, social media, whatsapp, email campaigns, and curated catalogs that help to increase the sales of your artwork.</p>
                   </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
            <div className={styles.stboxh}>
                <div className={styles.stvob}>
                <div className={styles.sticon}>
                  <img
                className={styles.img_fluid}
                src={"/img/curator-earn.svg"}
                alt="img"
              />  </div>
                  <h4>Commissions</h4>
                  <div className={styles.stcont}>
                  <p>Art Smiley charges a 40% commission on sales, with artists setting prices to ensure fair earnings for each piece.</p>
                  </div>
                </div>
              </div>
            </Grid>
            </Grid>
          </Container>
          </section>
          <section className={styles.sec_sc}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Expand Your Artistic Horizons</h3>
          </div>
          <Grid container spacing={2} sx={{justifyContent: "center",}}>
            <Grid item xs={12} sm={8} md={8}>
              <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
            </Grid>
          </Grid>
          </Container>
          <div className={styles.archdvb}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <Grid container spacing={2}>
          {reachheight_data.map((val, index) => (
                <Grid item xs={12} sm={6} md={3} key={val.id} >
                  <div  key={index} className={styles.stboxh}>
                <div className={styles.rechhbox}>
                  <div className={styles.sticon}>
                  <img
                className={styles.img_fluid}
                src={`${val.src}.svg`}
                alt="img"
              />  </div>
                  <h4>{val.heading}</h4>
                  <div className={styles.stcont}>
                    {/* <p>{val.des}</p> */}
                    <div
      className={`${styles.textContent} ${
        expandedIndex === index ? styles.expanded : ""
      }`}
      ref={contentRef}
      style={{
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: expandedIndex === index ? "unset" : 2, // Show 3 lines or full content
        overflow: "hidden",
      }}
      dangerouslySetInnerHTML={{ __html: val.des }}
    />
    
    {isOverflowing && (
      <button
        className={styles.readMoreBtn}
        onClick={() => toggleReadMore(index)}
      >
        {expandedIndex === index ? "Read Less" : "Read More"}
      </button>
    )}
    </div>
                </div>
                </div>
                </Grid>
          ))}
            </Grid>
            
            </Container>
          </div>
          </section>
          <section className={styles.sec_scexcl}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>How to Apply as an Artist?</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={5} sm={6} xs={12}>
          <div>
              <img src={"/img/apply-atrist.jpeg"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
                  <div>
                    <ul className={styles.timeline}>
                      <li>
                      <div className={styles.stepli}>
                    <h4>Fill The Application Form</h4>
                    <p>To register as an artist, complete the registration form with accurate information. Include a valid ID, a professional profile outlining your achievements and experience, and high-quality images of your artwork. Providing all requirements will expedite the review process.</p>
                    </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                    <h4>Upload  Your profile</h4>
                <p>Upload a comprehensive profile that includes your education, artist statement, experience, materials & styles used in the artwork, inspirations and creative process, exhibitions history, awards, and recognitions received, etc., This helps the curatorial team to review your profile and your exceptional artistic skill.</p>
                  </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                    <h4>Highlight Your Achievements</h4>
                <p>Showcase your artistic milestones by listing the exhibitions you’ve participated in and the awards you’ve received. Adding the credentials such as solo exhibitions, a group show, or an international award increases the credibility of your profile, which helps to build trust with targeted buyers.</p>
                    </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                    <h4>Artist Background</h4>
                    <p>Share your story as an artist including your inspirations, creative process, and artistic philosophy. It allows potential buyers to understand the depth of your work and the vision behind your creations. Highlighting your experience and achievements can make your profile more compelling.</p>
                    </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                    <h4>Upload Artworks</h4>
                    <p>As a part of the registration process, please upload a minimum of two and a maximum of ten of your finest artworks to showcase your style and creativity. The Art Smiley curator team will review the artworks and the profile to approve your artist account.</p>
                    </div>
                      </li>
                    </ul>
                    
                  </div>
            </Grid>
            </Grid>
            <div className={styles.btnmt2}><button onClick={handleSignupButton} class="apply_btn">Apply Now</button></div>
            </div>
          
          </Container>
          </section>
          {/* <section className={styles.sec_sc}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Testimonials</h3>
          </div>
          <section className={styles.sections}>
          <Grid container spacing={2}  alignItems="center">
          <Grid item md={6} xs={12}>
          <div className={styles.testmibox}>
                      <Grid container spacing={2}  alignItems="center">
                      <Grid item md={3} xs={12}>
                      <div className={styles.testimonial_img_div}>
                          <img
                            src={"/img/event3.png"}
                            width="120px"
                            height="120px"
                            alt=""
                          />
                        </div>
                      </Grid>
                      <Grid item md={9} xs={12}>
                        <div className={styles.tecont}>
                        <div className={styles.test_metainfo}>
                        <h5 className={styles.test_card_heading}>
                        Albert Didier
                          </h5>
                          <h6 className={styles.test_card_subhead}>
                          Euismod ipsum
                          </h6>
                        </div>
                        <div className={styles.test_cont}>
                          <p className={styles.test_card_des}>In purus at morbi magna in in maecenas. Nunc nulla magna
                          elit, varius phasellus blandit convallis.</p>
                        </div>
                        </div>
                      </Grid>
                      </Grid>
                      </div>
          </Grid>
          <Grid item md={6} xs={12}>
          <div className={styles.testmibox}>
                      <Grid container spacing={2}  alignItems="center">
                      <Grid item md={3} xs={12}>
                      <div className={styles.testimonial_img_div}>
                          <img
                            src={"/img/event3.png"}
                            width="120px"
                            height="120px"
                            alt=""
                          />
                        </div>
                      </Grid>
                      <Grid item md={9} xs={12}>
                        <div className={styles.tecont}>
                        <div className={styles.test_metainfo}>
                        <h5 className={styles.test_card_heading}>
                        Albert Didier
                          </h5>
                          <h6 className={styles.test_card_subhead}>
                          Euismod ipsum
                          </h6>
                        </div>
                        <div className={styles.test_cont}>
                          <p className={styles.test_card_des}>In purus at morbi magna in in maecenas. Nunc nulla magna
                          elit, varius phasellus blandit convallis.</p>
                        </div>
                        </div>
                      </Grid>
                      </Grid>
                      </div>
          </Grid>
          </Grid>
        </section>
          
          </Container>
          </section> */}
           <section className={styles.sec_sc}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>How Can I Sell My Artwork Through Art Smiley?</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          
          <Grid item md={6} sm={6} xs={12}>
          <Grid container spacing={3}>
          {reach_data.map((val, index) => (
                <Grid item xs={12} sm={6} md={6} key={val.id} >
                  <div  key={index} className={styles.stboxbn}>
                <div className={styles.hbox1}>
                  <div className={styles.idnumbr}>{val.icon}</div>
                  <h4>{val.heading}</h4>
                  <div className={styles.stcont}>
                    {/* <p>{val.des}</p> */}
                    <div
      className={`${styles.textContent1}`}
       
      dangerouslySetInnerHTML={{ __html: val.des }}
    />
    
    
    </div>
                </div>
                </div>
                </Grid>
          ))}
            </Grid>
          </Grid>
          <Grid item md={5} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          </Grid>
         
          </div>
           
          </Container>
          </section>
          <section className={styles.sec_scexcl}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Exclusive Artist Benefits at Art Smiley</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={6} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={5} sm={6} xs={12}>
      <div className={`${styles.accordsc}`}>
      {service_data.map((val, index) => {
      const panelId = `excpanel${index}`; // Dynamic panel ID
      return (
        <Accordion
          key={panelId}
          expanded={exclusiveExpanded === panelId} // Check if the panel is expanded
          onChange={handleExclusiveChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto !important",
              margin:"0 0 10px 0 !important"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }}// Handle expansion state
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={styles.benfhdgn}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={styles.bendesc}>{val.des}</p>
          </AccordionDetails>
        </Accordion>
      );
    })}
      </div>
      
          </Grid>
          
          {/* {service_data.map((val) => (
            <Grid item md={3} xs={12} key={val.id} >
            <div className={styles.exclli}>
              <div className={styles.exbox}>
              <div className={styles.exboxmg}>
              <img
                    alt="img"
                    src={`${val.src}.png`}
                    className="img-fluid"
                  />
              </div>
              <div>
              <h4 className={styles.service_card_heading}></h4>
              <p className={styles.service_card_description}>{val.des}</p>
              </div>
              </div>
            </div>
          </Grid>
                
              ))} */}
          
          </Grid>
          </div>
           
          </Container>
          </section>
          <section className={styles.sec_sc}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Elite Services for Exceptional Artists</h3>
            </div>
            <div className={styles.taldartist}>
            <Grid container spacing={2}>
            {exclusive_data.map((val) => (
            <Grid item xs={12} sm={6} md={6} key={val.id}>
              <div className={styles.talhg}>
                <div className={styles.talgbx}>
                <div className={styles.talenimg}>
                    <img
                    alt="img"
                    src={`${val.src}.png`}
                    className="img-fluid"
                  />
                    </div>
                    <div className={styles.talentcont}>
                      <h4>{val.heading}</h4>
                      <p>{val.des}</p>
                    </div>
                </div>
              </div> 
            </Grid>
            ))}
            </Grid>
              {/* <ul> 

               {exclusive_data.map((val) => (
                <li  key={val.id}>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} md={3}>
                  <div className={styles.talenimg}>
                    <img
                    alt="img"
                    src={`${val.src}.png`}
                    className="img-fluid"
                  />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                  <div className={styles.talentcont}>
                      <h4>{val.heading}</h4>
                      <p>{val.des}</p>
                    </div>
                  </Grid>
                  </Grid>
                  <div className={styles.talnartst}>
                    
                    
                  </div>
                </li>
                ))}
              </ul> */}
            </div>
             
          </Container>
          </section>
          <section className={styles.sec_scexcl}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Artist Dashboard Features</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={6} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={5} sm={6} xs={12}>
      <div className={`${styles.accordsc}`}>
      {feature_data.map((val, index) => {
      const panelId = `excpanel${index}`; // Dynamic panel ID
      return (
        <Accordion
          key={panelId}
          expanded={exclusiveExpanded === panelId} // Check if the panel is expanded
          onChange={handleExclusiveChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto !important",
              margin:"0 0 10px 0 !important"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }}// Handle expansion state
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={styles.benfhdgn}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={styles.bendesc}>{val.des}</p>
          </AccordionDetails>
        </Accordion>
      );
    })}
      </div>
      
          </Grid>
          
          
          
          </Grid>
          </div>
        
          </Container>
          </section>
          {/* <section className={styles.subscriptionbg}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.text_white} ${styles.sec_title}`}> Artist Subscription Plans</h3>
          <p className={`${styles.text_white} ${styles.withor}`}>Elevate your artistic journey with Art Smiley's versatile subscription options, thoughtfully crafted to meet your individual aspirations as an artist. Select the plan that aligns with your goals and begin sharing your creativity with a global audience today!</p>
          </div>
                <div className={styles.plansec}>
                <Grid container spacing={2} sx={{justifyContent:"center"}}>
                <Grid item md={4} sm={6} xs={12}>
                <div className={`${styles.accordsc}`}>
                  <h3 className={styles.sucrhndg}>Basic</h3>
                  <div className={styles.prisec}>
                  <p className={styles.subsprice}>Free </p> 
                  </div>
      {subscription_data.map((val, index) => {
      const panelId = `excpanel${index}`;  
      return (
        <Accordion
          key={panelId}
          expanded={subscriptionExpanded === panelId}  
          onChange={handleSubscriptionChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0",  
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto !important",
              margin:"0 0 10px 0 !important"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }} 
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={styles.scbhdrng}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={styles.bendesc}>{val.des}</p>
          </AccordionDetails>
        </Accordion>
      );
    })}
      </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                <div className={`${styles.accordsc} ${styles.premimd}`}>
                  <h3 className={`${styles.sucrhndg} ${styles.text_white}`}>Standard</h3>
                  <div className={styles.prisec}>
                  <p className={styles.subsprice}><span className={`${styles.pric} ${styles.text_white}`}>$29</span> <span className={`${styles.predg} ${styles.text_white}`}>/month</span> </p>
                  <p className={`${styles.accorscc} ${styles.subsprice} `} ><span className={`${styles.pric} ${styles.text_white}`}>$276</span> <span className={`${styles.predg} ${styles.text_white}`}>/year</span> </p>
                </div> 
                <p className={styles.incl} style={{color:"#fff"}}>Includes all Basic Features plus:</p>
      {standard_subscription.map((val, index) => {
      const panelId = `excpanel${index}`;  
      return (
        <Accordion
          key={panelId}
          expanded={substandardscriptionExpanded === panelId}  
          onChange={handleStandardsubscriptionChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0",  
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
               background:"transparent",
               borderBottomLeftRadius:"0",
               borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto !important",
              margin:"0 0 10px 0 !important"
            },
            
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#fff",
              opacity:"1",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#fff",
              opacity:"1",
              color:"#1C1C1C",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }} 
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={`${styles.scbhdrng} ${styles.text_white}`}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={`${styles.bendesc} ${styles.text_white}`}>{val.des}</p>
          </AccordionDetails>
        </Accordion>
      );
    })}
      </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                <div className={`${styles.accordsc}`}>
                  <h3 className={`${styles.sucrhndg}`}>Premium</h3>
                  <div className={styles.prisec}>
                  <p className={styles.subsprice}><span className={styles.pric}>$59</span> <span className={styles.predg} style={{color:"#848199"}}>/month</span> </p>
                  <p className={`${styles.accorscc} ${styles.subsprice}`} ><span className={styles.pric}>$564</span> <span className={styles.predg} style={{color:"#848199"}}>/year</span> </p>
                 
                  </div>
                  <p className={styles.incl}>Includes all Standard Features plus:</p>
      {premium_subscription.map((val, index) => {
      const panelId = `excpanel${index}`;  
      return (
        <Accordion
          key={panelId}
          expanded={subpremiumscriptionExpanded === panelId}  
          onChange={handlePremiumsubscriptionChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0",  
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
             
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto !important",
              margin:"0 0 10px 0 !important"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }} 
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={styles.scbhdrng}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={styles.bendesc}>{val.des}</p>
          </AccordionDetails>
        </Accordion>
      );
    })}
      </div>
                </Grid>
                </Grid>
                
                </div>
                 
          </Container>
          </section> */}
          <section className={styles.sec_sc}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Why Choose Art Smiley to Sell My Art?</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{alignItems:"center"}}>
          {why_choose_data.map((val) => (
             <Grid item xs={12} sm={4} md={4} key={val.id}>
              <div className={styles.whyusboox}>
                {/* <h5>{val.id}</h5> */}
              <h4>{val.heading}</h4>
              <div className={styles.whubody}>
              <p>{val.des}</p>
              </div>
              </div> 
             </Grid>
          ))} 
          
          </Grid>
           

          <div class={styles.btn_div1}><button onClick={handleSignupButton} class="apply_btn">Apply Now</button></div>
            </div>
          
          </Container>
          </section>
          
          <section className={styles.sec_scexcl}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
    <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>FAQ</h3>
          </div>
             <div className={`${styles.accordsc} ${styles.faqx}`}>
             {faq_data.map((item) => (
      <Accordion
        key={item.id}
        expanded={expanded === `panel${item.id}`}
        onChange={handleChange(`panel${item.id}`)}
        sx={{
          "& .MuiAccordionSummary-contentGutters": {
            margin: "10px 0", // Adjust as needed
          },
          "&.MuiPaper-root": {
            boxShadow: "none",
            borderBottom: "1px solid #D9DBE9",
            marginBottom:"0",
            borderBottomLeftRadius:"0",
            borderBottomRightRadius:"0",
          },
          "&.MuiAccordion-root::before":{
            display:"none",
          },
          "& .Mui-expanded": {
            minHeight:"auto !important",
            margin:"0 0 10px 0 !important"
          },
          "& .MuiAccordionSummary-expandIconWrapper":{
            background:"#B7B7B7",
            opacity:".3",
            textAlign: "center",
            borderRadius:"8px",
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
            background:"#1C1C1C",
            opacity:"1",
            color:"#fff",
          },
          "& .MuiAccordionDetails-root":{
            paddingTop:"0"
          },
          
        }}// Handle expansion state
      >
        <AccordionSummary
          // expandIcon={
          //   expanded === `panel${item.id}` ? (
          //     <RemoveIcon  />
          //   ) : (
          //     <AddIcon/>
          //   )
          // }
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${item.id}bh-content`}
          id={`panel${item.id}bh-header`}
          className={styles.accordian_show}
        >
           
          <h3 className={styles.faqhdr}>
            {expanded === `panel${item.id}` }
            {/* && <div className={styles.accodian_no}>{item.id}</div> */}
            {item.question}
          </h3>
        </AccordionSummary>
        <AccordionDetails >
        <div className={styles.acpra} dangerouslySetInnerHTML={{ __html: item.answer }} />
          {/* <p div dangerouslySetInnerHTML={{ __html: faq.answer }}>{item.answer}</p> */}
        </AccordionDetails>
      </Accordion>
    ))}
             </div>
             <div className={styles.btnx_div}>
                           <button
                             variant="contained"
                             onClick={() => handleNavigate('/faq')}
                             className="apply_btn"
                           >More FAQ's
                           </button>
                         </div>
          </Container>
          </section>
        
      </React.Fragment>
      {/* <Footer /> */}
    </>
  );
}

export default Home;
