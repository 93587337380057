import isEmpty from "./isEmpty";


const validationForSecondFormArt = (uploadArt,category,selectedColors) => {
  const errors = {};

  console.log("uploadArt",uploadArt)
  // Validate category_id
  if (!uploadArt.category_id.trim()) {
    errors.category_id = "Category is required";
  }

  // Validate subject_id
  if (!uploadArt.subject_id.trim()) {
    errors.subject_id = "Subject is required";
  }

  // Validate material_id
  if (!Array.isArray(uploadArt.material_id) || uploadArt.material_id.length === 0) {
    errors.material_id = "Material is required";
  }

  // Validate style_id
  if (!Array.isArray(uploadArt.style_id) || uploadArt.style_id.length === 0) {
    errors.style_id = "Style is required";
  }

  // Validate height
  const height = String(uploadArt.height).trim();
  if (height === "" || isNaN(Number(height))) {
    errors.height = "Height is required and must be a number";
  }

  const width = String(uploadArt.width).trim();
  if (width === "" || isNaN(Number(width))) {
    errors.width = "Width is required and must be a number";
  }

  // Validate depth
  const depth = String(uploadArt.depth).trim();
  if (depth === "" || isNaN(Number(depth))) {
    errors.depth = "Depth is required and must be a number";
  }
  // Validate year
  if (uploadArt.year === null || isNaN(Number(uploadArt.year))) {
    errors.year = "Year is required and must be a number";
  }

  // Validate medium
  if (!Array.isArray(uploadArt.medium_id) || uploadArt.medium_id.length === 0) {
    errors.medium_id = "Medium is required";
  }

  // Validate keywords
  if (!Array.isArray(uploadArt.keywords) || uploadArt.keywords.length < 3 || uploadArt.keywords.length > 12) {
    errors.keywords = "Please enter between 3 and 12 keywords";
  }

  // Validate description
  if (typeof uploadArt.description !== 'string' || uploadArt.description.trim().length < 50) {
    errors.description = "Description is required and must be at least 50 characters long";
  }

  // Validate colors
  // if (!Array.isArray(uploadArt.colors) || uploadArt.colors.length === 0) {
  //   errors.colors = "Color is required";
  // }

  if (!Array.isArray(selectedColors) || selectedColors.length === 0) {
    errors.colors = "Color is required";
  }
  

   if(!["Sculpture",
    "Crafts",
    "Installation"].includes(category)) {
  // Validate orientation
  if (!uploadArt.orientation || !uploadArt.orientation.trim()) {
    errors.orientation = "Orientation is required";
  }
    }


  return {
    secondFormErrors: errors,
    isValidSecondForm: isEmpty(errors),
  };
};


export default validationForSecondFormArt