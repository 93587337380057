import validator from "validator"
import isEmpty from "./isEmpty"



export const ValidationAdminExhibition = (exhibition) => {
  console.log("exhibtionValidation",exhibition)
  let exhibitionErrors = {}

  if (validator.isEmpty(exhibition.title)) {
    exhibitionErrors.title = "Exhibition Title is required"
  }
  
  if (validator.isEmpty(exhibition.concept)) {
    exhibitionErrors.concept = "Concept message is required"
  }
  
 

  if (validator.isEmpty(exhibition.start_date)) {
    exhibitionErrors.start_date = "Start Date is required"
  }
  if (validator.isEmpty(exhibition.end_date)) {
    exhibitionErrors.end_date = "End Date is required"
  }
  if (validator.isEmpty(exhibition.end_date_registration)) {
    exhibitionErrors.end_date_registration = "End Date Receving Application is required"
  }

  if (validator.isEmpty(exhibition.chief_guest)) {
    exhibitionErrors.chief_guest = "Cheif Guest is required"
  }

  if (validator.isEmpty(exhibition.city)) {
    exhibitionErrors.city = "Place is required"
  }

  if (validator.isEmpty(exhibition.venue_address)) {
    exhibitionErrors.venue_address = "Venue is required"
  }

  if (exhibition.is_paid === true) {
    if (
      exhibition.price_per_art === undefined ||
      exhibition.price_per_art === null ||
      exhibition.price_per_art === "" ||
      Number(exhibition.price_per_art) <= 0
    ) {
      exhibitionErrors.price_per_art = "Price per artwork required";
    }
  } else {
    delete exhibitionErrors.price_per_art;
  }
  
  
  

  // if (validator.isEmpty(exhibition.artist_emails)) {
  //   exhibitionErrors.artist_emails = "Invite Artist is required"
  // }

  if (!Array.isArray(exhibition.artist_emails) || exhibition.artist_emails.length === 0) {
    exhibitionErrors.artist_emails = "At least one artist email is required";
  } else {
    // Validate each email in the array
    const invalidEmails = exhibition.artist_emails.filter((email) => !validator.isEmail(email));
    if (invalidEmails.length > 0) {
      exhibitionErrors.artist_emails = "Please enter valid  email address";
    }
  }

  if (validator.isEmpty(String(exhibition.curator_id))) {
    exhibitionErrors.curator_id = "Invite Specific Curator is required"
  }
  return {
    exhibitionErrors,
    isValid: isEmpty(exhibitionErrors),
  }
}
