import React, { useEffect, useState } from "react";
import styles from "./artisttestimonal.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  editTestimonial,
  fetchTestimonialData,
  handleAddTestimonial,
  handleFetchCuratorProfile,
} from "../../../State/api/superadminApi";
import { handleFetchArtistProfile } from "../../../State/api/artistapi";
import { Successnotify } from "../../../common/alert/Alert";
import { ToastContainer } from "react-toastify";
function ArtistTestimonial() {
  const [data, setData] = useState({ offset: 0 });
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [mode, setMode] = useState("add"); // "add" or "edit"
  const [isActive, setIsActive] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [position, setPosition] = useState("");

  const [error, setError] = useState("");
  const [positionError, setPositionError] = useState("");

  const validateDescription = (value) => {
    if (!value.trim()) {
      setError("Message is required.");
      return false;
    }
    if (value.length < 10) {
      setError("Message must be at least 10 characters long.");
      return false;
    }
    setError(""); // No errors
    return true;
  };

  const validatePosition = (value) => {
    if (!value.trim()) {
      setPositionError("Position is required.");
      return false;
    }
    if (value.length < 3) {
      setPositionError("Position must be at least 3 characters long.");
      return false;
    }
    setPositionError(""); // No errors
    return true;
  };

  const fetchData = () => {
    dispatch(fetchTestimonialData(data));
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  useEffect(() => {
    dispatch(handleFetchCuratorProfile());
    dispatch(handleFetchArtistProfile());
  }, [dispatch]);

  const testdata = useSelector((state) => state.testmonialdata.data);
  const ArtistDetails = useSelector((state) => state.artistdetails.data);

  console.log("ArtistDetails", ArtistDetails);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
    validateDescription(value); // Validate while typing
  };

  const handlePositionChange = (e) => {
    const value = e.target.value;
    setPosition(value);
    validatePosition(value);
  };

  useEffect(() => {
    if (testdata?.data && testdata.data.length > 0) {
      const firstTestimonial = testdata.data[0];
      setDescription(firstTestimonial.description || "");
      setPosition(firstTestimonial.position || "");
      setIsActive(firstTestimonial.is_active === "Active");
      setMode("edit"); // Switch to edit mode if data exists
      setCurrentId(firstTestimonial._id); // Capture the ID for editing
    } else {
      setMode("add"); // Default to add mode if no data exists
    }
  }, [testdata]);

  const handleSubmit = () => {
    const isDescriptionValid = validateDescription(description);
    const isPositionValid = validatePosition(position);

    if (isDescriptionValid && isPositionValid) {
      const data = {
        customer_name: ArtistDetails?.data?.artist_name || "",
        description,
        position,
        ...(mode === "add" && { image: ArtistDetails?.data?.image || null }),
        ...(mode === "edit" && { is_active: isActive ? "Active" : "Inactive" }),
      };

      if (mode === "add") {
        dispatch(handleAddTestimonial(data, () => {}, fetchData));       
      } else if (mode === "edit") {
        dispatch(editTestimonial(currentId, data, () => {}, fetchData));       
      }
    }
  };

  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <div>
          <div className="form-group">
            <label>Position *</label>
            <input
              className="form-control"
              placeholder="Enter your position"
              value={position}
              onChange={handlePositionChange}
            />
            {positionError && <small className="text-danger">{positionError}</small>}
          </div>

          <div className="form-group">
            <label>Your Message *</label>
            <textarea
              rows={8}
              placeholder="Type your message here..."
              value={description}
              onChange={handleDescriptionChange}
              className="form-control"
            />
            {error && <small className="text-danger">{error}</small>}
          </div>

          <button className="action_btn" onClick={handleSubmit}>
            {mode === "add" ? "Add Testimonial" : "Update Testimonial"}
          </button>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default ArtistTestimonial;
