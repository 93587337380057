import React, { useRef, useState, useEffect } from "react"
import { Link } from 'react-router-dom';
import styles from "./SliderCarousel.module.css"
import StarIcon from "@mui/icons-material/Star"
import { Button, Container, Grid } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  setFilterByPrice,
  setFilterByPriceNav,
} from "../../../../State/Features/user/filterationSlice"
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai"
import { handelAddFollow, handelAddtocart, handelAddWishlist, handelUnFollow } from "../../../../State/api/userApi"
import { Errornotify } from "../../../../common/alert/Alert"
import CurrencyFormat from "../../../../common/countrycurrency/CurrencyFormat"
import capitalizeWords from "../../../../utils/capitalizeContent"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import {FiHeart, FiShoppingCart } from "react-icons/fi";
import { FaPlay } from "react-icons/fa";
// Swiper styles
import 'swiper/swiper-bundle.min.css';
import TranslateWrapper from "../../../../languageTranslator/TranslateWrapper";
function SliderCarousel({
  data,
  type,
  orderType,
  handleRecently,
  currency,
  formatNewCurrency,
}) {
  const [isHovered, setIsHovered] = useState(false)
  const navigate = useNavigate()
  const userty = useSelector((state) => state.user.data)
  const dispatch = useDispatch()
  const userDetail = useSelector((state) => state.buyerdetail.data)
  const allitemdt = useSelector((state) => state.allitemData.data)


  const carouselRef = useRef(null)
  let resetTimeout

  const breakPoints1 = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 800, itemsToShow: 4 },
  ]
  const breakPoints2 = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 800, itemsToShow: 1 },
  ]

  const handleNavigateCategory = (id) => {
    const searchParams = new URLSearchParams()
    searchParams.set("category", id)

    const newSearch = searchParams.toString()
      ? `?${decodeURIComponent(searchParams.toString())}`
      : ""

    navigate(`/user/search${newSearch}`)
  }

  const handleRecentlyView = (id) => {
    const recent = localStorage.getItem("recently_view")
    if (recent) {
      if (!recent.includes(id)) {
        const r = JSON.parse(recent) || []
        r.push(id)
        localStorage.setItem("recently_view", JSON.stringify(r))
      } else {
        const include = JSON.parse(recent) || []
        const index = include.indexOf(id)
        include.splice(index, 1)
        include.push(id)
        localStorage.setItem("recently_view", JSON.stringify(include))
      }
    } else {
      const recently = [id]
      localStorage.setItem("recently_view", JSON.stringify(recently))
    }
  }
  const handleNavigateNewestPainting = (id) => {
    const recent = localStorage.getItem("recently_view")
    // if (recent) {
    //   if (!recent.includes(id)) {
    //     const r = JSON.parse(recent) || []
    //     r.push(id)
    //     localStorage.setItem("recently_view", JSON.stringify(r))
    //   }
    //   else {
    //     const include = JSON.parse(recent) || []
    //     const index = include.indexOf(id);

    //     include.splice(index, 1);
    //     include.push(id)
    //     localStorage.setItem("recently_view", JSON.stringify(include))
    //   }
    // } else {
    //   const recently = [id]
    //   localStorage.setItem("recently_view", JSON.stringify(recently))
    // }
    // handleRecently(id);
    navigate(`/user/newestpaintings/${id}`)
  }

  const handleNavigateFollow = (id) => {
    console.log(id,'id')
    navigate(`/user/followed/${id}`)
  }

  const handleNavigateRecently = (id) => {
    navigate(`/user/recently_view/${id}`)
  }
  const handleRouteArtist = (id) => {
    window.location.href = `/user/artist/${id}`
  }
  const handleClickMostPopular = (id) => {
    navigate(`/user/mostpopular/${id}`)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  // const handleFilterByPrice = (value) => {
  //   navigate("/user/search")
  //   // dispatch(setFilterByPriceNav(value));
  //   dispatch(setFilterByPrice(value))
  // }
  const handleFilterByPrice = (value) => {
    const searchParams = new URLSearchParams();
    searchParams.set("price-range", `0-${value}`);
  
    const newSearch = searchParams.toString()
      ? `?${decodeURIComponent(searchParams.toString())}`
      : "";
  
    navigate(`/user/search${newSearch}`);
  } 
  
  const handleAddFollowArtist = (type, id, artist_name) => {
    const user_id = localStorage.getItem("user_id")
    if (!user_id) {
      Errornotify("Please Login First")
    } else if (type === "follow") {
      dispatch(handelAddFollow({ user_id: id }, artist_name))
    } else {
      dispatch(handelUnFollow({ user_id: id }, artist_name))
    }
  }
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === "PREV" ? (
        <div
          className={styles.right_actions}
          style={{
            position: "absolute",
            left: "10px", // Align left for the "PREV" arrow
            zIndex: 100,
          }}
        >
          <button
            type="button"
            className={isEdge ? styles.right_inactive : styles.right_active}
          >
            <AiOutlineLeft size={20} />
          </button>
        </div>
      ) : (
        <div
          className={styles.right_actions}
          style={{
            position: "absolute",
            right: "50px", // Align right for the "NEXT" arrow
            zIndex: 100,
          }}
        >
          <button
            type="button"
            className={isEdge ? styles.right_inactive : styles.right_active}
          >
            <AiOutlineRight size={20} />
          </button>
        </div>
      )

    return (
      <Button
        sx={{
          display: { xs: "none", sm: "block" },
          // Prevent button from taking unnecessary width
          width: "fit-content", // Let button shrink to fit content
          padding: 0, // Remove extra padding if any
        }}
        className={styles.carousel_btn}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </Button>
    );
  };
  const user_id = localStorage.getItem("user_id")
  const mac_Address = localStorage.getItem("device_id")

  const handleAddCart = async (id,orderTypeId) => {
    const val = id
    if (userty.signed === false) {
      Errornotify("Please login ")

    } else {
      dispatch(
        handelAddtocart(
          {
            item_id: val,
            orderType_id: orderType,
            user_id: user_id,
  
            quantity: 1,
            IP_address: mac_Address,
          },
          { offset: 1, user_id: localStorage.getItem("user_id") }
        )
      )
    }
   

 
 
    
  }

  const handleWishlist = (id) => {
    if (userty.signed === false) {
      Errornotify("Please login ")

    } else {
      dispatch(handelAddWishlist({ item_id: id }))
    }
  } 

  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const [isLoopEnabled, setIsLoopEnabled] = useState(false);

  // Check the screen width to set the loop condition dynamically
  useEffect(() => {
    const updateLoopCondition = () => {
      if (window.innerWidth < 480) {
        // Example: Loop only if more than 4 items for small screens
        setIsLoopEnabled(data?.length > 0);
      } else if (window.innerWidth >= 480 && window.innerWidth < 768) {
        // Example: Loop only if more than 4 items for medium screens
        setIsLoopEnabled(data?.length > 2);
      } else {
        // For large screens, you can choose whether to loop based on data length
        setIsLoopEnabled(data?.length > 4);
      }
    };

    // Initial check
    updateLoopCondition();

    // Update on window resize
    window.addEventListener('resize', updateLoopCondition);
    
    // Clean up the event listener when component unmounts
    return () => window.removeEventListener('resize', updateLoopCondition);
  }, [data?.length]); // Re-run whenever data changes
  
  return (
    <>
      { type === "hero" ? (
        <section className={`hero-slider ${styles.sections}`}>
           <Swiper
           modules={[Navigation, Pagination]} // Declare required modules here
           slidesPerView={1}
           loop={true}
           navigation={true}
           pagination={false}
            
           breakpoints={{
             1024: {
               slidesPerView: 1,
             },
             768: {
               slidesPerView: 1,
             },
             480: {
               slidesPerView: 1,
             },
           }}
         >
            {data &&
              data.map((item, index) => {
                return item.position === "" ? (
                  <SwiperSlide>
                  <div key={index} className={styles.homepage__LandingPage}
                    style={{ backgroundImage: `url(${item?.image})` }}>
                      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
                      <div className={styles.landingpage__Text}>
                        <h5><TranslateWrapper>{item.sub_title}</TranslateWrapper></h5>
                        <h3><TranslateWrapper>{item.title}</TranslateWrapper></h3>
                        <button  onClick={() => {
                          if (item.link) {
                            window.location.href = item.link
                          } else {
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        }} className="apply_ltbtn">
                        <TranslateWrapper>{item.button_name || "Buy Now"}</TranslateWrapper>
                        </button>
                      </div>
                      </Container>
                 
                  </div>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide>
                  <div key={index} className={styles.homepage__LandingPage}
                   style={{ backgroundImage: `url(${item?.image})` }}
                  >
                  
                    <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
                    <div className={styles.landingpage__Text}>
                      <h5><TranslateWrapper>{capitalizeWords(item.sub_title)}</TranslateWrapper></h5>
                      <h3><TranslateWrapper>{capitalizeWords(item.title)}</TranslateWrapper></h3>
                      <button onClick={() => {
                          if (item.link) {
                            window.location.href = item.link
                          } else {
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        }}
                        className="apply_ltbtn"

                        ><TranslateWrapper>{item.button_name || "Buy Now"}</TranslateWrapper></button>
                      
                    </div>
                    </Container>
                  </div>
                  </SwiperSlide>
                )
                
              })}
          </Swiper>
        </section>
      ) : type === "price" ? (
        <section className={styles.sections}>
          <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1}
        spaceBetween={15}
        loop={true}
        navigation={true}
        pagination={false}
        autoplay={{
          delay: 3000, // Set autoplay delay in milliseconds (3 seconds here)
          disableOnInteraction: false, // Allows autoplay to continue after user interactions
        }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
        }}
      >
            
            {data &&
              data.map((item) => {
                return (
				<SwiperSlide>
                  <div
                    className={styles.price_card}
                    onClick={() => handleFilterByPrice(item.price)}
                  >
                    <div className={styles.price_img_div}>
                      {/* <div className={styles.main_img_div}> */}
                      <img
                        className={styles.price_img}
                        src={item.img}
                        width="100%"
                        height="100%"
                        alt="img"
                      />
                      {/* </div> */}
                    </div>
                    <div className={styles.by_price}>
                      <p><TranslateWrapper>UpTo {item.price}</TranslateWrapper></p>
                      <div className={styles.price_action1}>
                        {/* <h4 style={{ marginBottom: 0 }}>nitin</h4> */}
                        <FaPlay
                          variant="contained"
                          className={styles.trianglebtn}
                          //  onClick={() => {
                          //   alert("hello");
                          //  }}
                        ></FaPlay>
                      </div>
                    </div>
                  </div>
				  </SwiperSlide>
                )
              })}
           </Swiper>
        </section>
      ) : (
        <section className={styles.sections}>
         <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={type === "latestartwork" ? 1 : 1}
        spaceBetween={10}
        loop={isLoopEnabled}
        // loop={true}
        autoplay={{
          delay: 3000, // Set autoplay delay in milliseconds (3 seconds here)
          disableOnInteraction: false, // Allows autoplay to continue after user interactions
        }}
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        breakpoints={{
          480: {
            slidesPerView: type === "latestartwork" ? 1 : 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: type === "latestartwork" ? 2 : 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: type === "latestartwork" ? 3 : 4,
            spaceBetween: 20,
          },
        }}
      >
            
            {data &&
              data.map((item, index) => {
                
                return type === "most_popular" ? (
                
                    <SwiperSlide>
                    <div
                    className={styles.card__main}
                    onClick={() => handleClickMostPopular(item._id)}
                  >
                    <div className={styles.card__top}>
                      <img
                        className={styles.card__Image}
                        src={item.image}
                        width="100%"
                        alt="img"
                      />
                    </div>
                    <div className={styles.pic_info}>
                      <div className={styles.card__middle}>
                        <span className={styles.titles}><TranslateWrapper>{capitalizeWords(item.name)}</TranslateWrapper></span>
                      </div>
                      <div className={styles.info_like}>
                        <div className={styles.wishlst}>
                          <FiHeart />
                        </div>
                        <span className={styles.like_count}>
                          {item.likesview?.likes.length || 0}
                        </span>
                      </div>
                    </div>
                    <p className={styles.names}>
                          <TranslateWrapper>{capitalizeWords(item.artist_id && item.artist_id.first_name)}</TranslateWrapper>{" "}
                          <TranslateWrapper>{capitalizeWords(item.artist_id && item.artist_id.last_name)}</TranslateWrapper>                          
                        </p>
                    <div className={styles.price_sec}>
                      <div className={styles.card__bottom}>
                        <span className={styles.buys}><TranslateWrapper>Buy</TranslateWrapper></span>
                        <span className={styles.prices}>
                        {item?.sale?.price ? `$ ${item?.sale?.price}` : "---"}
                        </span>
                      </div>
                     
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}><TranslateWrapper>Rent/Month</TranslateWrapper></span>
                            <span className={styles.prices}>
                            {item?.rent?.rent_price > 0 ? `$ ${item.rent.rent_price}` : "---"}
                           </span>
                          </div>
                        </>
                    
                    
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}><TranslateWrapper>Print</TranslateWrapper></span>
                            <span className={styles.prices}>
                            {item?.print?.[0]?.print_price > 0 ? 
                              `$ ${item?.print[0]?.print_price}`
                             : "---"}
                            </span>
                          </div>
                        </>
                    
                    </div>
                  </div>
                  </SwiperSlide>
                ) : type === "paintings" ? (

                    <SwiperSlide>
                  <div
                    className={styles.card__main}
                    onClick={() => handleNavigateNewestPainting(item._id)}
                    onMouseEnter={() => setIsHovered(item?._id)}
                    onMouseLeave={() => setIsHovered(item?._id)}
                  >
                    <div className={styles.card__top}>
                      <img
                        className={styles.card_Image}
                        src={item.image}
                        width="100%"
                        alt="img"
                      />
                    </div>

                    {isHovered === item?._id ? (
                      <>
                      <div className={styles.actnicons}>
                        <ul>
                          <li><Link href="#"
                           type="button"
                           onClick={(e) => {
                             e.preventDefault()
                             e.stopPropagation()
                             e.nativeEvent.stopPropagation();
                             handleWishlist(item._id)
                           }}><FiHeart sx={{ fontSize: "20px" }} /></Link></li>
                          <li><Link href="#"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            e.nativeEvent.stopPropagation();
                            handleAddCart(item._id, item.orderType_id)
                          }}> <FiShoppingCart sx={{ fontSize: "20px" }} /></Link></li>
                        </ul>
                      </div>
                       
                      </>
                    ) : null}

                    <div className={styles.pic_info}>
                      <div className={styles.card__middle}>
                        <span className={styles.titles}><TranslateWrapper>{capitalizeWords(item.name)}</TranslateWrapper></span>
                      </div>
                      <div className={styles.info_like}>
                      <div className={styles.wishlst}>
                          <FiHeart />
                        </div>
                        <span className={styles.like_count}>
                          {item?.likesview?.likes.length || 0}
                        </span>
                      </div>
                    </div>
                    <p className={styles.names}>
                          <TranslateWrapper>{capitalizeWords(item.artist_id && item.artist_id.first_name)}</TranslateWrapper>{" "}
                          <TranslateWrapper>{capitalizeWords(item.artist_id && item.artist_id.last_name)}</TranslateWrapper>
                        </p>
                    <div className={styles.price_sec}>
                      <div className={styles.card__bottom}>
                        <span className={styles.buys}><TranslateWrapper>Buy</TranslateWrapper></span>
                        <span className={styles.prices}>
                        {item?.sale?.price ? `$ ${item?.sale?.price}` : "---"}
                      </span>
                      </div>
                    
                        <div className={styles.hrs} />
                      <div style={{ textAlign: "center" }}>
                        <span className={styles.buys}><TranslateWrapper>Rent/Month</TranslateWrapper></span>
                        <span className={styles.prices}>
                        {item?.rent?.rent_price > 0 ? (
    `${formatNewCurrency(item.rent?.rent_price)} / ${item?.rent?.time || "month"}`
  ) :
    "---"
  }</span>
                      </div>
                      <div className={styles.hrs} />
                  
                      {/* {(item?.print[0]?.print_price || item?.print_by_artsmiley?.[0]?.print_price) && (
  <> 
    <div className={styles.hrs} /> 
  </>
)} */}
                      <div>
                        <span className={styles.buys}><TranslateWrapper>Print</TranslateWrapper></span>
                        <span className={styles.prices}>
                        {item?.print[0]?.print_price || item?.print_by_artsmiley?.[0]?.print_price ? (
    item?.print[0]?.print_price
      ? formatNewCurrency(item?.print[0]?.print_price)
      : formatNewCurrency(item?.print_by_artsmiley?.[0]?.print_price || 0)
  ) : "---"}
                        </span>
                      </div>
                     
                    </div>
                  </div>
                 </SwiperSlide>
                ) : type === "latestartwork" ? (
                  // < className={styles.carousel__main}>
                  // {newestpaintingData.map((item) => (
                    <SwiperSlide>
                  <div
                    className={styles.card__main}
                    onClick={() => handleNavigateNewestPainting(item._id)}
                    // onMouseEnter={() => setIsHovered(item?._id)}
                    // onMouseLeave={() => setIsHovered(item?._id)}
                  >
                    <div className={styles.card__top}>
                      <img
                        className={styles.card_Image}
                        src={item.image}
                        width="100%"
                        alt="img"
                      />
                    </div>

                    <div className={styles.pic_info}>
                      <div className={styles.card__middle}>
                        <span className={styles.titles}><TranslateWrapper>{item.name}</TranslateWrapper></span>
                       
                      </div>
                      <div className={styles.info_like}>
                      <div className={styles.wishlst}>
                          <FiHeart />
                        </div>
                        <span className={styles.like_count}>0</span>
                      </div>
                    </div>
                    <p className={styles.names}>
                          <TranslateWrapper>{item.artist_id && item.artist_id.first_name}</TranslateWrapper>{" "}
                          <TranslateWrapper>{item.artist_id && item.artist_id.last_name}</TranslateWrapper>
                        </p>
                    <div className={styles.price_sec}>
                      <div className={styles.card__bottom}>
                        <span className={styles.buys}><TranslateWrapper>Buy</TranslateWrapper></span>
                        <span className={styles.prices}>
                          {formatNewCurrency(item?.sale?.price)}
                        </span>
                      </div>
                      <div className={styles.hrs} />
                      <div style={{ textAlign: "center" }}>
                        <span className={styles.buys}><TranslateWrapper>Rent/Month</TranslateWrapper></span>
                        <span className={styles.prices}>
                          {item?.rent?.rent_price
                            ? `${formatNewCurrency(item.rent.rent_price)} / ${
                                item.rent?.time
                              }`
                            : `${formatNewCurrency(0)} / ${
                                item.rent?.time || "month"
                              }`}
                        </span>
                      </div>
                      <div className={styles.hrs} />
                      <div>
                        <span className={styles.buys}><TranslateWrapper>Print</TranslateWrapper></span>
                        <span className={styles.prices}>
                          {item?.print[0]?.print_price
                            ? formatNewCurrency(item?.print[0]?.print_price)
                            : formatNewCurrency(0)}
                        </span>
                      </div>
                    </div>
                  </div>
                  </SwiperSlide>
                ) : type === "curator" ? (
                  <SwiperSlide>
                  <div
                    className={styles.card__main}
                    // onClick={()=>handleNavigateNewestPainting(item._id)}
                  > 
                    <div className={styles.card__top}>
                      <img
                        className={styles.card__Image}
                        src={
                          Array.isArray(item?.item_ids) && item.item_ids.length > 0
                            ? item.item_ids[item.item_ids.length - 1]?._id?.image
                            : undefined
                        }
                        width="100%"
                        alt="img"
                      />
                    </div>
                    <div className={styles.pic_info}>
                      <div className={styles.card__middle}>
                        <span className={styles.titles}><TranslateWrapper>{item?.item_ids?.[0]?._id?.name}</TranslateWrapper></span>
                        
                      </div>
                      <div className={styles.info_like}>
                      <div className={styles.wishlst}>
                          <FiHeart />
                        </div>
                        <span className={styles.like_count}>0</span>
                      </div>
                    </div>
                    <p className={styles.names}>
                          <TranslateWrapper>{item?.item_ids?.[0]?._id?.artist_id?.first_name}</TranslateWrapper>{" "}
                          <TranslateWrapper>{item?.item_ids?.[0]?._id?.artist_id?.last_name}</TranslateWrapper>
                        </p>
                    <div className={styles.price_sec}>
                      <div className={styles.card__bottom}>
                        <span className={styles.buys}><TranslateWrapper>Buy</TranslateWrapper></span>
                        <span className={styles.prices}>
                      {item?.item_ids?.[0]?._id?.sale?.price ? `$ ${item?.item_ids?.[0]?._id?.sale?.price}` : "---" }
                        </span>
                      </div>
                      <div className={styles.hrs} />
                      <div>
                        <span className={styles.buys}><TranslateWrapper>Rent/Month</TranslateWrapper></span>
                        <span className={styles.prices}>
                        {item?.item_ids?.[0]?._id?.rent?.rent_price ? `$ ${item?.item_ids?.[0]?._id?.rent?.rent_price}` : "---"}
                      
                        </span>
                      </div>
                      <div className={styles.hrs} />
                      <div>
                        <span className={styles.buys}><TranslateWrapper>Print</TranslateWrapper></span>
                        <span className={styles.prices}>
                        {item?.item_ids?.[0]?._id?.print?.[0]?.print_price ? `$ ${item?.item_ids?.[0]?._id?.print?.[0]?.print_price}` : "---"}
                        </span>
                      </div>
                    </div>
                  </div>
                  </SwiperSlide>
                ) : type === "follow" ? (
                  <SwiperSlide>
                  <div
                    className={styles.card__main}
                    onClick={() => {
                      //console.log(item,'item')
                       handleNavigateFollow(item?._id)
                    }}
                  >
                    <div className={styles.card__top}>
                      <img
                        className={styles.card__Image}
                        src={item.image}
                        width="100%"
                        alt="img"
                      />
                    </div>
                    <div className={styles.pic_info}>
                      <div className={styles.card__middle}>
                        <span className={styles.titles}><TranslateWrapper>{item.name}</TranslateWrapper></span>
                        
                      </div>
                      <div className={styles.info_like}>
                      <div className={styles.wishlst}>
                          <FiHeart />
                        </div>
                        <span className={styles.like_count}>
                          {" "}
                          {item.likesview?.likes.length || 0}
                        </span>
                      </div>
                    </div>
                    <p className={styles.names}>
                          <TranslateWrapper>{item?.artist_id?.first_name}</TranslateWrapper>{" "}
                          <TranslateWrapper>{item?.artist_id?.last_name}</TranslateWrapper>
                        </p>
                    <div className={styles.price_sec}>
                      <div className={styles.card__bottom}>
                        <span className={styles.buys}><TranslateWrapper>Buy</TranslateWrapper></span>
                        <span className={styles.prices}>
                        {item.sale.price ? `$ ${item.sale.price}` : "---"}
                        </span>
                      </div>
                    
                        
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}><TranslateWrapper>Rent/Month</TranslateWrapper></span>
                            <span className={styles.prices}>
                            {item?.rent?.rent_price > 0 ? `$ ${item.rent.rent_price}` : "---"}
                            </span>
                          </div>
                        
                     
                   
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}><TranslateWrapper>Print</TranslateWrapper></span>
                            <span className={styles.prices}>
                            {item.print && item.print.length > 0
    ? `$ ${item.print[0].print_price}`
    : "---"}
                            </span>
                          </div>
                       
                    </div>
                  </div>
                  </SwiperSlide>
                ) : type === "recently" ? (
                  <SwiperSlide>
                  <div
                    className={styles.card__main}
                    onClick={() => handleNavigateRecently(item?.item?._id)}
                  >
                    <div className={styles.card__top}>
                      <img
                        className={styles.card__Image}
                       
                        src={item.item?.image}
                        width="100%"
                        alt="img"
                      />
                    </div>
                    <div className={styles.pic_info}>
                      <div className={styles.card__middle}>
                        <span className={styles.titles}><TranslateWrapper>{item.item.name}</TranslateWrapper></span>
                        
                      </div>
                      <div className={styles.info_like}>
                      <div className={styles.wishlst}>
                          <FiHeart />
                        </div>
                        <span className={styles.like_count}>
                          {" "}
                          {item.likesview?.likes.length || 0}
                        </span>
                      </div>
                    </div>
                    <p className={styles.names}>
                          <TranslateWrapper>{item.artist && item.artist?.first_name}</TranslateWrapper>{" "}
                          <TranslateWrapper>{item?.artist && item?.artist?.last_name}</TranslateWrapper>
                        </p>
                        <div className={styles.price_sec}>
  <div className={styles.card__bottom}>
    <span className={styles.buys}><TranslateWrapper>Buy</TranslateWrapper></span>
    <span className={styles.prices}>
      {item.item?.sale?.price ? `$ ${item.item.sale.price}` : "---"}
    </span>
  </div>
  <div className={styles.hrs} />

  <div>
    <span className={styles.buys}><TranslateWrapper>Rent/Month</TranslateWrapper></span>
    <span className={styles.prices}>
      {item.item?.rent?.rent_price ? `$ ${item.item.rent.rent_price}` : "---"}
    </span>
  </div>
  <div className={styles.hrs} />

  <div>
    <span className={styles.buys}><TranslateWrapper>Print</TranslateWrapper></span>
    <span className={styles.prices}>
      {item.item?.print?.length > 0 && item.item?.print[0]?.print_price 
        ? `$ ${item.item.print[0].print_price}` 
        : "---"}
    </span>
  </div>
</div>


                  </div>
                  </SwiperSlide>
                ) : type === "featured" ? (
                  <SwiperSlide>
                  <div className={styles.feature_card}>
                    <div className={styles.featured_img_div}>
                      <div className={styles.main_img_div}>
                        <img
                          src={
                            item.backgroundImage
                              ? item.backgroundImage
                              : "/img/artist_bg_alts.jpeg"
                          }
                          width="100%"
                          height="100%"
                          alt="img"
                        />
                      </div>
                     
                      <div className={styles.art_img_div}>
                        {allitemdt &&
                          allitemdt
                            .filter(
                              (itm) => itm?.artist_id?._id === item?.user_id
                            )
                            .slice(0, 3)
                            .map((val) => (
                              <div>
                                <img
                                  src={
                                    val.image
                                      ? val.image
                                      : "/img/profile_alt.jpg"
                                  }
                                  width="100%"
                                  height="100%"
                                  alt="img"
                                />
                              </div>
                            ))}
                      </div>
                     
                      <img
                        onClick={() => handleRouteArtist(item._id)}
                        className={styles.artist_img}
                        src={item.image ? item.image : "/img/profile_alt.jpg"}
                        width="100%"
                        height="100%"
                        alt="profile_img"
                      />
                    </div>
                    <div className={styles.feature_action}> 
                    <Grid  container spacing={2} sx={{justifyContent:"space-between",alignItems:"center"}}>
                    <Grid item xs={12} sm={6} md={6}><div className={styles.arname}><h4><TranslateWrapper>{capitalizeWords(item.artist_name)}</TranslateWrapper></h4></div></Grid>
                    <Grid item xs={12} sm={6} md={6}>
                    <div className={styles.btnme}>
                      {userDetail &&
                      userDetail.data.followings &&
                      userDetail.data.followings
                        .map((val) => val._id?._id)
                        .includes(item.user_id) ? (
                        <button
                          className="action_libtn"
                          onClick={() =>
                            handleAddFollowArtist(
                              "unfolow",
                              item.user_id,
                              item?.artist_name
                            )
                          }
                        >
                          <TranslateWrapper>Unfollow</TranslateWrapper>
                        </button>
                      ) : (
                        <button
                          className="action_btn"
                          onClick={() =>
                            handleAddFollowArtist(
                              "follow",
                              item.user_id,
                              item?.artist_name
                            )
                          }
                        >
                          <TranslateWrapper>Follow</TranslateWrapper>
                        </button>
                      )}
                      </div>
                    </Grid>
                    </Grid>
                      
                      
                      
                    
                    </div>
                  </div>
                  </SwiperSlide>
                ) 
                
                : type === "rating" ? (
                  <SwiperSlide>
                  <div className={styles.rating_main}>
                    <div className={styles.ratings}>
                      <div className={styles.rating_div}>
                        <StarIcon
                          sx={{
                            width: "12px",
                            height: "12px",
                            color: "white",
                            backgroundColor: "#00b67a",
                          }}
                        />
                        <StarIcon
                          sx={{
                            width: "12px",
                            height: "12px",
                            color: "white",
                            backgroundColor: "#00b67a",
                          }}
                        />
                        <StarIcon
                          sx={{
                            width: "12px",
                            height: "12px",
                            color: "white",
                            backgroundColor: "#00b67a",
                          }}
                        />
                        <StarIcon
                          sx={{
                            width: "12px",
                            height: "12px",
                            color: "white",
                            backgroundColor: "#00b67a",
                          }}
                        />
                        <StarIcon
                          sx={{
                            width: "12px",
                            height: "12px",
                            color: "white",
                            backgroundColor: "#00b67a",
                          }}
                        />
                      </div>
                      <h5>{item.duration}</h5>
                    </div>
                    <h2>{item.heading}</h2>
                    <p>{item.des}</p>
                    <h6>{item.name}</h6>
                    <p>{item.hint}</p>
                  </div>
                  </SwiperSlide>
                )
                : (
                  //            : type === "rating" ? (
                  //   <div className={styles.rating_main}>
                  //     <div className={styles.ratings}>
                  //       {/* <div className={styles.rating_div}>
                  //                                     <Rating
                  //                                         icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                  //                                         size="small"
                  //                                         max={1}
                  //                                         name="simple-controlled"
                  //                                         value={value}
                  //                                         sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  //                                         onChange={(event, newValue) => {
                  //                                             setValue(newValue);
                  //                                         }}
                  //                                     /><Rating
                  //                                         max={1} size="small" icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                  //                                         name="simple-controlled"
                  //                                         value={value}
                  //                                         sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  //                                         onChange={(event, newValue) => {
                  //                                             setValue(newValue);
                  //                                         }}
                  //                                     /><Rating
                  //                                         max={1} size="small"
                  //                                         name="simple-controlled"
                  //                                         value={value} icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                  //                                         sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  //                                         onChange={(event, newValue) => {
                  //                                             setValue(newValue);
                  //                                         }}
                  //                                     /><Rating
                  //                                         max={1} size="small"
                  //                                         name="simple-controlled"
                  //                                         value={value} icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                  //                                         sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  //                                         onChange={(event, newValue) => {
                  //                                             setValue(newValue);
                  //                                         }}
                  //                                     /><Rating
                  //                                         max={1} size="small"
                  //                                         name="simple-controlled"
                  //                                         value={value} icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                  //                                         sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  //                                         onChange={(event, newValue) => {
                  //                                             setValue(newValue);
                  //                                         }}
                  //                                     />
                  //                                 </div> */}
                  //       <div className={styles.rating_div}>
                  //         <StarIcon
                  //           sx={{
                  //             width: "15px",
                  //             height: "15px",
                  //             color: "white",
                  //             backgroundColor: "green",
                  //           }}
                  //         />
                  //         <StarIcon
                  //           sx={{
                  //             width: "15px",
                  //             height: "15px",
                  //             color: "white",
                  //             backgroundColor: "green",
                  //           }}
                  //         />
                  //         <StarIcon
                  //           sx={{
                  //             width: "15px",
                  //             height: "15px",
                  //             color: "white",
                  //             backgroundColor: "green",
                  //           }}
                  //         />
                  //         <StarIcon
                  //           sx={{
                  //             width: "15px",
                  //             height: "15px",
                  //             color: "white",
                  //             backgroundColor: "green",
                  //           }}
                  //         />
                  //         <StarIcon
                  //           sx={{
                  //             width: "15px",
                  //             height: "15px",
                  //             color: "white",
                  //             backgroundColor: "green",
                  //           }}
                  //         />
                  //       </div>
                  //       <h5> {item.duration} day ago</h5>
                  //     </div>
                  //     <h2>{item.heading}</h2>
                  //     <p>{item.des}</p>
                  //     <h6>{item.name}</h6>
                  //     <p>{item.hint}</p>
                  //   </div>
                  // )
                  <SwiperSlide>
                  <div
                    className={styles.category_card}
                    onClick={() => handleNavigateCategory(item?.name)}
                  >
                    <div className={styles.card__mai}>
                      <div className={styles.category_img_div}>
                        <img
                          src={item.image}
                          height="100%"
                          width="100%"
                          alt="img"
                        />
                      </div>
                      <div className={styles.category_text}>
                        <div className={styles.category_text_child}>
                          <h4><TranslateWrapper>{item.name}</TranslateWrapper></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  </SwiperSlide>
                )
              })}
          </Swiper>
         
          <div
        ref={prevButtonRef}
        className="swiper-button-prev"
      />
      <div
        ref={nextButtonRef}
        className="swiper-button-next"
      /> 
        </section>
      )}
    </>
  )
}
export default SliderCarousel
