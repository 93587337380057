import {
  Grid, Container, Link
} from "@mui/material";
import React from "react";
import styles from "./Catelog.module.css";
function Catelog() {
 
  return (
    <div className={styles.catelog}>
      <div className={styles.header}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Art Smiley catalogue: A new era in art discovery</h1>
              {/* <p>Art Smiley 2024 CATALOG: A NEW ERA IN ART DISCOVERY</p> */}
            </div>
        </Container>
        <div>
        </div>
      </div>
      <div className={styles.pdsec}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div>
          <div className={styles.spcebtnm}>
                    <Grid container spacing={2} sx={{justifyContent:"center", alignItems:"center"}}>
                    <Grid item md={6} sm={6} xs={12}>
                    <div className={styles.mobalign}>
                            <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
              <h3 className={styles.sec_title}>Discover The Best Artwork With Art Smiley Catalogue</h3>
              <p className={`${styles.text_left} ${styles.withor}`}>At Art Smiley, we’re redefining how art connects with collectors. Our catalogue doesn’t just showcase art-it tells the stories of our artists, highlighting their journeys, creative processes, and the cultural influences behind their work.</p>
              <p className={`${styles.text_left} ${styles.withor}`}>Explore the new wave of talent and trends shaping the future of art, all brought to you by Art Smiley’s curatorial experts, you can also browse our <Link href="/user/search">Online collection.</Link></p>
                          <div className={styles.btn_div1}>
                            <a className="apply_btn" target="_blank" rel="noopener noreferrer" href="/img/artsmiley_brochure_btb.pdf">View Catalogue</a>
                          </div>
                            </div>
                      </Grid>
                      <Grid item md={1} sm={1} xs={12}></Grid>
                      <Grid item md={3} sm={3} xs={12}>
                    <div className={styles.cataloglist} style={{marginBottom:"0"}}>
            <a href="/img/artsmiley_brochure_btb.pdf" target="_blank" rel="noopener noreferrer">
            <img src={"/img/artsmiley-marketplace.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>Art Smiley Marketplace for Art</p>
            </div>
            </a>
          </div>
                    </Grid>
                      </Grid>
                      </div>
        </div>
        </Container>
      </div>
      <section className={styles.sec_scexcl}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
    <div className={`${styles.text_center} ${styles.max_width}`}>
              
              </div>
              <div className={styles.mt2}>
              <Grid container spacing={2} sx={{justifyContent:"center", alignItems:"center"}}>
              <Grid item md={6} sm={6} xs={12}>
                <div>
                  <div className={styles.mobalign}>
                  <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
                  <h3 className={styles.sec_title}>Catalogue Highlights</h3>
                  </div>
                                    <ul className={styles.timeline}>
                                      <li>
                                      <div className={styles.stepli}>
                                    <h4>Emerging Artists to Watch</h4>
                                    <p>Explore fresh talent redefining contemporary art.</p>
                                    </div>
                                      </li>
                                      <li>
                                      <div className={styles.stepli}>
                                    <h4>Top Picks in Abstract Art</h4>
                                    <p>Bold, imaginative abstracts that ignite your space.</p>
                                    </div>
                                      </li>
                                      <li>
                                      <div className={styles.stepli}>
                                    <h4>Renowned Artists Worth the Investment</h4>
                                    <p>Timeless works from renowned artists with lasting value.</p>
                                    </div>
                                      </li>
                                      <li>
                                      <div className={styles.stepli}>
                                    <h4>Highlighting Bold Statement Pieces</h4>
                                    <p>Standout artworks that transform and elevate any room.</p>
                                    </div>
                                      </li>
                                    </ul>
                                  </div>
              </Grid>
              <Grid item md={1} sm={1} xs={12}></Grid>
              <Grid item md={3} sm={3} xs={12}>
              <div>
              <img src={"/img/artsmiley_brochure_btb.gif"} alt="mockup" className="img-fluid" />
              </div>
              </Grid>
      </Grid>
              </div>
  </Container>
      </section>
      <div className={styles.pdsec}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
    <div className={`${styles.text_center} ${styles.max_width}`}>
              <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
              <h3 className={styles.sec_title}>Art Catalogues</h3>
              <p className={styles.withor}>Explore our extensive catalogue, offering a wide variety of exceptional artworks. From best-selling pieces to exclusive creations, each section is thoughtfully organized to help you find the perfect art for your collection.</p>
              </div>
        <div>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-1.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-2.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-3.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-4.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-1.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        </Grid>
        </div>
        </Container>
      </div>
    </div>
  );
}
export default Catelog;