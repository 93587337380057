import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data:null
};
const ChatreplyDetailsSlice = createSlice({
    name:"inquirychatdetails",
    initialState,
       reducers: {        
            setenquirychatdetails: (state, action) => {
                state.data = action.payload;                            
            },          
        },
      
}) 
export const { setenquirychatdetails} = ChatreplyDetailsSlice.actions;
export default ChatreplyDetailsSlice.reducer;