import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess, Warnnoti } from "../../../../common/alert/Alert"
import {
  deletePress,
  editPress,
  fetchPressData,
  handleAddPress,
} from "../../../../State/api/superadminApi"
import { setPressrelease } from "../../../../State/Features/superadmin/pressreleaseSlice"
import Pagination from "../Pagination/Pagination"
import Addimgpopup from "../Popupdelete/addSubscription/addwithImage/Addimgpopup"
import Sixcolimg from "../Table/sixcolumn/Sixcolimg/Sixcolimg"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./Press.module.css"

const buyhead = [
  {
    id: "image ",
    label: "Image",
  },
  {
    id: "order_id",
    label: "Title",
  },
  {
    id: "date",
    label: "Date",
  },
  {
    id: "item",
    label: "Created By",
  },
  {
    id: "type",
    label: "Press Type",
  },
  {
    id: "amount",
    label: "Description",
  },
  {
    id:'action',
    label:'Action'
  }
]
const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
]
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
]
export default function Press() {
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [data, setData] = useState({ offset: 0 })
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editpress, setEditpress] = useState({
    title: "",
    item_type_id: "",
    image: "",
    description: "",
    link: "",
  })
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const dispatch = useDispatch()
  const pressdata = useSelector((state) => state.pressreleasedata.data)
  const fetchData = () => {
    dispatch(fetchPressData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])
  const handleAdd = (data) => {
    console.log(data)
    dispatch(handleAddPress(data, setOpen, fetchData))
  }
  const handleDelete = (id) => {
    Warnnoti("Are you sure?", () => {
      dispatch(deletePress(id, fetchData))
    });
   
  }
  const handleEditpopup = (dt) => {
    console.log(dt, "dt-=-")
    setEditpress({
      title: dt.title,
      image: dt.media,
      description: dt.description,
      press_type: dt.press_type,
      link:dt.link,
      _id: dt._id,
    })
    setEdit(true)
  }
  const handleEdit = (id, data) => {
    dispatch(editPress(id, data, setEdit, fetchData))
  }

  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })

    if (value === "name-asc") {
      dispatch(
        setPressrelease({
          data:
            pressdata.data &&
            [...pressdata.data].sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            ),
          totalCount: pressdata.totalCount,
        })
      )
    } else if (value === "name-desc") {
      dispatch(
        setPressrelease({
          data:
            pressdata.data &&
            [...pressdata.data].sort((a, b) =>
              a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1
            ),
          totalCount: pressdata.totalCount,
        })
      )
    } else {
      fetchData()
    }
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setPressrelease({
          data:
            pressdata.data &&
            [...pressdata.data].sort((a, b) => {
              if (a.date.toLowerCase() < b.date.toLowerCase()) return -1
            }),
          totalCount: pressdata.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setPressrelease({
          data:
            pressdata.data &&
            [...pressdata.data].sort((a, b) => {
              if (a.date.toLowerCase() > b.date.toLowerCase()) return -1
            }),
          totalCount: pressdata.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Addimgpopup
          handleAdd={handleAdd}
          add={true}
          poptype="pressrelease"
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Addimgpopup
          handleEdit={handleEdit}
          poptype="pressrelease"
          add={false}
          editpress={editpress}
          open={edit}
          setOpen={setEdit}
        />
      ) : (
        ""
      )}
      <div className={styles.compoents}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
        />
        <Sixcolimg
          handleEditpopup={handleEditpopup}
          handleDelete={handleDelete}
          activetab="press"
          head={buyhead}
          data={
            pressdata &&
            pressdata.data
              .filter((val) => {
                const search = condition.search.toLowerCase().trim()
                const nameMatch =
                  val.title && val.title.toLowerCase().includes(search)
                const createdby =
                  val.assign_by && val.assign_by.toLowerCase().includes(search)
                const type =
                  val.press_type &&
                  val.press_type.toLowerCase().includes(search)

                return nameMatch || createdby || type
              })
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          }
        />
        <Pagination
          totaldata={pressdata && pressdata.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  )
}
