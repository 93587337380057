import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    createTheme,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    ThemeProvider,
    Typography,
  } from "@mui/material"
  import React, { useEffect, useMemo, useState } from "react"
  import styles from "./ProductSidebar.module.css"
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
  import Slider from "@mui/material/Slider"
  import homeImage from "../../../constant"
  
  import CircularLoader from "../../../common/loader/Loader"
  import { useDispatch, useSelector } from "react-redux"
  
  import { Checkbox } from "@mui/material"
  import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked"
  import CircleCheckedFilled from "@material-ui/icons/CheckCircle"
  import { setLoader } from "../../../State/Features/common/loaderSlice"
  import api from "../../../State/api/Api"
  import {fetchArtsData, fetchOfferData} from "../../../State/api/superadminApi";
  import {
    fetchArtistTypes,
    fetchCategoryData,
    fetchProductType,
    fetchUserArtist,
  } from "../../../State/api/userApi"
  import serverimage from "../../../serverConstant"
  import {
    setArtistTypeFilter,
    setCategoryFilter,
    setColorFilter,
    setStyleFilter,
    setTypeFilter,
    setCountryFilter,
    setArtistFilter,
    setOrientationFilter,
    setFilterByPriceNav,
  } from "../../../State/Features/user/filterationSlice"
  import { CropDin, CropLandscape, CropPortrait } from "@mui/icons-material"
  import { handleFetchStyle } from "../../../State/api/superadminApi"
  import { useParams } from "react-router-dom"
  import { fetchAdminOffer } from "../../../State/api/artistapi"
  import { useSearchParams } from "react-router-dom";
  
  
  function ProductGallerySidebar(props) {
  
    console.log("Product Side Bar ",props);
    
    const { id } = useParams()
    function valuetext(value) {
      return `${value}°C`
    }
    function valuetext_height(value_height) {
      return `${value_height}°C`
    }
    function valuetext_width(value_width) {
      return `${value_width}°C`
    }
    const dispatch = useDispatch()
  
    const [countrydt, setCountrydt] = useState()
    const [orientation, setOrientation] = useState()
    const [colordt, setColordt] = useState()
   console.log("orientation",orientation);
   console.log("props.filter",props.filteredValues)
   
    const [activeIndex, setActiveIndex] = useState(null)
    const loader = useSelector((state) => state.loader)
    const productType = useSelector((state) => state.productType)
    const artistTypes = useSelector((state) => state.artistTypedata)
    const userartists = useSelector((state) => state.userartistData)
    const allcategory = useSelector((state) => state.allcategoryData)
    const filter = useSelector((state) => state.filteration)
    const startPrice = Number(props.filteredValues?.startPrice ?? 0)
    const endPrice = Number(props.filteredValues?.endPrice ?? 50000)
    const minHeight = useMemo(
      () => Number(props.filteredValues?.minHeight ?? 0),
      [props.filteredValues.minHeight]
    )
    const maxHeight = useMemo(() => {
      const value = Number(props.filteredValues.maxHeight)
      return value > 0 ? value : 1500 // Default to 1500 if maxHeight is 0 or not provided
    }, [props.filteredValues.maxHeight])
    const minWidth = useMemo(
      () => Number(props.filteredValues.minWidth ?? 0),
      [props.filteredValues.minWidth]
    )
    const maxWidth = useMemo(() => {
      const value = Number(props.filteredValues.maxWidth)
      return value > 0 ? value : 1500 // Default to 1500 if maxHeight is 0 or not provided
    }, [props.filteredValues.maxWidth])
    const priceValue = [startPrice, endPrice]
    const heightValue = useMemo(
      () => [minHeight, maxHeight],
      [minHeight, maxHeight]
    )
    const widthValue = useMemo(() => [minWidth, maxWidth], [minWidth, maxWidth])
    const handleChange = (event, newValue) => {
      if (newValue.length) {
        if (newValue[0] !== 0 || newValue[1] !== 50000) {
          props.setFilteredValues({
            ...props.filteredValues,
            startPrice: newValue[0],
            endPrice: newValue[1],
          })
        } else {
          props.setFilteredValues({
            ...props.filteredValues,
            startPrice: "0",
            endPrice: "50000",
          })
        }
        setTimeout(() => {
          window.scrollTo({
            top: 120,
            behavior: "smooth",
          })
        }, 5000)
      }
      dispatch(setFilterByPriceNav(newValue))
    }
    // const handleChange_height = (event, newValue) => {
    //   props.setValueHeight(newValue);
    // };
    const handleChange_height = (event, newValue) => {
      if (newValue.length) {
        if (newValue[0] !== 0 || newValue[1] !== 1500) {
          props.setFilteredValues({
            ...props.filteredValues,
            minHeight: newValue[0],
            maxHeight: newValue[1],
          })
        } else {
          props.setFilteredValues({
            ...props.filteredValues,
            minHeight: 0,
            maxHeight: 1500,
          })
        }
        setTimeout(() => {
          window.scrollTo({
            top: 120,
            behavior: "smooth",
          })
        }, 5000)
      }
      dispatch(setFilterByPriceNav(newValue))
    }
    const handleChange_width = (event, newValue) => {
      if (newValue.length) {
        if (newValue[0] !== 0 || newValue[1] !== 1500) {
          props.setFilteredValues({
            ...props.filteredValues,
            minWidth: newValue[0],
            maxWidth: newValue[1],
          })
        } else {
          props.setFilteredValues({
            ...props.filteredValues,
            minWidth: 0,
            maxWidth: 1500,
          })
        }
        setTimeout(() => {
          window.scrollTo({
            top: 120,
            behavior: "smooth",
          })
        }, 5000)
      }
    }
    const country = [
      { code: "AD", name: "Andorra", phone: "376" },
      {
        code: "AE",
        name: "United Arab Emirates",
        phone: "971",
      },
      { code: "AF", name: "Afghanistan", phone: "93" },
      {
        code: "AG",
        name: "Antigua and Barbuda",
        phone: "1-268",
      },
      { code: "AI", name: "Anguilla", phone: "1-264" },
      { code: "AL", name: "Albania", phone: "355" },
      { code: "AM", name: "Armenia", phone: "374" },
      { code: "AO", name: "Angola", phone: "244" },
      { code: "AQ", name: "Antarctica", phone: "672" },
      { code: "AR", name: "Argentina", phone: "54" },
      { code: "AS", name: "American Samoa", phone: "1-684" },
      { code: "AT", name: "Austria", phone: "43" },
      {
        code: "AU",
        name: "Australia",
        phone: "61",
        suggested: true,
      },
      { code: "AW", name: "Aruba", phone: "297" },
      { code: "AX", name: "Alland Islands", phone: "358" },
      { code: "AZ", name: "Azerbaijan", phone: "994" },
      {
        code: "BA",
        name: "Bosnia and Herzegovina",
        phone: "387",
      },
      { code: "BB", name: "Barbados", phone: "1-246" },
      { code: "BD", name: "Bangladesh", phone: "880" },
      { code: "BE", name: "Belgium", phone: "32" },
      { code: "BF", name: "Burkina Faso", phone: "226" },
      { code: "BG", name: "Bulgaria", phone: "359" },
      { code: "BH", name: "Bahrain", phone: "973" },
      { code: "BI", name: "Burundi", phone: "257" },
      { code: "BJ", name: "Benin", phone: "229" },
      { code: "BL", name: "Saint Barthelemy", phone: "590" },
      { code: "BM", name: "Bermuda", phone: "1-441" },
      { code: "BN", name: "Brunei Darussalam", phone: "673" },
      { code: "BO", name: "Bolivia", phone: "591" },
      { code: "BR", name: "Brazil", phone: "55" },
      { code: "BS", name: "Bahamas", phone: "1-242" },
      { code: "BT", name: "Bhutan", phone: "975" },
      { code: "BV", name: "Bouvet Island", phone: "47" },
      { code: "BW", name: "Botswana", phone: "267" },
      { code: "BY", name: "Belarus", phone: "375" },
      { code: "BZ", name: "Belize", phone: "501" },
      {
        code: "CA",
        name: "Canada",
        phone: "1",
        suggested: true,
      },
      {
        code: "CC",
        name: "Cocos (Keeling) Islands",
        phone: "61",
      },
      {
        code: "CD",
        name: "Congo, Democratic Republic of the",
        phone: "243",
      },
      {
        code: "CF",
        name: "Central African Republic",
        phone: "236",
      },
      {
        code: "CG",
        name: "Congo, Republic of the",
        phone: "242",
      },
      { code: "CH", name: "Switzerland", phone: "41" },
      { code: "CI", name: "Cote d'Ivoire", phone: "225" },
      { code: "CK", name: "Cook Islands", phone: "682" },
      { code: "CL", name: "Chile", phone: "56" },
      { code: "CM", name: "Cameroon", phone: "237" },
      { code: "CN", name: "China", phone: "86" },
      { code: "CO", name: "Colombia", phone: "57" },
      { code: "CR", name: "Costa Rica", phone: "506" },
      { code: "CU", name: "Cuba", phone: "53" },
      { code: "CV", name: "Cape Verde", phone: "238" },
      { code: "CW", name: "Curacao", phone: "599" },
      { code: "CX", name: "Christmas Island", phone: "61" },
      { code: "CY", name: "Cyprus", phone: "357" },
      { code: "CZ", name: "Czech Republic", phone: "420" },
      {
        code: "DE",
        name: "Germany",
        phone: "49",
        suggested: true,
      },
      { code: "DJ", name: "Djibouti", phone: "253" },
      { code: "DK", name: "Denmark", phone: "45" },
      { code: "DM", name: "Dominica", phone: "1-767" },
      {
        code: "DO",
        name: "Dominican Republic",
        phone: "1-809",
      },
      { code: "DZ", name: "Algeria", phone: "213" },
      { code: "EC", name: "Ecuador", phone: "593" },
      { code: "EE", name: "Estonia", phone: "372" },
      { code: "EG", name: "Egypt", phone: "20" },
      { code: "EH", name: "Western Sahara", phone: "212" },
      { code: "ER", name: "Eritrea", phone: "291" },
      { code: "ES", name: "Spain", phone: "34" },
      { code: "ET", name: "Ethiopia", phone: "251" },
      { code: "FI", name: "Finland", phone: "358" },
      { code: "FJ", name: "Fiji", phone: "679" },
      {
        code: "FK",
        name: "Falkland Islands (Malvinas)",
        phone: "500",
      },
      {
        code: "FM",
        name: "Micronesia, Federated States of",
        phone: "691",
      },
      { code: "FO", name: "Faroe Islands", phone: "298" },
      {
        code: "FR",
        name: "France",
        phone: "33",
        suggested: true,
      },
      { code: "GA", name: "Gabon", phone: "241" },
      { code: "GB", name: "United Kingdom", phone: "44" },
      { code: "GD", name: "Grenada", phone: "1-473" },
      { code: "GE", name: "Georgia", phone: "995" },
      { code: "GF", name: "French Guiana", phone: "594" },
      { code: "GG", name: "Guernsey", phone: "44" },
      { code: "GH", name: "Ghana", phone: "233" },
      { code: "GI", name: "Gibraltar", phone: "350" },
      { code: "GL", name: "Greenland", phone: "299" },
      { code: "GM", name: "Gambia", phone: "220" },
      { code: "GN", name: "Guinea", phone: "224" },
      { code: "GP", name: "Guadeloupe", phone: "590" },
      { code: "GQ", name: "Equatorial Guinea", phone: "240" },
      { code: "GR", name: "Greece", phone: "30" },
      {
        code: "GS",
        name: "South Georgia and the South Sandwich Islands",
        phone: "500",
      },
      { code: "GT", name: "Guatemala", phone: "502" },
      { code: "GU", name: "Guam", phone: "1-671" },
      { code: "GW", name: "Guinea-Bissau", phone: "245" },
      { code: "GY", name: "Guyana", phone: "592" },
      { code: "HK", name: "Hong Kong", phone: "852" },
      {
        code: "HM",
        name: "Heard Island and McDonald Islands",
        phone: "672",
      },
      { code: "HN", name: "Honduras", phone: "504" },
      { code: "HR", name: "Croatia", phone: "385" },
      { code: "HT", name: "Haiti", phone: "509" },
      { code: "HU", name: "Hungary", phone: "36" },
      { code: "ID", name: "Indonesia", phone: "62" },
      { code: "IE", name: "Ireland", phone: "353" },
      { code: "IL", name: "Israel", phone: "972" },
      { code: "IM", name: "Isle of Man", phone: "44" },
      { code: "IN", name: "India", phone: "91" },
      {
        code: "IO",
        name: "British Indian Ocean Territory",
        phone: "246",
      },
      { code: "IQ", name: "Iraq", phone: "964" },
      {
        code: "IR",
        name: "Iran, Islamic Republic of",
        phone: "98",
      },
      { code: "IS", name: "Iceland", phone: "354" },
      { code: "IT", name: "Italy", phone: "39" },
      { code: "JE", name: "Jersey", phone: "44" },
      { code: "JM", name: "Jamaica", phone: "1-876" },
      { code: "JO", name: "Jordan", phone: "962" },
      {
        code: "JP",
        name: "Japan",
        phone: "81",
        suggested: true,
      },
      { code: "KE", name: "Kenya", phone: "254" },
      { code: "KG", name: "Kyrgyzstan", phone: "996" },
      { code: "KH", name: "Cambodia", phone: "855" },
      { code: "KI", name: "Kiribati", phone: "686" },
      { code: "KM", name: "Comoros", phone: "269" },
      {
        code: "KN",
        name: "Saint Kitts and Nevis",
        phone: "1-869",
      },
      {
        code: "KP",
        name: "Korea, Democratic People's Republic of",
        phone: "850",
      },
      { code: "KR", name: "Korea, Republic of", phone: "82" },
      { code: "KW", name: "Kuwait", phone: "965" },
      { code: "KY", name: "Cayman Islands", phone: "1-345" },
      { code: "KZ", name: "Kazakhstan", phone: "7" },
      {
        code: "LA",
        name: "Lao People's Democratic Republic",
        phone: "856",
      },
      { code: "LB", name: "Lebanon", phone: "961" },
      { code: "LC", name: "Saint Lucia", phone: "1-758" },
      { code: "LI", name: "Liechtenstein", phone: "423" },
      { code: "LK", name: "Sri Lanka", phone: "94" },
      { code: "LR", name: "Liberia", phone: "231" },
      { code: "LS", name: "Lesotho", phone: "266" },
      { code: "LT", name: "Lithuania", phone: "370" },
      { code: "LU", name: "Luxembourg", phone: "352" },
      { code: "LV", name: "Latvia", phone: "371" },
      { code: "LY", name: "Libya", phone: "218" },
      { code: "MA", name: "Morocco", phone: "212" },
      { code: "MC", name: "Monaco", phone: "377" },
      {
        code: "MD",
        name: "Moldova, Republic of",
        phone: "373",
      },
      { code: "ME", name: "Montenegro", phone: "382" },
      {
        code: "MF",
        name: "Saint Martin (French part)",
        phone: "590",
      },
      { code: "MG", name: "Madagascar", phone: "261" },
      { code: "MH", name: "Marshall Islands", phone: "692" },
      {
        code: "MK",
        name: "Macedonia, the Former Yugoslav Republic of",
        phone: "389",
      },
      { code: "ML", name: "Mali", phone: "223" },
      { code: "MM", name: "Myanmar", phone: "95" },
      { code: "MN", name: "Mongolia", phone: "976" },
      { code: "MO", name: "Macao", phone: "853" },
      {
        code: "MP",
        name: "Northern Mariana Islands",
        phone: "1-670",
      },
      { code: "MQ", name: "Martinique", phone: "596" },
      { code: "MR", name: "Mauritania", phone: "222" },
      { code: "MS", name: "Montserrat", phone: "1-664" },
      { code: "MT", name: "Malta", phone: "356" },
      { code: "MU", name: "Mauritius", phone: "230" },
      { code: "MV", name: "Maldives", phone: "960" },
      { code: "MW", name: "Malawi", phone: "265" },
      { code: "MX", name: "Mexico", phone: "52" },
      { code: "MY", name: "Malaysia", phone: "60" },
      { code: "MZ", name: "Mozambique", phone: "258" },
      { code: "NA", name: "Namibia", phone: "264" },
      { code: "NC", name: "New Caledonia", phone: "687" },
      { code: "NE", name: "Niger", phone: "227" },
      { code: "NF", name: "Norfolk Island", phone: "672" },
      { code: "NG", name: "Nigeria", phone: "234" },
      { code: "NI", name: "Nicaragua", phone: "505" },
      { code: "NL", name: "Netherlands", phone: "31" },
      { code: "NO", name: "Norway", phone: "47" },
      { code: "NP", name: "Nepal", phone: "977" },
      { code: "NR", name: "Nauru", phone: "674" },
      { code: "NU", name: "Niue", phone: "683" },
      { code: "NZ", name: "New Zealand", phone: "64" },
      { code: "OM", name: "Oman", phone: "968" },
      { code: "PA", name: "Panama", phone: "507" },
      { code: "PE", name: "Peru", phone: "51" },
      { code: "PF", name: "French Polynesia", phone: "689" },
      { code: "PG", name: "Papua New Guinea", phone: "675" },
      { code: "PH", name: "Philippines", phone: "63" },
      { code: "PK", name: "Pakistan", phone: "92" },
      { code: "PL", name: "Poland", phone: "48" },
      {
        code: "PM",
        name: "Saint Pierre and Miquelon",
        phone: "508",
      },
      { code: "PN", name: "Pitcairn", phone: "870" },
      { code: "PR", name: "Puerto Rico", phone: "1" },
      {
        code: "PS",
        name: "Palestine, State of",
        phone: "970",
      },
      { code: "PT", name: "Portugal", phone: "351" },
      { code: "PW", name: "Palau", phone: "680" },
      { code: "PY", name: "Paraguay", phone: "595" },
      { code: "QA", name: "Qatar", phone: "974" },
      { code: "RE", name: "Reunion", phone: "262" },
      { code: "RO", name: "Romania", phone: "40" },
      { code: "RS", name: "Serbia", phone: "381" },
      { code: "RU", name: "Russian Federation", phone: "7" },
      { code: "RW", name: "Rwanda", phone: "250" },
      { code: "SA", name: "Saudi Arabia", phone: "966" },
      { code: "SB", name: "Solomon Islands", phone: "677" },
      { code: "SC", name: "Seychelles", phone: "248" },
      { code: "SD", name: "Sudan", phone: "249" },
      { code: "SE", name: "Sweden", phone: "46" },
      { code: "SG", name: "Singapore", phone: "65" },
      { code: "SH", name: "Saint Helena", phone: "290" },
      { code: "SI", name: "Slovenia", phone: "386" },
      {
        code: "SJ",
        name: "Svalbard and Jan Mayen",
        phone: "47",
      },
      { code: "SK", name: "Slovakia", phone: "421" },
      { code: "SL", name: "Sierra Leone", phone: "232" },
      { code: "SM", name: "San Marino", phone: "378" },
      { code: "SN", name: "Senegal", phone: "221" },
      { code: "SO", name: "Somalia", phone: "252" },
      { code: "SR", name: "Suriname", phone: "597" },
      { code: "SS", name: "South Sudan", phone: "211" },
      {
        code: "ST",
        name: "Sao Tome and Principe",
        phone: "239",
      },
      { code: "SV", name: "El Salvador", phone: "503" },
      {
        code: "SX",
        name: "Sint Maarten (Dutch part)",
        phone: "1-721",
      },
      {
        code: "SY",
        name: "Syrian Arab Republic",
        phone: "963",
      },
      { code: "SZ", name: "Swaziland", phone: "268" },
      {
        code: "TC",
        name: "Turks and Caicos Islands",
        phone: "1-649",
      },
      { code: "TD", name: "Chad", phone: "235" },
      {
        code: "TF",
        name: "French Southern Territories",
        phone: "262",
      },
      { code: "TG", name: "Togo", phone: "228" },
      { code: "TH", name: "Thailand", phone: "66" },
      { code: "TJ", name: "Tajikistan", phone: "992" },
      { code: "TK", name: "Tokelau", phone: "690" },
      { code: "TL", name: "Timor-Leste", phone: "670" },
      { code: "TM", name: "Turkmenistan", phone: "993" },
      { code: "TN", name: "Tunisia", phone: "216" },
      { code: "TO", name: "Tonga", phone: "676" },
      { code: "TR", name: "Turkey", phone: "90" },
      {
        code: "TT",
        name: "Trinidad and Tobago",
        phone: "1-868",
      },
      { code: "TV", name: "Tuvalu", phone: "688" },
      {
        code: "TW",
        name: "Taiwan, Province of China",
        phone: "886",
      },
      {
        code: "TZ",
        name: "United Republic of Tanzania",
        phone: "255",
      },
      { code: "UA", name: "Ukraine", phone: "380" },
      { code: "UG", name: "Uganda", phone: "256" },
      {
        code: "US",
        name: "United States",
        phone: "1",
        suggested: true,
      },
      { code: "UY", name: "Uruguay", phone: "598" },
      { code: "UZ", name: "Uzbekistan", phone: "998" },
      {
        code: "VA",
        name: "Holy See (Vatican City State)",
        phone: "379",
      },
      {
        code: "VC",
        name: "Saint Vincent and the Grenadines",
        phone: "1-784",
      },
      { code: "VE", name: "Venezuela", phone: "58" },
      {
        code: "VG",
        name: "British Virgin Islands",
        phone: "1-284",
      },
      {
        code: "VI",
        name: "US Virgin Islands",
        phone: "1-340",
      },
      { code: "VN", name: "Vietnam", phone: "84" },
      { code: "VU", name: "Vanuatu", phone: "678" },
      { code: "WF", name: "Wallis and Futuna", phone: "681" },
      { code: "WS", name: "Samoa", phone: "685" },
      { code: "XK", name: "Kosovo", phone: "383" },
      { code: "YE", name: "Yemen", phone: "967" },
      { code: "YT", name: "Mayotte", phone: "262" },
      { code: "ZA", name: "South Africa", phone: "27" },
      { code: "ZM", name: "Zambia", phone: "260" },
      { code: "ZW", name: "Zimbabwe", phone: "263" },
    ];
    const nationalities = [
      { code: "AD", name: "Andorran" },
      { code: "AE", name: "Emirati" },
      { code: "AF", name: "Afghan" },
      { code: "AG", name: "Antiguan or Barbudan" },
      { code: "AI", name: "Anguillian" },
      { code: "AL", name: "Albanian" },
      { code: "AM", name: "Armenian" },
      { code: "AO", name: "Angolan" },
      { code: "AQ", name: "Antarctican" },
      { code: "AR", name: "Argentine" },
      { code: "AS", name: "American Samoan" },
      { code: "AT", name: "Austrian" },
      { code: "AU", name: "Australian" },
      { code: "AW", name: "Aruban" },
      { code: "AX", name: "Ålander" },
      { code: "AZ", name: "Azerbaijani" },
      { code: "BA", name: "Bosnian" },
      { code: "BB", name: "Barbadian" },
      { code: "BD", name: "Bangladeshi" },
      { code: "BE", name: "Belgian" },
      { code: "BF", name: "Burkinabe" },
      { code: "BG", name: "Bulgarian" },
      { code: "BH", name: "Bahraini" },
      { code: "BI", name: "Burundian" },
      { code: "BJ", name: "Beninese" },
      { code: "BL", name: "Barthélemois" },
      { code: "BM", name: "Bermudian" },
      { code: "BN", name: "Bruneian" },
      { code: "BO", name: "Bolivian" },
      { code: "BR", name: "Brazilian" },
      { code: "BS", name: "Bahamian" },
      { code: "BT", name: "Bhutanese" },
      { code: "BV", name: "Bouvet Islander" },
      { code: "BW", name: "Botswana" },
      { code: "BY", name: "Belarusian" },
      { code: "BZ", name: "Belizean" },
      { code: "CA", name: "Canadian" },
      { code: "CC", name: "Cocos Islander" },
      { code: "CD", name: "Congolese (DRC)" },
      { code: "CF", name: "Central African" },
      { code: "CG", name: "Congolese (Republic)" },
      { code: "CH", name: "Swiss" },
      { code: "CI", name: "Ivorian" },
      { code: "CK", name: "Cook Islander" },
      { code: "CL", name: "Chilean" },
      { code: "CM", name: "Cameroonian" },
      { code: "CN", name: "Chinese" },
      { code: "CO", name: "Colombian" },
      { code: "CR", name: "Costa Rican" },
      { code: "CU", name: "Cuban" },
      { code: "CV", name: "Cape Verdean" },
      { code: "CW", name: "Curaçaoan" },
      { code: "CX", name: "Christmas Islander" },
      { code: "CY", name: "Cypriot" },
      { code: "CZ", name: "Czech" },
      { code: "DE", name: "German" },
      { code: "DJ", name: "Djiboutian" },
      { code: "DK", name: "Danish" },
      { code: "DM", name: "Dominican" },
      { code: "DO", name: "Dominican" },
      { code: "DZ", name: "Algerian" },
      { code: "EC", name: "Ecuadorian" },
      { code: "EE", name: "Estonian" },
      { code: "EG", name: "Egyptian" },
      { code: "EH", name: "Western Saharan" },
      { code: "ER", name: "Eritrean" },
      { code: "ES", name: "Spanish" },
      { code: "ET", name: "Ethiopian" },
      { code: "FI", name: "Finnish" },
      { code: "FJ", name: "Fijian" },
      { code: "FK", name: "Falkland Islander" },
      { code: "FM", name: "Micronesian" },
      { code: "FO", name: "Faroe Islander" },
      { code: "FR", name: "French" },
      { code: "GA", name: "Gabonese" },
      { code: "GB", name: "British" },
      { code: "GD", name: "Grenadian" },
      { code: "GE", name: "Georgian" },
      { code: "GF", name: "French Guianese" },
      { code: "GG", name: "Guernsey" },
      { code: "GH", name: "Ghanaian" },
      { code: "GI", name: "Gibraltarian" },
      { code: "GL", name: "Greenlandic" },
      { code: "GM", name: "Gambian" },
      { code: "GN", name: "Guinean" },
      { code: "GP", name: "Guadeloupean" },
      { code: "GQ", name: "Equatorial Guinean" },
      { code: "GR", name: "Greek" },
      { code: "GS", name: "South Georgian" },
      { code: "GT", name: "Guatemalan" },
      { code: "GU", name: "Guamanian" },
      { code: "GW", name: "Bissau-Guinean" },
      { code: "GY", name: "Guyanese" },
      { code: "HK", name: "Hong Konger" },
      { code: "HM", name: "Heard Islander" },
      { code: "HN", name: "Honduran" },
      { code: "HR", name: "Croatian" },
      { code: "HT", name: "Haitian" },
      { code: "HU", name: "Hungarian" },
      { code: "ID", name: "Indonesian" },
      { code: "IE", name: "Irish" },
      { code: "IL", name: "Israeli" },
      { code: "IM", name: "Manx" },
      { code: "IN", name: "Indian" },
      { code: "IO", name: "British Indian Ocean Territory" },
      { code: "IQ", name: "Iraqi" },
      { code: "IR", name: "Iranian" },
      { code: "IS", name: "Icelander" },
      { code: "IT", name: "Italian" },
      { code: "JE", name: "Jerseyman" },
      { code: "JM", name: "Jamaican" },
      { code: "JO", name: "Jordanian" },
      { code: "JP", name: "Japanese" },
      { code: "KE", name: "Kenyan" },
      { code: "KG", name: "Kyrgyzstani" },
      { code: "KH", name: "Cambodian" },
      { code: "KI", name: "Kiribati" },
      { code: "KM", name: "Comoran" },
      { code: "KN", name: "Kittitian or Nevisian" },
      { code: "KP", name: "North Korean" },
      { code: "KR", name: "South Korean" },
      { code: "KW", name: "Kuwaiti" },
      { code: "KY", name: "Caymanian" },
      { code: "KZ", name: "Kazakh" },
      { code: "LA", name: "Laotian" },
      { code: "LB", name: "Lebanese" },
      { code: "LC", name: "Saint Lucian" },
      { code: "LI", name: "Liechtensteiner" },
      { code: "LK", name: "Sri Lankan" },
      { code: "LR", name: "Liberian" },
      { code: "LS", name: "Basotho" },
      { code: "LT", name: "Lithuanian" },
      { code: "LU", name: "Luxembourger" },
      { code: "LV", name: "Latvian" },
      { code: "LY", name: "Libyan" },
      { code: "MA", name: "Moroccan" },
      { code: "MC", name: "Monegasque" },
      { code: "MD", name: "Moldovan" },
      { code: "ME", name: "Montenegrin" },
      { code: "MF", name: "Saint Martin" },
      { code: "MG", name: "Malagasy" },
      { code: "MH", name: "Marshallese" },
      { code: "MK", name: "Macedonian" },
      { code: "ML", name: "Malian" },
      { code: "MM", name: "Burmese" },
      { code: "MN", name: "Mongolian" },
      { code: "MO", name: "Macao" },
      { code: "MP", name: "Northern Mariana Islander" },
      { code: "MQ", name: "Martinican" },
      { code: "MR", name: "Mauritanian" },
      { code: "MS", name: "Montserratian" },
      { code: "MT", name: "Maltese" },
      { code: "MU", name: "Mauritian" },
      { code: "MV", name: "Maldivian" },
      { code: "MW", name: "Malawian" },
      { code: "MX", name: "Mexican" },
      { code: "MY", name: "Malaysian" },
      { code: "MZ", name: "Mozambican" },
      { code: "NA", name: "Namibian" },
      { code: "NC", name: "New Caledonian" },
      { code: "NE", name: "Nigerien" },
      { code: "NF", name: "Norfolk Islander" },
      { code: "NG", name: "Nigerian" },
      { code: "NI", name: "Nicaraguan" },
      { code: "NL", name: "Dutch" },
      { code: "NO", name: "Norwegian" },
      { code: "NP", name: "Nepali" },
      { code: "NR", name: "Nauruan" },
      { code: "NU", name: "Niuean" },
      { code: "NZ", name: "New Zealander" },
      { code: "OM", name: "Omani" },
      { code: "PA", name: "Panamanian" },
      { code: "PE", name: "Peruvian" },
      { code: "PF", name: "French Polynesian" },
      { code: "PG", name: "Papua New Guinean" },
      { code: "PH", name: "Filipino" },
      { code: "PK", name: "Pakistani" },
      { code: "PL", name: "Polish" },
      { code: "PM", name: "Saint-Pierrais or Miquelonnais" },
      { code: "PN", name: "Pitcairn Islander" },
      { code: "PR", name: "Puerto Rican" },
      { code: "PT", name: "Portuguese" },
      { code: "PW", name: "Palauan" },
      { code: "PY", name: "Paraguayan" },
      { code: "QA", name: "Qatari" },
      { code: "RE", name: "Réunionese" },
      { code: "RO", name: "Romanian" },
      { code: "RS", name: "Serbian" },
      { code: "RU", name: "Russian" },
      { code: "RW", name: "Rwandan" },
      { code: "SA", name: "Saudi Arabian" },
      { code: "SB", name: "Solomon Islander" },
      { code: "SC", name: "Seychellois" },
      { code: "SD", name: "Sudanese" },
      { code: "SE", name: "Swedish" },
      { code: "SG", name: "Singaporean" },
      { code: "SH", name: "Saint Helenian" },
      { code: "SI", name: "Slovenian" },
      { code: "SJ", name: "Svalbardian" },
      { code: "SK", name: "Slovak" },
      { code: "SL", name: "Sierra Leonean" },
      { code: "SM", name: "Sammarinese" },
      { code: "SN", name: "Senegalese" },
      { code: "SO", name: "Somali" },
      { code: "SR", name: "Surinamese" },
      { code: "SS", name: "South Sudanese" },
      { code: "ST", name: "Sao Tomean" },
      { code: "SV", name: "Salvadoran" },
      { code: "SX", name: "Sint Maarten" },
      { code: "SY", name: "Syrian" },
      { code: "SZ", name: "Swazi" },
      { code: "TC", name: "Turks and Caicos Islander" },
      { code: "TD", name: "Chadian" },
      { code: "TF", name: "French Southern and Antarctic Lands" },
      { code: "TG", name: "Togolese" },
      { code: "TH", name: "Thai" },
      { code: "TJ", name: "Tajikistani" },
      { code: "TK", name: "Tokelauan" },
      { code: "TL", name: "Timorese" },
      { code: "TM", name: "Turkmen" },
      { code: "TN", name: "Tunisian" },
      { code: "TO", name: "Tongan" },
      { code: "TR", name: "Turkish" },
      { code: "TT", name: "Trinidadian" },
      { code: "TV", name: "Tuvaluan" },
      { code: "TZ", name: "Tanzanian" },
      { code: "UA", name: "Ukrainian" },
      { code: "UG", name: "Ugandan" },
      { code: "UM", name: "U.S. Minor Outlying Islands" },
      { code: "UN", name: "United Nations" },
      { code: "US", name: "American" },
      { code: "UY", name: "Uruguayan" },
      { code: "UZ", name: "Uzbekistani" },
      { code: "VA", name: "Vatican" },
      { code: "VC", name: "Saint Vincentian" },
      { code: "VE", name: "Venezuelan" },
      { code: "VG", name: "British Virgin Islander" },
      { code: "VI", name: "American Virgin Islander" },
      { code: "VN", name: "Vietnamese" },
      { code: "VU", name: "Vanuatuan" },
      { code: "WF", name: "Wallis and Futunan" },
      { code: "WS", name: "Samoan" },
      { code: "YE", name: "Yemeni" },
      { code: "YT", name: "Mayottean" },
      { code: "ZA", name: "South African" },
      { code: "ZM", name: "Zambian" },
      { code: "ZW", name: "Zimbabwean" },
    ];
    const Materials = [
      { id: 1, name: "Acrylic" },
      { id: 2, name: "Bronze" },
      { id: 3, name: "Canvas" },
      { id: 4, name: "Carbon Fiber" },
      { id: 5, name: "Ceramic" },
      { id: 6, name: "Clay" },
      { id: 7, name: "Concrete" },
      { id: 8, name: "Copper" },
      { id: 9, name: "Cork" },
      { id: 10, name: "Corrugated Cardboard" },
      { id: 11, name: "Cotton Paper" },
      { id: 12, name: "Enamel" },
      { id: 13, name: "Fabric" },
      { id: 14, name: "Fiber" },
      { id: 15, name: "Fiberglass" },
      { id: 16, name: "Fine Art Paper" },
      { id: 17, name: "Found Objects" },
      { id: 18, name: "Glass" },
      { id: 19, name: "Gold" },
      { id: 20, name: "Granite" },
      { id: 21, name: "Graphite" },
      { id: 22, name: "Hardboard" },
      { id: 23, name: "Iron" },
      { id: 24, name: "LED" },
      { id: 25, name: "Leather" },
      { id: 26, name: "Linen" },
      { id: 27, name: "Marble" },
      { id: 28, name: "Masonite" },
      { id: 29, name: "MDF" },
      { id: 30, name: "Metal" },
      { id: 31, name: "Mosaic" },
      { id: 32, name: "Neon" },
      { id: 33, name: "Other" },
      { id: 34, name: "Panel" },
      { id: 35, name: "Paper" },
      { id: 36, name: "Paper Mache" },
      { id: 37, name: "Photo Paper" },
      { id: 38, name: "Plaster" },
      { id: 39, name: "Plastic" },
      { id: 40, name: "Plexiglass" },
      { id: 41, name: "Plywood" },
      { id: 42, name: "Porcelain" },
      { id: 43, name: "Pottery" },
      { id: 44, name: "Pressed Cardboard" },
      { id: 45, name: "Resin" },
      { id: 46, name: "Rubber" },
      { id: 47, name: "Sand" },
      { id: 48, name: "Silicone" },
      { id: 49, name: "Silk" },
      { id: 50, name: "Silver" },
      { id: 51, name: "Stainless Steel" },
      { id: 52, name: "Steel" },
      { id: 53, name: "Stone" },
      { id: 54, name: "Textile" },
      { id: 55, name: "Thread" },
      { id: 56, name: "Timber" },
      { id: 57, name: "Wax" },
      { id: 58, name: "Wood" },
      { id: 59, name: "Wool" },
      { id: 60, name: "Yarn" },
    ];
    const Editions = [
      { id: 1, name: "Limited Edition"},
      { id: 2, name: "Open Edition"},
    ]
    const theme = createTheme({
      palette: {
        secondary: {
          main: "#ccc",
        },
      },
      typography: {
        accordionheading: {
          fontSize: "14px",
          fontWeight: 500,
        },
        radioname: {
          fontSize: "0.95vw",
          fontWeight: "400",
        },
      },
    })
    const handleCountry = (e) => {
      const val = e.target.value
      dispatch(setCountryFilter(val))
    }
    const handleFetchCountry = async function () {
      dispatch(setLoader(true))
      try {
        const result = await api.applicationApi(`api/user/getcountry`)
        if (result && result.status === 200) {
          setCountrydt(result.data.response)
          dispatch(setLoader(false))
        } else if (result.status === 401) {
        }
      } catch (err) {
      }
    }
    const handleFetchOrientation = async function () {
      dispatch(setLoader(true))
      try {
        const result = await api.applicationApi(`api/user/getorientation`)
        if (result && result.status === 200) {
          setOrientation(result.data.response)
          dispatch(setLoader(false))
        } else if (result.status === 401) {
        }
      } catch (err) {
      }
    }
    const handleFetchColor = async function () {
      dispatch(setLoader(true))
      try {
        const result = await api.applicationApi(`api/user/getcolors`)
        if (result && result.status === 200) {
          setColordt(result.data.response)
          dispatch(setLoader(false))
        } else if (result.status === 401) {
        }
      } catch (err) {
      }
    }
    const handleChangeCheckbox = (e) => {
      const val = e.target.id
      dispatch(setTypeFilter(val))
      // const val = e.target.id;
      // const type = [...props.filter.type];
      // const index = props.filter.type.findIndex((item) => item === val);
      // if (index === -1) {
      //   type.push(val);
      //   props.setFilter({ ...props.filter, type: type });
      // } else {
      //   type.splice(index, 1);
      //   props.setFilter({ ...props.filter, type: type });
      // }
    }
    const handleArtistType = (e) => {
      const val = e.target.id
      dispatch(setArtistTypeFilter(val))
      // const val = e.target.value;
      // const artist_type = [...props.filter.artist_type];
      // const index = props.filter.artist_type.findIndex((item) => item === val);
      // if (index === -1) {
      //   artist_type.push(val);
      //   props.setFilter({ ...props.filter, artist_type: artist_type });
      // } else {
      //   artist_type.splice(index, 1);
      //   props.setFilter({ ...props.filter, artist_type: artist_type });
      // }
    }
    const handleFilterColor = (e) => {
      const val = e.target.id
      dispatch(setColorFilter(val))
    }
    const handleChangeStyle = (e) => {
      const val = e.target.value
      dispatch(setStyleFilter(val))
    }
    const handleFilterCategory = (e) => {
      const val = e.target.id
      if (val.length === 0) {
        dispatch(setCategoryFilter(val))
        dispatch(setStyleFilter([]))
      } else {
        dispatch(setStyleFilter([]))
        dispatch(setCategoryFilter(val))
        dispatch(handleFetchStyle({ category_id: val }, props.setStyle))
      }
    }
    const handleFilterArtist = (e) => {
      const val = e.target.id
      dispatch(setArtistFilter(val))
    }
    const [searchParams, setSearchParams] = useSearchParams();
  
    const handleChangeOrientation = (e) => {
      const value = e.target.id;
      dispatch(setOrientationFilter(value));
      const isChecked = e.target.checked;
      const newSearchParams = new URLSearchParams(searchParams);
      if (isChecked) {
        newSearchParams.set("orientation", value); // Set new orientation (only one)
      } else {
        newSearchParams.delete("orientation"); // Remove when unchecked
      }
    // Update or add the orientation filter
    newSearchParams.set("orientation", value);
    
      // Update search params
      searchParams.set("orientation", value);
      setSearchParams(searchParams);
    };
    // const [data, setData] = useState({ is_accepted: "Pending", offset: 1 })
      const [dataOffer, setDataOffer] = useState({ offset: 1 })
    const [dataArts, setDataArts] = useState({ is_accepted: "Approved", offset: 1 })
    const offerData = useSelector((state) => state.offerdata.data)
    const adminofferDt = useSelector((state) => state.adminOfferData.data);
    useEffect(() => {
      handleFetchCountry()
      dispatch(fetchAdminOffer(dataArts))
    //   dispatch(fetchArtsData(data))
      handleFetchColor()
      dispatch(fetchProductType())
      dispatch(fetchArtistTypes())
      dispatch(fetchUserArtist({}))
      dispatch(fetchCategoryData({}))
      handleFetchOrientation()
      id && dispatch(handleFetchStyle({ category_id: id }, props.setStyle))
    }, [])
    console.log("adminoffers", adminofferDt)
    const [searchQueryGalleryName, setSearchQueryGalleryName] = useState('');
    const [searchGalleryCountry, setSearchGalleryCountry] = useState("");
    const [searchGalleryNationality, setSearchGalleryNationality] = useState("");
    const [searchStyle, setSearchStyle] = useState("")
    const [searchSubject, setSearchSubject] = useState("")
    const [searchMedium, setSearchMedium] = useState("")
    const [searchMaterial, setSearchMaterial] = useState("");
    // Filter the artists based on the search query
    const filteredStyles = props.style ?
    props.style.filter(val =>
      val.name.toLowerCase()?.includes(searchStyle.toLowerCase())
    )
    : [];
    const filteredSubjects = props.subject ?
    props.subject.filter(val =>
      val.name.toLowerCase()?.includes(searchSubject.toLowerCase())
    )
    : [];
    const filteredMediums = props.medium ?
    props.medium.filter(val =>
      val.name.toLowerCase()?.includes(searchMedium.toLowerCase())
    )
    : [];
    const filteredCountries = country
      ? country.filter(val =>
        val.name.toLowerCase()?.includes(searchGalleryCountry.toLowerCase())
        )
      : [];
      const filteredNationality = nationalities
      ? nationalities.filter(val =>
        val.name.toLowerCase()?.includes(searchGalleryNationality.toLowerCase())
        )
      : [];
    const filteredGalleries = props.galleryListDt
      ? props.galleryListDt.filter(val =>
          val.gallery_name.toLowerCase()?.includes(searchQueryGalleryName.toLowerCase())
        )
      : [];
      const filteredMaterials = Materials
    ? Materials.filter(val =>
    val.name.toLowerCase()?.includes(searchMaterial.toLowerCase())
  )
  : [];
    return (
      <ThemeProvider theme={theme}>
        <div className={`filtersidebar ${styles.ProductSidebar}`}>    
          {window.location.href.includes("allcategory") ? (
            ""
          ) : (
            <>
              <Accordion
                defaultExpanded
                elevation={0}
                
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <h3>
                    Category
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : (                 
                    <div className={styles.radio__div}>
                      <ul className={styles.ctfilist}>
                      {allcategory.data &&
                        allcategory.data.allcategories.map((val) => (                          
                            <li key={val._id}>
                            <label htmlFor={val._id}>
                            <input type="checkbox"
                                  className={styles.checkbox_animated}
                                  name="categoryName"
                                  value={val.name}
                                  onChange={props.handleChangeFilteredValues}
                                  id={val._id}
                                  checked={props?.filteredValues?.categoryName?.includes(val.name)}
                                />
                              {val.name}
                              </label>
                          </li>
                                                   
                        ))}
                        </ul>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          )}
          <Accordion
            elevation={0}
            sx={{
              width: "100%",
              border: "none",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>
                Style
              </h3>
            </AccordionSummary>
            <AccordionDetails>
            <input
              type="text"
              placeholder="Search Style..."
              className={styles.searchStyleInput}
              value={searchStyle}
              onChange={(e) => setSearchStyle(e.target.value)}
            />
              {true ? (
                <div className={styles.radio__div}>
                   <ul className={styles.ctfilist1}>
                  {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : (
                    filteredStyles &&
                    filteredStyles.map((val) => (
                        <li key={val._id}>
                          <label htmlFor={val._id}>
                              <input  type="checkbox"
                                className={styles.checkbox_animated}
                                name="styleName"
                                value={val.name}
                                onChange={props.handleChangeFilteredValues}
                                checked={props?.filteredValues?.styleName === (val.name)}
                                id={val._id}
                              />
                            {val.name}
                            </label>
                        </li>
                    ))
                  )}
                  </ul>
                </div>
              ) : filter.category.length === 0 ? (
                "Please Select Category"
              ) : (
                <div className={styles.radio__div}>
                  <ul className={styles.ctfilist}>
                  {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : props.style && props.style.length === 0 ? (
                    "No props.style found"
                  ) : (
                    filteredStyles &&
                    filteredStyles.map((val) => (
                      <React.Fragment key={val._id}>
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          id={val._id}                     
                          type="checkbox"
                          name="styleName"
                          value={val.name}
                          onChange={props.handleChangeFilteredValues}
                          checked={props?.filteredValues?.styleName === (val.name)}
                          size="small"
                          color="primary" />
                        <label className={styles.radio__label} htmlFor="Abstract">
                          {val.name}
                        </label>
                        <br />
                      </React.Fragment>
                    ))
                  )}
                </ul>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{
              width: "100%",
              border: "none",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>
                Subject
              </h3>
            </AccordionSummary>
            <AccordionDetails>
            <input
              type="text"
              placeholder="Search Subject..."
              className={styles.searchStyleInput}
              value={searchSubject}
              onChange={(e) => setSearchSubject(e.target.value)}
            />
              {true ? (
                <div className={styles.radio__div}>
                   <ul className={styles.ctfilist1}>
                  {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : (
                    filteredSubjects &&
                    filteredSubjects.map((val) => (
                        <li key={val._id}>
                          <label htmlFor={val._id}>
                              <input  type="checkbox"
                                className={styles.checkbox_animated}
                                name="subjectName"
                                value={val.name}
                                onChange={props.handleChangeFilteredValues}
                                checked={props?.filteredValues?.subjectName === (val.name)}
                                id={val.name}
                              />
                            {val.name}
                            </label>
                        </li>
                    ))
                  )}
                  </ul>
                </div>
              ) : filter.category.length === 0 ? (
                "Please Select Category"
              ) : (
                <div className={styles.radio__div}>
                  <ul className={styles.ctfilist}>
                  {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : props.subject && props.subject.length === 0 ? (
                    "No props.subject found"
                  ) : (
                    filteredSubjects &&
                    filteredSubjects.map((val) => (
                      <React.Fragment key={val._id}>
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          id={val._id}                     
                          type="checkbox"
                          name="subjectName"
                          value={val.name}
                          onChange={props.handleChangeFilteredValues}
                          checked={props?.filteredValues?.subjectName === (val.name)}
                          size="small"
                          color="primary" />
                        <label className={styles.radio__label} htmlFor="Abstract">
                          {val.name}
                        </label>
                        <br />
                      </React.Fragment>
                    ))
                  )}
                </ul>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{
              width: "100%",
              border: "none",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>
                Medium
              </h3>
            </AccordionSummary>
            <AccordionDetails>
            <input
              type="text"
              placeholder="Search Medium..."
              className={styles.searchStyleInput}
              value={searchMedium}
              onChange={(e) => setSearchMedium(e.target.value)}
            />
              {true ? (
                <div className={styles.radio__div}>
                   <ul className={styles.ctfilist1}>
                  {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : (
                    filteredMediums &&
                    filteredMediums.map((val) => (
                        <li key={val._id}>
                          <label htmlFor={val._id}>
                              <input  type="checkbox"
                                className={styles.checkbox_animated}
                                name="mediumName"
                                value={val.name}
                                onChange={props.handleChangeFilteredValues}
                                checked={props?.filteredValues?.mediumName === (val.name)}
                                id={val.name}
                              />
                            {val.name}
                            </label>
                        </li>
                    ))
                  )}
                  </ul>
                </div>
              ) : filter.category.length === 0 ? (
                "Please Select Category"
              ) : (
                <div className={styles.radio__div}>
                  <ul className={styles.ctfilist}>
                  {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : props.medium && props.medium.length === 0 ? (
                    "No props.medium found"
                  ) : (
                    filteredMediums &&
                    filteredMediums.map((val) => (
                      <React.Fragment key={val._id}>
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          id={val._id}                     
                          type="checkbox"
                          name="mediumName"
                          value={val.name}
                          onChange={props.handleChangeFilteredValues}
                          checked={props?.filteredValues?.mediumName === (val.name)}
                          size="small"
                          color="primary" />
                        <label className={styles.radio__label} htmlFor="Abstract">
                          {val.name}
                        </label>
                        <br />
                      </React.Fragment>
                    ))
                  )}
                </ul>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
          elevation={0}
          sx={{
            width: "100%",
            border: "none",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>Material</h3>
            </AccordionSummary>
          <AccordionDetails>
          <input
            type="text"
            placeholder="Search Material..."
            className={styles.searchInput}
            value={searchMaterial}
            onChange={(e) => setSearchMaterial(e.target.value)}
          />
          <ul className={styles.ctfilist1}>
          {loader.loader ? (
                  <div className={styles.loader}>
                    <CircularLoader />
                  </div>
                ) : (
                  filteredMaterials &&
                  filteredMaterials?.map((val) => (
                <li key={val._id}>
                  <label htmlFor={val._id}>
                    <input 
                    type="checkbox"
                    className={styles.checkbox_animated}
                    name="materialName"
                    value={val.name}
                    onChange={props.handleChangeFilteredValues}
                    checked={props.filteredValues?.materialName === val.name}
                    id={val.name}
                     />
                     {val.name}
                  </label>
                </li>
              ))
                )}
          </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          sx={{
            width: "100%",
            border: "none",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>Edition</h3>
            </AccordionSummary>
          <AccordionDetails>
          <ul className={styles.ctfilist}>
          {loader.loader ? (
                  <div className={styles.loader}>
                    <CircularLoader />
                  </div>
                ) : (
                  Editions &&
                  Editions?.map((val) => (
                <li key={val._id}>
                  <label htmlFor={val._id}>
                    <input 
                    type="checkbox"
                    className={styles.checkbox_animated}
                    name="edition"
                    value={val.name}
                    onChange={props.handleChangeFilteredValues}
                    checked={props.filteredValues?.edition === val.name}
                    id={val.name}
                     />
                     {val.name}
                  </label>
                </li>
              ))
                )}
          </ul>
          </AccordionDetails>
        </Accordion>
          <div className={styles.price__slider}>
            <h3>Price</h3>
            <Box sx={{ width: 234, m: "auto", marginTop: 1 }}>
              <Slider
                value={priceValue}
                getAriaLabel={() => "Price"}
                size="small"
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                sx={{
                  color: "grey",
                  "& .MuiSlider-thumb": {
                    color: "#3A3B3C",
                  },
                  "& .MuiSlider-rail": {
                    color: "black",
                  },
                  ".MuiSlider-track": {
                    height: "4px",
                  },
                }}
                color="secondary"
                min={0}
                max={50000}
              />
              <div className={styles.labels}>
                <span className={styles.start}>0</span>
                <span className={styles.end}>$50,000</span>
              </div>
            </Box>
          </div>
          <div className={styles.price__slider}>
            <h3>Height</h3>
            <Box sx={{ width: 234, m: "auto", marginTop: 1 }}>
              <Slider
                value={heightValue}
                getAriaLabel={() => "height"}
  
                onChange={handleChange_height}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext_height}
                size="small"
                sx={{
                  color: "grey",
                  "& .MuiSlider-thumb": {
                    color: "#3A3B3C",
                  },
                  "& .MuiSlider-rail": {
                    color: "black",
                  },
                  ".MuiSlider-track": {
                    height: "4px",
                  },
                }}
                color="secondary"
                min={0}
                max={1500}
              />
              <div className={styles.labels}>
                <span className={styles.start}>0</span>
                <span className={styles.end}>1500+cm</span>
              </div>
            </Box>
          </div>
          <div className={styles.price__slider}>
            <h3>Width</h3>
            <Box sx={{ width: 234, m: "auto", marginTop: 1 }}>
              <Slider
                getAriaLabel={() => "Temperature range"}
                value={widthValue}
                size="small"
                onChange={handleChange_width}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext_width}
                sx={{
                  fontSize: "5px",
                  color: "grey",
                  "& .MuiSlider-thumb": {
                    color: "#3A3B3C",
                  },
                  "& .MuiSlider-rail": {
                    color: "black",
                  },
                  ".MuiSlider-track": {
                    height: "4px",
                  },
                }}
                color="secondary"
                min={0}
                max={1500}
              />
              <div className={styles.labels} style={{ marginBottom: "3%" }}>
                <span className={styles.start}>0</span>
                <span className={styles.end}>1500+cm</span>
              </div>
            </Box>
          </div>
          <Accordion
            elevation={0}
            sx={{
              width: "100%",
              border: "none",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>Gallery</h3>
              </AccordionSummary>
            <AccordionDetails>
              {/* Search input */}
            <input
              type="text"
              placeholder="Search Gallery..."
              className={styles.searchInput}
              value={searchQueryGalleryName}
              onChange={(e) => setSearchQueryGalleryName(e.target.value)}
            />
            <ul className={styles.ctfilist1}>
            {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : (
                    filteredGalleries && filteredGalleries.length > 0 &&
                    filteredGalleries?.map((val) => (
                  <li key={val._id}>
                    <label htmlFor={val._id} >
                      <input 
                      type="checkbox"
                      className={styles.checkbox_animated}
                      name="galleryName"
                      value={val.gallery_name}
                      onChange={props.handleChangeFilteredValues}
                      checked={props?.filteredValues?.galleryName === val.gallery_name}
                      id={val._id}
                       />
                       {val.gallery_name}
                    </label>
                  </li>
                ))
                  )}
            </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{
              width: "100%",
              border: "none",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>Gallery Type</h3>
              </AccordionSummary>
            <AccordionDetails>
            <ul className={styles.ctfilist}>
            {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : (
                    props.artisttypeData &&
                    props.artisttypeData?.map((val) => (
                  <li key={val._id}>
                    <label htmlFor={val._id}>
                      <input 
                      type="checkbox"
                      className={styles.checkbox_animated}
                      name="artistTypeName"
                      value={val.name}
                      onChange={props.handleChangeFilteredValues}
                      checked={props?.filteredValues?.artistTypeName === (val.name)}
                      id={val.name}
                       />
                       {val.name}
                    </label>
                  </li>
                ))
                  )}
            </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{
              width: "100%",
              border: "none",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>By Offer</h3>
              </AccordionSummary>
            <AccordionDetails>
            <ul className={styles.ctfilist}>
            {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : (
                    adminofferDt?.data && adminofferDt?.data.length > 0 &&
                    adminofferDt?.data?.map((val) => (
                  <li key={val._id}>
                    <label htmlFor={val._id}>
                      <input 
                      type="checkbox"
                      className={styles.checkbox_animated}
                      name="offername"
                      value={val.name}
                      onChange={props.handleChangeFilteredValues}
                      checked={props?.filteredValues?.offername === val.name}
                      id={val._id}
                       />
                       {val.name}
                    </label>
                  </li>
                ))
                  )}
            </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{
              width: "100%",
              border: "none",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>Gallery Nationality</h3>
              </AccordionSummary>
            <AccordionDetails>
            <input
              type="text"
              placeholder="Search Gallery Nationality..."
              className={styles.searchInput}
              value={searchGalleryNationality}
              onChange={(e) => setSearchGalleryNationality(e.target.value)}
            />
            <ul className={styles.ctfilist1}>
            {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : (
                    filteredNationality &&
                    filteredNationality?.map((val) => (
                  <li key={val._id}>
                    <label htmlFor={val._id}>
                      <input 
                      type="checkbox"
                      className={styles.checkbox_animated}
                      name="galleryNationality"
                      value={val.name}
                      onChange={props.handleChangeFilteredValues}
                      checked={props.filteredValues?.galleryNationality?.includes(val.name)}
                      id={val.name}
                       />
                       {val.name}
                    </label>
                  </li>
                ))
                  )}
            </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{
              width: "100%",
              border: "none",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>Gallery Country</h3>
              </AccordionSummary>
            <AccordionDetails>{/* Search input */}
            <input
              type="text"
              placeholder="Search Gallery Country..."
              className={styles.searchInput}
              value={searchGalleryCountry}
              onChange={(e) => setSearchGalleryCountry(e.target.value)}
            />
            <ul className={styles.ctfilist1}>
            {loader.loader ? (
                    <div className={styles.loader}>
                      <CircularLoader />
                    </div>
                  ) : (
                    filteredCountries &&
                    filteredCountries?.map((val) => (
                  <li key={val._id}>
                    <label htmlFor={val.value}>
                      <input 
                      type="checkbox"
                      className={styles.checkbox_animated}
                      name="galleryCountry"
                      value={val.name}
                      onChange={props.handleChangeFilteredValues}
                      checked={props.filteredValues?.galleryCountry?.includes(val.name)}
                      id={val.value}
                       />
                       {val.name}
                    </label>
                  </li>
                ))
                  )}
            </ul>
            </AccordionDetails>
          </Accordion>
        </div>
      </ThemeProvider>
    )
  }
  
  export default React.memo(ProductGallerySidebar)
  