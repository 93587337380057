import React, { useState } from "react";
import styles from "./Recretepopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Grid } from "@mui/material";
import dateFormat from "../../../../utils/dateFormat";

export default function Buyerofferpopup(props) {
  console.log("props.data",props.data );
  const [amount, setAmount] = useState();
  const handleClose = () => props.setOpen(false);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_del}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4}>
                <div className={styles.img_div}>
                  <img
                    alt="img"
                    src={props.acceptPopupData.item_id?.image}
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <div>
                  <p className={styles.odlist}>
                    Buyer Name :{" "}
                    <span>
                      {props.acceptPopupData.first_name}{" "}
                      {props.acceptPopupData.last_name}
                    </span>
                  </p>
                  <p className={styles.odlist}>
                    Sku_Id :{" "}
                    <span>{props.acceptPopupData.item_id?.sku_id}</span>
                  </p>
                  <p className={styles.odlist}>
                    Art Name :{" "}
                    <span>{props.acceptPopupData.item_id?.name}</span>
                  </p>
                  <p className={styles.odlist}>
                    Art Type :{" "}
                    <span>
                      {props.acceptPopupData.item_id?.sale ? "Sale" : "Pring"}
                    </span>
                  </p>
                  <p className={styles.odlist}>
                    Price :{" "}
                    <span>${props.acceptPopupData.item_id?.sale?.price}</span>
                  </p>
                  <p className={styles.odlist}>
                    Offer Price : <span>${props.acceptPopupData?.price}</span>
                  </p>
                  <p className={styles.odlist}>
                    Offer Date :{" "}
                    <span>{dateFormat(props.acceptPopupData.updatedAt)}</span>
                  </p>
                </div>
              </Grid>
            </Grid>
            <div className={styles.description_div}>
              <p className={styles.odlist}>
                Description <br /> <span>{props.acceptPopupData.message}</span>
              </p>
            </div>
            {props.activetab === "galleryrecrete" || "buyeroffer" ? (
              ""
            ) : (
              <div className="form-group">
                <label>Quatation</label>
                <input
                  className="form-control"
                  fullWidth
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  variant="outlined"
                  placeholder="Enter amount"
                />
              </div>
            )}
            <div className={styles.btn_div}>
              {props.activetab === "galleryrecrete" || "buyeroffer"  ? (
                <>
                  {props.acceptPopupData.is_accepted &&
                  props.acceptPopupData.is_accepted === "Approved" ? (
                    <span className={styles.accepted}> Accepted</span>
                  ) : props.acceptPopupData.is_accepted === "Rejected" ? (
                    <span className={styles.rejected}> Rejected</span>
                  ) : (
                    <>
                      <button
                        className="action_btn"
                        onClick={() =>
                          props.handleAcceptRejectOffer(
                            props.acceptPopupData._id,
                            { is_accepted: "Approved" }
                          )
                        }
                      >
                        Accept
                      </button>
                      <button
                        className="action_libtn"
                        onClick={() =>
                          // handleAcceptRejectRecreate
                          props.handleAcceptRejectOffer(
                            props.acceptPopupData._id,
                            { is_accepted: "Rejected" }
                          )
                        }
                      >
                        Decline
                      </button>
                    </>
                  )}
                  {/* <button className="action_btn"
                    onClick={() =>
                      props.handleAcceptRejectRecreate(props.acceptPopupData._id, { is_accepted: "Approved" })
                    }>
                    Accept
                  </button>
                  <button className="action_libtn"
                    onClick={() =>
                      props.handleAcceptRejectRecreate(props.acceptPopupData._id, { is_accepted: "Rejected" })
                    }>
                    Decline
                  </button> */}
                </>
              ) : props.activetab === "qouterequest" ? (
                <>
                  <button
                    className="action_btn"
                    onClick={() =>
                      props.handleAcceptRejectQuate(props.acceptPopupData._id, {
                        is_accepted: "Approved",
                      })
                    }
                  >
                    Accept
                  </button>
                  <button
                    className="action_libtn"
                    onClick={() =>
                      props.handleAcceptRejectQuate(props.acceptPopupData._id, {
                        is_accepted: "Rejected",
                      })
                    }
                  >
                    Decline
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="action_btn"
                    onClick={() => {
                      props.handleAcceptRejectOffer(props.acceptPopupData._id, {
                        is_accepted: "Approved",
                      });
                      handleClose();
                    }}
                  >
                    Accept
                  </button>
                  <button
                    className="action_libtn"
                    onClick={() => {
                      props.handleAcceptRejectOffer(props.acceptPopupData._id, {
                        is_accepted: "Rejected",
                      });
                      handleClose();
                    }}
                  >
                    Decline
                  </button>
                </>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
