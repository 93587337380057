import React, { useState } from 'react';
import styles from "./AddInquarypopup.module.css";
import { Modal, Box, Link, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ArtistInquiryChat } from '../../../../State/api/artistapi';
import { RiCloseCircleFill } from 'react-icons/ri';
import { InquiryChat } from '../../../../State/api/commonapi';

function InquiryChatpopup(props) {
  console.log("popup props",props);
  
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    reply: '',
    attachment: null,
    enquiryid: props?.id,
    usertype: localStorage.getItem("usertype") || "",
  });

  // State for errors
  const [errors, setErrors] = useState({ reply: '' });

  // Handle input changes for reply (message)
  const handleInputChange = (e) => {
    setFormData({ ...formData, reply: e.target.value });

    // Clear error when user starts typing
    if (errors.reply) {
      setErrors({ ...errors, reply: '' });
    }
  };

  // Handle file input changes for attachment
  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      attachment: e.target.files[0], // Store the selected file
    });
  };

  // Handle form submission
  const handleSubmit = () => {
    let validationErrors = {};
    if (!formData.reply.trim()) {
      validationErrors.reply = 'Please enter a message';
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    if (props?.inquiries?._id !== undefined) {
      dispatch(InquiryChat(formData)).then(() => {
        // After successful InquiryChat dispatch, get the updated inquiry replies
        dispatch(props?.GetInquaryReply(props?.id));  
      });  
    } else {
      dispatch(ArtistInquiryChat(formData)).then(()=>{        
        dispatch(props?.fetchEnquiryReplyByEnquiryId(props?.id));  
      });
    } 
    setFormData({
      reply: '',
      attachment: null,
    });
    props.setOpen(false);
  };




  // Handle closing the modal
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Modal open={props.Open} onClose={handleClose}>
        <Box className={styles.modalBox}>
          <div className={styles.clobtn}>
            <Link onClick={handleClose}>
              <RiCloseCircleFill className={styles.iconru} />
            </Link>
          </div>
          <p className={styles.mpophndg} id="transition-modal-title">
            Inquiry Chat
          </p>

          <div className="form-group">
            <label>Message *</label>
            <TextField
              className='form-control'
              value={formData.reply}
              onChange={handleInputChange}
              placeholder='please enter your message'
              multiline
              rows={4}
              sx={{ mt: 2, width: '100%' }}
              error={!!errors.reply}
              helperText={errors.reply} // Show validation message
            />
          </div>

          <div className="form-group">
            <input
              type="file"
              accept="image/*, .pdf"
              className='form-control'
              onChange={handleFileChange}
            />
          </div>

          <div className='text-center'>
            <button
              variant="contained"
              onClick={handleSubmit}
              className='action_btn'
            >
              Submit
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default InquiryChatpopup;
