import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Pagination from "../Pagination/Pagination";
import Fivecolimg from "../Table/Fivecolumn/Fivecolimg/Fivecolimg";
import Tableheader from "../Table/Tableheader/Tableheader";
import styles from "./Ratingreview.module.css";
import { setLoader } from "../../../../State/Features/common/loaderSlice";
import {
  AlertsSuccess,
  Errornotify,
  Successnotify,
  Warnnoti,
} from "../../../../common/alert/Alert";
import api from "../../../../State/api/Api";
import AdminratingReview from "../Table/Fivecolumn/AdminRatingReview/AdminRatingReview";
const ratinghead = [
  {
    id: "artist_name ",
    label: "Product",
  },
  {
    id: "artist_name ",
    label: "Buyer name",
  },
  {
    id: "location",
    label: "Rating",
  },
  {
    id: "star_rating",
    label: "Star Rating Count",
  },
  {
    id: "like",
    label: "Description",
  },
  {
    id: "Created_Date",
    label: "Created Date",
  },
  {
    id: "like",
    label: "Type",
  },
];

const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
];
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
];
export default function Ratingreview() {
  const [ratingData, setRatingData] = useState(null);
  const [data, setData] = useState({ offset: 0 });
  console.log(ratingData);
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  });

  const dispatch = useDispatch();
  const handleFetchRatingReview = async function (data) {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(
        "api/ratingReview/fetchRatingReview",
        "POST",
        data
      );
      if (result && result.status === 200) {
        setRatingData(result.data.data);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
        Errornotify("Error with code 401");
      }
    } catch (err) {
      Errornotify("Network Error");
    }
  };
  const handleDeleOrder = (id) => {
    Warnnoti("Are you sure?", async () => {
      dispatch(setLoader(true));
  
      try {
        const result = await api.applicationApi(
          `api/ratingReview/deleteRatingReview/${id}`,
          "DELETE"
        );
  
        if (result?.status === 200) {
          Successnotify("Deleted Successfully");
          handleFetchRatingReview();
        } else if (result?.status === 401) {
          Errornotify("Error with code 401");
        } else {
          Errornotify("Unexpected error occurred");
        }
      } catch (err) {
        Errornotify("Network Error");
      } finally {
        dispatch(setLoader(false)); // Ensures loader is always reset
      }
    });
  };
  
  useEffect(() => {
    handleFetchRatingReview();
  }, [data]);

  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });

    if (value === "name-asc") {
      setRatingData({
        data:
          ratingData.data &&
          [...ratingData.data].sort((a, b) =>
            a.user.toLowerCase() > b.user.toLowerCase() ? 1 : -1
          ),
        totalCount: ratingData.totalCount,
      });
    } else if (value === "name-desc") {
      setRatingData({
        data:
          ratingData.data &&
          [...ratingData.data].sort((a, b) =>
            a.user.toLowerCase() < b.user.toLowerCase() ? 1 : -1
          ),
        totalCount: ratingData.totalCount,
      });
    } else {
      handleFetchRatingReview();
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, search: value });
  };

  const handleSorting = (e) => {
    let value = e.target.value;
    if (value === "low") {
      setCondition({ ...condition, sorting: value });

      setRatingData({
        data:
          ratingData.data &&
          [...ratingData.data].sort((a, b) => {
            if (a.createdAt.toLowerCase() < b.createdAt.toLowerCase())
              return -1;
          }),
        totalCount: ratingData.totalCount,
      });
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value });

      setRatingData({
        data:
          ratingData.data &&
          [...ratingData.data].sort((a, b) => {
            if (a.createdAt.toLowerCase() > b.createdAt.toLowerCase())
              return -1;
          }),
        totalCount: ratingData.totalCount,
      });
    } else {
      handleFetchRatingReview();
      setCondition({ ...condition, sorting: value });
    }
  };

  console.log("ratingData",ratingData);
  
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      <div className={styles.components}>
        <div className={styles.tableheader_enter}>
          <Tableheader
            handleFilter={handleFilter}
            handleSorting={handleSorting}
            filterdata={filterdata}
            sortdata={sortdata}
            condition={condition}
            search={condition.search}
            handleSearch={handleSearch}
            button=""
          />
        </div>
        <AdminratingReview
          handleDeleOrder={handleDeleOrder}
          activetab="RatingReview"
          head={ratinghead}
          data={
            ratingData &&
            ratingData.data.filter((val) =>
              val.user.toLowerCase().includes(condition.search.toLowerCase())
            )
          }
        />
        <Pagination
          totaldata={ratingData && ratingData.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  );
}
