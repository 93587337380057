import { Container, createTheme, Grid, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import homeImage from "../../../constant";
import styles from "./UserAuction.module.css";
import { setActiveStatus } from "../../../State/Features/user/activetabSlice";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../State/api/Api";
import serverimage from "../../../serverConstant";
import { Errornotify } from "../../../common/alert/Alert";

function PastUserExhibition() {
  const theme = createTheme({
    typography: {
      h4: {
        fontFamily: "Marcellus",
        fontSize: "2vw",
      },
      h6: {
        fontFamily: "Marcellus",
        fontSize: "1vw",
      },
    },
  });
  const AuctionCardDetails = [
    {
      id: 1,
      imgUrl: "/img/Rectangle auction1.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle auction2.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle auction3.png",
    },
    {
      id: 4,
      imgUrl: "/img/Rectangle auction4.png",
    },
    {
      id: 5,
      imgUrl: "/img/Rectangle auction1.png",
    },
    {
      id: 6,
      imgUrl: "/img/Rectangle auction2.png",
    },
    {
      id: 7,
      imgUrl: "/img/Rectangle auction3.png",
    },
    {
      id: 8,
      imgUrl: "/img/Rectangle auction4.png",
    },
  ];
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  const loader = useSelector((state) => state.loader);
  const offersdt = useSelector((state) => state.offersData);
  const [orderTypeId, setOrderTypeId] = useState("");
  const [exhibitionDetailArt, setExhibitionDetailArt] = useState([]);
  const auctionStatus = useSelector((state) => state.activetab);
  const handleAuctionStatus = (status) => {
    dispatch(setActiveStatus({ status, maintab: "exhibition" }));
  };
  const fetchExhibitionArtDetails = async () => {
    try {
      const { data } = await api.applicationApi(
        `api/exhibition/fetchExhibitionArtDetails/${id}`,
        "POST"
      );
      setExhibitionDetailArt(data.data.data);
      setOrderTypeId(data?.data?.orderType?._id);
    } catch (error) {
    }
  };
  console.log("exhibitionDetailArt", exhibitionDetailArt)
  useEffect(() => {
    // handleFetchOffer();
    fetchExhibitionArtDetails();
    dispatch(
      setActiveStatus({ status: "ongoing exhibitions", maintab: "exhibition" })
    );
  }, []);
  const handleOfferDetail = (id) => {
    navigate(`/user/productdetail/${id}`);
  };
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.userauction}>
        <div className={styles.headerCategory}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <div className={styles.pageitle}>
              <h1>Exhibitions</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>{" "} 
                <li>Exhibition</li>
              </ul>
            </div>
          </Container> 
        </div>
        <section className={styles.sec_sc}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          {/* <div className={styles.link__div}>
            <ul>
              <li>
              <Link
              style={{
                textDecoration: "none",
                color: auctionStatus.status === "ongoing exhibitions" ? "black" : "#636365",
                fontWeight: auctionStatus.status === "ongoing exhibitions" ? "500" : "400",
              }}
              onClick={() => handleAuctionStatus("ongoing exhibitions")}
            >
               
                CURRENT EXHIBITIONS 
            </Link>
              </li>
              <li>
              <Link
              style={{
                textDecoration: "none",
                color: auctionStatus.status === "past" ? "black" : "#636365",
                fontWeight: auctionStatus.status === "past" ? "500" : "400",
              }}
              onClick={() => handleAuctionStatus("past")}
            >
               
                PAST 
            </Link>
              </li>
              <li>
              <Link
               style={{
                textDecoration: "none",
                color: auctionStatus.status === "upcoming" ? "black" : "#636365",
                fontWeight: auctionStatus.status === "upcoming" ? "500" : "400",
              }}
              onClick={() => handleAuctionStatus("upcoming")}
            >
               
                UPCOMING 
            </Link>
              </li>
            </ul>
          </div> */}
          <div className={styles.auctioncards__Main}>
            <Grid container spacing={2}>
              {
               exhibitionDetailArt && exhibitionDetailArt.length === 0 ?

               <>
               <div className={styles.no_result}><h3 >No Exhibition Found </h3></div>
               </>
               :
              exhibitionDetailArt.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={3}>
                {item?.item?.map((art, artIndex) => (
                  <div
                    key={art._id || artIndex}
                    className={styles.auction__cards}
                    onClick={() => {
                      if (art?.sale?.qty === 0) {
                        Errornotify("Product is out of stock !");
                        return;
                      }

                      if (art?.sale?.qty > 0) {
                        navigate("/user/checkout", {
                          state: {
                            artist_id: art.artist_id,
                            order_type_id: orderTypeId,
                            item_id: art._id,
                            price: item?.price,
                          },
                        });

                        localStorage.setItem("product_prices", item?.price);
                      }
                    }}
                  >
                    {console.log("itemz", art?.sale?.qty)}
                    <img className={styles.auctionImg} src={art.image} alt="" />

                    <h3 className={styles.dtitle}>{art.name}</h3>
                    <p className={styles.atdec}>{item?.description}{" "}</p>

                    <div className={styles.auction__price}>
                      <div className={styles.bid__price}>
                        <span className={styles.buys}>Price</span>
                        <span className={styles.picrs}>$ {item?.price}</span>
                      </div>
                      <div className={styles.verticalLine}></div>
                      <div className={styles.bid__price}>
                        <span className={styles.buys}>Width</span>
                        <span className={styles.picrs}>{art.width}</span>
                      </div>
                      <div className={styles.verticalLine}></div>
                      <div className={styles.bid__price}>
                        <span className={styles.buys}>Height</span>
                        <span className={styles.picrs}>{art.height}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Grid>
              ))}
            </Grid>
          </div>
          </Container>
        </section>
        
      </div>
    </ThemeProvider>
  );
}

export default PastUserExhibition;
