import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import styles from "./Commonthirdsecond.module.css";
import { useNavigate } from "react-router-dom";
import {
  handleAddArt,
  handleUploadUpdateArt,
} from "../../../../../../State/api/artistapi";
import { useDispatch, useSelector } from "react-redux";
import { Errornotify } from "../../../../../../common/alert/Alert";
import api from "../../../../../../State/api/Api";
import { FiPlus, FiTrash2, FiUpload } from "react-icons/fi";
import PrintTableArtsmiley from "./TableForPrintCal";
import validationForThirdFormArt from "../../../../../../validation/thirdFormArtValidation";
const materialArray = [
  { id: 1, label: "Canvas", value: "canvas" },
  { id: 1, label: "Fine Art Paper", value: "fine art paper" },
  { id: 1, label: "Photo Paper", value: "photo paper" },
  { id: 1, label: "Poster", value: "poster" },
  { id: 1, label: "Metal", value: "metal" },
  { id: 1, label: "Acrylic", value: "acrylic" },
  { id: 1, label: "Acrylic Sandwich", value: "acrylic_sandwich" },
];
const top100Films = [
  { label: "1 Month", year: 1994 },
  { label: "3 Month", year: 1972 },
  { label: "6 Month", year: 1974 },
  { label: "1 Year", year: 2008 },
  { label: "3 Year", year: 2008 },
  { label: "5 Year", year: 2008 },
  { label: "10 Year", year: 2008 },
];
export default function Commonthirdsecond(props) {
  console.log("third props page", props);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [thirdFormError, setThirdFormErrors] = useState({});
  const [printType, setPrintType] = useState("printbyme");
  const editArtId = useSelector((state) => state.editArtId?.id);
  const [materialValues, setMaterialValues] = useState({
    canvas: "",
    fineArtPaper: "",
    photoPaper: "",
    poster: "",
    metal: "",
    acrylicSandwich: "",
  });

  console.log("thirdFormError", thirdFormError);

  const [buy_percentage, setBuyPercentage] = useState(null);
  const [curator_percentage, setCuratorPercentage] = useState(null);
  const [pricingData, setPricingData] = useState([]);
  const [rent_percentage, setRentPercentage] = useState(null);
  const [print_percentage, setPrintPercentage] = useState(null);
  const [printPricing, setPrintPricing] = useState({
    material: "",
    product: "",
    metal_print: "",
    acrylic_print: "",
    acrylic_sandwich: "",
  });

  const [printImage, setPrintImage] = useState("");
  const [printPricingResponse, setPrintPricingResponse] = useState({});
  const [type, setType] = useState({ sale: true, rent: false, print: false });

  console.log("type", type);

  useEffect(() => {
    setType((prevType) => ({
      ...prevType,
      sale: ["Print", "Photography", "Digital Prints"].includes(props?.category)
        ? false
        : prevType.sale || props?.uploadArt?.sale?.price > 0,
      rent: prevType.rent || props?.uploadArt?.rent?.rent_price > 0,
    }));
  }, [props?.category, props?.uploadArt?.sale?.price, props?.uploadArt?.rent?.rent_price]);


  useEffect(() => {
    if (props.uploadArt.printImage) {
      if (typeof props.uploadArt.printImage === "string") {
        setPrintImage(props.uploadArt.printImage); // Direct URL from API
      } else {
        setPrintImage(URL.createObjectURL(props.uploadArt.printImage)); // Blob URL for new upload
      }
    }
  }, [props.uploadArt.printImage]);

  const handleChangeMainImage = (e) => {
    if (e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(img);
      const MIN_FILE_SIZE = 5000; // kb
      const MAX_FILE_SIZE = 100000; // kb
      const fileSizeKiloBytes = img.size / 1024;

      // Validate file type
      const allowedImageTypes = [
        "image/jpeg",
        // "image/png",
        // "image/gif",
        // "image/webp",
        "image/tiff",
      ];
      if (!allowedImageTypes.includes(img.type)) {
        Errornotify(
          "Invalid file type. Only JPEG, TIFF allowed."
        );
        return;
      }

      if (fileSizeKiloBytes < MIN_FILE_SIZE) {
        Errornotify("File size should be greater than 5MB");
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        Errornotify("File size should be less than 100MB");
      } else {
        props.setUploadArt({ ...props.uploadArt, printImage: img });
        setPrintImage(imgs); // Update preview
      }
    }
  };

  const [selectedMaterialsForDropdown, setSelectedMaterialsForDropdown] = useState([]);

  // Handle checkbox changes
  const handleChange = (event) => {
    const { name, checked } = event.target;

    setMaterialValues((prevMaterialValues) => {
      const updatedValues = { ...prevMaterialValues, [name]: checked };

      // Keep `print_by_artsmiley_material` updated
      const filtered = materialArray.filter((item) => updatedValues[item.value]);

      props.setUploadArt((prevUploadArt) => ({
        ...prevUploadArt,
        print_by_artsmiley_material: filtered,
      }));

      return updatedValues;
    });
  };


  useEffect(() => {
    setMaterialValues((prevMaterialValues) => {
      const updatedMaterialValues = { ...prevMaterialValues };

      // Keep existing checked materials
      props.uploadArt.print_by_artsmiley.forEach((printItem) => {
        if (printItem.material) {
          updatedMaterialValues[printItem.material] = true;
        }
      });

      return updatedMaterialValues;
    });
  }, [props.uploadArt.print_by_artsmiley]);

  //Slider Price
  const handlePriceChange = (event) => {
    const value = event.target.value;

    // Ensure there's at least one entry in print_by_artsmiley
    const newPrints = [...props.uploadArt.print_by_artsmiley];

    if (newPrints.length === 0) {
      newPrints.push({ print_price: value }); // If empty, create new entry
    } else {
      newPrints[0].print_price = value; // Only modify 0th position
    }

    // Calculate commissions
    const artsmiley_commission_print = parseFloat(
      ((value * print_percentage) / 100).toFixed(2)
    );
    const artist_profit_print = parseFloat(
      ((value * (100 - print_percentage)) / 100).toFixed(2)
    );

    // Update the new properties
    newPrints[0].artsmiley_commission_print = artsmiley_commission_print;
    newPrints[0].artist_profit_print = artist_profit_print;
    newPrints[0].price_of_collector_print = value;

    // Update state
    props.setUploadArt({
      ...props.uploadArt,
      print_by_artsmiley: newPrints,
    });
  };

  const renderedMenuItems = materialArray
    .filter((item) => materialValues[item.value]) // Filter out undefined or false items
    .map((item) => (
      <MenuItem key={item.label} value={item.value}>
        {item.label}
      </MenuItem>
    ));


  useEffect(() => {
    setPrintType("printbyme");
  }, []);
  const getProductOptions = () => {
    switch (printPricing.material) {
      case "canvas":
        return [
          { value: "canvas print", label: "Canvas Print" },
          {
            value: "canvas print and stretch",
            label: "Stretch Print & Stretched",
          },
          {
            value: "canvas print with stretching and framing",
            label: "Canvas Print With Stretching & Framing",
          },
        ];
      case "fine art paper":
        return [
          { value: "fine art print", label: "Fine Art Print" },
          { value: "mounting", label: "Mounting" },
          {
            value: "fine art print with framing",
            label: "Fine Art Print With Framing",
          },
        ];
      case "photo paper":
        return [
          { value: "photo paper print", label: "Photo Paper Print" },
          { value: "mounting", label: "Mounting" },
          {
            value: "photo paper print with framing",
            label: "Photo Paper Print With Framing",
          },
        ];
      case "poster":
        return [
          { value: "poster", label: "Poster" },
          { value: "mounting", label: "Mounting" },
          { value: "poster with framing", label: "Poster With Framing" },
        ];
      case "metal print 1.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 2.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 3.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "acrylic print 2 mm":
        return [
          { value: "acrylic print", label: "acrylic Print" },
          {
            value: "acrylic print with aluminium hanging",
            label: "acrylic Print With Aluminium Hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ];
      case "acrylic print 4 mm":
        return [
          { value: "acrylic print", label: "acrylic Print" },
          {
            value: "acrylic print with aluminium hanging",
            label: "acrylic Print With Aluminium Hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ];
      case "acrylic print 6 mm":
        return [
          { value: "acrylic print", label: "acrylic Print" },
          {
            value: "acrylic print with aluminium hanging",
            label: "acrylic Print With Aluminium Hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ];
      case "acrylic print 8 mm":
        return [
          { value: "acrylic print", label: "acrylic Print" },
          {
            value: "acrylic print with aluminium hanging",
            label: "acrylic Print With Aluminium Hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ];
      case "acrylic print 10 mm":
        return [
          { value: "acrylic print", label: "acrylic Print" },
          {
            value: "acrylic print with aluminium hanging",
            label: "acrylic Print With Aluminium Hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ];
      case "acrylic sandwich":
        return [
          {
            value: "acrylic sandwich 4+2 mm",
            label: "acrylic sandwich 4+2 mm",
          },
          {
            value: "acrylic sandwich 6+2 mm",
            label: "acrylic sandwich 6+2 mm",
          },
        ];
      case "acrylic sandwich 4+2 mm":
        return [
          {
            value: "acrylic sandwich 4+2 mm",
            label: "acrylic sandwich 4+2 mm",
          },
          // {
          //   value: "acrylic sandwich 6+2 mm",
          //   label: "acrylic sandwich 6+2 mm",
          // },
        ];
      case "acrylic sandwich 6+2 mm":
        return [
          // { value: "acrylic sandwich 4+2 mm", label: "acrylic sandwich 4+2 mm" },
          {
            value: "acrylic sandwich 6+2 mm",
            label: "acrylic sandwich 6+2 mm",
          },
        ];
      // case "acrylic sandwich print 6+2 mm":
      //   return [
      //     { value: "acrylic sandwich print", label: "acrylic sandwich Print" },
      //     {
      //       value: "acrylic sandwich print with aluminium hanging",
      //       label: "acrylic sandwich Print With Aluminium Hanging",
      //     },
      //   ];

      default:
        return [];
    }
  };

  const getProductOptionsMetalPrint = () => {
    switch (printPricing.metal_print) {
      case "metal print 1.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 2.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 3.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "acrylic print 2 mm":
        return [
          { value: "acrylic printing", label: "acrylic Print" },
          {
            value: "acrylic print with aluminium hanging",
            label: "acrylic Print With Aluminium Hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ];
      case "acrylic print 4 mm":
        return [
          { value: "acrylic printing", label: "acrylic Print" },
          {
            value: "acrylic print with aluminium hanging",
            label: "acrylic Print With Aluminium Hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ];
      case "acrylic print 6 mm":
        return [
          { value: "acrylic printing", label: "acrylic Print" },
          {
            value: "acrylic print with aluminium hanging",
            label: "acrylic Print With Aluminium Hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ];
      case "acrylic print 8 mm":
        return [
          { value: "acrylic printing", label: "acrylic Print" },
          {
            value: "acrylic print with aluminium hanging",
            label: "acrylic Print With Aluminium Hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ];
      case "acrylic print 10 mm":
        return [
          { value: "acrylic printing", label: "acrylic Print" },
          {
            value: "acrylic print with aluminium hanging",
            label: "acrylic Print With Aluminium Hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ];
      case "acrylic sandwich 4+2 mm":
        return [
          {
            value: "acrylic sandwich 4+2 mm",
            label: "acrylic sandwich Print 4+2",
          },
        ];
      case "acrylic sandwich 6+2 mm":
        return [
          {
            value: "acrylic sandwich 6+2 mm",
            label: "acrylic sandwich Print 6+2",
          },
        ];

      default:
        return [];
    }
  };

  const handleChangePrintpricing1 = (e) => {
    const { name, value } = e.target;

    // Update `printPricing` state
    setPrintPricing((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Define a mapping for the corresponding fields
    const fieldMap = {
      material: "material",
      metal_print: "metal_thickness",
      acrylic_print: "acrylic_thickness",
      acrylic_sandwich: "acrylic_sandwich_thickness",
    };

    // Update `uploadArt`
    if (fieldMap[name]) {
      props.setUploadArt((prevUploadArt) => {
        const updatedPrintByArtsmiley = prevUploadArt.print_by_artsmiley.map((item, index) =>
          index === 0 ? { ...item, [fieldMap[name]]: value } : item
        );

        return {
          ...prevUploadArt,
          print_by_artsmiley: updatedPrintByArtsmiley,
        };
      });
    }

    // ✅ Update `materialValues` to keep checkboxes in sync
    if (name === "material") {
      setMaterialValues((prev) => ({
        ...prev,
        [value]: true, // Ensure selected value remains checked
      }));
    }
  };


  // Handle dropdown selection
  const handleChangePrintpricing = (e) => {
    const { name, value } = e.target;

    // Update `printPricing` state
    setPrintPricing((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Define a mapping for the corresponding fields
    const fieldMap = {
      material: "material",
      metal_print: "metal_thickness",
      acrylic_print: "acrylic_thickness",
      acrylic_sandwich: "acrylic_sandwich_thickness",
    };

    // Update `uploadArt`
    if (fieldMap[name]) {
      props.setUploadArt((prevUploadArt) => {
        const updatedPrintByArtsmiley = prevUploadArt.print_by_artsmiley.map((item, index) =>
          index === 0 ? { ...item, [fieldMap[name]]: value } : item
        );

        return {
          ...prevUploadArt,
          print_by_artsmiley: updatedPrintByArtsmiley,
        };
      });
    }

    // ✅ Keep checkboxes (`materialValues`) unchanged
    // ✅ Update separate dropdown selection state
    setSelectedMaterialsForDropdown((prev) =>
      prev.includes(value) ? prev : [...prev, value]
    );
  };

  const handleChangePrintSize = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "edition") {
      props.setUploadArt({
        ...props.uploadArt,
        print: {
          size: props.uploadArt.print
            ? props.uploadArt.print.size && props.uploadArt.print.size
            : [],
          print_price: props.uploadArt.print
            ? props.uploadArt.print.print_price
            : "",
          edition: value,
          qty: props.uploadArt.print ? props.uploadArt.print.qty : "",
        },
      });
    } else if (name === "qty") {
      props.setUploadArt({
        ...props.uploadArt,
        print: {
          size: props.uploadArt.print
            ? props.uploadArt.print.size && props.uploadArt.print.size
            : [],
          print_price: props.uploadArt.print
            ? props.uploadArt.print.print_price
            : "",
          edition: props.uploadArt.print ? props.uploadArt.print.edition : "",
          qty: value,
        },
      });
    } else if (name === "print_main_price") {
      props.setUploadArt({
        ...props.uploadArt,
        print_main_price: {
          print_main_price: value,
        },
      });
    } else if (name === "print_price") {
      let print = { ...props.uploadArt.print, [name]: value };
      let artsmiley_commission_print = parseFloat(
        (print.print_price * (print_percentage / 100)).toFixed(2)
      ); // Use curator percentage dynamically
      let artist_profit_print = parseFloat(
        (print.print_price * ((100 - print_percentage) / 100)).toFixed(2)
      ); // Use curator percentage dynamically

      props.setUploadArt({
        ...props.uploadArt,
        print: {
          size: props.uploadArt.print
            ? props.uploadArt.print.size && props.uploadArt.print.size
            : [],
          print_price: value,
          edition: props.uploadArt.print ? props.uploadArt.print.edition : "",
          qty: props.uploadArt.print ? props.uploadArt.print.qty : "",
        },
        artist_profit_print,
        artsmiley_commission_print,
        price_of_collector_print: value,
      });
    } else {
      props.setUploadArt({
        ...props.uploadArt,
        print: {
          size: value,
          print_price: props.uploadArt.print
            ? props.uploadArt.print.print_price
            : "",
          edition: props.uploadArt.print ? props.uploadArt.print.edition : "",
          qty: props.uploadArt.print ? props.uploadArt.print.qty : "",
        },
      });
    }
  };
  const [priceError, setPriceError] = useState("");
  const [saleweighterror, setsaleweighterror] = useState("");
  const handleChangeSale = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (!isNaN(value)) {
      if (name === "price") {
        // Validate the price
        if (isNaN(value) || value < 100) {
          setPriceError("Price must be at least $100");
        } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
          setPriceError("Price can have only up to 2 decimal places");
        } else {
          setPriceError(""); // Clear error if valid
        }

        let sale = { ...props.uploadArt.sale, [name]: value };
        let artsmiley_commission = parseFloat(
          (sale.price * (buy_percentage / 100)).toFixed(2)
        );
        let curatorPercentage = parseFloat(
          (artsmiley_commission * (curator_percentage / 100)).toFixed(2)
        );
        // Reduce curatorPercentage from artsmiley_commission
        artsmiley_commission = parseFloat(
          (artsmiley_commission - curatorPercentage).toFixed(2)
        );
        let artist_profit = parseFloat(
          (sale.price * ((100 - buy_percentage) / 100)).toFixed(2)
        ); // Use curator percentage dynamically
        props.setUploadArt((prevState) => ({
          ...prevState,
          sale: {
            qty: props.uploadArt.sale.qty || "",
            price: value,
            price_of_collector: value,
            artsmiley_commission,
            artist_profit,
            curator_percentage: curatorPercentage,
            rolled: prevState.sale.rolled || [
              { rolled_size: "", rolled_price: "" },
            ],
          },
        }));
      } else if (name === "qty") {
        props.setUploadArt((prevState) => ({
          ...prevState,
          sale: {
            qty: value,
            sale_weight: props.uploadArt.sale.sale_weight || "",
            package_type: props.uploadArt.sale.package_type || "Tube",
            price: props.uploadArt.sale.price || "",
            price_of_collector: props.uploadArt.sale.price_of_collector,
            artsmiley_commission: props.uploadArt.sale.artsmiley_commission,
            artist_profit: props.uploadArt.sale.artist_profit,
            isframe: props.uploadArt.sale.isframe,
            rolled: prevState.sale.rolled || [
              { rolled_size: "", rolled_price: "" },
            ],
          },
        }));
      } else if (name === "sale_weight") {
        if (value === "") {
          setsaleweighterror(""); // Clear error if input is empty
        } else if (isNaN(value) || parseFloat(value) < 0.1 || parseFloat(value) > 3000) {
          setsaleweighterror("Weight must be at least 0.1kg and at most 3000kg");
        } else {
          setsaleweighterror(""); // Clear error if valid
        }

        // Always update state to reflect user input
        props.setUploadArt((prevState) => ({
          ...prevState,
          sale: {
            ...prevState.sale,
            sale_weight: value,
          },
        }));
      } else if (name === "height" || name === "width" || name === "depth") {
        props.setUploadArt((prevState) => ({
          ...prevState,
          sale: {
            ...prevState.sale,
            [name]: value, // Dynamically update only the changed field
          },
        }));

      }
      else {
        props.setUploadArt((prevState) => ({
          ...prevState,
          sale: {
            qty: props.uploadArt.sale.qty || "",
            sale_weight: value,
            package_type: props.uploadArt.sale.package_type || "Tube",
            price: props.uploadArt.sale.price || "",
            price_of_collector: props.uploadArt.sale.price_of_collector,
            artsmiley_commission: props.uploadArt.sale.artsmiley_commission,
            artist_profit: props.uploadArt.sale.artist_profit,
            isframe: props.uploadArt.sale.isframe,
            rolled: prevState.sale.rolled || [
              { rolled_size: "", rolled_price: "" },
            ],
          },
        }));
      }
    }
  };

  const [rentpriceerror, setrentpriceerror] = useState()
  const [rentweighterror, setrentweighterror] = useState("")
  const handleChangeRent = (e) => {
    const { name, value } = e.target;
    let rent = { ...props.uploadArt.rent };
    if (name === "rent_price") {
      const numericValue = value.replace(/[^0-9.]/g, "");
      if (isNaN(numericValue)) {
        return;
      }
      const checkCategory = ["Print", "Photography", "Digital Prints"].includes(props?.category);
      const salePrice = parseFloat(props?.uploadArt?.sale?.price) || 0;
      const decimalCheck = numericValue.toString().split(".");

      if (decimalCheck.length > 1 && decimalCheck[1].length > 2) {
        setrentpriceerror("Price can have only up to 2 decimal places");
      } else if (!checkCategory && numericValue >= salePrice) {
        setrentpriceerror("Rent price should be less than sale price");
      } else {
        setrentpriceerror(""); // Clear error if valid
      }

      rent.rent_price = numericValue;

      // Calculate Artsmiley commission and artist profit
      const artsmiley_commission_rent = parseFloat(
        (rent.rent_price * (rent_percentage / 100)).toFixed(2)
      );
      const artist_profit_rent = parseFloat(
        (rent.rent_price * ((100 - rent_percentage) / 100)).toFixed(2)
      );
      const curatorPercentage = parseFloat(
        (artsmiley_commission_rent * (curator_percentage / 100)).toFixed(2)
      );

      // Update Artsmiley commission after curator percentage
      const adjusted_commission_rent = parseFloat(
        (artsmiley_commission_rent - curatorPercentage).toFixed(2)
      );

      // Store all calculated values in the rent object
      rent.price_of_collector_rent = numericValue;
      rent.artsmiley_commission_rent = adjusted_commission_rent;
      rent.artist_profit_rent = artist_profit_rent;
      rent.curator_percentage_rent = curatorPercentage;
      rent.package_type = props.uploadArt.sale.package_type || "Tube";

      // Update the state with calculated values
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent,
      }));
    }
    else if (name === "is_hanged") {
      rent.is_hanged = value;

      if (value === "Yes") {
        rent.tube_size = ""
        rent.package_type = ""
      }
      else if (value === "No") {
        rent.package_type = "Tube"
        rent.isframe = false
        rent.frame_color = ""
      }
      // Update the state with the new time value
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent,
      }));
    }
    else if (name === "rent_weight") {
      if (value === "") {
        setrentweighterror(""); // Clear error if input is empty
      } else if (isNaN(value) || parseFloat(value) < 0.1 || parseFloat(value) > 3000) {
        setrentweighterror("Weight must be at least 0.1kg and at most 3000kg");
      } else {
        setrentweighterror(""); // Clear error if valid
      }
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent: {
          ...prevState.rent,
          rent_weight: value,
        },
      }));
    }
    else if (name === "time") {
      rent.time = value;
      // Update the state with the new time value
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent,
      }));
    } else if (name === "rent_weight") {
      const numericValue = value.replace(/[^0-9]/g, "");
      rent.rent_weight = numericValue;
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent,
      }));
    }
    else if (name === "height" || name === "width" || name === "depth") {
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent: {
          ...prevState.rent,
          [name]: value, // Dynamically update only the changed field
        },
      }));

    }
  };

  useEffect(() => {
    if (props.uploadArt.sale?.package_type) {
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent: {
          ...prevState.rent, // Retain other fields in rent
          package_type: props.uploadArt.sale.package_type, // Push sale_weight into rent_weight
        },
      }));
    }
  }, [props.uploadArt.sale?.package_type]);

  // useEffect(() => {
  //   if (props.uploadArt.sale?.sale_weight) {
  //     props.setUploadArt((prevState) => ({
  //       ...prevState,
  //       rent: {
  //         ...prevState.rent, // Retain other fields in rent
  //         rent_weight: props.uploadArt.sale.sale_weight, // Push sale_weight into rent_weight
  //       },
  //     }));
  //   }
  // }, [props.uploadArt.sale?.sale_weight]);

  // placeholder="Enter Your Rent Weight"
  const sizes = [
    {
      _id: 1,
      height: props.uploadArt.height * 0.5,
      width: props.uploadArt.width * 0.5,
    },
    {
      _id: 2,
      height: props.uploadArt.height * 0.75,
      width: props.uploadArt.width * 0.75,
    },
    {
      _id: 3,
      height: Number(props.uploadArt.height),
      width: Number(props.uploadArt.width),
    },
    {
      _id: 4,
      height: props.uploadArt.height * 2,
      width: props.uploadArt.width * 2,
    },
    {
      _id: 5,
      height: props.uploadArt.height * 3,
      width: props.uploadArt.width * 3,
    },
  ];

  // const handleChangeType = (e) => {
  //   const name = e.target.name;
  //   if (name === "sale") {
  //     setType({ ...type, sale: !type.sale });
  //   } else if (name === "rent") {
  //     setType({ ...type, rent: !type.rent });
  //   } else {

  //   }
  // };
  const handleChangeType = (e) => {
    const name = e.target.name;

    // Dynamically toggle the property based on the input's name
    if (type.hasOwnProperty(name)) {
      setType({ ...type, [name]: !type[name] });
    }
  };
  const handlePrintChange = (e) => {
    const name = e.target.name;

    if (
      (name === "print" && printType === "printbyme") ||
      printType === "printbyartsmiley"
    ) {
      // Use `&&` instead of `||`
      const newPrints = [...(props.uploadArt.print || [])];
      if (newPrints[0]) {
        // Toggle `is_print` between `true` and `false`
        newPrints[0].is_print = !newPrints[0].is_print;
      }
      // else if (printType==="printbyartsmiley"){

      // }
      else {
        // Initialize 0th position with `is_print: false`
        newPrints[0] = { is_print: false };
      }

      props.setUploadArt((prevUploadArt) => ({
        ...prevUploadArt,
        print: newPrints,
      }));
    }
  };



  const handleUploadArt = () => {
    const { thirdFormErrors, isValidThirdForm } = validationForThirdFormArt(
      printType,
      props.uploadArt,
      type,
      props
    );
    // Check if the category is "Print", "Photography", or "Digital Prints"
    const isPrintCategory = ["Print", "Photography", "Digital Prints"].includes(props?.category);
    if (!isPrintCategory && !type.sale) {
      Errornotify("Artworks Sale Price information required.");
    } else {
      if (isValidThirdForm) {
        dispatch(
          handleAddArt(props.uploadArt, pricingData, printType, type, navigate, props.setUploadArt)
        );
      } else {
        setThirdFormErrors(thirdFormErrors);
      }
    }
  };

  const handleUpdateArt = () => {
    const isPrintCategory = ["Print", "Photography", "Digital Prints"].includes(props?.category);
    if (!isPrintCategory && props.uploadArt.sale.price <= 0) {
      Errornotify("Please fill Sale detail properly");
    } else if (props.uploadArt.sale.qty <= 0) {
      Errornotify("Please fill Sale detail properly");
    } else {
      dispatch(
        handleUploadUpdateArt(
          editArtId?.id,
          props.uploadArt,
          pricingData,
          printType,
          type,
          navigate
        )
      );
    }
  };

  const handleAddFields = () => {
    const newPrint = {
      edition: "Limited",
      qty: "1",
      size: {},
      print_price: "",
      material: "Fine Art Paper",
      artsmiley_commission_print: "",
      artist_profit_print: "",
      price_of_collector_print: "",
      package_type: "Tube",
      is_hanged: "No",
      is_frame: false,
      frame_color: "",
      is_print: true,
      tube_size: "",
    };
    props.setUploadArt({
      ...props.uploadArt,
      print: [...props.uploadArt.print, newPrint],
    });
  };

  const handleRemoveFields = (index) => {
    const newPrints = props.uploadArt.print.filter((_, i) => i !== index);
    props.setUploadArt({
      ...props.uploadArt,
      print: newPrints,
    });
  };

  const handleSetting = async function () {
    try {
      const result = await api.applicationApi(
        `api/superAdmin/fetchSettingDetails`,
        "GET"
      );
      if (result && result.status === 200) {
        result.data.data.forEach((element) => {
          setBuyPercentage(element.buy_percentage);
          setRentPercentage(element.rent_percentage);
          setPrintPercentage(element.print_percentage);
          setCuratorPercentage(element.curator_percentage);
        });
        // dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) { }
  };

  const handleFetchPriceByMaterialAndProduct1 = async function () {
    try {
      const productOptions = getProductOptions();
      const metalPrintOptions = getProductOptionsMetalPrint();
      // Step 2: Extract only the value properties
      const valuesToSend = productOptions.map((option) => option.value);
      const metalPrintValues = metalPrintOptions.map((option) => option.value);

      // Determine material
      const material = !["metal", "acrylic", "acrylic_sandwich"].includes(
        printPricing.material
      )
        ? printPricing.material
        : printPricing.metal_print;
      // Determine product values
      const product =
        printPricing.material === "metal" ||
          printPricing.material === "acrylic" ||
          printPricing.material === "acrylic_sandwich"
          ? metalPrintValues
          : valuesToSend;

      // Log the material and product values to check them
      console.log("Material:-----", material);
      console.log("Product:------", product);

      const result = await api.applicationApi(
        `api/printprice/getPrintPriceByMaterialAndProduct`,
        "POST",
        {
          material,
          product,
          // material:
          //   printPricing.material !== "metal"

          // material: !["metal", "acrylic", "acrylic_sandwich"].includes(printPricing.material)
          //     ? printPricing.material
          //     : printPricing.metal_print,

          // : printPricing.acrylic_print,
          // product:filteredProductValues
          // product:printPricing.material === "metal" ? metalPrintValues : valuesToSend,
          // product:printPricing.material === "acrylic_sandwich" ? metalPrintValues : valuesToSend,
        }
      );
      if (result && result.status === 200) {
        setPrintPricingResponse(result.data);
        // dispatch(setLoader(false));
      } else if (result.status === 401) {
        // Handle unauthorized response
        console.warn("Unauthorized access while fetching print price.");
      }
    } catch (err) {
      console.error("Error fetching print price:", err);
    }
  };

  const handleFetchPriceByMaterialAndProduct = async function () {
    try {
      const productOptions = getProductOptions();
      const metalPrintOptions = getProductOptionsMetalPrint();

      // Step 2: Extract only the value properties
      const valuesToSend = productOptions.map((option) => option.value);
      const metalPrintValues = metalPrintOptions.map((option) => option.value);

      // Determine material and product values
      let material;
      let product;

      if (
        ["metal", "acrylic", "acrylic_sandwich"].includes(printPricing.material)
      ) {
        if (printPricing.metal_print.includes("acrylic sandwich")) {
          material = "acrylic sandwich";
          product = [printPricing.metal_print];
        } else {
          material = printPricing.metal_print;
          product = metalPrintValues;
        }
      } else {
        material = printPricing.material;
        product = valuesToSend;
      }

      const result = await api.applicationApi(
        `api/printprice/getPrintPriceByMaterialAndProduct`,
        "POST",
        {
          material,
          product,
        }
      );

      if (result && result.status === 200) {
        setPrintPricingResponse(result.data);
      } else if (result.status === 401) {
        console.warn("Unauthorized access while fetching print price.");
      }
    } catch (err) {
      console.error("Error fetching print price:", err);
    }
  };

  useEffect(() => {
    if (printPricing.material) {
      handleFetchPriceByMaterialAndProduct();
    }
  }, [printPricing]);
  useEffect(() => {
    handleSetting();
  }, []);

  return (
    <div className={styles.mains}>
      <div className={styles.discription_div}>
        <h2 className={styles.main_heading}>{props.data.main_heading}</h2>
        <h5 className={styles.status_avail}>{props.data.sub_heading}</h5>
        <FormControlLabel
          className={styles.checkbox_labels}
          control={
            <Checkbox
              name="sale"
              onChange={handleChangeType}
              checked={type.sale}
              disabled={["Print", "Photography", "Digital Prints"].includes(props?.category)}
              sx={{ color: "#665D6F" }}
            />
          }
          label={
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "26px",
              }}
              className={styles.checkbox_label}
            >
              Sale
            </Typography>
          }
        />
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <div className="form-group">
              <label>Artist Price *</label>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  onChange={handleChangeSale}
                  value={props.uploadArt.sale.price || ""}
                  fullWidth
                  disabled={!type.sale}
                  placeholder="Enter Your Sale Price"
                  type="text"
                  name="price"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
                {thirdFormError?.salePrice ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {thirdFormError?.salePrice}
                  </span>
                ) : null}
                {priceError && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {priceError}
                  </span>
                )}
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="form-group">
              <label>Quantity *</label>
              <input
                className="form-control"
                type="text"
                fullWidth
                disabled
                onChange={handleChangeSale}
                placeholder="Enter your quantity"
                value={props.uploadArt.sale?.qty}
                name="qty"
              />
            </div>
            {thirdFormError?.salePrice ? (
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                {thirdFormError?.saleQty}
              </span>
            ) : null}
          </Grid>
      {/* {props?.category === "Installation"
       ? 
      (<p>Installation is coming</p>)
      :
      
      (<>

      </>)} */}



          <Grid item xs={12} sm={6} md={6}>
            <div className="form-group">
              <div className={styles.medium_child1}>
                <label>Ready to Hang*</label>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="is_hanged"
                    value={props.uploadArt.is_hanged}
                    onChange={props.handleChangedata}

                  >
                    <FormControlLabel
                      className={styles.radio_label}
                      sx={{ m: 0.5 }}
                      value="Yes"
                      control={
                        <Radio size="small" sx={{ m: 0, p: 0.3 }} />
                      }
                      label="Yes"
                      disabled={
                        !type?.sale ||
                        [
                          "Sculpture",
                          "Crafts",
                          "Installation",
                        ].includes(props.category)}
                    />
                    <FormControlLabel
                      className={styles.radio_label}
                      sx={{ m: 0.5 }}
                      value="No"
                      control={
                        <Radio size="small" sx={{ m: 0, p: 0.3 }} />
                      }
                      label="No"
                      disabled={
                        !type?.sale ||
                        [
                          "Sculpture",
                          "Crafts",
                          "Installation",
                        ].includes(props.category)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="form-group">
              <label>Tube Size *</label>
              <Select
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  width: "100%",
                  background: "#fff",
                }}
                labelId="select-mutli-artist"
                id="demo-select-artist"
                onChange={(e) => {
                  const { value } = e.target; // Get the new value from the input

                  props.setUploadArt({
                    ...props.uploadArt, // Spread the existing state
                    tube_size: value, // Update the tube_size field
                  });
                }}
                name="tube_size"
                value={props.uploadArt.tube_size}
                disabled={
                  !type.sale ||
                    props.uploadArt.is_hanged === "Yes" ||
                    ["Sculpture", "Crafts", "Installation"].includes(
                      props.category
                    )
                    ? true
                    : false
                }
                fullWidth
                placeholder="Enter your tube size"
              >
                {[
                  { _id: 1, label: "Small" },
                  { _id: 2, label: "Medium" },
                  { _id: 3, label: "Large" },
                  { _id: 2, label: "Extra Large" },
                ].map((val) => (
                  <MenuItem value={val.label} key={val.label}>
                    {val.label}
                  </MenuItem>
                ))}
              </Select>
              {thirdFormError?.tubeSize ? (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  {thirdFormError?.tubeSize}
                </span>
              ) : null}
            </div>
          </Grid>



          {props?.uploadArt?.is_hanged === "Yes" ? (
            <>
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                  <label>Packaging Type</label>
                  <Select
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    onChange={(e) => {
                      const { value } = e.target; // Get the new value from the input
                      props.setUploadArt({
                        ...props.uploadArt, // Spread the existing state
                        sale: {
                          ...props.uploadArt.sale, // Spread the existing sale object to retain other fields
                          package_type: value, // Update the package_type field inside sale
                        },
                      });
                    }}
                    name="package_type"
                    value={props.uploadArt.sale.package_type || "Tube"}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        padding: ".45rem .75rem",
                        height: "auto",
                        borderRadius: ".25rem",
                        fontSize: "16px",
                        fontWeight: "400",
                      },
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      width: "100%",
                      background: "#fff",
                    }}
                    placeholder="Select your frame color"
                  >
                    {[
                      { _id: 1, label: "Crate" },
                      { _id: 2, label: "Box" },
                    ].map((val) => (
                      <MenuItem value={val.label} key={val.label}>
                        {val.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {thirdFormError?.packagetype ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                        marginLeft: "5px",
                      }}
                    >
                      {thirdFormError?.packagetype}
                    </span>
                  ) : null}
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                  <label>Is it Framed?</label>
                  <Select
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    onChange={(e) => {
                      const { value } = e.target; // Get the new value from the input
                      // Convert "Yes" to true and "No" to false
                      const isFrameBoolean = value === "Yes";
                      props.setUploadArt({
                        ...props.uploadArt, // Spread the existing state
                        sale: {
                          ...props.uploadArt.sale,
                          isframe: isFrameBoolean,
                        },
                      });
                    }}
                    name="isframe"
                    value={props.uploadArt.sale?.isframe ? "Yes" : "No"}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        padding: ".45rem .75rem",
                        height: "auto",
                        borderRadius: ".25rem",
                        fontSize: "16px",
                        fontWeight: "400",
                      },
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      width: "100%",
                      background: "#fff",
                    }}
                    placeholder="available frame"
                  >
                    {[
                      { _id: 1, label: "Yes" },
                      { _id: 2, label: "No" },
                    ].map((val) => (
                      <MenuItem value={val.label} key={val.label}>
                        {val.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                  <label>Frame Color *</label>
                  <Select
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    onChange={(e) => {
                      const { value } = e.target; // Get the new value from the input
                      props.setUploadArt({
                        ...props.uploadArt, // Spread the existing state
                        frame_color: value, // Update the tube_size field
                      });
                    }}
                    disabled={!props.uploadArt.sale.isframe}
                    name="frame_color"
                    value={props.uploadArt.frame_color}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        padding: ".45rem .75rem",
                        height: "auto",
                        borderRadius: ".25rem",
                        fontSize: "16px",
                        fontWeight: "400",
                      },
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      width: "100%",
                      background: "#fff",
                    }}
                    placeholder="Select your frame color"
                  >
                    {[
                      { _id: 1, label: "Black" },
                      { _id: 2, label: "Brown" },
                      { _id: 3, label: "White" },
                      { _id: 4, label: "Silver" },
                      { _id: 5, label: "Gold" },
                      { _id: 6, label: "Patina" },
                      { _id: 7, label: "Other" },
                    ].map((val) => (
                      <MenuItem value={val.label} key={val.label}>
                        {val.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {thirdFormError?.saleisframe ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginLeft: "5px",
                    }}
                  >
                    {thirdFormError?.saleisframe}
                  </span>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                  <label>
                    Shipping weight (Artwork +{" "}
                    {props.uploadArt.sale.package_type}) KG *
                  </label>
                  <input
                    className="form-control"
                    onChange={handleChangeSale}
                    value={props.uploadArt.sale.sale_weight || ""}
                    fullWidth
                    disabled={!type.sale}
                    placeholder="Enter Your Sale Weight"
                    type="text"
                    name="sale_weight"
                  />
                  {thirdFormError?.saleWeight ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {thirdFormError?.saleWeight}
                    </span>
                  ) : null}
                  <br />
                  {saleweighterror && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {saleweighterror}
                    </span>
                  )}
                </div>
              </Grid>
            </>
          ) : null}
          {props.uploadArt.is_hanged === "No" ? (
            <>
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                  <label>Shipping weight (Artwork + Tube) KG *</label>
                  <input
                    className="form-control"
                    onChange={handleChangeSale}
                    value={props.uploadArt.sale.sale_weight || ""}
                    fullWidth
                    disabled={!type.sale}
                    placeholder="Enter Your Sale Weight"
                    type="text"
                    name="sale_weight"
                  />
                  {thirdFormError?.saleWeight ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {thirdFormError?.saleWeight}
                    </span>
                  ) : null}<br />
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {saleweighterror}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                  <label>Packaging Type *</label>
                  <input
                    className="form-control"
                    value={"Tube"}
                    fullWidth
                    disabled
                  />
                </div>
              </Grid>
            </>
          ) : null}

        </Grid>

        <h3 className={styles.status_avail}>Package Dimensions</h3>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Height *</label>
              <input
                className="form-control"
                onChange={handleChangeSale}
                value={props.uploadArt?.sale?.height || ""}
                disabled={!type?.sale} // Ensure type is not undefined
                placeholder="Enter Height"
                type="text" // Use "number" instead of "text" for numeric input
                name="height"
              />
              {thirdFormError?.salepackageheight && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {thirdFormError.salepackageheight}
                </span>
              )}
              <br />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Width *</label>
              <input
                className="form-control"
                onChange={handleChangeSale}
                value={props.uploadArt.sale.width || ""}
                fullWidth
                disabled={!type.sale}
                placeholder="Enter Width"
                type="text"
                name="width"
              />
              {thirdFormError?.salepackageweight ? (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {thirdFormError?.salepackageweight}
                </span>
              ) : null}<br />

            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Depth *</label>
              <input
                className="form-control"
                onChange={handleChangeSale}
                value={props.uploadArt.sale.depth || ""}
                disabled={!type.sale}
                fullWidth
                placeholder="Enter depth"
                type="text"
                name="depth"
              />
              {thirdFormError?.salepackagedepth ? (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {thirdFormError?.salepackagedepth}
                </span>
              ) : null}<br />
            </div>
          </Grid>
        </Grid>

        <h3 className={styles.status_avail}>Sale Pricing</h3>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Art Smiley Commission </label>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  onChange={handleChangeSale}
                  fullWidth
                  disabled={!type.sale}
                  value={props.uploadArt.sale.artsmiley_commission}
                  type="text"
                  name="artsmiley_commission"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  readOnly
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Your Profit</label>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  onChange={handleChangeSale}
                  fullWidth
                  disabled={!type.sale}
                  readOnly
                  type="text"
                  name="artist_profit"
                  value={props.uploadArt.sale.artist_profit}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Price the Collector will see</label>

              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  onChange={handleChangeSale}
                  fullWidth
                  disabled={!type.sale}
                  readOnly
                  type="text"
                  name="price_of_collector"
                  value={props.uploadArt.sale.price_of_collector}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Curator Percentage</label>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  onChange={handleChangeSale}
                  fullWidth
                  disabled={!type.sale}
                  readOnly
                  type="text"
                  name="curator_percentage"
                  value={(props.uploadArt?.sale?.curator_percentage)}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>

        <FormControlLabel
          className={styles.checkbox_labels}
          control={
            <Checkbox
              name="rent"
              checked={type.rent}
              onChange={handleChangeType}
              sx={{ color: "#665D6F" }}
            />
          }
          label={
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "26px",
              }}
              className={styles.checkbox_label}
            >
              Rent
            </Typography>
          }
        />
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <div className="form-group">
              <label>Artist Price *</label>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  fullWidth
                  value={
                    props.uploadArt.rent && props.uploadArt.rent.rent_price
                  }
                  onChange={handleChangeRent}
                  disabled={!type.rent}
                  placeholder="Enter Price"
                  sx={{ mt: 0.5 }}
                  type="text"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  name="rent_price"
                />
              </FormControl>{" "}
              {thirdFormError?.rentPrice ? (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {thirdFormError?.rentPrice}
                </span>
              ) : null}
              {rentpriceerror && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {rentpriceerror}
                </span>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="form-group">
              <label>Time *</label>
              <Select
                labelId="select-mutli-artist"
                id="demo-select-artist"
                disabled={!type.rent}
                value={props.uploadArt.rent && props.uploadArt.rent.time}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  width: "100%",
                  background: "#fff",
                }}
                placeholder="select type"
                onChange={handleChangeRent}
                name="time"
                readOnly
              >
                <MenuItem value="none" disabled>
                  None
                </MenuItem>
                {top100Films &&
                  top100Films.map((val) => (
                    <MenuItem value={val.label} key={val.label}>
                      {val.label}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="form-group">
              <div className={styles.medium_child1}>
                <label>Ready to Hang*</label>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="is_hanged"
                    value={props.uploadArt.rent && props.uploadArt.rent.is_hanged}
                    onChange={handleChangeRent}

                  >
                    <FormControlLabel
                      className={styles.radio_label}
                      sx={{ m: 0.5 }}
                      value="Yes"
                      control={
                        <Radio size="small" sx={{ m: 0, p: 0.3 }} />
                      }
                      label="Yes"
                      disabled={
                        !type?.rent ||
                        [
                          "Sculpture",
                          "Crafts",
                          "Installation",
                        ].includes(props.category)}
                    />
                    <FormControlLabel
                      className={styles.radio_label}
                      sx={{ m: 0.5 }}
                      value="No"
                      control={
                        <Radio size="small" sx={{ m: 0, p: 0.3 }} />
                      }
                      label="No"
                      disabled={
                        !type?.rent ||
                        [
                          "Sculpture",
                          "Crafts",
                          "Installation",
                        ].includes(props.category)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="form-group">
              <label>Tube Size *</label>
              <Select
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  width: "100%",
                  background: "#fff",
                }}
                labelId="select-mutli-artist"
                id="demo-select-artist"
                onChange={(e) => {
                  const { value } = e.target; // Get the new value from the input

                  props.setUploadArt((prevUploadArt) => ({
                    ...prevUploadArt, // Preserve other properties
                    rent: {
                      ...prevUploadArt.rent, // Preserve existing rent properties
                      tube_size: value, // Update only tube_size
                    },
                  }));
                }}
                name="tube_size"
                value={props.uploadArt.rent?.tube_size}
                disabled={
                  !type.rent ||
                    props.uploadArt.rent?.is_hanged === "Yes" ||
                    ["Sculpture", "Crafts", "Installation"].includes(
                      props.category
                    )
                    ? true
                    : false
                }
                fullWidth
                placeholder="Enter your tube size"
              >
                {[
                  { _id: 1, label: "Small" },
                  { _id: 2, label: "Medium" },
                  { _id: 3, label: "Large" },
                  { _id: 2, label: "Extra Large" },
                ].map((val) => (
                  <MenuItem value={val.label} key={val.label}>
                    {val.label}
                  </MenuItem>
                ))}
              </Select>
              {thirdFormError?.renttubeSize ? (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  {thirdFormError?.renttubeSize}
                </span>
              ) : null}
            </div>
          </Grid>
          {props.uploadArt.rent?.is_hanged === "Yes" ? (
            <>
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                  <label>Packaging Type *</label>

                  <Select
                    onChange={(e) => {
                      const { value } = e.target; // Get the new value from the input
                      props.setUploadArt({
                        ...props.uploadArt, // Spread the existing state
                        rent: {
                          ...props.uploadArt.rent, // Spread the existing sale object to retain other fields
                          package_type: value, // Update the package_type field inside sale
                        },
                      });
                    }}
                    name="package_type"
                    value={props.uploadArt.rent.package_type || "Tube"}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        padding: ".45rem .75rem",
                        height: "auto",
                        borderRadius: ".25rem",
                        fontSize: "16px",
                        fontWeight: "400",
                      },
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      width: "100%",
                      background: "#fff",
                    }}
                  >
                    {[
                      { _id: 1, label: "Crate" },
                      { _id: 2, label: "Box" },
                    ].map((val) => (
                      <MenuItem value={val.label} key={val.label}>
                        {val.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {thirdFormError?.rentpackagetype ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {thirdFormError?.rentpackagetype}
                    </span>
                  ) : null}
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                  <label>Is it Framed?</label>
                  <Select
                    onChange={(e) => {
                      const { value } = e.target; // Get the new value from the input
                      // Convert "Yes" to true and "No" to false
                      const isFrameBoolean = value === "Yes";
                      props.setUploadArt({
                        ...props.uploadArt, // Spread the existing state
                        rent: {
                          ...props.uploadArt.rent,
                          isframe: isFrameBoolean,
                        },
                      });
                    }}
                    name="isframe"
                    value={props.uploadArt.rent?.isframe ? "Yes" : "No"}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        padding: ".45rem .75rem",
                        height: "auto",
                        borderRadius: ".25rem",
                        fontSize: "16px",
                        fontWeight: "400",
                      },
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      width: "100%",
                      background: "#fff",
                    }}
                    placeholder="available frame"
                  >
                    {[
                      { _id: 1, label: "Yes" },
                      { _id: 2, label: "No" },
                    ].map((val) => (
                      <MenuItem value={val.label} key={val.label}>
                        {val.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                  <label>Frame Color</label>
                  <Select
                    onChange={(e) => {
                      const { value } = e.target; // Get the new value from the input
                      props.setUploadArt({
                        ...props.uploadArt, // Spread the existing state
                        rent: {
                          ...props.uploadArt.rent,
                          frame_color: value, // Update the tube_size field
                        }
                      });
                    }}
                    disabled={!props.uploadArt.rent.isframe}
                    // name="frame_color"
                    value={props.uploadArt.rent?.frame_color}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        padding: ".45rem .75rem",
                        height: "auto",
                        borderRadius: ".25rem",
                        fontSize: "16px",
                        fontWeight: "400",
                      },
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      width: "100%",
                      background: "#fff",
                    }}
                    placeholder="Select your frame color"
                  >
                    {[
                      { _id: 1, label: "Black" },
                      { _id: 2, label: "Brown" },
                      { _id: 3, label: "White" },
                      { _id: 4, label: "Silver" },
                      { _id: 5, label: "Gold" },
                      { _id: 6, label: "Patina" },
                      { _id: 7, label: "Other" },
                    ].map((val) => (
                      <MenuItem value={val.label} key={val.label}>
                        {val.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                  <label>Packaging Type *</label>
                  <Select
               
                    name="package_type"
                    value={"Tube"}
                    fullWidth
                    disabled={!type.rent}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        padding: ".45rem .75rem",
                        height: "auto",
                        borderRadius: ".25rem",
                        fontSize: "16px",
                        fontWeight: "400",
                      },
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      width: "100%",
                      background: "#fff",
                    }}
                  >
                    <MenuItem value={"Tube"}>{"Tube"}</MenuItem>
                  </Select>
                </div>
              </Grid>{" "}
            </>
          )}

          <Grid item xs={12} sm={6} md={6}>
            <div className="form-group">
              <label>
                Shipping weight (Artwork +{" "}
                {props.uploadArt.rent.package_type}) KG *
              </label>
              <FormControl
                disabled={!type?.rent}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  value={props.uploadArt.rent?.rent_weight || ""}
                  fullWidth
                  placeholder="Enter Your Rent Weight"
                  type="text"
                  name="rent_weight"
                  onChange={handleChangeRent}
                />
              </FormControl>
              {thirdFormError?.rentWeight ? (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {thirdFormError?.rentWeight}
                </span>
              ) : null}
              {rentweighterror && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {rentweighterror}
                </span>
              )}
            </div>
          </Grid>
        </Grid>

        <h3 className={styles.status_avail}>Package Dimensions</h3>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Height *</label>
              <input
                className="form-control"
                onChange={handleChangeRent}
                value={props.uploadArt.rent.height || ""}
                fullWidth
                disabled={!type?.rent}
                placeholder="Enter Height"
                type="text"
                name="height"
              />
              {thirdFormError?.rentpackageheight ? (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {thirdFormError?.rentpackageheight}
                </span>
              ) : null}<br />

            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Width *</label>
              <input
                className="form-control"
                onChange={handleChangeRent}
                value={props.uploadArt.rent.width || ""}
                fullWidth
                disabled={!type?.rent}
                placeholder="Enter Width"
                type="text"
                name="width"
              />
              {thirdFormError?.rentpackageweight ? (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {thirdFormError?.rentpackageweight}
                </span>
              ) : null}<br />
              {/* <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                {saleweighterror}
              </span> */}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Depth *</label>
              <input
                className="form-control"
                onChange={handleChangeRent}
                value={props.uploadArt.rent.depth || ""}
                disabled={!type?.rent}
                fullWidth
                placeholder="Enter depth"
                type="text"
                name="depth"
              />
              {thirdFormError?.rentpackagedepth ? (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {thirdFormError?.rentpackagedepth}
                </span>
              ) : null}<br />
              {/* <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                {saleweighterror}
              </span> */}
            </div>
          </Grid>
        </Grid>
        <h3 className={styles.status_avail}>Rent Pricing</h3>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Art Smiley Commission </label>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  onChange={handleChangeRent}
                  fullWidth
                  disabled={!type.rent}
                  value={props.uploadArt.rent.artsmiley_commission_rent}
                  type="text"
                  name="artsmiley_commission_rent"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  readOnly
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Your Profit</label>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  onChange={handleChangeRent}
                  fullWidth
                  disabled={!type.rent}
                  readOnly
                  type="text"
                  name="artist_profit_rent"
                  value={props.uploadArt.rent.artist_profit_rent}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Price the Collector will see</label>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  onChange={handleChangeRent}
                  fullWidth
                  disabled={!type.rent}
                  readOnly
                  type="text"
                  name="price_of_collector_rent"
                  value={props.uploadArt.rent.price_of_collector_rent}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="form-group">
              <label>Curator Percentage</label>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  },
                }}
              >
                <OutlinedInput
                  onChange={handleChangeRent}
                  fullWidth
                  disabled={!type.rent}
                  readOnly
                  type="text"
                  name="curator_percentage_rent"
                  // value={props.uploadArt.rent.curator_percentage_rent}
                  value={(props.uploadArt.rent.curator_percentage_rent)}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControlLabel
            className={styles.checkbox_labels}
            control={
              <Checkbox
                disabled={["Sculpture", "Crafts", "Installation"].includes(
                  props.category
                )}
                name="print"
                // checked={type?.print}
                // checked={props?.uploadArt?.print?.[0]?.is_print && props?.uploadArt?.print_by_artsmiley?.[0]?.print_price> 0 || false}
                // onChange={handleChangeType}
                onChange={handlePrintChange}
                checked={props?.uploadArt?.print?.[0]?.is_print || false}
                sx={{ color: "#665D6F" }}
              />
            }
            // props?.uploadArt?.print_by_artsmiley?.[0]?.print_price>0
            label={
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "26px",
                }}
                className={styles.checkbox_label}
              >
                Print
              </Typography>
            }
          />

          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                // disabled={!type.print}
                disabled={!props?.uploadArt?.print?.[0]?.is_print}
                value="printbyme"
                onChange={() => setPrintType("printbyme")}
                checked={printType === "printbyme"}
                control={<Radio />}
                label="Limited Edition"
              />
              <FormControlLabel
                // disabled={!type.print}
                disabled={!props?.uploadArt?.print?.[0]?.is_print}
                value="printbyartsmiley"
                onChange={() => setPrintType("printbyartsmiley")}
                checked={printType === "printbyartsmiley"}
                control={<Radio />}
                label="Open Edition"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {printType === "printbyme" ? (
          <>
            {props.uploadArt?.print?.map((printItem, index) => {
              const filteredSizes = sizes.filter((size) => {
                // Check if the size is already selected for the same Material in other printItems
                const isSizeUsed = props.uploadArt.print.some(
                  (item, i) =>
                    i !== index && // Exclude the current item
                    item.material === printItem.material && // Same Material
                    item.size?.width === size.width && // Same Width
                    item.size?.height === size.height && // Same Height
                    item.is_hanged === printItem.is_hanged // Same is_hanged value
                );
                return !isSizeUsed; // Include only if the size is not used
              });

              return (
                <>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className="form-group">
                        <label>Material *</label>
                        <Select
                          labelId="select-mutli-artist"
                          id="demo-select-artist"
                          className={styles.search_select}
                          value={printItem.material}
                          onChange={(e) => {
                            const newPrints = [...props.uploadArt.print];
                            newPrints[index].material = e.target.value;
                            props.setUploadArt({
                              ...props.uploadArt,
                              print: newPrints,
                            });
                          }}
                          disabled={!printItem?.is_print}
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiInputBase-input": {
                              padding: ".45rem .75rem",
                              height: "auto",
                              borderRadius: ".25rem",
                              fontSize: "16px",
                              fontWeight: "400",
                            },
                            border: "1px solid #ced4da",
                            borderRadius: ".25rem",
                            width: "100%",
                            background: "#fff",
                          }}
                          placeholder="select Edition"
                        >
                          <MenuItem value="none" disabled>
                            None
                          </MenuItem>
                          {[
                            { _id: 1, label: "Fine Art Paper" },
                            { _id: 2, label: "Canvas" },
                            { _id: 3, label: "Metal" },
                            { _id: 4, label: "Acrylic" },
                            { _id: 5, label: "Photo Paper" },
                          ].map((val) => (
                            <MenuItem value={val.label} key={val.label}>
                              {val.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {thirdFormError?.[`printMaterial${index}`] && (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            {thirdFormError[`printMaterial${index}`]}
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      className={
                        props.uploadArt.print &&
                          props.uploadArt.print.edition === "Open"
                          ? styles.print_qty
                          : ""
                      }
                    >
                      <div className="form-group">
                        <label>Add Quantity *</label>
                        <input
                          className="form-control"
                          fullWidth
                          placeholder="---------"
                          name="qty"
                          type="number"
                          disabled={!printItem?.is_print}
                          value={printItem.qty}
                        />
                        {thirdFormError?.[`printQty${index}`] && (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            {thirdFormError[`printQty${index}`]}
                          </span>
                        )}
                      </div>
                    </Grid>
                    {props.data.print_size &&
                      props.data.print_size.map((val, i) => (
                        <Grid item xs={12} sm={6} md={6}>
                          <div className="form-group">
                            <label>{val.label} *</label>
                            <Select
                              fullWidth
                              disabled={!printItem?.is_print}
                              value={
                                props.uploadArt.print[index]?.size || {
                                  width: "",
                                  height: "",
                                } // Dynamically set the value
                              }
                              renderValue={(selected) => {
                                return selected.width && selected.height
                                  ? `${selected.width} W x ${selected.height} H`
                                  : "Select Size"; // Fallback value
                              }}
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiInputBase-input": {
                                  padding: ".45rem .75rem",
                                  height: "auto",
                                  borderRadius: ".25rem",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                },
                                border: "1px solid #ced4da",
                                borderRadius: ".25rem",
                                width: "100%",
                                background: "#fff",
                              }}
                              placeholder="select size"
                              onChange={(e) => {
                                const newPrints = [...props.uploadArt.print];
                                newPrints[index].size = e.target.value;
                                props.setUploadArt({
                                  ...props.uploadArt,
                                  print: newPrints,
                                });
                              }}
                              name="size"
                            >
                              <MenuItem disabled value="">
                                None
                              </MenuItem>
                              {filteredSizes &&
                                filteredSizes.map((val) => (
                                  <MenuItem
                                    value={{
                                      height: val.height,
                                      width: val.width,
                                    }}
                                    key={val._id}
                                  >
                                    {val.width} W x {val.height} H
                                  </MenuItem>
                                ))}
                            </Select>
                            {thirdFormError?.[`printSize${index}`] && (
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                {thirdFormError[`printSize${index}`]}
                              </span>
                            )}
                            {/* <TextField fullWidth placeholder="---------" sx={{ mt: 1 }} name="keyword" /> */}
                          </div>
                        </Grid>
                      ))}

                    <Grid item xs={12} sm={6} md={6}>
                      <div className="form-group">
                        <label> Artist Price *</label>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: ".45rem .75rem",
                              height: "auto",
                              borderRadius: ".25rem",
                              fontSize: "16px",
                              fontWeight: "400",
                              background: "#fff",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #ced4da",
                              borderRadius: ".25rem",
                              width: "100%",
                            },
                          }}
                        >
                          <OutlinedInput
                            value={printItem.print_price}
                            onChange={(e) => {
                              let value = e.target.value;

                              // Ensure only numeric values are accepted (including decimal point)
                              const numericValue = value.replace(
                                /[^0-9.]/g,
                                ""
                              ); // Removes non-numeric characters except for the decimal point

                              // If value is not a valid number, return early or handle error
                              if (isNaN(numericValue)) {
                                return; // Optionally, display an error message or prevent further action
                              }

                              // Use numeric value for calculations
                              const newPrints = [...props.uploadArt.print];
                              newPrints[index].print_price = numericValue;

                              let artsmiley_commission_print = parseFloat(
                                (
                                  numericValue *
                                  (print_percentage / 100)
                                ).toFixed(2)
                              );
                              let curatorPercentage = parseFloat(
                                (
                                  artsmiley_commission_print *
                                  (curator_percentage / 100)
                                ).toFixed(2)
                              );
                              artsmiley_commission_print = parseFloat(
                                (
                                  artsmiley_commission_print - curatorPercentage
                                ).toFixed(2)
                              );
                              const artist_profit_print = parseFloat(
                                (
                                  numericValue *
                                  ((100 - print_percentage) / 100)
                                ).toFixed(2)
                              );

                              // Update the new properties
                              newPrints[index].artsmiley_commission_print =
                                artsmiley_commission_print;
                              newPrints[index].artist_profit_print =
                                artist_profit_print;
                              newPrints[index].price_of_collector_print =
                                numericValue;
                              newPrints[index].curator_percentage_print =
                                curatorPercentage;
                              props.setUploadArt({
                                ...props.uploadArt,
                                print: newPrints,
                              });
                            }}
                            fullWidth
                            disabled={!printItem?.is_print}
                            placeholder="Enter Your Print Price"
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            type="text"
                            name="print_price"
                          />
                        </FormControl>
                        {thirdFormError?.[`printPrice${index}`] && (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            {thirdFormError[`printPrice${index}`]}
                          </span>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <div className="form-group">
                        <div className={styles.medium_child1}>
                          <label className={styles.labels}>
                            Ready to Hang*
                          </label>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="is_hanged"
                              disabled={!printItem?.is_print}
                              value={
                                printItem.is_hanged === "Yes" ? "Yes" : "No"
                              }
                              onChange={(e) => {
                                const newPrints = [...props.uploadArt.print]; // Create a shallow copy of the print array
                                const isHanged = e.target.value; // Determine if "Yes" or "No" is selected

                                // Update the specific index in the array
                                newPrints[index] = {
                                  ...newPrints[index], // Retain other properties of the object
                                  is_hanged: isHanged, // Update is_hanged value
                                  package_type: isHanged
                                    ? newPrints[index].package_type
                                    : "Tube", // Update package_type only if is_hanged is "No"
                                };

                                // Update the state with the modified array
                                props.setUploadArt({
                                  ...props.uploadArt,
                                  print: newPrints,
                                });
                              }}
                            >
                              <FormControlLabel
                                className={styles.radio_label}
                                sx={{ m: 0.5 }}
                                value="Yes"
                                control={
                                  <Radio size="small" sx={{ m: 0, p: 0.3 }} />
                                }
                                label="Yes"
                                disabled={
                                  !printItem?.is_print ||
                                  [
                                    "Sculpture",
                                    "Crafts",
                                    "Installation",
                                  ].includes(props.category)
                                }
                              />
                              <FormControlLabel
                                className={styles.radio_label}
                                sx={{ m: 0.5 }}
                                value="No"
                                control={
                                  <Radio size="small" sx={{ m: 0, p: 0.3 }} />
                                }
                                label="No"
                                disabled={
                                  !printItem?.is_print ||
                                  [
                                    "Sculpture",
                                    "Crafts",
                                    "Installation",
                                  ].includes(props.category)
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <div className="form-group">
                        <label>Tube Size *</label>
                        <Select
                          labelId="select-mutli-artist"
                          id="demo-select-artist"
                          onChange={(e) => {
                            const newPrints = [...props.uploadArt.print];
                            newPrints[index].tube_size = e.target.value;
                            props.setUploadArt({
                              ...props.uploadArt,
                              print: newPrints,
                            });
                          }}
                          name="tube_size"
                          value={printItem.tube_size}
                          disabled={
                            !printItem.is_print ||
                              printItem.is_hanged === "Yes" ||
                              ["Sculpture", "Crafts", "Installation"].includes(
                                props.category
                              )
                              ? true
                              : false
                          }
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiInputBase-input": {
                              padding: ".45rem .75rem",
                              height: "auto",
                              borderRadius: ".25rem",
                              fontSize: "16px",
                              fontWeight: "400",
                            },
                            border: "1px solid #ced4da",
                            borderRadius: ".25rem",
                            width: "100%",
                            background: "#fff",
                          }}
                          placeholder="Enter your tube size"
                        >
                          {[
                            { _id: 1, label: "Small" },
                            { _id: 2, label: "Medium" },
                            { _id: 3, label: "Large" },
                            { _id: 4, label: "Extra Large" },
                          ].map((val) => (
                            <MenuItem value={val.label} key={val.label}>
                              {val.label}
                            </MenuItem>
                          ))}
                        </Select>


                        {thirdFormError?.[`printTubeSize${index}`] && (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            {thirdFormError[`printTubeSize${index}`]}
                          </span>
                        )}
                      </div>
                    </Grid>
                    {printItem.is_hanged === "Yes" ? (
                      <>
                        <Grid item xs={12} sm={6} md={6}>
                          <div className="form-group">
                            <label>Is it Framed?</label>
                            <Select
                              disabled={!printItem?.is_print}
                              onChange={(e) => {
                                const newPrints = [...props.uploadArt.print];
                                newPrints[index].is_frame =
                                  e.target.value === "Yes";
                                props.setUploadArt({
                                  ...props.uploadArt,
                                  print: newPrints,
                                });
                              }}
                              name="is_frame"
                              value={printItem?.is_frame ? "Yes" : "No"}
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiInputBase-input": {
                                  padding: ".45rem .75rem",
                                  height: "auto",
                                  borderRadius: ".25rem",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                },
                                border: "1px solid #ced4da",
                                borderRadius: ".25rem",
                                width: "100%",
                                background: "#fff",
                              }}
                              placeholder="available frame"
                            >
                              {[
                                { _id: 1, label: "Yes" },
                                { _id: 2, label: "No" },
                              ].map((val) => (
                                <MenuItem value={val.label} key={val.label}>
                                  {val.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <div className="form-group">
                            <label>Frame Color</label>
                            <Select
                              onChange={(e) => {
                                const newPrints = [...props.uploadArt.print];
                                newPrints[index].frame_color = e.target.value;
                                props.setUploadArt({
                                  ...props.uploadArt,
                                  print: newPrints,
                                });
                              }}
                              disabled={
                                !printItem.is_print || !printItem.is_frame
                              }
                              name="frame_color"
                              value={printItem?.frame_color}
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiInputBase-input": {
                                  padding: ".45rem .75rem",
                                  height: "auto",
                                  borderRadius: ".25rem",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                },
                                border: "1px solid #ced4da",
                                borderRadius: ".25rem",
                                width: "100%",
                                background: "#fff",
                              }}
                              placeholder="Select your frame color"
                            >
                              {[
                                { _id: 1, label: "Black" },
                                { _id: 2, label: "Brown" },
                                { _id: 3, label: "White" },
                                { _id: 4, label: "Silver" },
                                { _id: 5, label: "Gold" },
                                { _id: 6, label: "Patina" },
                                { _id: 7, label: "Other" },
                              ].map((val) => (
                                <MenuItem value={val.label} key={val.label}>
                                  {val.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <div className="form-group">
                            <label>Packaging Type *</label>
                            <Select
                              onChange={(e) => {
                                const newPrints = [...props.uploadArt.print];
                                newPrints[index].package_type = e.target.value;
                                props.setUploadArt({
                                  ...props.uploadArt,
                                  print: newPrints,
                                });
                              }}
                              name="package_type"
                              value={printItem?.package_type}
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiInputBase-input": {
                                  padding: ".45rem .75rem",
                                  height: "auto",
                                  borderRadius: ".25rem",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                },
                                border: "1px solid #ced4da",
                                borderRadius: ".25rem",
                                width: "100%",
                                background: "#fff",
                              }}
                              placeholder="Select your frame color"
                            >
                              {[
                                { _id: 1, label: "Crate" },
                                { _id: 2, label: "Box" },
                              ].map((val) => (
                                <MenuItem value={val.label} key={val.label}>
                                  {val.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {thirdFormError?.[`printPackageType${index}`] && (
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                {thirdFormError[`printPackageType${index}`]}
                              </span>
                            )}
                          </div>
                        </Grid>{" "}
                      </>
                    ) : (
                      <></>
                    )}

                    <Grid item xs={12} sm={6} md={6}>
                      <div className="form-group">
                        <label>
                          Shipping weight (Artwork + {printItem?.package_type})
                          KG *
                        </label>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: ".45rem .75rem",
                              height: "auto",
                              borderRadius: ".25rem",
                              fontSize: "16px",
                              fontWeight: "400",
                              background: "#fff",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #ced4da",
                              borderRadius: ".25rem",
                              width: "100%",
                            },
                          }}
                        >
                          <OutlinedInput
                            onChange={(e) => {
                              const value = e.target.value;
                              if (isNaN(value)) {
                                e.target.value = "";
                              }
                              const newPrints = [...props.uploadArt.print];
                              newPrints[index].print_weight = e.target.value;

                              props.setUploadArt({
                                ...props.uploadArt,
                                print: newPrints,
                              });
                            }}
                            value={printItem.print_weight}
                            fullWidth
                            disabled={!printItem.is_print}
                            placeholder="Enter Your Print Weight"
                            type="text"
                            name="sale_weight"
                          />
                        </FormControl>
                        {thirdFormError?.[`printWeight${index}`] && (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            {thirdFormError[`printWeight${index}`]}
                          </span>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <h3 className={styles.status_avail}>Package Dimensions</h3>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className="form-group">
                        <label>Height *</label>
                        <input
                          className="form-control"
                          value={printItem?.height}
                          onChange={(e) => {
                            const newPrints = [...props.uploadArt.print];
                            newPrints[index].height = e.target.value;
                            props.setUploadArt({
                              ...props.uploadArt,
                              print: newPrints,
                            });
                          }}
                          fullWidth
                          disabled={!printItem.is_print}
                          placeholder="Enter Height"
                          type="text"
                          name="height"
                        />
                        {thirdFormError?.[`printpackageheight${index}`] && (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            {thirdFormError[`printpackageheight${index}`]}
                          </span>
                        )}<br />

                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className="form-group">
                        <label>Width *</label>
                        <input
                          className="form-control"
                          value={printItem?.width}
                          onChange={(e) => {
                            const newPrints = [...props.uploadArt.print];
                            newPrints[index].width = e.target.value;
                            props.setUploadArt({
                              ...props.uploadArt,
                              print: newPrints,
                            });
                          }}
                          fullWidth
                          disabled={!printItem.is_print}
                          placeholder="Enter Width"
                          type="text"
                          name="width"
                        />
                        {thirdFormError?.[`printpackageweight${index}`] && (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            {thirdFormError[`printpackageweight${index}`]}
                          </span>
                        )}<br />

                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className="form-group">
                        <label>Depth *</label>
                        <input
                          className="form-control"
                          value={printItem?.depth}
                          onChange={(e) => {
                            const newPrints = [...props.uploadArt.print];
                            newPrints[index].depth = e.target.value;
                            props.setUploadArt({
                              ...props.uploadArt,
                              print: newPrints,
                            });
                          }}
                          disabled={!printItem.is_print}
                          fullWidth
                          placeholder="Enter depth"
                          type="text"
                          name="depth"
                        />
                        {thirdFormError?.[`printpackagedepth${index}`] && (
                          <span
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            {thirdFormError[`printpackagedepth${index}`]}
                          </span>
                        )}<br />
                      </div>
                    </Grid>
                  </Grid>
                  <h3 className={styles.status_avail}>Print Pricing</h3>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className="form-group">
                        <label>Art Smiley Commission </label>

                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: ".45rem .75rem",
                              height: "auto",
                              borderRadius: ".25rem",
                              fontSize: "16px",
                              fontWeight: "400",
                              background: "#fff",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #ced4da",
                              borderRadius: ".25rem",
                              width: "100%",
                            },
                          }}
                        >
                          <OutlinedInput
                            onChange={handleChangeSale}
                            fullWidth
                            disabled={!type.sale}
                            value={printItem.artsmiley_commission_print}
                            type="text"
                            name="artsmiley_commission_print"
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            readOnly
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className="form-group">
                        <label>Your Profit</label>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: ".45rem .75rem",
                              height: "auto",
                              borderRadius: ".25rem",
                              fontSize: "16px",
                              fontWeight: "400",
                              background: "#fff",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #ced4da",
                              borderRadius: ".25rem",
                              width: "100%",
                            },
                          }}
                        >
                          <OutlinedInput
                            onChange={handleChangeSale}
                            fullWidth
                            disabled={!type.sale}
                            readOnly
                            type="text"
                            name="artist_profit_print"
                            value={printItem.artist_profit_print}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <div className="form-group">
                        <label>Price the Collector will see</label>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: ".45rem .75rem",
                              height: "auto",
                              borderRadius: ".25rem",
                              fontSize: "16px",
                              fontWeight: "400",
                              background: "#fff",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #ced4da",
                              borderRadius: ".25rem",
                              width: "100%",
                            },
                          }}
                        >
                          <OutlinedInput
                            onChange={handleChangeSale}
                            fullWidth
                            disabled={!type.sale}
                            readOnly
                            type="text"
                            name="price_of_collector_print"
                            value={printItem.price_of_collector_print}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className="form-group">
                        <label>Curator Percentage</label>
                        <FormControl
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: ".45rem .75rem",
                              height: "auto",
                              borderRadius: ".25rem",
                              fontSize: "16px",
                              fontWeight: "400",
                              background: "#fff",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #ced4da",
                              borderRadius: ".25rem",
                              width: "100%",
                            },
                          }}
                        >
                          <OutlinedInput
                            onChange={handleChangeSale}
                            fullWidth
                            readOnly
                            type="text"
                            name="curator_percentage_print"
                            value={printItem?.curator_percentage_print}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Stack
                      direction="row"
                      display="flex"
                      justifyContent="flex-end"
                      spacing={2}
                      sx={{ marginTop: "20px" }}
                    >
                      {props.uploadArt.print.length > 1 && (
                        <div>
                          <button
                            variant="contained"
                            onClick={() => handleRemoveFields(index)}
                            className="action_libtn"
                          >
                            <FiTrash2 /> Remove
                          </button>
                        </div>
                      )}
                      <div>
                        <button
                          variant="contained"
                          onClick={handleAddFields}
                          className="action_btn"
                        >
                          <FiPlus /> Add
                        </button>
                      </div>
                    </Stack>
                  </Grid>
                </>
              );
            })}
          </>
        ) : (
          <>

            <div className={styles.preview_img}>
              <input
                accept="image/*"
                type="file"
                name="image"
                id="input"
                hidden
                onChange={handleChangeMainImage}
              />
              <label htmlFor="input" className={styles.labelv}>
                {printImage ? (
                  <img
                    src={printImage}
                    alt="preview"
                    id="img"
                    name="image"
                    className={styles.img_view}
                  />
                ) : (
                  <>
                    <FiUpload />
                    <p>Upload your image</p>
                  </>
                )}
              </label>
            </div>
            {thirdFormError?.openeditionimg && (
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                {thirdFormError.openeditionimg}
                <br />
              </span>
            )}
            <p style={{ color: "gray", marginTop: "18px" }}>
              Upload High Quality Image format TIFF/JPEG (5MB - 100MB), 300 DPI
              Resolution
            </p>

            <label>
             Select printing material options available for purchase
            </label>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormGroup
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}

              >
                {materialArray.map((material) => (
                  <FormControlLabel
                    disabled={!props?.uploadArt?.print?.[0]?.is_print}
                    key={material.id}
                    checked={!!materialValues[material.value]} // Controlled by state
                    onChange={handleChange}
                    control={<Checkbox name={material.value} size="small" />}
                    label={material.label}
                  />
                ))}
              </FormGroup>

              {thirdFormError?.material01 && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {thirdFormError?.material01}
                  <br />
                </span>
              )}
            </div>
            <Grid
              container
              columnSpacing={{ sm: 2 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Artist Price</lable>
                  <p className={styles.para_slider}>
                  Adjust the Artist Price with the slider
                  </p>

                  <div
                    style={{
                      background: "#eeeeee",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Container maxWidth="sm">
                      <Slider
                        disabled={!printImage || printImage.startsWith("blob:") === false}
                        size="small"
                        value={
                          props.uploadArt.print_by_artsmiley?.[0]
                            ?.print_price || 0
                        }
                        onChange={handlePriceChange}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        min={25}
                        max={["Print", "Photography", "Digital Prints"].includes(props?.category)
                          ? 5000 :
                          props.uploadArt.sale?.price / 2}
                      />

                    </Container>

                  </div>
                  Selcted Price = Your earnings per print. View corresponding Sale Prices and Art Smiley Commission for each printing material option below
                </div>
              </Grid>

              <Grid
                container
                columnSpacing={{ sm: 2 }}
                columns={{ xs: 12, sm: 12, md: 12 }}
                px={3}
                direction="row"
                display="flex"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>Select printing material options available for purchase*</label>
                    <Select
                      labelId="select-mutli-artist"
                      id="demo-select-artist"
                      value={printPricing.material}
                      label="Material"
                      fullWidth
                      placeholder="select type"
                      onChange={handleChangePrintpricing}
                      name="material"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input": {
                          padding: ".45rem .75rem",
                          height: "auto",
                          borderRadius: ".25rem",
                          fontSize: "16px",
                          fontWeight: "400",
                        },
                        border: "1px solid #ced4da",
                        borderRadius: ".25rem",
                        width: "100%",
                        background: "#fff",
                      }}
                    >
                      {renderedMenuItems}
                    </Select>
                    {/* {thirdFormError?.[`printbyMaterial${index}`] && (
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                {thirdFormError[`printbyMaterial${index}`]}
                              </span>
                            )} */}
                  </div>
                </Grid>
                {printPricing.material === "metal" && (
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label>Metal Thickness</label>
                      <Select
                        // value={printPricing.material}
                        label="Metal Print"
                        fullWidth
                        placeholder="select type"
                        onChange={handleChangePrintpricing}
                        name="metal_print"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".45rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "16px",
                            fontWeight: "400",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                          background: "#fff",
                        }}
                      >
                        <MenuItem value="metal print 1.8 mm">1.8 mm</MenuItem>
                        <MenuItem value="metal print 2.8 mm">2.8 mm</MenuItem>
                        <MenuItem value="metal print 3.8 mm">3.8 mm</MenuItem>
                      </Select>
                    </div>
                  </Grid>
                )}
                {printPricing.material === "acrylic" && (
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label>Acrylic Thickness</label>
                      <Select
                        // value={printPricing.material}
                        label="Acrylic Print"
                        fullWidth
                        placeholder="select type"
                        onChange={handleChangePrintpricing}
                        name="metal_print"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".45rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "16px",
                            fontWeight: "400",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                          background: "#fff",
                        }}
                      >
                        <MenuItem value="acrylic print 2 mm">2 mm</MenuItem>
                        <MenuItem value="acrylic print 4 mm">4 mm</MenuItem>
                        <MenuItem value="acrylic print 6 mm">6 mm</MenuItem>
                        <MenuItem value="acrylic print 8 mm">8 mm</MenuItem>
                        <MenuItem value="acrylic print 10 mm">10 mm</MenuItem>
                      </Select>
                    </div>
                  </Grid>
                )}
                {printPricing.material === "acrylic_sandwich" && (
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                      <label>Acrylic Sandwich Thickness</label>
                      <Select
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".45rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "16px",
                            fontWeight: "400",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                          background: "#fff",
                        }}
                        // value={printPricing.material}
                        label="Acrylic Sandwich"
                        fullWidth
                        placeholder="select type"
                        onChange={handleChangePrintpricing}
                        name="metal_print"
                      >
                        <MenuItem value="acrylic sandwich 4+2 mm">
                          4+2 mm
                        </MenuItem>
                        <MenuItem value="acrylic sandwich 6+2 mm">
                          6+2 mm
                        </MenuItem>
                      </Select>
                    </div>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <PrintTableArtsmiley
                  print_percentage={print_percentage}
                  printPricingResponse={printPricingResponse}
                  width={props.uploadArt.width}
                  height={props.uploadArt.height}
                  getProductOptions={getProductOptions}
                  basePrice={
                    props.uploadArt.print_by_artsmiley?.[0].print_price
                  }
                  props={props}
                  pricingData={pricingData}
                  setPricingData={setPricingData}
                />
              </Grid>
            </Grid>
            {/* </>
                  );
                })} */}
          </>
        )}

        {window.location.pathname === "/edit/stepthird" ? (
          <div className={styles.btn_div}>
            <button onClick={handleUpdateArt} className="action_btn">
              Update
            </button>
          </div>
        ) : (
          <div className={styles.btn_div}>
            <button onClick={handleUploadArt} className="action_btn">
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
