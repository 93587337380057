import React, { useState } from "react";
import styles from "./Questionsui.module.css";
import RichTextEditor from "react-rte";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Container
} from "@mui/material";
import Entermobile from "../../../pages/applyForm/mobileNumber/Entermobile";
import Uploadimage from "../uploadImage/Uploadimage";
import { check } from "prettier";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import homeImage from "../../../../constant";
import { Errornotify, Successnotify } from "../../../../common/alert/Alert";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../../../State/api/superadminApi";
import CircularLoader from "../../../../common/loader/Loader";
import country from "../../../../Country";
import { ToastContainer } from "react-toastify";
import { DeleteOutline } from "@material-ui/icons";
import { validationForArtistApplication } from "../../../../validation/ValidationForArtistApplication";
import { validationForGalleryApplication } from "../../../../validation/ValidationForGalleryApplication";
import api from "../../../../State/api/Api";
import { FiUpload } from "react-icons/fi";
const phoneLengthByCountry = {
  ad: 9, // Andorra
  af: 9, // Afghanistan
  al: 9, // Albania
  dz: 9, // Algeria
  ao: 9, // Angola
  ag: 10, // Antigua and Barbuda
  ar: 10, // Argentina
  am: 8, // Armenia
  aw: 7, // Aruba
  au: 9, // Australia
  at: 10, // Austria
  az: 9, // Azerbaijan
  bh: 8, // Bahrain
  bd: 10, // Bangladesh
  bn: 7, // Brunei
  bs: 10, // Bahamas
  bb: 10, // Barbados
  by: 9, // Belarus
  bq: 7, // Caribbean Netherlands
  be: 9, // Belgium
  bz: 7, // Belize
  bj: 8, // Benin
  bt: 8, // Bhutan
  bo: 8, // Bolivia
  ba: 8, // Bosnia and Herzegovina
  bw: 7, // Botswana
  br: 11, // Brazil
  bg: 9, // Bulgaria
  bf: 8, // Burkina Faso
  bi: 8, // Burundi
  kh: 9, // Cambodia
  cm: 9, // Cameroon
  ca: 10, // Canada
  cv: 7, // Cape Verde
  ci: 8, // Ivory Coast
  cw: 7, // Curaçao
  cg: 9, // Congo - Brazzaville
  cd: 9, // Congo - Kinshasa
  cf: 8, // Central African Republic
  td: 8, // Chad
  cl: 9, // Chile
  cn: 11, // China
  co: 10, // Colombia
  km: 7, // Comoros
  cr: 8, // Costa Rica
  hr: 9, // Croatia
  cu: 8, // Cuba
  cy: 8, // Cyprus
  cz: 9, // Czech Republic
  dk: 8, // Denmark
  dj: 8, // Djibouti
  dm: 10, // Dominica
  do: 10, // Dominican Republic
  ec: 9, // Ecuador
  eg: 10, // Egypt
  sv: 8, // El Salvador
  gq: 9, // Equatorial Guinea
  er: 7, // Eritrea
  ee: 7, // Estonia
  et: 9, // Ethiopia
  fj: 7, // Fiji
  fi: 9, // Finland
  fr: 9, // France
  ga: 7, // Gabon
  gm: 8, // Gambia
  gp: 9, // Guadeloupe
  gu: 10, // Guam
  ge: 9, // Georgia
  de: 10, // Germany
  gh: 9, // Ghana
  gr: 10, // Greece
  gd: 10, // Grenada
  gf: 9, // French Guiana
  gt: 8, // Guatemala
  gn: 9, // Guinea
  gw: 9, // Guinea-Bissau
  gy: 7, // Guyana
  ht: 8, // Haiti
  hn: 8, // Honduras
  hk: 8, // Hong Kong
  hu: 9, // Hungary
  is: 7, // Iceland
  in: 10, // India
  id: 10, // Indonesia
  io: 7, // British Indian Ocean Territory
  ir: 10, // Iran
  iq: 10, // Iraq
  ie: 9, // Ireland
  il: 9, // Israel
  it: 10, // Italy
  jm: 7, // Jamaica
  jp: 10, // Japan
  jo: 9, // Jordan
  kz: 10, // Kazakhstan
  kp: 9, // North Korea
  ke: 10, // Kenya
  ki: 7, // Kiribati
  ks: 8, // Kosovo
  kn: 7, // Saint Kitts and Nevis
  kw: 8, // Kuwait
  kg: 9, // Kyrgyzstan
  la: 9, // Laos
  lv: 8, // Latvia
  lb: 8, // Lebanon
  ls: 8, // Lesotho
  lr: 7, // Liberia
  ly: 9, // Libya
  li: 7, // Liechtenstein
  lt: 8, // Lithuania
  lu: 9, // Luxembourg
  lc: 7, // Saint Lucia
  mo: 8, // Macau
  mg: 10, // Madagascar
  mw: 9, // Malawi
  my: 10, // Malaysia
  mv: 7, // Maldives
  ml: 8, // Mali
  mk: 8, // Macedonia
  mq: 9, // Martinique
  mt: 8, // Malta
  mh: 7, // Marshall Islands
  mr: 8, // Mauritania
  mu: 8, // Mauritius
  mx: 10, // Mexico
  fm: 7, // Micronesia
  md: 8, // Moldova
  mc: 9, // Monaco
  mn: 8, // Mongolia
  me: 8, // Montenegro
  ma: 9, // Morocco
  mz: 9, // Mozambique
  mm: 8, // Myanmar
  na: 9, // Namibia
  nc: 8, // New Caledonia
  nr: 7, // Nauru
  np: 10, // Nepal
  nl: 9, // Netherlands
  nz: 9, // New Zealand
  ni: 8, // Nicaragua
  ne: 8, // Niger
  ng: 10, // Nigeria
  no: 8, // Norway
  om: 8, // Oman
  pk: 10, // Pakistan
  pw: 7, // Palau
  pa: 7, // Panama
  pf: 8, // French Polynesia
  pg: 8, // Papua New Guinea
  py: 9, // Paraguay
  pe: 9, // Peru
  ph: 10, // Philippines
  pl: 9, // Poland
  pt: 9, // Portugal
  ps: 9, // Palestine
  pr: 10, // Puerto Rico
  qa: 8, // Qatar
  ro: 10, // Romania
  ru: 10, // Russia
  re: 9, // Reunion
  rw: 9, // Rwanda
  ws: 7, // Samoa
  sa: 9, // Saudi Arabia
  sn: 9, // Senegal
  sm: 9, // San Marino
  st: 7, // Sao Tome and Principe
  ss: 9, // South Sudan
  sz: 8, // Swaziland
  rs: 9, // Serbia
  sc: 7, // Seychelles
  sl: 8, // Sierra Leone
  sg: 8, // Singapore
  sk: 9, // Slovakia
  si: 8, // Slovenia
  sb: 7, // Solomon Islands
  so: 7, // Somalia
  za: 9, // South Africa
  kr: 10, // South Korea
  es: 9, // Spain
  lk: 9, // Sri Lanka
  sd: 9, // Sudan
  sr: 7, // Suriname
  se: 9, // Sweden
  ch: 9, // Switzerland
  sy: 9, // Syria
  tw: 9, // Taiwan
  tj: 9, // Tajikistan
  tz: 9, // Tanzania
  th: 9, // Thailand
  tl: 7, // Timor-Leste
  tg: 8, // Togo
  to: 7, // Tonga
  tt: 10, // Trinidad and Tobago
  tn: 8, // Tunisia
  tr: 10, // Turkey
  tm: 8, // Turkmenistan
  tv: 6, // Tuvalu
  ug: 9, // Uganda
  ua: 9, // Ukraine
  vc: 7, // Saint Vincent and the Grenadines
  va: 9, // Vatican CityS
  ae: 9, // United Arab Emirates
  gb: 10, // United Kingdom
  us: 10, // United States
  uy: 9, // Uruguay
  uz: 9, // Uzbekistan
  vu: 7, // Vanuatu
  ve: 7, // Venezuela
  vn: 10, // Vietnam
  ye: 9, // Yemen
  zm: 9, // Zambia
  zw: 9, // Zimbabwe
};
const nationalities = [
  { code: "AD", label: "Andorran" },
  { code: "AE", label: "Emirati" },
  { code: "AF", label: "Afghan" },
  { code: "AG", label: "Antiguan or Barbudan" },
  { code: "AI", label: "Anguillian" },
  { code: "AL", label: "Albanian" },
  { code: "AM", label: "Armenian" },
  { code: "AO", label: "Angolan" },
  { code: "AQ", label: "Antarctican" },
  { code: "AR", label: "Argentine" },
  { code: "AS", label: "American Samoan" },
  { code: "AT", label: "Austrian" },
  { code: "AU", label: "Australian" },
  { code: "AW", label: "Aruban" },
  { code: "AX", label: "Ålander" },
  { code: "AZ", label: "Azerbaijani" },
  { code: "BA", label: "Bosnian" },
  { code: "BB", label: "Barbadian" },
  { code: "BD", label: "Bangladeshi" },
  { code: "BE", label: "Belgian" },
  { code: "BF", label: "Burkinabe" },
  { code: "BG", label: "Bulgarian" },
  { code: "BH", label: "Bahraini" },
  { code: "BI", label: "Burundian" },
  { code: "BJ", label: "Beninese" },
  { code: "BL", label: "Barthélemois" },
  { code: "BM", label: "Bermudian" },
  { code: "BN", label: "Bruneian" },
  { code: "BO", label: "Bolivian" },
  { code: "BR", label: "Brazilian" },
  { code: "BS", label: "Bahamian" },
  { code: "BT", label: "Bhutanese" },
  { code: "BV", label: "Bouvet Islander" },
  { code: "BW", label: "Botswana" },
  { code: "BY", label: "Belarusian" },
  { code: "BZ", label: "Belizean" },
  { code: "CA", label: "Canadian" },
  { code: "CC", label: "Cocos Islander" },
  { code: "CD", label: "Congolese (DRC)" },
  { code: "CF", label: "Central African" },
  { code: "CG", label: "Congolese (Republic)" },
  { code: "CH", label: "Swiss" },
  { code: "CI", label: "Ivorian" },
  { code: "CK", label: "Cook Islander" },
  { code: "CL", label: "Chilean" },
  { code: "CM", label: "Cameroonian" },
  { code: "CN", label: "Chinese" },
  { code: "CO", label: "Colombian" },
  { code: "CR", label: "Costa Rican" },
  { code: "CU", label: "Cuban" },
  { code: "CV", label: "Cape Verdean" },
  { code: "CW", label: "Curaçaoan" },
  { code: "CX", label: "Christmas Islander" },
  { code: "CY", label: "Cypriot" },
  { code: "CZ", label: "Czech" },
  { code: "DE", label: "German" },
  { code: "DJ", label: "Djiboutian" },
  { code: "DK", label: "Danish" },
  { code: "DM", label: "Dominican" },
  { code: "DO", label: "Dominican" },
  { code: "DZ", label: "Algerian" },
  { code: "EC", label: "Ecuadorian" },
  { code: "EE", label: "Estonian" },
  { code: "EG", label: "Egyptian" },
  { code: "EH", label: "Western Saharan" },
  { code: "ER", label: "Eritrean" },
  { code: "ES", label: "Spanish" },
  { code: "ET", label: "Ethiopian" },
  { code: "FI", label: "Finnish" },
  { code: "FJ", label: "Fijian" },
  { code: "FK", label: "Falkland Islander" },
  { code: "FM", label: "Micronesian" },
  { code: "FO", label: "Faroe Islander" },
  { code: "FR", label: "French" },
  { code: "GA", label: "Gabonese" },
  { code: "GB", label: "British" },
  { code: "GD", label: "Grenadian" },
  { code: "GE", label: "Georgian" },
  { code: "GF", label: "French Guianese" },
  { code: "GG", label: "Guernsey" },
  { code: "GH", label: "Ghanaian" },
  { code: "GI", label: "Gibraltarian" },
  { code: "GL", label: "Greenlandic" },
  { code: "GM", label: "Gambian" },
  { code: "GN", label: "Guinean" },
  { code: "GP", label: "Guadeloupean" },
  { code: "GQ", label: "Equatorial Guinean" },
  { code: "GR", label: "Greek" },
  { code: "GS", label: "South Georgian" },
  { code: "GT", label: "Guatemalan" },
  { code: "GU", label: "Guamanian" },
  { code: "GW", label: "Bissau-Guinean" },
  { code: "GY", label: "Guyanese" },
  { code: "HK", label: "Hong Konger" },
  { code: "HM", label: "Heard Islander" },
  { code: "HN", label: "Honduran" },
  { code: "HR", label: "Croatian" },
  { code: "HT", label: "Haitian" },
  { code: "HU", label: "Hungarian" },
  { code: "ID", label: "Indonesian" },
  { code: "IE", label: "Irish" },
  { code: "IL", label: "Israeli" },
  { code: "IM", label: "Manx" },
  { code: "IN", label: "Indian" },
  { code: "IO", label: "British Indian Ocean Territory" },
  { code: "IQ", label: "Iraqi" },
  { code: "IR", label: "Iranian" },
  { code: "IS", label: "Icelander" },
  { code: "IT", label: "Italian" },
  { code: "JE", label: "Jerseyman" },
  { code: "JM", label: "Jamaican" },
  { code: "JO", label: "Jordanian" },
  { code: "JP", label: "Japanese" },
  { code: "KE", label: "Kenyan" },
  { code: "KG", label: "Kyrgyzstani" },
  { code: "KH", label: "Cambodian" },
  { code: "KI", label: "Kiribati" },
  { code: "KM", label: "Comoran" },
  { code: "KN", label: "Kittitian or Nevisian" },
  { code: "KP", label: "North Korean" },
  { code: "KR", label: "South Korean" },
  { code: "KW", label: "Kuwaiti" },
  { code: "KY", label: "Caymanian" },
  { code: "KZ", label: "Kazakh" },
  { code: "LA", label: "Laotian" },
  { code: "LB", label: "Lebanese" },
  { code: "LC", label: "Saint Lucian" },
  { code: "LI", label: "Liechtensteiner" },
  { code: "LK", label: "Sri Lankan" },
  { code: "LR", label: "Liberian" },
  { code: "LS", label: "Basotho" },
  { code: "LT", label: "Lithuanian" },
  { code: "LU", label: "Luxembourger" },
  { code: "LV", label: "Latvian" },
  { code: "LY", label: "Libyan" },
  { code: "MA", label: "Moroccan" },
  { code: "MC", label: "Monegasque" },
  { code: "MD", label: "Moldovan" },
  { code: "ME", label: "Montenegrin" },
  { code: "MF", label: "Saint Martin" },
  { code: "MG", label: "Malagasy" },
  { code: "MH", label: "Marshallese" },
  { code: "MK", label: "Macedonian" },
  { code: "ML", label: "Malian" },
  { code: "MM", label: "Burmese" },
  { code: "MN", label: "Mongolian" },
  { code: "MO", label: "Macao" },
  { code: "MP", label: "Northern Mariana Islander" },
  { code: "MQ", label: "Martinican" },
  { code: "MR", label: "Mauritanian" },
  { code: "MS", label: "Montserratian" },
  { code: "MT", label: "Maltese" },
  { code: "MU", label: "Mauritian" },
  { code: "MV", label: "Maldivian" },
  { code: "MW", label: "Malawian" },
  { code: "MX", label: "Mexican" },
  { code: "MY", label: "Malaysian" },
  { code: "MZ", label: "Mozambican" },
  { code: "NA", label: "Namibian" },
  { code: "NC", label: "New Caledonian" },
  { code: "NE", label: "Nigerien" },
  { code: "NF", label: "Norfolk Islander" },
  { code: "NG", label: "Nigerian" },
  { code: "NI", label: "Nicaraguan" },
  { code: "NL", label: "Dutch" },
  { code: "NO", label: "Norwegian" },
  { code: "NP", label: "Nepali" },
  { code: "NR", label: "Nauruan" },
  { code: "NU", label: "Niuean" },
  { code: "NZ", label: "New Zealander" },
  { code: "OM", label: "Omani" },
  { code: "PA", label: "Panamanian" },
  { code: "PE", label: "Peruvian" },
  { code: "PF", label: "French Polynesian" },
  { code: "PG", label: "Papua New Guinean" },
  { code: "PH", label: "Filipino" },
  { code: "PK", label: "Pakistani" },
  { code: "PL", label: "Polish" },
  { code: "PM", label: "Saint-Pierrais or Miquelonnais" },
  { code: "PN", label: "Pitcairn Islander" },
  { code: "PR", label: "Puerto Rican" },
  { code: "PT", label: "Portuguese" },
  { code: "PW", label: "Palauan" },
  { code: "PY", label: "Paraguayan" },
  { code: "QA", label: "Qatari" },
  { code: "RE", label: "Réunionese" },
  { code: "RO", label: "Romanian" },
  { code: "RS", label: "Serbian" },
  { code: "RU", label: "Russian" },
  { code: "RW", label: "Rwandan" },
  { code: "SA", label: "Saudi Arabian" },
  { code: "SB", label: "Solomon Islander" },
  { code: "SC", label: "Seychellois" },
  { code: "SD", label: "Sudanese" },
  { code: "SE", label: "Swedish" },
  { code: "SG", label: "Singaporean" },
  { code: "SH", label: "Saint Helenian" },
  { code: "SI", label: "Slovenian" },
  { code: "SJ", label: "Svalbardian" },
  { code: "SK", label: "Slovak" },
  { code: "SL", label: "Sierra Leonean" },
  { code: "SM", label: "Sammarinese" },
  { code: "SN", label: "Senegalese" },
  { code: "SO", label: "Somali" },
  { code: "SR", label: "Surinamese" },
  { code: "SS", label: "South Sudanese" },
  { code: "ST", label: "Sao Tomean" },
  { code: "SV", label: "Salvadoran" },
  { code: "SX", label: "Sint Maarten" },
  { code: "SY", label: "Syrian" },
  { code: "SZ", label: "Swazi" },
  { code: "TC", label: "Turks and Caicos Islander" },
  { code: "TD", label: "Chadian" },
  { code: "TF", label: "French Southern and Antarctic Lands" },
  { code: "TG", label: "Togolese" },
  { code: "TH", label: "Thai" },
  { code: "TJ", label: "Tajikistani" },
  { code: "TK", label: "Tokelauan" },
  { code: "TL", label: "Timorese" },
  { code: "TM", label: "Turkmen" },
  { code: "TN", label: "Tunisian" },
  { code: "TO", label: "Tongan" },
  { code: "TR", label: "Turkish" },
  { code: "TT", label: "Trinidadian" },
  { code: "TV", label: "Tuvaluan" },
  { code: "TZ", label: "Tanzanian" },
  { code: "UA", label: "Ukrainian" },
  { code: "UG", label: "Ugandan" },
  { code: "UM", label: "U.S. Minor Outlying Islands" },
  { code: "UN", label: "United Nations" },
  { code: "US", label: "American" },
  { code: "UY", label: "Uruguayan" },
  { code: "UZ", label: "Uzbekistani" },
  { code: "VA", label: "Vatican" },
  { code: "VC", label: "Saint Vincentian" },
  { code: "VE", label: "Venezuelan" },
  { code: "VG", label: "British Virgin Islander" },
  { code: "VI", label: "American Virgin Islander" },
  { code: "VN", label: "Vietnamese" },
  { code: "VU", label: "Vanuatuan" },
  { code: "WF", label: "Wallis and Futunan" },
  { code: "WS", label: "Samoan" },
  { code: "YE", label: "Yemeni" },
  { code: "YT", label: "Mayottean" },
  { code: "ZA", label: "South African" },
  { code: "ZM", label: "Zambian" },
  { code: "ZW", label: "Zimbabwean" },
];
const languages = [
  { code: "en", name: "English" },
  { code: "hi", name: "Hindi" },
  { code: "ar", name: "Arabic" },
  { code: "es", name: "Spanish" },
  { code: "fr", name: "French" },
  { code: "de", name: "German" },
  { code: "zh", name: "Chinese" },
  { code: "ja", name: "Japanese" },
  { code: "ru", name: "Russian" },
  { code: "pt", name: "Portuguese" },
  { code: "it", name: "Italian" },
  { code: "ko", name: "Korean" },
  { code: "tr", name: "Turkish" },
  { code: "vi", name: "Vietnamese" },
  { code: "nl", name: "Dutch" },
  { code: "sv", name: "Swedish" },
  { code: "pl", name: "Polish" },
  { code: "uk", name: "Ukrainian" },
  { code: "el", name: "Greek" },
  { code: "he", name: "Hebrew" },
  { code: "th", name: "Thai" },
  { code: "cs", name: "Czech" },
  { code: "fi", name: "Finnish" },
  { code: "no", name: "Norwegian" },
  { code: "da", name: "Danish" },
  { code: "hu", name: "Hungarian" },
  { code: "ro", name: "Romanian" },
  { code: "sk", name: "Slovak" },
  { code: "bg", name: "Bulgarian" },
  { code: "hr", name: "Croatian" },
  { code: "sr", name: "Serbian" },
  { code: "lt", name: "Lithuanian" },
  { code: "lv", name: "Latvian" },
  { code: "et", name: "Estonian" },
  { code: "sl", name: "Slovenian" },
  { code: "is", name: "Icelandic" },
  { code: "ga", name: "Irish" },
  { code: "mt", name: "Maltese" },
  { code: "cy", name: "Welsh" },
  { code: "sq", name: "Albanian" },
  { code: "mk", name: "Macedonian" },
  { code: "bs", name: "Bosnian" },
  { code: "ms", name: "Malay" },
  { code: "sw", name: "Swahili" },
  { code: "am", name: "Amharic" },
  { code: "fa", name: "Persian" },
  { code: "ur", name: "Urdu" },
  { code: "bn", name: "Bengali" },
  { code: "ta", name: "Tamil" },
  { code: "te", name: "Telugu" },
  { code: "mr", name: "Marathi" },
  { code: "gu", name: "Gujarati" },
  { code: "kn", name: "Kannada" },
  { code: "ml", name: "Malayalam" },
  { code: "pa", name: "Punjabi" },
  { code: "si", name: "Sinhala" },
  { code: "ne", name: "Nepali" },
  { code: "km", name: "Khmer" },
  { code: "my", name: "Burmese" },
  { code: "lo", name: "Lao" },
  { code: "mn", name: "Mongolian" },
  { code: "kk", name: "Kazakh" },
  { code: "uz", name: "Uzbek" },
  { code: "hy", name: "Armenian" },
  { code: "az", name: "Azerbaijani" },
  { code: "ka", name: "Georgian" },
  // Add more languages as needed
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 500],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export default function Questionsui(props) {

  const numberRegex = props.apply?.exp?.length
    ? new RegExp("^[0-9]+$").test(props.apply?.exp)
    : false;
  const [error, setError] = useState("")
  // console.log(error,'error')
  const [image, setImage] = useState("");
  const [imageBestWorks, setImageBestWorks] = useState("");
  
  const [src, setSrc] = useState({
    cv: "",
    id: "",
  });
  const [age, setAge] = React.useState("");
  const [achivement, setAchivement] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me|ir|pk|ae|ue)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;

  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const loader = useSelector((state) => state.loader);
  const categorydt = useSelector((state) => state.categoryData.data);
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const handleChangeNumber = (vl) => {
    props.setApply({ ...props.apply, phone: vl });
  };

  const handleChangeNumberCurator = (value, country) => {
    // Get country code and expected length
    const countryCode = country.countryCode; // e.g., 'np' for Nepal
    const dialCode = country.dialCode; // e.g., '977'
    const expectedLength = phoneLengthByCountry[countryCode]; // e.g., 10 for Nepal

    // Clean the phone number input, stripping non-numeric characters
    let phoneNumber = value.replace(/[^0-9]/g, "");

    // Remove the dial code from the phone number before validation
    if (phoneNumber.startsWith(dialCode)) {
      phoneNumber = phoneNumber.slice(dialCode.length);
    }

    // Prevent user from entering more digits than the expected length
    if (expectedLength && phoneNumber.length === expectedLength) {
      phoneNumber = phoneNumber.slice(0, expectedLength);
    }

    // Construct the formatted value with the country dial code
    const formattedValue = `+${dialCode}${phoneNumber}`;

    // Update the phone state with the formatted value
    props.setApply({ ...props.apply, phone: formattedValue });

    // Validate length for error display
    if (expectedLength && phoneNumber.length !== expectedLength) {
      setError(
        `Phone number must be ${expectedLength} digits long for this country code`
      );
    } else {
      setError(""); // Clear error if the length is valid
    }
  };
  const handleNavigate = () => {
    navigate("/artist/applyform");
  };

  const isValidURL = (url) => {
    // Regular expression to match URLs
    const regex =
      /^(https:\/\/www\.|http:\/\/www\.|www\.|https:\/\/|)[a-zA-Z0-9\-_$]+\.[a-zA-Z]{2,3}(\/[a-zA-Z0-9\-_$]{0,}|){0,}$/g;
    // const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
  };
  const youtubeRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/.+$/;

  const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*\/?(\/\?.*)?$/;


  const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+(\/?.*)?$/;

  const twitterRegex = /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+(\/?.*)?$/;


  const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.-]+(\/?.*)?$/;



  const validateAndHandle = (url, errormsg, social_name) => {
    let isValid = true;

    if (social_name === "linked_in" && !linkedinRegex.test(url)) {
      Errornotify(errormsg);
      isValid = false;
    }

    if (social_name === "twitter" && !twitterRegex.test(url)) {
      Errornotify(errormsg);
      isValid = false;
    }

    if (social_name === "instagram" && !instagramRegex.test(url)) {
      Errornotify(errormsg);
      isValid = false;
    }

    if (social_name === "facebook" && !facebookRegex.test(url)) {
      Errornotify(errormsg);
      isValid = false;
    }

    if (social_name === "youtube" && !youtubeRegex.test(url)) {
      Errornotify(errormsg);
      isValid = false;
    }

    // Return true if valid, false if not
    return isValid;
  };
  const handleChangeImage = (e) => {
    const name = e.target.name;
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);

      if (name === "portfolio_file") {
        // setSrc({ ...src, profile: imgs });
        const validTypes = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]; // pdf and word doc types

        if (!validTypes.includes(img.type)) {
          Errornotify("Please upload a PDF or Word document.");
          e.target.value = "";
        } else if (img.size > 10 * 1024 * 1024) { // Check if file size exceeds 1MB
          Errornotify("File size should be less than or equal to 10 MB");
          e.target.value = ""; // Clear input field for large file
        } else {
          props.setApply({ ...props.apply, portfolio_file: img });
        }
      }
      if (name === "best_work") {
        if (img.type !== "image/png" && img.type !== "image/jpeg") {
          Errornotify("Please upload an image in png/jpeg format");
          e.target.value = ""; // Clear input field for invalid img type
        } else {
          props.setApply({ ...props.apply, best_work: img });
          setImageBestWorks(img);
        }
      }
      setImage(e.target.files[0]);

    }
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",

  ];


  const [email_errors, setEmail_Errors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);


  const role = props.type === "affiliate" ? "affiliate" : "curator"
  const email_value = props.type === "affiliate" ? props?.apply?.email : props?.apply?.curator_email



  const handleCheckEmailExistOrNot = async () => {
    const { data } = await api.applicationApi("api/user/checkEmailIsExistOrNot", "POST", { email: email_value, role: role })

    if (data.success === false) {
      setEmail_Errors({ ...email_errors, email: "This email is already registered. Please use a different email address." })

      setIsEmailValid(false);
    } else {
      setEmail_Errors({ ...email_errors, email: "" })
      setIsEmailValid(true);
    }
  }

  const handleEmailValidation = (email) => {
    // Regex to validate email ending with .com
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) && email.endsWith(".com");
  };

  // useEffect(() => {
  //   if (
  //     (props.apply.email || props.apply.curator_email) && 
  //     handleEmailValidation(email_value)
  //   ) {
  //     handleCheckEmailExistOrNot();
  //   }
  // }, [props.apply.email, props.apply.curator_email]);

  const [othersReason, setOthersReason] = useState(sessionStorage.getItem("othersReason") || "");
  const [othersReasonArray, setOtherReasonArray] = useState([]);
  const [othersReasonCategory, setOthersReasonCategory] = useState(sessionStorage.getItem("othersReasonCategory") || "");
  const [othersReasonArrayCategory, setOtherReasonArrayCategory] = useState([]);

  const handleChangeKeyword = (e) => {
    const { value } = e.target;
    // Uncomment this line if you want to add the value on every change
    setOthersReason(value);
    sessionStorage.setItem("othersReason", value);
  };

  const handleKeywordChangeRemove = () => {
    setOtherReasonArray([]);
    setOthersReason("");
    sessionStorage.removeItem("othersReason");
  }

  const handleKeywordChangeRemoveCategory = () => {
    setOtherReasonArrayCategory([]);
    setOthersReasonCategory("");
    sessionStorage.removeItem("othersReasonCategory");
  }
  const handleChangeKeywordCategory = (e) => {
    const { value } = e.target;
    // Uncomment this line if you want to add the value on every change

    setOthersReasonCategory(value);
    sessionStorage.setItem("othersReasonCategory", value);
  };



  const renderPhotos = (source) => {
    return source?.map((photo, index) => {
      return (
        <div className={styles.photoWrapper} key={index}>
          {" "}
          {/* Wrapper div with unique key */}
          <img
            className={styles.imgs}
            src={photo}
            alt=""
            style={{ cursor: "pointer" }}
          />
          <div className={styles.del_img_div}>
            <IconButton onClick={() => props.handleRemoveImage(index)}>
              <img
                src="/img/delete.png"
                height="15px"
                width="14px"
                alt="delete icon"
              />
            </IconButton>
          </div>
        </div>
      );
    });
  };
  const renderPhotosGallery = (source) => {
    return source?.map((photo, index) => {
      return (
        <div className={styles.photoWrapper} key={index}>
          {" "}
          {/* Wrapper div with unique key */}
          <img
            className={styles.imgs}
            src={photo}
            alt=""
            style={{ cursor: "pointer" }}
          />
          <div className={styles.del_img_div}>
            <IconButton onClick={() => props.handleRemoveImageGallery(index)}>
              <img
                src="/img/delete.png"
                height="15px"
                width="14px"
                alt="delete icon"
              />
            </IconButton>
          </div>
        </div>
      );
    });
  };
  const [artistApplicationErrors, setArtistApplicationErrors] = useState({});

  const [galleryApplicationErrors, setGalleryApplicationErrors] = useState({});

  return (
    <>
      {props.type === "artist" ? (
        <div className={styles.mains}>
          <Container maxWidth="xl" sx={{
            paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
            paddingRight: { xs: '16px', sm: '24px', md: '100px' },
          }}>
            <Grid container spacing={2} sx={{
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Grid item xs={12} sm={10} md={10} lg={9}>
                <div className={styles.components}>
                  <div className={styles.logo_img}>
                    <img onClick={() => navigate("/")} src={"/img/updated_logo.png"} className="img-fluid" alt="" />
                  </div>
                  <BorderLinearProgress
                    color="primary"
                    variant="determinate"
                    value={props.steps.stepper}
                  />
                  {props.steps.step === "1" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          <span>1</span>
                        </div>
                        <h2>Artistic experience plays an important role in our selection process. Incomplete information will not be taken into consideration. </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className="form-group">
                          <label>
                          Please upload your latest portfolio in word doucment or pdf
                          <small className="wrtsmall">(Max Size 100MB)</small>
                          </label>
                          <div
                            className={styles.preview_img}

                          >
                            {props.apply.CV === "" ? (
                              <div className={styles.imguplod}>
                                <div
                                  htmlFor="input"
                                  style={{ textAlign: "center" }}
                                  onClick={props.handleChangeImage}
                                >
                                  <input
                                    name="CV"
                                    onChange={props.handleChangeImage}
                                    type="file"
                                    id="CV"
                                    hidden
                                    accept=".pdf, .doc, .docx" 
                                  />
                                  <label htmlFor="CV" className={styles.labelv}>
                                    <FiUpload />
                                    <p>Upload Your Latest Portfolio</p>
                                  </label>

                                </div>
                              </div>
                            ) : (
                              <>
                                <div className={styles.imguplod}>
                                  <div
                                    htmlFor="input"
                                    style={{ textAlign: "center" }}
                                    onClick={props.handleChangeImage}
                                  >
                                    <input
                                      name="CV"
                                      onChange={props.handleChangeImage}
                                      type="file"
                                      id="CV"
                                      hidden
                                      accept=".pdf, .doc, .docx" 
                                    />
                                    <label htmlFor="CV" className={styles.labelv}>
                                      <FiUpload />
                                      <p>{props.apply?.CV?.name}</p>
                                    </label>

                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          
                        </div>
                        <div className="form-group">
                          <label>
                          <span className="lablhdng">About Artist:</span>
                          Please write about yourself, education, art qualitfications, inspiration behind your artworks etc *
                          </label>
                          <ReactQuill
                            className="reactQuilStle"

                            value={props.apply.about}
                            onChange={props.handleChangeTextEditor("about")}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                          />
                          {artistApplicationErrors.about && (
                            <small
                              style={{
                                color: "red",
                                fontWeight: "500", 
                              }}
                            >
                              {artistApplicationErrors.about}
                            </small>
                          )}
                        </div>
                        <div className="form-group">
                          <label>
                          <span className="lablhdng">Professional Details:</span>
                          What are your most important exhibitions, awards/prizes, etc? *
                          <small className="wrtsmall">Please provide us with the name, the year and the place of each experience.</small>
                          </label>
                          <ReactQuill
                            className="reactQuilStle"

                            value={props.apply.award_prize}
                            onChange={props.handleChangeTextEditor("award_prize")}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                          />
                         
                          {artistApplicationErrors.award_prize && (
                            <small
                              style={{
                                color: "red",
                                fontWeight: "500",
                                display:"block"
                              }}
                            >
                              {artistApplicationErrors.award_prize}
                            </small>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Upload your best artworks images *
                          <small className="wrtsmall">(Upload: Max-10 and Min-2 Artworks, File format: PNG/JPG, Max Size: 2MB)</small>
                          </label>
                          <div
                            className={styles.preview_img}>
                            <div className={styles.imguplod}>
                              <div htmlFor="input" style={{ textAlign: "center" }}>
                                <input
                                  name="art_work"
                                  onChange={props.handleImageChangeMultiple}
                                  type="file"
                                  id="art_work"
                                  hidden
                                  accept="image/*"
                                />
                                <label htmlFor="art_work" className={styles.labelv}>
                                  <FiUpload />
                                  <p>Upload Your Art Works</p>
                                </label>

                              </div>
                            </div>
                          </div>
                          {artistApplicationErrors.art_work && (
                            <small
                              style={{
                                color: "red",
                                fontWeight: "500",
                                display:"block"
                              }}
                            >
                              {artistApplicationErrors.art_work}
                            </small>
                          )}

                        </div>
                        <div className={styles.result}>
                          {renderPhotos(props.selectedFiles)}
                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => {
                              const { isValid, artistApplicationErrors } =
                                validationForArtistApplication(
                                  props.apply.about,
                                  props.apply.award_prize,
                                  props.apply.art_work
                                );
                              if (isValid) {
                                props.handleSteps();
                                setArtistApplicationErrors({});
                              } else {
                                console.log(artistApplicationErrors, 'artistApplicationErrors')
                                setArtistApplicationErrors(artistApplicationErrors);
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : props.steps.step === "2" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          2
                        </div>
                        <h2> Are you a full time professional artist? *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className={styles.inputs_multi}>
                            <div className={styles.checkbox_div}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"

                                name="is_fulltime_artist"
                                onChange={props.handleChangeApplyData}
                                value={props.apply.is_fulltime_artist}
                              >

                                <FormControlLabel
                                  sx={{
                                    backgroundColor: "#F6F6F6",
                                    borderRadius: "6px",
                                    marginLeft: "0",
                                    paddingRight: "12px",
                                    marginBottom:"5px"
                                  }}
                                  value="Yes"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  sx={{
                                    backgroundColor: "#F6F6F6",
                                    borderRadius: "6px",
                                    marginLeft: "0",
                                    paddingRight: "12px",
                                    marginBottom:"5px"
                                  }}
                                  value="No"
                                  control={<Radio />}
                                  label="No"
                                />

                              </RadioGroup>
                            </div>
                          </div>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("1")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.is_fulltime_artist) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please select Yes or No");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "3" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          3
                        </div>
                        <h2>How many years of experience you have as an artist? *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            variant="standard"
                            value={props.apply.exp}
                            className={styles.input_text}
                            fullWidth
                            focused
                            name="exp"
                            placeholder="Enter Experience"
                            type="text"
                            error={
                              props.apply.exp.trim() !== "" &&
                              isNaN(props.apply.exp.trim())
                            }
                            color={
                              !props.apply.exp
                                ? ""
                                : isNaN(props.apply.exp.trim())
                                  ? "error"
                                  : "success"
                            }
                            onChange={props.handleChangeApplyData}
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("2")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            // onClick={() => handleStepsss("/applyform")}
                            onClick={() => {
                              if (props.apply?.exp.length) {
                                if (!isNaN(props.apply?.exp)) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("only numbers allowed");
                                }
                              } else {
                                Errornotify("kindly mention your experience");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "4" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          4
                        </div>
                        <h2>
                        If you are represented by another online art gallery, Please provide URL links of your artworks on those galleries
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            className={styles.input_text}
                            fullWidth
                            name="online_art_gallary_URL"
                            value={props.apply.online_art_gallary_URL}
                            focused
                            error={
                              props.apply.online_art_gallary_URL === ""
                                ? false
                                : !props.apply.online_art_gallary_URL.match(regex)
                            }
                            color={
                              props.apply.online_art_gallary_URL.length === 0
                                ? ""
                                : "success"
                            }
                            placeholder="www.example.com"
                            type="text"
                            onChange={props.handleChangeApplyData}
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("3")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply.online_art_gallary_URL.length > 0) {
                                if (props.apply.online_art_gallary_URL.match(regex)) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("Kindly enter a valid url");
                                }
                              } else {
                                props.handleSteps();
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "5" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          5
                        </div>
                        <h2>Please provide the link to your online portfolio (Artist Website)</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            className={styles.input_text}
                            fullWidth
                            name="portfolio"
                            focused
                            error={
                              props.apply.portfolio === ""
                                ? false
                                : !props.apply.portfolio.match(regex)
                            }
                            color={props.apply.portfolio.length === 0 ? "" : "success"}
                            value={props.apply.portfolio}
                            placeholder="www.example.com"
                            type="text"
                            onChange={props.handleChangeApplyData}
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("4")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply.portfolio.length > 0) {
                                if (props.apply.portfolio.match(regex)) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("Kindly enter a valid url");
                                }
                              } else {
                                props.handleSteps();
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : props.steps.step === "6" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          6
                        </div>
                        <h2>
                          Please provide us the links of your social media platform
                          here
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <Grid
                            container
                            spacing={2}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <div className="form-group">
                                <input
                                  className={styles.input_text}
                                  name="linked_in"
                                  focused
                                  error={
                                    props.apply.social_links.linked_in === ""
                                      ? false
                                      : !props.apply.social_links.linked_in.match(
                                        linkedinRegex
                                      )
                                  }
                                  color={
                                    props.apply.social_links.linked_in.length === 0
                                      ? ""
                                      : "success"
                                  }
                                  value={props.apply.social_links.linked_in}
                                  placeholder="www.linkedin.com/in/example"
                                  type="text"
                                  onChange={props.handleChangeSocialLinks}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <div className="form-group">
                                <input
                                  className={styles.input_text}
                                  focused
                                  error={
                                    props.apply.social_links.facebook === ""
                                      ? false
                                      : !props.apply.social_links.facebook.match(
                                        facebookRegex
                                      )
                                  }
                                  color={
                                    props.apply.social_links.facebook === ""
                                      ? ""
                                      : "success"
                                  }
                                  name="facebook"
                                  value={props.apply.social_links.facebook}
                                  placeholder="www.facebook.com/example"
                                  type="text"
                                  onChange={props.handleChangeSocialLinks}
                                />
                              </div>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <div className="form-group">
                                <input
                                  className={styles.input_text}
                                  name="instagram"
                                  focused
                                  error={
                                    props.apply.social_links.instagram === ""
                                      ? false
                                      : !props.apply.social_links.instagram.match(
                                        instagramRegex
                                      )
                                  }
                                  color={
                                    props.apply.social_links.instagram === ""
                                      ? ""
                                      : "success"
                                  }
                                  value={props.apply.social_links.instagram}
                                  placeholder="www.instagram.com/example"
                                  type="text"
                                  onChange={props.handleChangeSocialLinks}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <div className="form-group">
                                <input
                                  className={styles.input_text}
                                  name="twitter"
                                  focused
                                  error={
                                    props.apply.social_links.twitter === ""
                                      ? false
                                      : !props.apply.social_links.twitter.match(
                                        twitterRegex
                                      )
                                  }
                                  color={
                                    props.apply.social_links.twitter === ""
                                      ? ""
                                      : "success"
                                  }
                                  value={props.apply.social_links.twitter}
                                  placeholder="www.x.com/example"
                                  type="text"
                                  onChange={props.handleChangeSocialLinks}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <div className="form-group">
                                <input
                                  className={styles.input_text}
                                  name="youtube"
                                  focused
                                  error={
                                    props.apply.social_links.youtube === ""
                                      ? false
                                      : !props.apply.social_links.youtube.match(
                                        youtubeRegex
                                      )
                                  }
                                  color={
                                    props.apply.social_links.youtube === ""
                                      ? ""
                                      : "success"
                                  }
                                  value={props.apply.social_links.youtube}
                                  placeholder="www.youtube.com/example"
                                  type="text"
                                  onChange={props.handleChangeSocialLinks}
                                />
                              </div>

                            </Grid>
                          </Grid>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("5")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              const socialLinks = props.apply?.social_links;

                              // Check if any social links are provided
                              const hasSocialLinks = Object.keys(socialLinks || {}).some(
                                (key) => socialLinks[key]?.length > 0
                              );

                              // If no social links are provided, call handleSteps immediately
                              if (!hasSocialLinks) {
                                props.handleSteps();
                                return;
                              }

                              // Track validity of each social link
                              const validationResults = [];

                              // Validate each social link and store the result
                              if (socialLinks?.linked_in.length > 0) {
                                validationResults.push(
                                  validateAndHandle(
                                    socialLinks?.linked_in,
                                    "Please enter a valid linkedin url",
                                    "linked_in"
                                  )
                                );
                              }
                              if (socialLinks?.twitter.length > 0) {
                                validationResults.push(
                                  validateAndHandle(
                                    socialLinks?.twitter,
                                    "Please enter a valid x url",
                                    "twitter"
                                  )
                                );
                              }
                              if (socialLinks?.instagram.length > 0) {
                                validationResults.push(
                                  validateAndHandle(
                                    socialLinks?.instagram,
                                    "Please enter a valid instagram url",
                                    "instagram"
                                  )
                                );
                              }
                              if (socialLinks?.facebook.length > 0) {
                                validationResults.push(
                                  validateAndHandle(
                                    socialLinks?.facebook,
                                    "Please enter a valid facebook url",
                                    "facebook"
                                  )
                                );
                              }
                              if (socialLinks?.youtube.length > 0) {
                                validationResults.push(
                                  validateAndHandle(
                                    socialLinks?.youtube,
                                    "Please enter a valid youtube url",
                                    "youtube"
                                  )
                                );
                              }

                              // If all validation results are true, call handleSteps
                              if (validationResults.every((isValid) => isValid)) {
                                props.handleSteps();
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "7" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          7
                        </div>
                        <h2>What is your main category? * </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className={styles.inputs_multi}>
                            <div className={styles.checkbox_div}>
                              {loader.loader ? (
                                <div className={styles.loader}>
                                  <CircularLoader />
                                </div>
                              ) : (
                                categorydt &&
                                [...categorydt]?.sort((a, b) => (a.name === "Other Media" ? 1 : b.name === "Other Media" ? -1 : 0))?.map((item) => (

                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue=""
                                    name="category_id"
                                    onClick={props.handleSelectCheckbox}
                                    key={item._id}
                                  >
                                    <FormControlLabel
                                      sx={{
                                        backgroundColor: "#F6F6F6",
                                        borderRadius: "6px",
                                        marginLeft: "0",
                                        paddingRight: "12px",
                                        marginBottom:"5px",
                                        
                                        "& .MuiFormControlLabel-label":{
                                          fontSize:"16px",
                                        }
                                      }}
                                      key={item._id}

                                      checked={props.apply?.category_id.some(
                                        (cat) => cat._id === item._id
                                      )}
                                      onChange={(event) => {
                                        if (!event.target.checked) {
                                          handleKeywordChangeRemoveCategory();
                                        }
                                      }}
                                      value={item._id}
                                      name="category_id"
                                      control={<Checkbox />}
                                      label={item.name}
                                    />
                                  </RadioGroup>
                                  // </div>
                                ))
                              )}

                            </div>
                            {props.apply?.category_id.some(
                              (cat) => cat._id === "67334c2511d9938babd097f3"
                            ) ? (

                              <div className="form-group">
                                <input
                                  className={styles.input_text}
                                  value={othersReasonCategory}
                                  fullWidth
                                  focused
                                  startAdornment={
                                    <InputAdornment position="end">↵</InputAdornment>
                                  }
                                  // onKeyDown={handleKeywordChangeCategory}
                                  onChange={handleChangeKeywordCategory}
                                  placeholder="Please specify"
                                  type="text"
                                />
                              </div>

                            ) : null}
                          </div>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("6")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.category_id?.length) {
                                if (props.apply?.category_id.some(
                                  (cat) => cat._id === "67334c2511d9938babd097f3"
                                )) {
                                  if (!othersReasonCategory?.length) {
                                    Errornotify("Please enter other category");
                                    return;
                                  }
                                }
                                props.handleSteps();
                              } else {
                                Errornotify("Please select your categories");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : props.steps.step === "8" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          8
                        </div>
                        <h2>Please select your artwork selling price range *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className={styles.inputs_multi}>
                            <div className={styles.checkbox_div}>
                              {props.checkboxdt.step6 &&
                                props.checkboxdt.step6.map((item) => (
                                  // <RadioGroup
                                  //  aria-labelledby="demo-radio-buttons-group-label"
                                  //  defaultValue=""
                                  //  name="art_price_range"
                                  //  key={item.id}>
                                  //  <FormControlLabel
                                  //   key={item.label}
                                  //   onChange={props.handleChangeApplyData}
                                  //   sx={{
                                  //    backgroundColor: "#f6f6f6",
                                  //    minWidth: "180px",
                                  //    maxWidth: "max-content",
                                  //    borderRadius: "6px",
                                  //    px: 1,
                                  //   }}
                                  //   value={item.label}
                                  //   control={<Radio />}
                                  //   label={item.label}
                                  //  />
                                  // </RadioGroup>
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="art_price_range"
                                    value={props.apply.art_price_range}
                                    key={item.label}
                                    onClick={props.handleSelectCheckbox}
                                  >
                                    <FormControlLabel
                                      sx={{
                                        backgroundColor: "#F6F6F6",
                                        borderRadius: "6px",
                                        marginLeft: "0",
                                        paddingRight: "12px",
                                        marginBottom:"5px"
                                      }}
                                      name="art_price_range"
                                      value={item.label}
                                      checked={props.apply.art_price_range.includes(
                                        item.label
                                      )}
                                      control={<Checkbox />}
                                      label={item.label}
                                    />
                                  </RadioGroup>
                                  // <FormControlLabel
                                  //  name="art_price_range"
                                  //  value={props.apply.art_price_range}
                                  //  key={item.label}
                                  //  onChange={props.handleSelectCheckbox}
                                  //  label={item.label}
                                  //  control={<Checkbox sx={{ p: 0, m: 0 }} />}
                                  //  sx={{ p: 0, m: 0 }}
                                  // />

                                  //  <Select
                                  //  labelId="select-mutli-artist"
                                  //  id="demo-select-artist"
                                  //  value={addData.artist_ids.length === 0 ? [] : addData.artist_ids.map((val) => val._id)}
                                  //  fullWidth
                                  //  multiple
                                  //  sx={{
                                  //   background: "#f6f6f6",
                                  //   ".MuiOutlinedInput-notchedOutline": { border: "none" },
                                  //   "& .MuiInputBase-root": {
                                  //    borderRadius: "6px",
                                  //   },
                                  //   height: "40px",
                                  //  }}
                                  //  placeholder="select type"
                                  //  onChange={handleSelectArtist}
                                  //  name="artist_ids"
                                  //  // input={<OutlinedInput label="Aritst Name" />}
                                  //  // MenuProps={MenuProps}
                                  // >
                                  //  {artistdatas &&
                                  //   artistdatas.data
                                  //    .filter((fl) => {
                                  //     return fl.is_accepted === "Approved";
                                  //    })
                                  //    .map((val) => (
                                  //     <MenuItem value={val._id} key={val._id}>
                                  //      {val.first_name} {val.last_name}
                                  //     </MenuItem>
                                  //    ))}
                                  // </Select>
                                ))}
                            </div>
                          </div>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("7")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.art_price_range?.length) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please select your selling price range");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                      {/* {val.data &&
        val.data.map((item) => (
         <TextField
          key={item.id}
          InputProps={{ style: { fontSize: 25 } }}
          sx={{ width: { xs: "100%", sm: "45%" }, lineHeight: "34px", fontSize: "25px" }}
          variant="standard"
          className={styles.input_text}
          fullWidth
          name="{item.name}"
          placeholder="{item.placeholder}"
          type="{item.type}"
         />
        ))} */}
                    </div>
                  ) : props.steps.step === "9" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          9
                        </div>
                        <h2>
                        How many artworks have you sold in the past 12 months? *
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            className={styles.input_text}
                            fullWidth
                            name="arts_solds_in_past_12_months"
                            value={props.apply.arts_solds_in_past_12_months}
                            placeholder="Number of arts"
                            type="text"
                            error={
                              props.apply.arts_solds_in_past_12_months.trim() !== "" &&
                              isNaN(props.apply.arts_solds_in_past_12_months.trim())
                            }
                            color={
                              !props.apply.arts_solds_in_past_12_months
                                ? ""
                                : isNaN(props.apply.arts_solds_in_past_12_months.trim())
                                  ? "error"
                                  : "success"
                            }
                            onChange={props.handleChangeApplyData}
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("8")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            // onClick={() => handleStepsss("/applyform")}
                            onClick={() => {
                              if (props.apply?.arts_solds_in_past_12_months.length) {
                                if (
                                  !isNaN(props.apply?.arts_solds_in_past_12_months)
                                ) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("kindly enter the number");
                                }
                              } else {
                                Errornotify("kindly enter number of arts");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "10" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          10
                        </div>
                        <h2>How many artworks do you produce each month? * </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            className={styles.input_text}
                            fullWidth
                            name="produce_art_each_month"
                            value={props.apply.produce_art_each_month}
                            placeholder="Number of arts"
                            type="text"
                            onChange={props.handleChangeApplyData}
                            error={
                              props.apply.produce_art_each_month?.trim() !== "" &&
                              props.apply.produce_art_each_month.trim() === "0" // Check if produce_art_each_month is "0"
                            }
                            color={
                              !props.apply.produce_art_each_month
                                ? ""
                                : isNaN(props.apply.produce_art_each_month.trim())
                                  ? "error"
                                  : "success"
                            }
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("9")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.produce_art_each_month.length) {
                                if (!isNaN(props.apply?.produce_art_each_month)) {
                                  if (props.apply.produce_art_each_month > 0) {
                                    props.handleSteps();
                                  } else {
                                    Errornotify(
                                      "kindly enter a number greater than 0"
                                    );
                                  }
                                } else {
                                  Errornotify("kindly enter the number");
                                }
                              } else {
                                Errornotify("kindly enter number of arts");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "11" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          11
                        </div>
                        <h2>
                        Why do you think Art Smiley would need to select you and promote your works? *
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className={styles.inputs_multi}>
                            <div className={styles.checkbox_div}>
                              {props.checkboxdt.step9 &&
                                props.checkboxdt.step9.map((item) => (
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="promoting_by_reason"
                                    onClick={props.handleSelectCheckbox}
                                    key={item.id}
                                  >
                                    <FormControlLabel
                                      key={item.id}
                                      sx={{
                                        backgroundColor: "#F6F6F6",
                                        borderRadius: "6px",
                                        marginLeft: "0",
                                        paddingRight: "12px",
                                        marginBottom:"5px"
                                      }}
                                      value={item.label}
                                      control={<Checkbox />}
                                      checked={props.apply.promoting_by_reason.includes(
                                        item.label
                                      )}
                                      onChange={(event) => {
                                        if (!event.target.checked) {
                                          handleKeywordChangeRemove();
                                        }
                                      }}
                                      label={item.label}
                                      name="promoting_by_reason"
                                    />
                                  </RadioGroup>
                                ))}
                            </div>


                            {props.apply.promoting_by_reason.includes("Others") ? (
                              <input
                                value={othersReason}
                                className={styles.input_text}
                                fullWidth
                                focused
                                startAdornment={
                                  <InputAdornment position="end">↵</InputAdornment>
                                }
                                // onKeyDown={handleKeywordChange}
                                onChange={handleChangeKeyword}
                                placeholder="Please specify"
                                type="text"
                              />
                            ) : null}
                          </div>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("10")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>

                          <button
                            onClick={() => {
                              if (props.apply?.promoting_by_reason?.length) {
                                if (props.apply?.promoting_by_reason?.includes("Others")) {
                                  if (othersReason.length) {
                                    props.handleSteps();
                                  } else {
                                    Errornotify("Please enter your reason");
                                  }
                                } else {
                                  props.handleSteps();
                                }
                                // props.handleSteps();
                              } else {
                                Errornotify("Please select your reason");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : props.steps.step === "12" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          12
                        </div>
                        <h2>Which Catergory of Artist you belong to? *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className={styles.inputs_multi}>
                            <div className={styles.checkbox_div}>
                              {props.artistTypes?.map((val) => (
                                <RadioGroup
                                  key={val?._id}
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  value={props.apply.completion}
                                  onClick={props.handleChangeApplyData}
                                  name="artist_type_id"
                                >
                                  <FormControlLabel
                                    key={val?._id}
                                    value={val?._id}
                                    sx={{
                                      backgroundColor: "#F6F6F6",
                                      borderRadius: "6px",
                                      marginLeft: "0",
                                      paddingRight: "12px",
                                      marginBottom:"5px"
                                    }}
                                    checked={props.apply.artist_type_id === val?._id}
                                    control={<Radio />}
                                    label={val?.name}
                                  />
                                </RadioGroup>
                              ))}
                            </div>
                          </div>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("11")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply.artist_type_id) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please select your artist type");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : props.steps.step === "13" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          13
                        </div>
                        <h2>Do you prefer to work Exclusively with Art Smiley? *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className={styles.inputs_multi}>
                            <div className={styles.checkbox_div}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={props.apply.exclusive_work}
                                name="exclusive_work"
                                onClick={props.handleChangeApplyData}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  sx={{
                                    backgroundColor: "#F6F6F6",
                                    borderRadius: "6px",
                                    marginLeft: "0",
                                    paddingRight: "12px",
                                    marginBottom:"5px"
                                  }}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="No"
                                  sx={{
                                    backgroundColor: "#F6F6F6",
                                    borderRadius: "6px",
                                    marginLeft: "0",
                                    paddingRight: "12px",
                                  }}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </div>
                          </div>
                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("12")}
                            // onClick={props.handleSteps}
                            className="apply_btn"
                          >
                            Previous
                          </button>

                          <button
                            onClick={() => {
                              if (props.apply.exclusive_work) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please select Yes or No");
                              }
                            }}
                            className="apply_ltbtn"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </Grid>
            </Grid>

          </Container>

        </div>
      ) : props.type === "gallery" ? (
        <div className={styles.mains}>
          <Container maxWidth="xl" sx={{
            paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
            paddingRight: { xs: '16px', sm: '24px', md: '100px' },
          }}>
            <Grid container spacing={2} sx={{
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Grid item xs={12} sm={10} md={10} lg={9}>
                <div className={styles.components}>
                  <div className={styles.logo_img}>
                    <img
                      onClick={() => navigate("/")}
                      src={"/img/updated_logo.png"} className="img-fluid"
                      alt="img"
                    />
                  </div>
                  <BorderLinearProgress
                    color="primary"
                    variant="determinate"
                    value={props.steps.stepper}
                  />
                  {props.steps.step === "1" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          1
                        </div>
                        <h2>
                        Gallery Profile plays an important role in our selection process. Please fill all details properly. Incomplete information will not be taken into consideration.
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className="form-group">
                            <label>Please upload portfolio of your gallery in word document or pdf *
                            <small class="wrtsmall">(Max Size 10MB)</small>
                            </label>
                            <div
                              className={styles.preview_img}

                            >
                              {props?.apply?.portfolio_file === "" ? (
                                <div className={styles.imguplod}>
                                  <div
                                    htmlFor="input"
                                    style={{ textAlign: "center" }}
                                    onClick={handleChangeImage}
                                  >
                                    <input
                                      name="portfolio_file"
                                      onChange={handleChangeImage}
                                      type="file"
                                      id="portfolio_file"
                                      hidden
                                      accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    />
                                    <label htmlFor="portfolio_file" className={styles.labelv}>
                                      <FiUpload />
                                      <p>Upload Your Portfolio</p>
                                    </label>
                                  </div>
                                </div>
                              ) : (
                                <div className={styles.imguplod}>
                                  <div
                                    htmlFor="input"
                                    style={{ textAlign: "center" }}
                                    onClick={handleChangeImage}
                                  >
                                    <input
                                      name="portfolio_file"
                                      onChange={handleChangeImage}
                                      type="file"
                                      id="portfolio_file"
                                      hidden
                                      accept=".pdf"
                                    />
                                    <label htmlFor="portfolio_file" className={styles.labelv}>
                                      <FiUpload />
                                      <p>{props.apply.portfolio_file?.name}</p>
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div>
                            {galleryApplicationErrors?.portfolio_file && (
                              <small
                                style={{
                                  color: "red",
                                  fontWeight: "500",
                                  display:"block"
                                }}
                              >
                                {galleryApplicationErrors?.portfolio_file}
                              </small>
                            )}
                          </div>
                          <div className="form-group">
                            <label>About gallery *</label>
                            <ReactQuill
                              className="reactQuilStle"
                              value={props.apply.description}
                              style={{ 
                                textAlign: "left",
                              }}
                              //  value={props?.apply?.description}
                              onChange={props.handleChangeTextEditor("description")}
                              theme="snow"
                              modules={modules}
                              formats={formats}
                            />
                            {galleryApplicationErrors.description && (
                              <small
                                style={{
                                  color: "red",
                                  fontWeight: "500",
                                }}
                              >
                                {galleryApplicationErrors.description}
                              </small>
                            )}
                          </div>
                          <div className="form-group">
                            <label>
                            <span class="lablhdng">Professional Details:</span>
                            Please provide details of prestigious exhibitions, awards/prizes, commissions, grants, recognitions etc? *
                            <small class="wrtsmall">Please provide us with the name, the year and the place of each experience.</small>
                            </label>
                            <ReactQuill
                              className="reactQuilStle"

                              value={props.apply.award}
                              onChange={props.handleChangeTextEditor("award")}
                              theme="snow"
                              modules={modules}
                              formats={formats}
                            />
                            {/* <small>(Please provide us with the name, the year and the place of each experience.)</small> */}
                            {galleryApplicationErrors.award && (
                              <small
                                style={{
                                  color: "red",
                                  fontWeight: "500",
                                  display:"block"
                                }}
                              >
                                {galleryApplicationErrors.award}
                              </small>
                            )}
                          </div>
                          {/* <div className="form-group">
                            <label>
                              Please provide us with the name, the year and the place of
                              each experience *. 
                            </label>
                            <ReactQuill
                              className="reactQuilStle"

                              value={props.apply.degree_year}
                              onChange={props.handleChangeTextEditor("degree_year")}
                              theme="snow"
                              modules={modules}
                              formats={formats}
                            />
                            {galleryApplicationErrors.degree_year && (
                              <div
                                style={{
                                  color: "red",
                                  fontWeight: "500",
                                }}
                              >
                                {galleryApplicationErrors.degree_year}
                              </div>
                            )}
                          </div> */}
                        </div>
                        {/* Next Button */}
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => {
                              const { isValid, galleryApplicationErrors } =
                                validationForGalleryApplication(
                                  props?.apply?.portfolio_file,
                                  props?.apply?.description,
                                  props?.apply?.award,
                                  props?.apply?.degree_year,
                                );
                              if (isValid) {
                                props.handleSteps();
                                setGalleryApplicationErrors({});
                              } else {
                                //console.log(artistApplicationErrors,'artistApplicationErrors')
                                setGalleryApplicationErrors(galleryApplicationErrors);
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>

                      </div>


                    </div>

                  ) : props.steps.step === "2" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          2
                        </div>
                        <h2>
                        Upload your best artists works *{" "} <br />
                        <small class="wrtsmall">(Upload: Max-10 and Min-2 Artworks, File format: PNG/JPG, Max Size: 2MB)</small>
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className={styles.preview_img}>

                            <div className={styles.img_box}>
                              <div
                                htmlFor="input"
                                style={{ textAlign: "center" }}

                              >
                                <input
                                  name="best_work"
                                  onChange={props.handleImageChangeMultipleGallery}
                                  type="file"
                                  id="best_work"
                                  hidden
                                  accept="image/*"
                                />
                                <label htmlFor="best_work" className={styles.labelv}>
                                  <FiUpload />
                                  <p>Upload Your Best Artists Works</p>
                                </label>
                              </div>
                            </div>


                          </div>
                          <div className={styles.result}>
                            {renderPhotosGallery(props?.selectedFilesGallery)}
                          </div>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("1")}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button

                            onClick={() => {
                              if (props?.apply?.best_work.length) {
                                if (props?.selectedFilesGallery.length >= 2 && props?.selectedFilesGallery.length <= 10) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("Upload between 2 and 10 best artist works");
                                }
                              } else {
                                Errornotify("Please upload your best artist works");
                              }

                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "3" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          3
                        </div>
                        <h2>
                        If your gallery is represented by another online platform, Please provide URL links
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className="form-group">
                            <input
                              variant="standard"
                              className={styles.input_text}
                              fullWidth
                              name="other_URL"
                              error={
                                props.apply.other_URL === ""
                                  ? false
                                  : !props.apply.other_URL.match(regex)
                              }
                              color={props.apply.other_URL.length === 0 ? "" : "success"}

                              value={props.apply.other_URL}
                              placeholder="www.example.com"
                              type="text"
                              onChange={props.handleChangeApplyData}
                            />
                          </div>


                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("2")}

                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.other_URL.length > 0) {
                                if (props.apply?.other_URL.match(regex)) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("Please enter the valid url");
                                }
                              } else {
                                props.handleSteps();
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "4" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          4
                        </div>
                        <h2>
                        Please provide the link to your gallery online portfolio (Gallery Website)
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            className={styles.input_text}
                            fullWidth
                            error={
                              props.apply.portfolio === ""
                                ? false
                                : !props.apply.portfolio.match(regex)
                            }
                            color={props.apply.portfolio.length === 0 ? "" : "success"}
                            name="portfolio"
                            value={props.apply.portfolio}
                            placeholder="www.example.com"
                            type="text"
                            onChange={props.handleChangeApplyData}
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("3")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.portfolio.length) {
                                if (props.apply?.portfolio.match(regex)) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("Please enter the valid url");
                                }
                              } else {
                                props.handleSteps();
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "5" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          5
                        </div>
                        <h2>
Please provide us the links of your gallery social media platform platform here
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <Grid
                            container
                            spacing={2}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <div className="form-group">
                                <input
                                  variant="standard"
                                  className={styles.input_text}
                                  fullWidth
                                  name="linked_in"
                                  focused
                                  error={
                                    props.apply.social_links.linked_in === ""
                                      ? false
                                      : !props.apply.social_links.linked_in.match(linkedinRegex)
                                  }
                                  color={
                                    props.apply.social_links.linked_in.length === 0
                                      ? ""
                                      : "success"
                                  }
                                  value={props.apply.social_links.linked_in}
                                  placeholder="www.linkedin.com/in/example"
                                  type="text"
                                  onChange={props.handleChangeSocialLinks}
                                />
                              </div>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <div className="form-group">
                                <input
                                  variant="standard"
                                  className={styles.input_text}
                                  fullWidth
                                  // focused
                                  error={
                                    props.apply.social_links.facebook === ""
                                      ? false
                                      : !props.apply.social_links.facebook.match(facebookRegex)
                                  }
                                  color={
                                    props.apply.social_links.facebook === ""
                                      ? ""
                                      : "success"
                                  }
                                  name="facebook"
                                  value={props.apply.social_links.facebook}
                                  placeholder="www.facebook.com/example"
                                  type="text"
                                  onChange={props.handleChangeSocialLinks}
                                />
                              </div>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <div className="form-group">
                                <input
                                  variant="standard"
                                  className={styles.input_text}
                                  fullWidth
                                  name="instagram"
                                  error={
                                    props.apply.social_links.instagram === ""
                                      ? false
                                      : !props.apply.social_links.instagram.match(instagramRegex)
                                  }
                                  color={
                                    props.apply.social_links.instagram === ""
                                      ? ""
                                      : "success"
                                  }
                                  value={props.apply.social_links.instagram}
                                  placeholder="www.instagram.com/example"
                                  type="text"
                                  onChange={props.handleChangeSocialLinks}
                                />
                              </div>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <div className="form-group">
                                <input
                                  variant="standard"
                                  className={styles.input_text}
                                  fullWidth
                                  name="twitter"
                                  error={
                                    props.apply.social_links.twitter === ""
                                      ? false
                                      : !props.apply.social_links.twitter.match(twitterRegex)
                                  }
                                  color={
                                    props.apply.social_links.twitter === ""
                                      ? ""
                                      : "success"
                                  }
                                  value={props.apply.social_links.twitter}
                                  placeholder="www.x.com/example"
                                  type="text"
                                  onChange={props.handleChangeSocialLinks}
                                />
                              </div>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <div className="form-group">
                                <input
                                  variant="standard"
                                  className={styles.input_text}
                                  fullWidth
                                  name="youtube"
                                  error={
                                    props.apply.social_links.youtube === ""
                                      ? false
                                      : !props.apply.social_links.youtube.match(youtubeRegex)
                                  }
                                  color={
                                    props.apply.social_links.youtube === ""
                                      ? ""
                                      : "success"
                                  }
                                  value={props.apply.social_links.youtube}
                                  placeholder="www.youtube.com/example"
                                  type="text"
                                  onChange={props.handleChangeSocialLinks}
                                />
                              </div>

                            </Grid>
                          </Grid>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("4")}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              const socialLinks = props.apply?.social_links;

                              // Check if any social links are provided
                              const hasSocialLinks = Object.keys(socialLinks || {}).some(
                                (key) => socialLinks[key]?.length > 0
                              );

                              // If no social links are provided, call handleSteps immediately
                              if (!hasSocialLinks) {
                                props.handleSteps();
                                return;
                              }

                              // Track validity of each social link
                              const validationResults = [];

                              // Validate each social link and store the result
                              if (socialLinks?.linked_in.length > 0) {
                                validationResults.push(
                                  validateAndHandle(
                                    socialLinks?.linked_in,
                                    "Please enter a valid linkedin url",
                                    "linked_in"
                                  )
                                );
                              }
                              if (socialLinks?.twitter.length > 0) {
                                validationResults.push(
                                  validateAndHandle(
                                    socialLinks?.twitter,
                                    "Please enter a valid x url",
                                    "twitter"
                                  )
                                );
                              }
                              if (socialLinks?.instagram.length > 0) {
                                validationResults.push(
                                  validateAndHandle(
                                    socialLinks?.instagram,
                                    "Please enter a valid instagram url",
                                    "instagram"
                                  )
                                );
                              }
                              if (socialLinks?.facebook.length > 0) {
                                validationResults.push(
                                  validateAndHandle(
                                    socialLinks?.facebook,
                                    "Please enter a valid facebook url",
                                    "facebook"
                                  )
                                );
                              }
                              if (socialLinks?.youtube.length > 0) {
                                validationResults.push(
                                  validateAndHandle(
                                    socialLinks?.youtube,
                                    "Please enter a valid youtube url",
                                    "youtube"
                                  )
                                );
                              }

                              // If all validation results are true, call handleSteps
                              if (validationResults.every((isValid) => isValid)) {
                                props.handleSteps();
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "6" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          6
                        </div>
                        <h2>
                        Please select the main categories your artists work with? *
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className={styles.inputs_multi}>
                            <div className={styles.checkbox_div}>
                              {loader.loader ? (
                                <div className={styles.loader}>
                                  <CircularLoader />
                                </div>
                              ) : (
                                categorydt &&
                                [...categorydt]?.sort((a, b) => (a.name === "Other Media" ? 1 : b.name === "Other Media" ? -1 : 0))?.map((item) => (
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue=""
                                    name="category_id"
                                    onClick={props.handleSelectCheckbox}
                                    key={item._id}
                                  >
                                    <FormControlLabel
                                      key={item._id}
                                      checked={props.apply?.category_id.some(
                                        (cat) => cat._id === item._id
                                      )}
                                      sx={{
                                        backgroundColor: "#F6F6F6",
                                        borderRadius: "6px",
                                        marginLeft: "0",
                                        paddingRight: "12px",
                                        marginBottom:"5px",
                                      }}
                                      onChange={(event) => {
                                        if (!event.target.checked) {
                                          handleKeywordChangeRemoveCategory();
                                        }
                                      }}
                                      value={item._id}
                                      name="category_id"
                                      control={<Checkbox />}
                                      label={item.name}
                                    />
                                  </RadioGroup>
                                  // </div>
                                ))
                              )}
                              {othersReasonArrayCategory.length ? (
                                <>
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                  >
                                    {othersReasonArrayCategory?.map((val, index) => (
                                      <FormControlLabel
                                        key={index} // Use a unique key for each child
                                        sx={{
                                          backgroundColor: "#F6F6F6",
                                          borderRadius: "6px",
                                          marginLeft: "0",
                                          paddingRight: "12px",
                                          marginBottom:"5px"
                                        }}
                                        onChange={(event) => {
                                          if (!event.target.checked) {
                                            handleKeywordChangeRemoveCategory();
                                          }
                                        }}
                                        checked={true}
                                        control={<Checkbox sx={{ display: "none" }} />}
                                        label={val} // Display the value as the label
                                      />
                                    ))}
                                  </RadioGroup>
                                </>
                              ) : null}
                            </div>
                            {props.apply?.category_id.some(
                              (cat) => cat._id === "67334c2511d9938babd097f3"
                            ) ? (
                              // Your conditional rendering here
                              <div className="form-group">
                                <input
                                  variant="standard"
                                  className={styles.input_text}
                                  value={othersReasonCategory}
                                  fullWidth
                                  focused
                                  startAdornment={
                                    <InputAdornment position="end">↵</InputAdornment>
                                  }
                                  // onKeyDown={handleKeywordChangeCategory}
                                  onChange={handleChangeKeywordCategory}
                                  placeholder="Please specify"
                                  type="text"
                                />
                              </div>

                            ) : null}
                          </div>

                        </div>

                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("5")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.category_id?.length) {
                                if (props.apply?.category_id.some(
                                  (cat) => cat._id === "67334c2511d9938babd097f3"
                                )) {
                                  if (!othersReasonCategory.length) {
                                    Errornotify("Please enter other category");
                                    return;
                                  }
                                }
                                props.handleSteps();
                              } else {
                                Errornotify("Please select your categories");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "7" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          7
                        </div>
                        <h2>
                        Please select your artwork selling price range *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className={styles.inputs_multi}>
                            <div className={styles.checkbox_div}>
                              {props.checkboxdt.step11 &&
                                props.checkboxdt.step11.map((item) => (
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="art_price_range"
                                    value={props.apply.art_price_range}
                                    key={item.label}
                                    onClick={props.handleSelectCheckbox}
                                  >
                                    <FormControlLabel
                                      sx={{
                                        backgroundColor: "#F6F6F6",
                                        borderRadius: "6px",
                                        marginLeft: "0",
                                        paddingRight: "12px",
                                        marginBottom:"5px"
                                      }}
                                      name="art_price_range"
                                      checked={props.apply.art_price_range.includes(
                                        item.label
                                      )}
                                      value={item.label}
                                      control={<Checkbox />}
                                      label={item.label}
                                    />
                                  </RadioGroup>
                                ))}
                            </div>
                          </div>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("6")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.art_price_range?.length) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please select your selling price range");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "8" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          8
                        </div>
                        <h2>How many artworks have you sold in the past 12 months? *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            variant="standard"
                            className={styles.input_text}
                            fullWidth
                            name="arts_solds_in_past_12_months"
                            value={props.apply.arts_solds_in_past_12_months}
                            placeholder="Number of arts"
                            type="text"
                            error={
                              props.apply.arts_solds_in_past_12_months.trim() &&
                              !/^\d+$/.test(props.apply.arts_solds_in_past_12_months)
                            }
                            color={
                              props.apply.arts_solds_in_past_12_months.trim()
                                ? /^\d+$/.test(props.apply.arts_solds_in_past_12_months)
                                  ? "success"
                                  : "error"
                                : ""
                            }
                            onChange={props.handleChangeApplyData}
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("7")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.arts_solds_in_past_12_months.length) {
                                if (
                                  !isNaN(props.apply?.arts_solds_in_past_12_months)
                                ) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("kindly enter the number");
                                }
                              } else {
                                Errornotify("kindly enter number of arts");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : (
                    ""
                  )}

                </div>
              </Grid>
            </Grid>
          </Container>

        </div>
      ) : props.type === "curator" ? (
        <div className={styles.mains}>
          <Container maxWidth="xl" sx={{
            paddingLeft: { xs: '16px', sm: '24px', md: '60px', lg: '100px' },
            paddingRight: { xs: '16px', sm: '24px', md: '60px', lg: '100px' },
          }}>
            <Grid container spacing={2} sx={{
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Grid item xs={12} sm={10} md={10} lg={9}>
                <div className={styles.components}>
                  <div className={styles.img_div}>
                    <img
                      onClick={() => navigate("/")}
                      src="/img/updated_logo.png"
                      alt="img" className="img-fluid"
                    />
                  </div>
                  <BorderLinearProgress
                    color="primary"
                    variant="determinate"
                    value={props.steps.stepper}
                  />
                  {props.steps.step === "1" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          1
                        </div>
                        <h2> What is your first name *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            className={styles.input_text}
                            fullWidth
                            name="first_name"
                            value={props.apply.first_name}
                            focused
                            placeholder="Enter your first name"
                            type="text"
                            onChange={props.handleChangeApplyData}
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            // onClick={() => handleStepsss("/applyform")}
                            onClick={() => {
                              if (props.apply?.first_name?.length) {
                                // Check if the first name contains only alphabets
                                const onlyAlphabets = /^[A-Za-z]+$/.test(
                                  props.apply.first_name
                                );

                                if (onlyAlphabets) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("only alphabets allowed");
                                }
                              } else {
                                Errornotify("Please enter your first name!");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "2" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          2
                        </div>
                        <h2> What is your last name *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            className={styles.input_text}
                            fullWidth
                            name="last_name"
                            value={props.apply.last_name}
                            focused
                            placeholder="Enter your last name"
                            type="text"
                            onChange={props.handleChangeApplyData}
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("1")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.last_name?.length) {
                                // Check if the first name contains only alphabets
                                const onlyAlphabets = /^[A-Za-z]+$/.test(
                                  props.apply.last_name
                                );

                                if (onlyAlphabets) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("only alphabets allowed");
                                }
                              } else {
                                Errornotify("Please enter your last name!");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "3" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          3
                        </div>
                        <h2> What is your email address *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            className={styles.input_text}
                            fullWidth
                            name="curator_email"
                            value={props.apply.curator_email}
                            focused
                            placeholder="Enter your email address"
                            type="text"
                            onChange={props.handleChangeApplyData}
                            error={
                              props.apply.curator_email.trim() &&
                              !handleEmailValidation(props.apply.curator_email)
                            }
                            // error={
                            //   props.apply.curator_email.trim() &&
                            //   !emailRegex.test(props.apply.curator_email)
                            // }
                            color={
                              props.apply.curator_email.trim()
                                ? emailRegex.test(props.apply.curator_email)
                                  ? "success"
                                  : "error"
                                : ""
                            }
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("2")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              const email = props.apply.curator_email?.trim();
                              if (!email?.length) {
                                Errornotify("Please enter your email address");
                                return;
                              }
                              if (!handleEmailValidation(email)) {
                                Errornotify("Please enter a valid email address ending with .com");
                                return;
                              }
                              props.handleSteps();
                            }}
                            // onClick={() => {
                            //   const email = props.apply.curator_email?.trim();
                            //   if (!email?.length) {
                            //     Errornotify("Please enter your email address");
                            //     return;
                            //   }
                            //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            //   if (!emailRegex.test(email)) {
                            //     Errornotify("Please enter a valid email address");
                            //     return;
                            //   }
                            //   if (!isEmailValid) {
                            //     Errornotify(email_errors.email || "Invalid email address. Please correct it.");
                            //     return;
                            //   }
                            //   props.handleSteps(); 
                            // }}


                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "4" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          4
                        </div>
                        <h2> What is your phone number *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <Entermobile
                            handleChangeNumber={handleChangeNumberCurator}
                            value={props.apply.phone}
                            border="applyform"
                          />
                          {props.apply.phone && error && (
                            <span
                              style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
                            >
                              {error}
                            </span>
                          )}

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("3")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply.phone?.length) {
                                if (
                                  error.length
                                ) {
                                  Errornotify("Please enter a valid phone number");
                                } else {
                                  props.handleSteps();
                                }
                              } else {
                                Errornotify("Please enter your phone number");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "5" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          5
                        </div>
                        <h2>Please write about yourself, education, art qualifications, achievements, etc *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>

                          <ReactQuill
                            className="reactQuilStle"
                            value={props.apply.achievement}
                            onChange={props.handleChangeShowsCurated("achievement")}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                          />
                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("4")}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              const hasAchievement =
                                props.apply.achievement &&
                                props.apply.achievement !== "<p><br></p>";
                              if (hasAchievement) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please Fill out this field");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>

                      </div>


                    </div>
                  ) : props.steps.step === "6" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          6
                        </div>
                        <h2>Professional qualifications *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <ReactQuill
                            className="reactQuilStle"
                            value={props.apply.qualification}

                            onChange={props.handleChangeShowsCurated("qualification")}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                          />
                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("5")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              const hasQualificationContent =
                                props.apply.qualification &&
                                props.apply.qualification !== "<p><br></p>";
                              if (hasQualificationContent) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please enter your qualification");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : props.steps.step === "7" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          7
                        </div>
                        <h2>Shows curated *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <ReactQuill
                            className="reactQuilStle"
                            value={props.apply.shows_curated}

                            onChange={props.handleChangeShowsCurated("shows_curated")}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("6")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button

                            onClick={() => {
                              const hasCuratedContent =
                                props.apply.shows_curated &&
                                props.apply.shows_curated !== "<p><br></p>";
                              if (hasCuratedContent) {
                                props.handleSteps();
                              } else {
                                Errornotify("This field is required");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>


                    </div>
                  ) : props.steps.step === "8" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          8
                        </div>
                        <h2>
                        Upload your profile detailing your experience? *  <small className="wrtsmall">
                            (File format in PDF or Word, Max Size: 10MB)
                          </small>
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className={styles.preview_img}>
                            {props.apply.CV === "" ? (
                              <div className={styles.img_box}>
                                <div
                                  htmlFor="input"
                                  style={{ textAlign: "center" }}
                                  onClick={props.handleChangeImage}
                                >
                                  <input
                                    name="CV"
                                    onChange={props.handleChangeImage}
                                    type="file"
                                    id="CV"
                                    hidden
                                    accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  />
                                  <label htmlFor="CV" className={styles.labelv}>
                                    <FiUpload />
                                    <p>Upload Your Profile Experience Details</p>
                                  </label>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className={styles.img_box}>
                                  <div
                                    htmlFor="input"
                                    style={{ textAlign: "center" }}
                                    onClick={props.handleChangeImage}
                                  >
                                    <input
                                      name="CV"
                                      onChange={props.handleChangeImage}
                                      type="file"
                                      id="CV"
                                      hidden
                                      accept=".pdf"
                                    />
                                    <label htmlFor="CV" className={styles.labelv}>
                                      <FiUpload />
                                      <p>{props.apply?.CV?.name}</p>
                                    </label>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("7")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply.CV?.name?.length) {
                                props.handleSteps();
                              } else {
                                Errornotify(
                                  "Please upload your profile detail document"
                                );
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "9" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          9
                        </div>
                        <h2>
                          Upload your ID * (i.e. Emirates ID, National ID, Aadhar Card, Passport, Driving License etc){" "}
                          <small className="wrtsmall">
                          (File format: PDF/PNG/JPEG/, Max Size: 2MB)
                          </small>
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          {props.apply.ID_proof === "" ? (
                            <div className={styles.img_box}>
                              <div
                                htmlFor="input"
                                style={{ textAlign: "center" }}
                                onClick={props.handleChangeImage}
                              >
                                <input
                                  name="ID_proof"
                                  onChange={props.handleChangeImage}
                                  type="file"
                                  id="ID_proof"
                                  hidden
                                  accept=".pdf, .jpeg, .jpg, .png, .doc, .docx"

                                />
                                <label htmlFor="ID_proof" className={styles.labelv}>
                                  <FiUpload />
                                  <p>Upload Your ID Proof</p>
                                </label>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className={styles.img_box}>
                                <div
                                  htmlFor="input"
                                  style={{ textAlign: "center" }}
                                  onClick={props.handleChangeImage}
                                >
                                  <input
                                    name="ID_proof"
                                    onChange={props.handleChangeImage}
                                    type="file"
                                    id="ID_proof"
                                    hidden
                                    accept=".pdf, .jpeg, .jpg, .png, .doc, .docx"

                                  />
                                  <label htmlFor="ID_proof" className={styles.labelv}>
                                    <FiUpload />
                                    <p>{props.apply?.ID_proof?.name}</p>
                                  </label>
                                </div>
                              </div>
                            </>
                          )}

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("8")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply.ID_proof?.name?.length) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please upload your ID proof!");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "10" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          10
                        </div>
                        <h2>
                          Upload your profile image (Your Photo) *
                          <small className="wrtsmall">
                          (File format: PNG/JPEG, Dimension: 200px - 200px, Max Size: 2MB)
                          </small>
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          {props.apply.profile_image === "" ? (
                            <div className={styles.img_box}>
                              <div
                                htmlFor="input"
                                style={{ textAlign: "center" }}
                                onClick={props.handleChangeImage}
                              >
                                <input
                                  name="profile_image"
                                  onChange={props.handleChangeImage}
                                  type="file"
                                  id="profile_image"
                                  hidden
                                  accept=".jpeg, .jpg, .png"

                                />
                                <label htmlFor="profile_image" className={styles.labelv}>
                                  <FiUpload />
                                  <p>Upload your Profile Image</p>
                                </label>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className={styles.img_box}>
                                <div
                                  htmlFor="input"
                                  style={{ textAlign: "center" }}
                                  onClick={props.handleChangeImage}
                                >
                                  <input
                                    name="profile_image"
                                    onChange={props.handleChangeImage}
                                    type="file"
                                    id="profile_image"
                                    hidden
                                    accept=".jpeg, .jpg, .png"

                                  />
                                  <label htmlFor="profile_image" className={styles.labelv}>
                                    <FiUpload />
                                    <p>{props.apply?.profile_image?.name}</p>
                                  </label>
                                </div>
                              </div>
                            </>
                          )}

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("9")}

                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply.profile_image?.name?.length) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please upload your profile Image!");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>


                    </div>
                  ) : props.steps.step === "11" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          11
                        </div>
                        <h2>
                          Upload your cover image *
                          <small className="wrtsmall">
                           (File format: PNG/JPEG, Dimension: 1520px - 275px, Max Size: 2MB)
                          </small>
                        </h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          {props.apply.cover_image === "" ? (
                            <div className={styles.img_box}>
                              <div
                                htmlFor="input"
                                style={{ textAlign: "center" }}
                                onClick={props.handleChangeImage}
                              >
                                <input
                                  name="cover_image"
                                  onChange={props.handleChangeImage}
                                  type="file"
                                  id="cover_image"
                                  hidden
                                  accept=".jpeg, .jpg, .png"

                                />
                                <label htmlFor="cover_image" className={styles.labelv}>
                                  <FiUpload />
                                  <p>Upload your Cover Image</p>
                                </label>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className={styles.img_box}>
                                <div
                                  htmlFor="input"
                                  style={{ textAlign: "center" }}
                                  onClick={props.handleChangeImage}
                                >
                                  <input
                                    name="cover_image"
                                    onChange={props.handleChangeImage}
                                    type="file"
                                    id="cover_image"
                                    hidden
                                    accept=".jpeg, .jpg, .png"

                                  />
                                  <label htmlFor="cover_image" className={styles.labelv}>
                                    <FiUpload />
                                    <p>{props.apply?.cover_image?.name}</p>
                                  </label>
                                </div>
                              </div>
                            </>
                          )}

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("10")}

                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply.cover_image?.name?.length) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please upload your Cover Image!");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>


                    </div>
                  ) : props.steps.step === "12" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          12
                        </div>
                        <h2>What is your nationality ? *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>

                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name="nationality"
                            value={props.apply.nationality}
                            onChange={props.handleChangeApplyData}
                            // variant=""
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-input": {
                                padding: ".45rem .75rem",
                                height: "auto",
                                borderRadius: "0",
                                fontSize: "16px",
                                fontWeight: "400"
                              },
                              border: "0",
                              borderBottom: "1px solid #ced4da",
                              borderRadius: "0",
                              width: "100%"
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {nationalities.map((c) => (
                              <MenuItem value={c.label}>{c.label}</MenuItem>
                            ))}
                          </Select>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("11")}

                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply.nationality.length) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please select your Nationality!");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "13" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          13
                        </div>
                        <h2> Enter your country of residence *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>

                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name="country"
                            value={props.apply.country}
                            onChange={props.handleChangeApplyData}
                            // variant=""
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-input": {
                                padding: ".45rem .75rem",
                                height: "auto",
                                borderRadius: "0",
                                fontSize: "16px",
                                fontWeight: "400"
                              },
                              border: "0",
                              borderBottom: "1px solid #ced4da",
                              borderRadius: "0",
                              width: "100%"
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {country.map((c) => (
                              <MenuItem value={c.label}>{c.label}</MenuItem>
                            ))}
                          </Select>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("12")}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply.country.length) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please select your country residence!");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>


        </div>
      ) : (
        <div className={styles.mains}>
          <Container maxWidth="xl" sx={{
            paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
            paddingRight: { xs: '16px', sm: '24px', md: '100px' },
          }}>
            <Grid container spacing={2} sx={{
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Grid item xs={12} sm={10} md={10} lg={9}>
                <div className={styles.components}>
                  <div className={styles.logo_img}>
                    <img
                      onClick={() => navigate("/")}
                      src={"/img/updated_logo.png"}
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                  <BorderLinearProgress
                    color="primary"
                    variant="determinate"
                    value={props.steps.stepper}
                  />
                  {props.steps.step === "1" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          1
                        </div>
                        <h2>Select your account type *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <div className={styles.inputs_multi}>
                            <div className={styles.checkbox_div}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="Yes"
                                value={props.apply.affiliate_account_type_id}
                                name="affiliate_account_type_id"
                                onClick={props.handleChangeApplyData}
                              >
                                {props.affiliate &&
                                  props.affiliate.map((item) => (
                                    <FormControlLabel
                                      value={item._id}
                                      sx={{
                                        backgroundColor: "#F6F6F6",
                                    borderRadius: "6px",
                                    marginLeft: "0",
                                    paddingRight: "12px",
                                    marginBottom:"5px"
                                      }}
                                      control={<Radio />}
                                      label={item.name}
                                    />
                                  ))}
                              </RadioGroup>
                            </div>
                          </div>
                          {props.apply.affiliate_account_type_id === "66307a1f62041dbbcad0609c" && (
                            <>
                              <div >
                              </div>

                              <div className="form-group">
                                {/* <label> Enter your company name*</label> */}
                                <input
                                  className={styles.input_text}
                                  name="company_name"
                                  value={props.apply.company_name}
                                  placeholder="Enter your company name *"
                                  type="text"
                                  onChange={props.handleChangeApplyData}
                                />

                              </div>
                            </>
                          )}

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => {
                              const { affiliate_account_type_id, company_name } = props.apply;
                              if (!affiliate_account_type_id?.length) {
                                Errornotify("Please select your account type");
                                return;
                              }

                              if (
                                affiliate_account_type_id === "66307a1f62041dbbcad0609c" &&
                                !company_name?.trim()
                              ) {
                                Errornotify("Company name is required");
                                return;
                              }

                              props.handleSteps();
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>



                    </div>

                  ) : props.steps.step === "2" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          2
                        </div>
                        <h2>What is your first name *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            className={styles.input_text}
                            fullWidth
                            name="first_name"
                            color={
                              props.apply.first_name.trim()
                                ? /^[A-Za-z]+$/.test(props.apply.first_name)
                                  ? "success"
                                  : "error"
                                : ""
                            }
                            error={
                              props.apply.first_name.trim() &&
                              !/^[A-Za-z]+$/.test(props.apply.first_name)
                            }
                            focused
                            value={props.apply.first_name}
                            placeholder="Enter your first name"
                            type="text"
                            onChange={props.handleChangeApplyData}
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("1")}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.first_name?.length) {

                                const onlyAlphabets = /^[A-Za-z]+$/.test(
                                  props.apply.first_name
                                );

                                if (onlyAlphabets) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("only alphabets allowed");
                                }
                              } else {
                                Errornotify("Please enter your first name!");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "3" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          3
                        </div>
                        <h2>What is your last name *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            className={styles.input_text}
                            fullWidth
                            name="last_name"
                            color={
                              props.apply.last_name.trim()
                                ? /^[A-Za-z]+$/.test(props.apply.last_name)
                                  ? "success"
                                  : "error"
                                : ""
                            }
                            error={
                              props.apply.last_name.trim() &&
                              !/^[A-Za-z]+$/.test(props.apply.last_name)
                            }
                            focused
                            value={props.apply.last_name}
                            placeholder="Enter your last name"
                            type="text"
                            onChange={props.handleChangeApplyData}
                          />
                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("2")}
                            // onClick={props.handleSteps}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.last_name?.length) {
                                // Check if the first name contains only alphabets
                                const onlyAlphabets = /^[A-Za-z]+$/.test(
                                  props.apply.last_name
                                );

                                if (onlyAlphabets) {
                                  props.handleSteps();
                                } else {
                                  Errornotify("only alphabets allowed");
                                }
                              } else {
                                Errornotify("Please enter your last name!");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : props.steps.step === "4" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          4
                        </div>
                        <h2>What is your email address *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <input
                            className={styles.input_text}
                            fullWidth
                            name="email"
                            focused
                            value={props.apply.email}
                            placeholder="Enter your email address"
                            type="email"
                            onChange={props.handleChangeApplyData}
                            error={
                              props.apply.email.trim() &&
                              !handleEmailValidation(props.apply.curator_email)
                            }
                            // error={
                            //   props.apply.email.trim() &&
                            //   !emailRegex.test(props.apply.email)
                            // }
                            color={
                              props.apply.email.trim()
                                ? emailRegex.test(props.apply.email)
                                  ? "success"
                                  : "error"
                                : ""
                            }
                          />

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("3")}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              const email = props.apply.email?.trim();

                              if (!email?.length) {
                                Errornotify("Please enter your email address");
                                return;
                              }

                              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                              if (!emailRegex.test(email)) {
                                Errornotify("Please enter a valid email address");
                                return;
                              }

                              if (!isEmailValid) {
                                Errornotify(email_errors.email || "Invalid email address. Please correct it.");
                                return;
                              }

                              props.handleSteps();
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "5" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          5
                        </div>
                        <h2>What is your country of residence *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>

                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name="country"
                            value={props.apply.country}
                            onChange={props.handleChangeApplyData}
                            // variant=""
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-input": {
                                padding: ".45rem .75rem",
                                height: "auto",
                                borderRadius: "0",
                                fontSize: "16px",
                                fontWeight: "400"
                              },
                              border: "0",
                              borderBottom: "1px solid #ced4da",
                              borderRadius: "0",
                              width: "100%"
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {country.map((c) => (
                              <MenuItem value={c.label}>{c.label}</MenuItem>
                            ))}
                          </Select>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("4")}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.country?.length) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please select your country!");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "6" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          6
                        </div>
                        <h2> What is your preferred language *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name="language"
                            value={props.apply.language}
                            onChange={props.handleChangeApplyData}
                            // variant=""
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-input": {
                                padding: ".45rem .75rem",
                                height: "auto",
                                borderRadius: "0",
                                fontSize: "16px",
                                fontWeight: "400"
                              },
                              border: "0",
                              borderBottom: "1px solid #ced4da",
                              borderRadius: "0",
                              width: "100%"
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {languages.map((c) => (
                              <MenuItem value={c.name}>{c.name}</MenuItem>
                            ))}
                          </Select>

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("5")}

                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply?.language?.length) {
                                props.handleSteps();
                              } else {
                                Errornotify("Please enter your language!");
                              }
                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : props.steps.step === "7" ? (
                    <div className={styles.card}>
                      <div className={styles.main_heading}>
                        <div className={styles.question_no_div}>
                          7
                        </div>
                        <h2>What is your phone number *</h2>
                      </div>
                      <div className={styles.form_sec}>
                        <div className={styles.inputs}>

                          <Entermobile
                            handleChangeNumber={handleChangeNumberCurator}
                            value={props.apply.phone}
                            border="applyform"
                          />
                          {props.apply.phone && error && (
                            <span
                              style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
                            >
                              {error}
                            </span>
                          )}

                        </div>
                        <div className={styles.btn_div}>
                          <button
                            onClick={() => props.handleStepsBack("6")}
                            className="apply_ltbtn"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              if (props.apply.phone?.length) {
                                if (
                                  error.length
                                ) {
                                  Errornotify("Please enter a valid phone number");
                                } else {
                                  props.handleSteps();
                                }
                              } else {
                                Errornotify("Please enter your phone number");
                              }

                            }}
                            className="apply_btn"
                          >
                            Next
                          </button>
                        </div>
                      </div>

                    </div>
                  ) : (
                    // : props.steps.step === "10" ? (
                    // <div className={styles.card}>
                    //   <div className={styles.main_heading}>
                    //     <div className={styles.question_no_div}>
                    //       <h5>10</h5>
                    //     </div>
                    //     <h2>Skype </h2>
                    //   </div>
                    //   <div className={styles.inputs}>
                    //     <TextField
                    //       InputProps={{ style: { fontSize: 25 } }}
                    //       sx={{ lineHeight: "34px", fontSize: "25px" }}
                    //       variant="standard"
                    //       className={styles.input_text}
                    //       fullWidth
                    //       name="skype_id"
                    //       value={props.apply.skype_id}
                    //       placeholder="Enter ID"
                    //       type="text"
                    //       onChange={props.handleChangeApplyData}
                    //     />
                    //     <div className={styles.btn_div}>
                    //       <Button
                    //         onClick={() => props.handleStepsBack("9")}
                    //         // onClick={props.handleSteps}
                    //         variant="contained"
                    //         sx={{ width: "126px", height: "50px", my: 2 }}
                    //       >
                    //         Previous
                    //       </Button>
                    //       <Button
                    //         onClick={() => {
                    //           if (props.apply?.skype_id?.length) {
                    //             props.handleSteps()
                    //           } else {
                    //             Errornotify("Please enter your skype ID!")
                    //           }
                    //         }}
                    //         variant="contained"
                    //         sx={{ width: "126px", height: "50px", my: 2 }}
                    //       >
                    //         Next
                    //       </Button>
                    //     </div>
                    //   </div>
                    // </div>

                    // )
                    ""
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>

        </div>
      )}
      <ToastContainer />
    </>
  );
}
