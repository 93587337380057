import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import axios from "axios";
import { Errornotify, Successnotify } from "../common/alert/Alert";
import { placeOrderApi } from "../State/api/orderApi";
import { useDispatch } from "react-redux";
import { ApplyGirfCard } from "../State/api/userApi";

const data = {
  intent: "sale",
  payer: {
    payment_method: "paypal",
  },
  transactions: [
    {
      amount: {
        total: "30.11",
        currency: "USD",
        details: {
          subtotal: "30.00",
          tax: "0.07",
          shipping: "0.03",
          handling_fee: "1.00",
          shipping_discount: "-1.00",
          insurance: "0.01",
        },
      },
      description: "This is the payment transaction description.",
      custom: "EBAY_EMS_90048630024435",
      invoice_number: "48787589673",
      payment_options: {
        allowed_payment_method: "INSTANT_FUNDING_SOURCE",
      },
      soft_descriptor: "ECHI5786786",
      item_list: {
        items: [
          {
            name: "hat",
            description: "Brown color hat",
            quantity: "5",
            price: "3",
            tax: "0.01",
            sku: "1",
            currency: "USD",
          },
          {
            name: "handbag",
            description: "Black color hand bag",
            quantity: "1",
            price: "15",
            tax: "0.02",
            sku: "product34",
            currency: "USD",
          },
        ],
        shipping_address: {
          recipient_name: "Hello World",
          line1: "4thFloor",
          line2: "unit#34",
          city: "SAn Jose",
          country_code: "US",
          postal_code: "95131",
          phone: "011862212345678",
          state: "CA",
        },
      },
    },
  ],
  note_to_payer: "Contact us for any questions on your order.",
  redirect_urls: {
    return_url: "https://example.com",
    cancel_url: "https://example.com",
  },
};
const PaypalGift = ({ gift_card_id, paymentType, amount, email,setStep, offers,setOffers }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <PayPalButtons
        fundingSource="paypal"
        style={{
          height: 36, // Adjust height if necessary
          position: "relative",
        }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: localStorage.getItem('user_id'),
                user_id:localStorage.getItem('user_id'),
                amount: {
                  value: amount,
                },
              },
            ],
          });
        }}
        onApprove={async (data, action) => {
          console.log(data, "datapaypal");
          const order = await action.order.capture();
          // const name = action.order.payer.name.given_name;
          if (order) {
          const session_id = order?.id;
          const recipientDetail = {
            receipient_email: offers.receipient_email,
            receipient_fullname: offers.receipient_fullname,
            gift_Date: offers.selected_date,
          };

          const senderInfo = {
            offering_occassion: offers.occasion,
            about_you: offers.gift_message,
            message_about_card: offers.gift_message,
          };
            dispatch(
              ApplyGirfCard(
                gift_card_id,
                paymentType,
                session_id,
                amount,
                email,
                recipientDetail,
                senderInfo
              )
            );
            setStep("1");
            setOffers({...offers,offer_amount: ''})
          } else {
            Errornotify("Payment Failed");
          }
          // alert(`Transaction completed by ${name}`);
          // handleApprove(data.orderID);
        }}
        // onCancel={() => {}}
        // onError={(err) => {
        //     setError(err);
        //     console.log("PayPal Checkout onError", err);
        // }}
      />
    </div>
  );
};

export default PaypalGift;
