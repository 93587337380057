import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  Select,
  TextareaAutosize,
  TextField,
  Tooltip,
  Link,
  Autocomplete,
} from "@mui/material";
import React, { useState } from 'react';
import styles from "./Commoninputs.module.css";
import "react-quill/dist/quill.snow.css"; // Default Quill styles
import "./quill.css";
import { RiEyeLine } from "react-icons/ri";
import { ToastContainer } from "react-toastify";
import ReactQuill from "react-quill";
import { FiUpload } from "react-icons/fi";
import PhoneInput from "react-phone-input-2";

export default function Commoninputs(props) {
  console.log("props, gallery--", props);
  const handlePopup = () => {
    props.setOpen && props.setOpen(true);
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];
  const country = [
    { code: "AD", label: "Andorra", phone: "376" },
    {
      code: "AE",
      label: "United Arab Emirates",
      phone: "971",
    },
    { code: "AF", label: "Afghanistan", phone: "93" },
    {
      code: "AG",
      label: "Antigua and Barbuda",
      phone: "1-268",
    },
    { code: "AI", label: "Anguilla", phone: "1-264" },
    { code: "AL", label: "Albania", phone: "355" },
    { code: "AM", label: "Armenia", phone: "374" },
    { code: "AO", label: "Angola", phone: "244" },
    { code: "AQ", label: "Antarctica", phone: "672" },
    { code: "AR", label: "Argentina", phone: "54" },
    { code: "AS", label: "American Samoa", phone: "1-684" },
    { code: "AT", label: "Austria", phone: "43" },
    {
      code: "AU",
      label: "Australia",
      phone: "61",
      suggested: true,
    },
    { code: "AW", label: "Aruba", phone: "297" },
    { code: "AX", label: "Alland Islands", phone: "358" },
    { code: "AZ", label: "Azerbaijan", phone: "994" },
    {
      code: "BA",
      label: "Bosnia and Herzegovina",
      phone: "387",
    },
    { code: "BB", label: "Barbados", phone: "1-246" },
    { code: "BD", label: "Bangladesh", phone: "880" },
    { code: "BE", label: "Belgium", phone: "32" },
    { code: "BF", label: "Burkina Faso", phone: "226" },
    { code: "BG", label: "Bulgaria", phone: "359" },
    { code: "BH", label: "Bahrain", phone: "973" },
    { code: "BI", label: "Burundi", phone: "257" },
    { code: "BJ", label: "Benin", phone: "229" },
    { code: "BL", label: "Saint Barthelemy", phone: "590" },
    { code: "BM", label: "Bermuda", phone: "1-441" },
    { code: "BN", label: "Brunei Darussalam", phone: "673" },
    { code: "BO", label: "Bolivia", phone: "591" },
    { code: "BR", label: "Brazil", phone: "55" },
    { code: "BS", label: "Bahamas", phone: "1-242" },
    { code: "BT", label: "Bhutan", phone: "975" },
    { code: "BV", label: "Bouvet Island", phone: "47" },
    { code: "BW", label: "Botswana", phone: "267" },
    { code: "BY", label: "Belarus", phone: "375" },
    { code: "BZ", label: "Belize", phone: "501" },
    {
      code: "CA",
      label: "Canada",
      phone: "1",
      suggested: true,
    },
    {
      code: "CC",
      label: "Cocos (Keeling) Islands",
      phone: "61",
    },
    {
      code: "CD",
      label: "Congo, Democratic Republic of the",
      phone: "243",
    },
    {
      code: "CF",
      label: "Central African Republic",
      phone: "236",
    },
    {
      code: "CG",
      label: "Congo, Republic of the",
      phone: "242",
    },
    { code: "CH", label: "Switzerland", phone: "41" },
    { code: "CI", label: "Cote d'Ivoire", phone: "225" },
    { code: "CK", label: "Cook Islands", phone: "682" },
    { code: "CL", label: "Chile", phone: "56" },
    { code: "CM", label: "Cameroon", phone: "237" },
    { code: "CN", label: "China", phone: "86" },
    { code: "CO", label: "Colombia", phone: "57" },
    { code: "CR", label: "Costa Rica", phone: "506" },
    { code: "CU", label: "Cuba", phone: "53" },
    { code: "CV", label: "Cape Verde", phone: "238" },
    { code: "CW", label: "Curacao", phone: "599" },
    { code: "CX", label: "Christmas Island", phone: "61" },
    { code: "CY", label: "Cyprus", phone: "357" },
    { code: "CZ", label: "Czech Republic", phone: "420" },
    {
      code: "DE",
      label: "Germany",
      phone: "49",
      suggested: true,
    },
    { code: "DJ", label: "Djibouti", phone: "253" },
    { code: "DK", label: "Denmark", phone: "45" },
    { code: "DM", label: "Dominica", phone: "1-767" },
    {
      code: "DO",
      label: "Dominican Republic",
      phone: "1-809",
    },
    { code: "DZ", label: "Algeria", phone: "213" },
    { code: "EC", label: "Ecuador", phone: "593" },
    { code: "EE", label: "Estonia", phone: "372" },
    { code: "EG", label: "Egypt", phone: "20" },
    { code: "EH", label: "Western Sahara", phone: "212" },
    { code: "ER", label: "Eritrea", phone: "291" },
    { code: "ES", label: "Spain", phone: "34" },
    { code: "ET", label: "Ethiopia", phone: "251" },
    { code: "FI", label: "Finland", phone: "358" },
    { code: "FJ", label: "Fiji", phone: "679" },
    {
      code: "FK",
      label: "Falkland Islands (Malvinas)",
      phone: "500",
    },
    {
      code: "FM",
      label: "Micronesia, Federated States of",
      phone: "691",
    },
    { code: "FO", label: "Faroe Islands", phone: "298" },
    {
      code: "FR",
      label: "France",
      phone: "33",
      suggested: true,
    },
    { code: "GA", label: "Gabon", phone: "241" },
    { code: "GB", label: "United Kingdom", phone: "44" },
    { code: "GD", label: "Grenada", phone: "1-473" },
    { code: "GE", label: "Georgia", phone: "995" },
    { code: "GF", label: "French Guiana", phone: "594" },
    { code: "GG", label: "Guernsey", phone: "44" },
    { code: "GH", label: "Ghana", phone: "233" },
    { code: "GI", label: "Gibraltar", phone: "350" },
    { code: "GL", label: "Greenland", phone: "299" },
    { code: "GM", label: "Gambia", phone: "220" },
    { code: "GN", label: "Guinea", phone: "224" },
    { code: "GP", label: "Guadeloupe", phone: "590" },
    { code: "GQ", label: "Equatorial Guinea", phone: "240" },
    { code: "GR", label: "Greece", phone: "30" },
    {
      code: "GS",
      label: "South Georgia and the South Sandwich Islands",
      phone: "500",
    },
    { code: "GT", label: "Guatemala", phone: "502" },
    { code: "GU", label: "Guam", phone: "1-671" },
    { code: "GW", label: "Guinea-Bissau", phone: "245" },
    { code: "GY", label: "Guyana", phone: "592" },
    { code: "HK", label: "Hong Kong", phone: "852" },
    {
      code: "HM",
      label: "Heard Island and McDonald Islands",
      phone: "672",
    },
    { code: "HN", label: "Honduras", phone: "504" },
    { code: "HR", label: "Croatia", phone: "385" },
    { code: "HT", label: "Haiti", phone: "509" },
    { code: "HU", label: "Hungary", phone: "36" },
    { code: "ID", label: "Indonesia", phone: "62" },
    { code: "IE", label: "Ireland", phone: "353" },
    { code: "IL", label: "Israel", phone: "972" },
    { code: "IM", label: "Isle of Man", phone: "44" },
    { code: "IN", label: "India", phone: "91" },
    {
      code: "IO",
      label: "British Indian Ocean Territory",
      phone: "246",
    },
    { code: "IQ", label: "Iraq", phone: "964" },
    {
      code: "IR",
      label: "Iran, Islamic Republic of",
      phone: "98",
    },
    { code: "IS", label: "Iceland", phone: "354" },
    { code: "IT", label: "Italy", phone: "39" },
    { code: "JE", label: "Jersey", phone: "44" },
    { code: "JM", label: "Jamaica", phone: "1-876" },
    { code: "JO", label: "Jordan", phone: "962" },
    {
      code: "JP",
      label: "Japan",
      phone: "81",
      suggested: true,
    },
    { code: "KE", label: "Kenya", phone: "254" },
    { code: "KG", label: "Kyrgyzstan", phone: "996" },
    { code: "KH", label: "Cambodia", phone: "855" },
    { code: "KI", label: "Kiribati", phone: "686" },
    { code: "KM", label: "Comoros", phone: "269" },
    {
      code: "KN",
      label: "Saint Kitts and Nevis",
      phone: "1-869",
    },
    {
      code: "KP",
      label: "Korea, Democratic People's Republic of",
      phone: "850",
    },
    { code: "KR", label: "Korea, Republic of", phone: "82" },
    { code: "KW", label: "Kuwait", phone: "965" },
    { code: "KY", label: "Cayman Islands", phone: "1-345" },
    { code: "KZ", label: "Kazakhstan", phone: "7" },
    {
      code: "LA",
      label: "Lao People's Democratic Republic",
      phone: "856",
    },
    { code: "LB", label: "Lebanon", phone: "961" },
    { code: "LC", label: "Saint Lucia", phone: "1-758" },
    { code: "LI", label: "Liechtenstein", phone: "423" },
    { code: "LK", label: "Sri Lanka", phone: "94" },
    { code: "LR", label: "Liberia", phone: "231" },
    { code: "LS", label: "Lesotho", phone: "266" },
    { code: "LT", label: "Lithuania", phone: "370" },
    { code: "LU", label: "Luxembourg", phone: "352" },
    { code: "LV", label: "Latvia", phone: "371" },
    { code: "LY", label: "Libya", phone: "218" },
    { code: "MA", label: "Morocco", phone: "212" },
    { code: "MC", label: "Monaco", phone: "377" },
    {
      code: "MD",
      label: "Moldova, Republic of",
      phone: "373",
    },
    { code: "ME", label: "Montenegro", phone: "382" },
    {
      code: "MF",
      label: "Saint Martin (French part)",
      phone: "590",
    },
    { code: "MG", label: "Madagascar", phone: "261" },
    { code: "MH", label: "Marshall Islands", phone: "692" },
    {
      code: "MK",
      label: "Macedonia, the Former Yugoslav Republic of",
      phone: "389",
    },
    { code: "ML", label: "Mali", phone: "223" },
    { code: "MM", label: "Myanmar", phone: "95" },
    { code: "MN", label: "Mongolia", phone: "976" },
    { code: "MO", label: "Macao", phone: "853" },
    {
      code: "MP",
      label: "Northern Mariana Islands",
      phone: "1-670",
    },
    { code: "MQ", label: "Martinique", phone: "596" },
    { code: "MR", label: "Mauritania", phone: "222" },
    { code: "MS", label: "Montserrat", phone: "1-664" },
    { code: "MT", label: "Malta", phone: "356" },
    { code: "MU", label: "Mauritius", phone: "230" },
    { code: "MV", label: "Maldives", phone: "960" },
    { code: "MW", label: "Malawi", phone: "265" },
    { code: "MX", label: "Mexico", phone: "52" },
    { code: "MY", label: "Malaysia", phone: "60" },
    { code: "MZ", label: "Mozambique", phone: "258" },
    { code: "NA", label: "Namibia", phone: "264" },
    { code: "NC", label: "New Caledonia", phone: "687" },
    { code: "NE", label: "Niger", phone: "227" },
    { code: "NF", label: "Norfolk Island", phone: "672" },
    { code: "NG", label: "Nigeria", phone: "234" },
    { code: "NI", label: "Nicaragua", phone: "505" },
    { code: "NL", label: "Netherlands", phone: "31" },
    { code: "NO", label: "Norway", phone: "47" },
    { code: "NP", label: "Nepal", phone: "977" },
    { code: "NR", label: "Nauru", phone: "674" },
    { code: "NU", label: "Niue", phone: "683" },
    { code: "NZ", label: "New Zealand", phone: "64" },
    { code: "OM", label: "Oman", phone: "968" },
    { code: "PA", label: "Panama", phone: "507" },
    { code: "PE", label: "Peru", phone: "51" },
    { code: "PF", label: "French Polynesia", phone: "689" },
    { code: "PG", label: "Papua New Guinea", phone: "675" },
    { code: "PH", label: "Philippines", phone: "63" },
    { code: "PK", label: "Pakistan", phone: "92" },
    { code: "PL", label: "Poland", phone: "48" },
    {
      code: "PM",
      label: "Saint Pierre and Miquelon",
      phone: "508",
    },
    { code: "PN", label: "Pitcairn", phone: "870" },
    { code: "PR", label: "Puerto Rico", phone: "1" },
    {
      code: "PS",
      label: "Palestine, State of",
      phone: "970",
    },
    { code: "PT", label: "Portugal", phone: "351" },
    { code: "PW", label: "Palau", phone: "680" },
    { code: "PY", label: "Paraguay", phone: "595" },
    { code: "QA", label: "Qatar", phone: "974" },
    { code: "RE", label: "Reunion", phone: "262" },
    { code: "RO", label: "Romania", phone: "40" },
    { code: "RS", label: "Serbia", phone: "381" },
    { code: "RU", label: "Russian Federation", phone: "7" },
    { code: "RW", label: "Rwanda", phone: "250" },
    { code: "SA", label: "Saudi Arabia", phone: "966" },
    { code: "SB", label: "Solomon Islands", phone: "677" },
    { code: "SC", label: "Seychelles", phone: "248" },
    { code: "SD", label: "Sudan", phone: "249" },
    { code: "SE", label: "Sweden", phone: "46" },
    { code: "SG", label: "Singapore", phone: "65" },
    { code: "SH", label: "Saint Helena", phone: "290" },
    { code: "SI", label: "Slovenia", phone: "386" },
    {
      code: "SJ",
      label: "Svalbard and Jan Mayen",
      phone: "47",
    },
    { code: "SK", label: "Slovakia", phone: "421" },
    { code: "SL", label: "Sierra Leone", phone: "232" },
    { code: "SM", label: "San Marino", phone: "378" },
    { code: "SN", label: "Senegal", phone: "221" },
    { code: "SO", label: "Somalia", phone: "252" },
    { code: "SR", label: "Suriname", phone: "597" },
    { code: "SS", label: "South Sudan", phone: "211" },
    {
      code: "ST",
      label: "Sao Tome and Principe",
      phone: "239",
    },
    { code: "SV", label: "El Salvador", phone: "503" },
    {
      code: "SX",
      label: "Sint Maarten (Dutch part)",
      phone: "1-721",
    },
    {
      code: "SY",
      label: "Syrian Arab Republic",
      phone: "963",
    },
    { code: "SZ", label: "Swaziland", phone: "268" },
    {
      code: "TC",
      label: "Turks and Caicos Islands",
      phone: "1-649",
    },
    { code: "TD", label: "Chad", phone: "235" },
    {
      code: "TF",
      label: "French Southern Territories",
      phone: "262",
    },
    { code: "TG", label: "Togo", phone: "228" },
    { code: "TH", label: "Thailand", phone: "66" },
    { code: "TJ", label: "Tajikistan", phone: "992" },
    { code: "TK", label: "Tokelau", phone: "690" },
    { code: "TL", label: "Timor-Leste", phone: "670" },
    { code: "TM", label: "Turkmenistan", phone: "993" },
    { code: "TN", label: "Tunisia", phone: "216" },
    { code: "TO", label: "Tonga", phone: "676" },
    { code: "TR", label: "Turkey", phone: "90" },
    {
      code: "TT",
      label: "Trinidad and Tobago",
      phone: "1-868",
    },
    { code: "TV", label: "Tuvalu", phone: "688" },
    {
      code: "TW",
      label: "Taiwan, Province of China",
      phone: "886",
    },
    {
      code: "TZ",
      label: "United Republic of Tanzania",
      phone: "255",
    },
    { code: "UA", label: "Ukraine", phone: "380" },
    { code: "UG", label: "Uganda", phone: "256" },
    {
      code: "US",
      label: "United States",
      phone: "1",
      suggested: true,
    },
    { code: "UY", label: "Uruguay", phone: "598" },
    { code: "UZ", label: "Uzbekistan", phone: "998" },
    {
      code: "VA",
      label: "Holy See (Vatican City State)",
      phone: "379",
    },
    {
      code: "VC",
      label: "Saint Vincent and the Grenadines",
      phone: "1-784",
    },
    { code: "VE", label: "Venezuela", phone: "58" },
    {
      code: "VG",
      label: "British Virgin Islands",
      phone: "1-284",
    },
    {
      code: "VI",
      label: "US Virgin Islands",
      phone: "1-340",
    },
    { code: "VN", label: "Vietnam", phone: "84" },
    { code: "VU", label: "Vanuatu", phone: "678" },
    { code: "WF", label: "Wallis and Futuna", phone: "681" },
    { code: "WS", label: "Samoa", phone: "685" },
    { code: "XK", label: "Kosovo", phone: "383" },
    { code: "YE", label: "Yemen", phone: "967" },
    { code: "YT", label: "Mayotte", phone: "262" },
    { code: "ZA", label: "South Africa", phone: "27" },
    { code: "ZM", label: "Zambia", phone: "260" },
    { code: "ZW", label: "Zimbabwe", phone: "263" },
  ];
  const nationalities = [
    { code: "AD", label: "Andorran" },
    { code: "AE", label: "Emirati" },
    { code: "AF", label: "Afghan" },
    { code: "AG", label: "Antiguan or Barbudan" },
    { code: "AI", label: "Anguillian" },
    { code: "AL", label: "Albanian" },
    { code: "AM", label: "Armenian" },
    { code: "AO", label: "Angolan" },
    { code: "AQ", label: "Antarctican" },
    { code: "AR", label: "Argentine" },
    { code: "AS", label: "American Samoan" },
    { code: "AT", label: "Austrian" },
    { code: "AU", label: "Australian" },
    { code: "AW", label: "Aruban" },
    { code: "AX", label: "Ålander" },
    { code: "AZ", label: "Azerbaijani" },
    { code: "BA", label: "Bosnian" },
    { code: "BB", label: "Barbadian" },
    { code: "BD", label: "Bangladeshi" },
    { code: "BE", label: "Belgian" },
    { code: "BF", label: "Burkinabe" },
    { code: "BG", label: "Bulgarian" },
    { code: "BH", label: "Bahraini" },
    { code: "BI", label: "Burundian" },
    { code: "BJ", label: "Beninese" },
    { code: "BL", label: "Barthélemois" },
    { code: "BM", label: "Bermudian" },
    { code: "BN", label: "Bruneian" },
    { code: "BO", label: "Bolivian" },
    { code: "BR", label: "Brazilian" },
    { code: "BS", label: "Bahamian" },
    { code: "BT", label: "Bhutanese" },
    { code: "BV", label: "Bouvet Islander" },
    { code: "BW", label: "Botswana" },
    { code: "BY", label: "Belarusian" },
    { code: "BZ", label: "Belizean" },
    { code: "CA", label: "Canadian" },
    { code: "CC", label: "Cocos Islander" },
    { code: "CD", label: "Congolese (DRC)" },
    { code: "CF", label: "Central African" },
    { code: "CG", label: "Congolese (Republic)" },
    { code: "CH", label: "Swiss" },
    { code: "CI", label: "Ivorian" },
    { code: "CK", label: "Cook Islander" },
    { code: "CL", label: "Chilean" },
    { code: "CM", label: "Cameroonian" },
    { code: "CN", label: "Chinese" },
    { code: "CO", label: "Colombian" },
    { code: "CR", label: "Costa Rican" },
    { code: "CU", label: "Cuban" },
    { code: "CV", label: "Cape Verdean" },
    { code: "CW", label: "Curaçaoan" },
    { code: "CX", label: "Christmas Islander" },
    { code: "CY", label: "Cypriot" },
    { code: "CZ", label: "Czech" },
    { code: "DE", label: "German" },
    { code: "DJ", label: "Djiboutian" },
    { code: "DK", label: "Danish" },
    { code: "DM", label: "Dominican" },
    { code: "DO", label: "Dominican" },
    { code: "DZ", label: "Algerian" },
    { code: "EC", label: "Ecuadorian" },
    { code: "EE", label: "Estonian" },
    { code: "EG", label: "Egyptian" },
    { code: "EH", label: "Western Saharan" },
    { code: "ER", label: "Eritrean" },
    { code: "ES", label: "Spanish" },
    { code: "ET", label: "Ethiopian" },
    { code: "FI", label: "Finnish" },
    { code: "FJ", label: "Fijian" },
    { code: "FK", label: "Falkland Islander" },
    { code: "FM", label: "Micronesian" },
    { code: "FO", label: "Faroe Islander" },
    { code: "FR", label: "French" },
    { code: "GA", label: "Gabonese" },
    { code: "GB", label: "British" },
    { code: "GD", label: "Grenadian" },
    { code: "GE", label: "Georgian" },
    { code: "GF", label: "French Guianese" },
    { code: "GG", label: "Guernsey" },
    { code: "GH", label: "Ghanaian" },
    { code: "GI", label: "Gibraltarian" },
    { code: "GL", label: "Greenlandic" },
    { code: "GM", label: "Gambian" },
    { code: "GN", label: "Guinean" },
    { code: "GP", label: "Guadeloupean" },
    { code: "GQ", label: "Equatorial Guinean" },
    { code: "GR", label: "Greek" },
    { code: "GS", label: "South Georgian" },
    { code: "GT", label: "Guatemalan" },
    { code: "GU", label: "Guamanian" },
    { code: "GW", label: "Bissau-Guinean" },
    { code: "GY", label: "Guyanese" },
    { code: "HK", label: "Hong Konger" },
    { code: "HM", label: "Heard Islander" },
    { code: "HN", label: "Honduran" },
    { code: "HR", label: "Croatian" },
    { code: "HT", label: "Haitian" },
    { code: "HU", label: "Hungarian" },
    { code: "ID", label: "Indonesian" },
    { code: "IE", label: "Irish" },
    { code: "IL", label: "Israeli" },
    { code: "IM", label: "Manx" },
    { code: "IN", label: "Indian" },
    { code: "IO", label: "British Indian Ocean Territory" },
    { code: "IQ", label: "Iraqi" },
    { code: "IR", label: "Iranian" },
    { code: "IS", label: "Icelander" },
    { code: "IT", label: "Italian" },
    { code: "JE", label: "Jerseyman" },
    { code: "JM", label: "Jamaican" },
    { code: "JO", label: "Jordanian" },
    { code: "JP", label: "Japanese" },
    { code: "KE", label: "Kenyan" },
    { code: "KG", label: "Kyrgyzstani" },
    { code: "KH", label: "Cambodian" },
    { code: "KI", label: "Kiribati" },
    { code: "KM", label: "Comoran" },
    { code: "KN", label: "Kittitian or Nevisian" },
    { code: "KP", label: "North Korean" },
    { code: "KR", label: "South Korean" },
    { code: "KW", label: "Kuwaiti" },
    { code: "KY", label: "Caymanian" },
    { code: "KZ", label: "Kazakh" },
    { code: "LA", label: "Laotian" },
    { code: "LB", label: "Lebanese" },
    { code: "LC", label: "Saint Lucian" },
    { code: "LI", label: "Liechtensteiner" },
    { code: "LK", label: "Sri Lankan" },
    { code: "LR", label: "Liberian" },
    { code: "LS", label: "Basotho" },
    { code: "LT", label: "Lithuanian" },
    { code: "LU", label: "Luxembourger" },
    { code: "LV", label: "Latvian" },
    { code: "LY", label: "Libyan" },
    { code: "MA", label: "Moroccan" },
    { code: "MC", label: "Monegasque" },
    { code: "MD", label: "Moldovan" },
    { code: "ME", label: "Montenegrin" },
    { code: "MF", label: "Saint Martin" },
    { code: "MG", label: "Malagasy" },
    { code: "MH", label: "Marshallese" },
    { code: "MK", label: "Macedonian" },
    { code: "ML", label: "Malian" },
    { code: "MM", label: "Burmese" },
    { code: "MN", label: "Mongolian" },
    { code: "MO", label: "Macao" },
    { code: "MP", label: "Northern Mariana Islander" },
    { code: "MQ", label: "Martinican" },
    { code: "MR", label: "Mauritanian" },
    { code: "MS", label: "Montserratian" },
    { code: "MT", label: "Maltese" },
    { code: "MU", label: "Mauritian" },
    { code: "MV", label: "Maldivian" },
    { code: "MW", label: "Malawian" },
    { code: "MX", label: "Mexican" },
    { code: "MY", label: "Malaysian" },
    { code: "MZ", label: "Mozambican" },
    { code: "NA", label: "Namibian" },
    { code: "NC", label: "New Caledonian" },
    { code: "NE", label: "Nigerien" },
    { code: "NF", label: "Norfolk Islander" },
    { code: "NG", label: "Nigerian" },
    { code: "NI", label: "Nicaraguan" },
    { code: "NL", label: "Dutch" },
    { code: "NO", label: "Norwegian" },
    { code: "NP", label: "Nepali" },
    { code: "NR", label: "Nauruan" },
    { code: "NU", label: "Niuean" },
    { code: "NZ", label: "New Zealander" },
    { code: "OM", label: "Omani" },
    { code: "PA", label: "Panamanian" },
    { code: "PE", label: "Peruvian" },
    { code: "PF", label: "French Polynesian" },
    { code: "PG", label: "Papua New Guinean" },
    { code: "PH", label: "Filipino" },
    { code: "PK", label: "Pakistani" },
    { code: "PL", label: "Polish" },
    { code: "PM", label: "Saint-Pierrais or Miquelonnais" },
    { code: "PN", label: "Pitcairn Islander" },
    { code: "PR", label: "Puerto Rican" },
    { code: "PT", label: "Portuguese" },
    { code: "PW", label: "Palauan" },
    { code: "PY", label: "Paraguayan" },
    { code: "QA", label: "Qatari" },
    { code: "RE", label: "Réunionese" },
    { code: "RO", label: "Romanian" },
    { code: "RS", label: "Serbian" },
    { code: "RU", label: "Russian" },
    { code: "RW", label: "Rwandan" },
    { code: "SA", label: "Saudi Arabian" },
    { code: "SB", label: "Solomon Islander" },
    { code: "SC", label: "Seychellois" },
    { code: "SD", label: "Sudanese" },
    { code: "SE", label: "Swedish" },
    { code: "SG", label: "Singaporean" },
    { code: "SH", label: "Saint Helenian" },
    { code: "SI", label: "Slovenian" },
    { code: "SJ", label: "Svalbardian" },
    { code: "SK", label: "Slovak" },
    { code: "SL", label: "Sierra Leonean" },
    { code: "SM", label: "Sammarinese" },
    { code: "SN", label: "Senegalese" },
    { code: "SO", label: "Somali" },
    { code: "SR", label: "Surinamese" },
    { code: "SS", label: "South Sudanese" },
    { code: "ST", label: "Sao Tomean" },
    { code: "SV", label: "Salvadoran" },
    { code: "SX", label: "Sint Maarten" },
    { code: "SY", label: "Syrian" },
    { code: "SZ", label: "Swazi" },
    { code: "TC", label: "Turks and Caicos Islander" },
    { code: "TD", label: "Chadian" },
    { code: "TF", label: "French Southern and Antarctic Lands" },
    { code: "TG", label: "Togolese" },
    { code: "TH", label: "Thai" },
    { code: "TJ", label: "Tajikistani" },
    { code: "TK", label: "Tokelauan" },
    { code: "TL", label: "Timorese" },
    { code: "TM", label: "Turkmen" },
    { code: "TN", label: "Tunisian" },
    { code: "TO", label: "Tongan" },
    { code: "TR", label: "Turkish" },
    { code: "TT", label: "Trinidadian" },
    { code: "TV", label: "Tuvaluan" },
    { code: "TZ", label: "Tanzanian" },
    { code: "UA", label: "Ukrainian" },
    { code: "UG", label: "Ugandan" },
    { code: "UM", label: "U.S. Minor Outlying Islands" },
    { code: "UN", label: "United Nations" },
    { code: "US", label: "American" },
    { code: "UY", label: "Uruguayan" },
    { code: "UZ", label: "Uzbekistani" },
    { code: "VA", label: "Vatican" },
    { code: "VC", label: "Saint Vincentian" },
    { code: "VE", label: "Venezuelan" },
    { code: "VG", label: "British Virgin Islander" },
    { code: "VI", label: "American Virgin Islander" },
    { code: "VN", label: "Vietnamese" },
    { code: "VU", label: "Vanuatuan" },
    { code: "WF", label: "Wallis and Futunan" },
    { code: "WS", label: "Samoan" },
    { code: "YE", label: "Yemeni" },
    { code: "YT", label: "Mayottean" },
    { code: "ZA", label: "South African" },
    { code: "ZM", label: "Zambian" },
    { code: "ZW", label: "Zimbabwean" },
  ];
  const years = [];
  for (let year = 1950; year <= 2024; year++) {
    years.push(year.toString());
  }

  const languages = [
    { code: "en", name: "English" },
    { code: "hi", name: "Hindi" },
    { code: "ar", name: "Arabic" },
    { code: "es", name: "Spanish" },
    { code: "fr", name: "French" },
    { code: "de", name: "German" },
    { code: "zh", name: "Chinese" },
    { code: "ja", name: "Japanese" },
    { code: "ru", name: "Russian" },
    { code: "pt", name: "Portuguese" },
    { code: "it", name: "Italian" },
    { code: "ko", name: "Korean" },
    { code: "tr", name: "Turkish" },
    { code: "vi", name: "Vietnamese" },
    { code: "nl", name: "Dutch" },
    { code: "sv", name: "Swedish" },
    { code: "pl", name: "Polish" },
    { code: "uk", name: "Ukrainian" },
    { code: "el", name: "Greek" },
    { code: "he", name: "Hebrew" },
    { code: "th", name: "Thai" },
    { code: "cs", name: "Czech" },
    { code: "fi", name: "Finnish" },
    { code: "no", name: "Norwegian" },
    { code: "da", name: "Danish" },
    { code: "hu", name: "Hungarian" },
    { code: "ro", name: "Romanian" },
    { code: "sk", name: "Slovak" },
    { code: "bg", name: "Bulgarian" },
    { code: "hr", name: "Croatian" },
    { code: "sr", name: "Serbian" },
    { code: "lt", name: "Lithuanian" },
    { code: "lv", name: "Latvian" },
    { code: "et", name: "Estonian" },
    { code: "sl", name: "Slovenian" },
    { code: "is", name: "Icelandic" },
    { code: "ga", name: "Irish" },
    { code: "mt", name: "Maltese" },
    { code: "cy", name: "Welsh" },
    { code: "sq", name: "Albanian" },
    { code: "mk", name: "Macedonian" },
    { code: "bs", name: "Bosnian" },
    { code: "ms", name: "Malay" },
    { code: "sw", name: "Swahili" },
    { code: "am", name: "Amharic" },
    { code: "fa", name: "Persian" },
    { code: "ur", name: "Urdu" },
    { code: "bn", name: "Bengali" },
    { code: "ta", name: "Tamil" },
    { code: "te", name: "Telugu" },
    { code: "mr", name: "Marathi" },
    { code: "gu", name: "Gujarati" },
    { code: "kn", name: "Kannada" },
    { code: "ml", name: "Malayalam" },
    { code: "pa", name: "Punjabi" },
    { code: "si", name: "Sinhala" },
    { code: "ne", name: "Nepali" },
    { code: "km", name: "Khmer" },
    { code: "my", name: "Burmese" },
    { code: "lo", name: "Lao" },
    { code: "mn", name: "Mongolian" },
    { code: "kk", name: "Kazakh" },
    { code: "uz", name: "Uzbek" },
    { code: "hy", name: "Armenian" },
    { code: "az", name: "Azerbaijani" },
    { code: "ka", name: "Georgian" },
    // Add more languages as needed
  ];
  const [activeTab, setActiveTab] = useState(0);
  // Artist Subscribtion
  const lastArray =
    props.subscription && props.subscription.length > 0
      ? props.subscription[props.subscription.length - 1]
      : {}; // Default to an empty object if no valid array is found

  console.log("for subscription lastArray", lastArray);

  const renderPhotosArtist = (source) => {
    return source?.map((photo, index) => {
      return (
        <div className={styles.photoWrapper} key={index}>
          <img
            className={styles.imgs}
            src={photo}
            alt=""
            style={{ cursor: "pointer", height: "100px", width: "100px" }}
          />
          <div className={styles.del_img_div}>
            <IconButton onClick={() => props.handleRemoveImageArtist(index)}>
              <img
                src="/img/delete.png"
                height="15px"
                width="14px"
                alt="delete icon"
              />
            </IconButton>
          </div>
        </div>
      );
    });
  };
  const renderPhotosGallery = (source) => {
    return source?.map((photo, index) => {
      return (
        <div className={styles.photoWrapper} key={index}>
          <img
            className={styles.imgs}
            src={photo}
            alt=""
            style={{ cursor: "pointer", height: "100px", width: "100px" }}
          />
          <div className={styles.del_img_div}>
            <IconButton onClick={() => props.handleRemoveImageGallery(index)}>
              <img
                src="/img/delete.png"
                height="15px"
                width="14px"
                alt="delete icon"
              />
            </IconButton>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {props.rendertype && props.rendertype === "Artist" ? (
        <>
          <div className={styles.mt_2}>
            <div className="tab-headers">
              <ul className={styles.tablist}>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 0 ? "active" : ""}`}
                    onClick={() => setActiveTab(0)}
                    style={{
                      background: activeTab === 0 ? "#1c1c1c" : "#fff",
                      color: activeTab === 0 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Personal Details
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 1 ? "active" : ""}`}
                    onClick={() => setActiveTab(1)}
                    style={{
                      background: activeTab === 1 ? "#1c1c1c" : "#fff",
                      color: activeTab === 1 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Professional Details
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 2 ? "active" : ""}`}
                    onClick={() => setActiveTab(2)}
                    style={{
                      background: activeTab === 2 ? "#1c1c1c" : "#fff",
                      color: activeTab === 2 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Shipping Address
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 3 ? "active" : ""}`}
                    onClick={() => setActiveTab(3)}
                    style={{
                      background: activeTab === 3 ? "#1c1c1c" : "#fff",
                      color: activeTab === 3 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Bank Details
                  </Link>
                </li>
              </ul>
            </div>

            {/* Tab Content */}
            <div className={`tab-content ${styles.tab_content}`}>
              {activeTab === 0 && (
                <div>
                  <div className={styles.second_flex}>
                    <div className="form-group">
                      <label>What is Your first name? *</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="first_name"
                        value={props.detail?.first_name}
                        placeholder="Enter your first name"
                        onChange={props.handleChange}
                        fullWidth
                      />
                      {props.errors?.first_name && (
                        <span style={{ color: "red" }}>{props.errors?.first_name}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>What is your last name? *</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="last_name"
                        value={props?.detail?.last_name}
                        placeholder="Enter your last name"
                        onChange={props.handleChange}
                        fullWidth
                      />
                      {props?.errors?.last_name && (
                        <span style={{ color: "red" }}>{props?.errors?.last_name}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>What is your phone number? *</label>
                      <PhoneInput
                        country={"us"}
                        value={props?.detail?.phone || ""}
                        onChange={(value) =>
                          props.handleChange({ target: { name: "phone", value } })
                        }
                        inputClass="form-control"
                        placeholder="Enter your phone number"
                      />

                      {props.errors?.phone && (
                        <span style={{ color: "red" }}>{props.errors.phone}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>What is your email address? *</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="email"
                        name="email"
                        disabled
                        onChange={props.handleChange}
                        value={props.detail && props.detail?.email}
                        placeholder="Enter Your Email"
                        sx={{
                          backgroundColor: "white",
                          height: 50,
                          borderRadius: "6px",
                          mt: 0.5,
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                        InputProps={{
                          readOnly: true, // This makes the field read-only
                        }}
                        fullWidth
                      />
                      {props.errors?.email && (
                        <span style={{ color: "red" }}>{props.errors?.email}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        What is your country of residence? *
                      </label>
                      <Autocomplete
                        id="combo-box-demo"
                        disableClearable
                        options={country.map((vl) => vl.label)}
                        name="country"
                        value={props.detail && props.detail?.country}// Load initial value from props
                        onChange={(event, newValue) => {
                          props.setDetail({ ...props.detail, country: newValue }); // Update value
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",
                            paddingLeft: ".75rem",
                            fontSize: "14px"// Adjust padding as needed
                          },
                          "& .MuiAutocomplete-input": {
                            paddingLeft: "0 !important"
                          },
                          width: "100%",
                          border: "none",
                        }}
                        className={styles.search_select}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ border: "none", color: "#636365" }}
                            placeholder="Search"
                          />
                        )}
                      />

                    </div>
                    <div className="form-group">
                      <label>
                        What is Your Nationality? *
                      </label>
                      <Autocomplete

                        id="combo-box-demo"
                        disableClearable
                        options={nationalities.map((vl) => vl.label)}
                        name="nationality"
                        value={props.detail && props.detail?.nationality}// Load initial value from props
                        onChange={(event, newValue) => {
                          props.setDetail({ ...props.detail, nationality: newValue }); // Update value
                        }}

                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",
                            paddingLeft: ".75rem",
                            fontSize: "14px" // Adjust padding as needed
                          },
                          "& .MuiAutocomplete-input": {
                            paddingLeft: "0 !important"
                          },
                          width: "100%",
                          border: "none",

                        }}
                        className={styles.search_select}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ border: "none", color: "#636365" }}
                            placeholder="Search"
                          />
                        )}
                      />
                    </div>
                    <div className="form-group">
                      <label>What is your Preferred Language? *</label>
                      <Autocomplete
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",
                            paddingLeft: ".75rem",
                            fontSize: "14px" // Adjust padding as needed
                          },
                          "& .MuiAutocomplete-input": {
                            paddingLeft: "0 !important"
                          },
                        }}
                        getOptionLabel={(option) => `${option.name}`}

                        disableClearable // Correcting the misspelling here

                        renderInput={(params) => (
                          <TextField {...params} fullWidth variant="outlined" />
                        )}
                      />

                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="password"
                        // disabled
                        value={localStorage.getItem("password")}
                        name="password"
                        placeholder="**"
                      />
                    </div>
                    <div className={styles.forgot_pass}>
                      <a onClick={() => props.setPasspop(true)}>Change Password?</a>
                    </div>

                    <h3 className="lablhdng">Please provide us the links of your social media platform here</h3>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label>Instargram</label>
                          <input
                            className="form-control"
                            variant="outlined"
                            type="text"
                            name="instagram"
                            value={
                              props.detail && props.detail.social_links
                                ? props.detail.social_links?.instagram
                                : ""
                            }
                            placeholder="www.instagram.com/example"
                            onChange={props.handleChange}
                          />
                          {props.errors?.instagram && (
                            <span style={{ color: "red" }}>{props.errors?.instagram}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label>Facebook</label>
                          <input
                            className="form-control"
                            variant="outlined"
                            type="text"
                            name="facebook"
                            value={
                              props.detail && props.detail.social_links
                                ? props.detail.social_links?.facebook
                                : ""
                            }
                            placeholder="www.facebook.com/example"
                            onChange={props.handleChange}
                          />
                          {props.errors?.facebook && (
                            <span style={{ color: "red" }}>{props.errors?.facebook}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label>X </label>
                          <input
                            className="form-control"
                            variant="outlined"
                            type="text"
                            name="twitter"
                            value={
                              props.detail && props.detail.social_links
                                ? props.detail.social_links?.twitter
                                : ""
                            }
                            placeholder="www.x.com/example"
                            onChange={props.handleChange}
                          />
                          {props.errors?.twitter && (
                            <span style={{ color: "red" }}>{props.errors?.twitter}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label>LinkedIn</label>
                          <input
                            className="form-control"
                            variant="outlined"
                            type="text"
                            name="linked_in"
                            value={
                              props.detail && props.detail.social_links
                                ? props.detail.social_links?.linked_in
                                : ""
                            }
                            placeholder="www.linkedin.com/example"
                            onChange={props.handleChange}
                          />
                          {props.errors?.linked_in && (
                            <span style={{ color: "red" }}>{props.errors?.linked_in}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label>Youtube</label>
                          <input
                            className="form-control"
                            variant="outlined"
                            type="text"
                            name="youtube"
                            value={
                              props.detail && props.detail.social_links
                                ? props.detail.social_links?.youtube
                                : ""
                            }
                            placeholder="www.youtube.com/example"
                            onChange={props.handleChange}
                          />
                          {/* {props.errors?.linked_in && (
                <span style={{ color: "red" }}>{props.errors?.linked_in}</span>
              )} */}
                        </div>
                      </Grid>
                    </Grid>

                    <div className="form-group">
                      <div className={styles.downlicon}>
                        <div>
                          <label>Upload your ID (i.e. Emirates ID, National ID, Aadhar Card, Passport, Driving License etc) *<small class="wrtsmall">(File format: PDF/PNG/JPEG, Max Size: 2MB)</small></label>
                        </div>
                        <div>
                          <ul className={styles.docaction}>
                            <li>
                              <Tooltip title="View Id Proof">
                                <a className={styles.downbtn}
                                  href={props.detail?.ID_proof}
                                  download
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                  rel="noreferrer"
                                >
                                  {props?.detail?.ID_proof ? (
                                    <IconButton sx={{
                                      padding: "0"
                                    }}>
                                      <RiEyeLine className={styles.iconwf} />
                                    </IconButton>
                                  ) : null}
                                </a>
                              </Tooltip>
                            </li>
                          </ul>
                        </div>
                      </div>


                      {props.src.id === "" ? (
                        <div className={styles.img_box}>
                          <div
                            htmlFor="ID_proof"
                            style={{ textAlign: "center" }}
                            onClick={props.handleChangeImage}
                          >
                            <input
                              onChange={props.handleChangeImage}
                              name="ID_proof"
                              type="file"
                              id="ID_proof"
                              hidden
                            />
                            <label htmlFor="ID_proof" className={styles.labelv}>
                              <FiUpload />
                              <p>Upload ID Proof</p>
                            </label>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={styles.img_box}>
                            <div
                              htmlFor="ID_proof"
                              style={{ textAlign: "center" }}
                              onClick={props.handleChangeImage}
                            >
                              <input
                                onChange={props.handleChangeImage}
                                name="ID_proof"
                                type="file"
                                id="ID_proof"
                                hidden
                              />
                              <label htmlFor="ID_proof" className={styles.labelv}>
                                <FiUpload />
                                {props?.src?.id?.name ? (
                                  <p>{props?.src?.id?.name}</p>
                                ) : props?.src?.id ? (
                                  <p>{props?.src?.id}</p>
                                ) : (
                                  <p>Upload your ID Proof</p>
                                )}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <h3 className="lablhdng">Education</h3>
                    <div className="form-group">
                      <label>Arts qualification university</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="qualification"
                        value={props.detail && props.detail?.qualification}
                        placeholder="Enter your qualification"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Degree Year</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="degree_year"
                        value={props.detail?.degree_year || ""}
                        placeholder="Enter Degree year"
                        onChange={props.handleChange}
                        maxLength={4}
                      />
                    </div>
                    {/* <h3 className={styles.ckndr}>Active Plan</h3>
            <div className="form-group">
              <label>Subscription Plans</label>
              <input
                className="form-control"
                type="text"
                disabled
                value={lastArray?.subscriptionDetails?.plan_name}
                name="subsciption_plan_id"
                placeholder="Select a plan"
              />
            </div> */}
                  </div>
                </div>
              )}
              {activeTab === 1 && (
                <div>
                  <div className={styles.second_flex}>

                    <div className="form-group">
                      <div className={styles.downlicon}>
                        <div>
                          <label>Please upload your latest portfolio in word doucment or pdf
                            <small className="wrtsmall">(Max Size 100MB)</small>
                          </label> </div>
                        <div>
                          <ul className={styles.docaction}>
                            <li>
                              <Tooltip title="View Portfolio">
                                <a className={styles.downbtn}
                                  href={props.detail?.CV}
                                  download
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                  rel="noreferrer"
                                >
                                  {props?.detail?.CV ? (
                                    <IconButton sx={{
                                      padding: "0"
                                    }}>
                                      <RiEyeLine className={styles.iconwf} />
                                    </IconButton>
                                  ) : null}
                                </a>
                              </Tooltip>
                            </li>
                          </ul>
                        </div>
                      </div>


                      {props.src?.cv === "" ? (
                        <div className={styles.img_box}>
                          <div
                            htmlFor="CV"
                            style={{ textAlign: "center" }}
                            onClick={props.handleChangeImage}
                          >
                            <input
                              onChange={props.handleChangeImage}
                              name="CV"
                              type="file"
                              id="CV"
                              hidden
                            />
                            <label htmlFor="CV" className={styles.labelv}>
                              <FiUpload />
                              <p>Upload latest portfolio</p>
                            </label>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={styles.img_box}>
                            <div
                              htmlFor="CV"
                              style={{ textAlign: "center" }}
                              onClick={props.handleChangeImage}
                            >
                              <input
                                onChange={props.handleChangeImage}
                                name="CV"
                                type="file"
                                id="CV"
                                hidden
                              />
                              <label htmlFor="CV" className={styles.labelv}>
                                <FiUpload />
                                {props?.src?.cv?.name ? (
                                  <p>{props?.src?.cv?.name}</p>
                                ) : props?.src?.cv ? (
                                  <p>{props?.src?.cv}</p>
                                ) : (
                                  <p>Upload your CV</p>
                                )}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      <div>

                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        <span className="lablhdng">About Artist:</span>
                        Please write about yourself, education, art qualitfications, inspiration behind your artworks etc *
                      </label>
                      <ReactQuill
                        name="about"
                        value={props.detail?.about || ""}
                        placeholder="Write about yourself"
                        onChange={(value) =>
                          props.setApply({ ...props.detail, about: value })
                        }
                        theme="snow"
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        <span className="lablhdng">Professional Details:</span>
                        What are your most important exhibitions, awards/prizes, etc? *
                        <small className="wrtsmall">Please provide us with the name, the year and the place of each experience.</small>
                      </label>
                      <ReactQuill
                        name="professional_details"
                        value={props.detail && props.detail.professional_details}
                        placeholder="Enter Your professional details"
                        onChange={(value) =>
                          props.setApply({
                            ...props.detail,
                            professional_details: value,
                          })
                        }
                        theme="snow"
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                    <div className="form-group">
                      <label>Upload your best artworks images *
                        <small className="wrtsmall">(Upload: Max-10 and Min-2 Artworks, File format: PNG/JPG, Max Size: 2MB)</small>
                      </label>
                      <div className={styles.imguplod}>
                        <div htmlFor="input" style={{ textAlign: "center" }}>
                          <input
                            name="art_work"
                            onChange={props.handleImageChangeMultipleArtist}
                            type="file"
                            id="art_work"
                            hidden
                            accept="image/*"
                          />
                          <label htmlFor="art_work" className={styles.labelv}>
                            <FiUpload />
                            <p>Upload Your Art Works</p>
                          </label>
                        </div>
                      </div>
                      <div className={styles.result}>
                        {renderPhotosArtist(props?.selectedFilesArtist)}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Are you a full time professional artist? *</label>
                      <Grid container>
                        <Grid item xs={2}>
                          <FormGroup>
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label ": {
                                  fontSize: "14px"
                                }
                              }}
                              control={
                                <Radio
                                  name="is_fulltime_artist"
                                  value="Yes"
                                  checked={props?.detail?.is_fulltime_artist === "Yes"}
                                  onChange={props.handleChange}
                                />
                              }
                              label="Yes"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={2}>
                          <FormGroup>
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label ": {
                                  fontSize: "14px"
                                }
                              }}
                              control={
                                <Radio
                                  name="is_fulltime_artist"
                                  value="No"
                                  checked={props?.detail?.is_fulltime_artist === "No"}
                                  onChange={props.handleChange}
                                />
                              }
                              label="No"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </div>

                    <div className="form-group">
                      <label>
                        How many years of experience you have as an artist? *
                      </label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="number"
                        minLength={0}
                        rows={1}
                        name="exp"
                        value={props.detail?.exp}
                        placeholder="Enter your experience"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        If you are represented by another online art gallery, Please provide URL links of your artworks on those galleries
                      </label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="online_art_gallary_URL"
                        value={props?.detail?.online_art_gallary_URL}
                        placeholder="www.example.com"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Please provide the link to your online portfolio (Artist Website)</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="portfolio"
                        value={props.detail && props.detail.portfolio}
                        placeholder="www.example.com"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        What is your main category? *
                      </label>
                      <Grid container>
                        {props?.maincategory?.map((item) => {
                          const array1 = props?.detail?.category_id || [];
                          const isChecked = array1.some(
                            (ele) => ele?._id?._id === item?._id
                          );
                          return (
                            <Grid item xs={12} sm={6} md={4} xl={3} key={item._id}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{
                                    "& .MuiFormControlLabel-label ": {
                                      fontSize: "14px"
                                    }
                                  }}
                                  control={
                                    <Checkbox
                                      name="category_id"
                                      value={item._id}
                                      onClick={props.handleChange}
                                      checked={isChecked}
                                    />
                                  }
                                  label={item?.name}
                                />
                              </FormGroup>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                    <div className="form-group">
                      <label>Please select your artwork selling price range *</label>
                      <Grid container>
                        {props?.pricerange?.map((item) => {
                          // Check if the current item exists in art_price_range
                          const isChecked = props?.detail?.art_price_range?.includes(item?.label) || false;


                          return (
                            <Grid item xs={12} sm={6} md={4} xl={3} key={item?.label}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{
                                    "& .MuiFormControlLabel-label ": {
                                      fontSize: "14px"
                                    }
                                  }}
                                  control={
                                    <Checkbox
                                      onClick={props.handleChange}
                                      checked={isChecked}
                                      value={item.label}
                                      name="art_price_range"
                                    />
                                  }
                                  label={item?.label}
                                />
                              </FormGroup>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                    <div className="form-group">
                      <label>
                        How many artworks have you sold in the past 12 months? *
                      </label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="number"
                        name="arts_solds_in_past_12_months"
                        value={props?.detail?.arts_solds_in_past_12_months}
                        placeholder="Enter your Artworks"
                        onChange={props.handleChange}
                        minLength={0}
                      />
                    </div>
                    <div className="form-group">
                      <label>How many artworks do you produce each month? *</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="number"
                        name="produce_art_each_month"
                        value={props?.detail?.produce_art_each_month}
                        placeholder="Enter Artwork"
                        onChange={props.handleChange}
                        minLength={0}
                      />
                    </div>

                    <label>
                      Why do you think Art Smiley would need to select you and promote
                      your works?
                    </label>
                    <div className="form-group">
                      <Grid container>
                        {props?.promote?.map((item) => {
                          const ischecked = props?.detail?.promoting_by_reason?.includes(item?.label) || false;
                          return (
                            <Grid item xs={12}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{
                                    "& .MuiFormControlLabel-label ": {
                                      fontSize: "14px"
                                    }
                                  }}
                                  control={<Checkbox
                                    checked={ischecked}
                                    name="promoting_by_reason"
                                    value={item?.label}
                                    onChange={props?.handleChange}
                                  />}
                                  label={item?.label}
                                />
                              </FormGroup>
                            </Grid>
                          )
                        })}


                      </Grid>
                    </div>

                    <div className="form-group">
                      <label>Which category of artist you belong to?*</label>
                      <Grid container>
                        {props?.artistType?.map((item) => (
                          <Grid item xs={12} key={item?._id}>
                            <FormGroup>
                              <FormControlLabel
                                sx={{
                                  "& .MuiFormControlLabel-label ": {
                                    fontSize: "14px"
                                  }
                                }}
                                control={
                                  <Radio
                                    checked={
                                      props?.detail?.artist_type_id === item?._id ||
                                      false
                                    } // Ensure it defaults to false
                                    onChange={() =>
                                      props.handleChange({
                                        target: {
                                          name: "artist_type_id",
                                          value: item?._id,
                                        },
                                      })
                                    }
                                  />
                                }
                                label={item?.name || ""} // Use dynamic label
                              />
                            </FormGroup>
                          </Grid>
                        ))}
                      </Grid>
                    </div>

                    <div className="form-group">
                      <label>Do you prefer to work exclusively with Art Smiley?</label>
                      <Grid container>
                        <Grid item xs={2}>
                          <FormGroup>
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label ": {
                                  fontSize: "14px"
                                },

                              }}
                              control={<Radio
                                name="exclusive_work"
                                value="Yes"
                                checked={props?.detail?.exclusive_work === "Yes"}
                                onChange={props.handleChange} />}
                              label="Yes"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={2}>
                          <FormGroup>
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label ": {
                                  fontSize: "14px"
                                }
                              }}
                              control={<Radio
                                checked={props?.detail?.exclusive_work === "No"}
                                onChange={props.handleChange}
                                name="exclusive_work"
                                value="No"
                              />} label="No" />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 2 && (
                <div>
                  <div className={styles.second_flex}>
                    <div className="form-group">
                      <label>City</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="city"
                        value={props.detail && props.detail?.addresses?.[0]?.city}
                        placeholder="Enter your city"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>State</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="state"
                        value={props.detail && props.detail?.addresses?.[0]?.state}
                        placeholder="Enter Your State"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Country</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="country"
                        value={
                          props.detail && props.detail?.addresses?.[0]?.country
                        }
                        placeholder="Enter Your Country"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Zipcode</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="zipcode"
                        value={
                          props.detail && props.detail?.addresses?.[0]?.zipcode
                        }
                        placeholder="Enter Your zipcode"
                        onChange={props.handleChange}
                        fullWidth
                      />
                    </div>
                    <div className="form-group">
                      <label>Address 1</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="address_line1"
                        value={
                          props.detail &&
                          props.detail?.addresses?.[0]?.address_line1
                        }
                        placeholder="Enter Your address1"
                        onChange={props.handleChange}
                        fullWidth
                      />
                    </div>
                    <div className="form-group">
                      <label>Address 2</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="address_line2"
                        value={
                          props.detail &&
                          props.detail?.addresses?.[0]?.address_line2
                        }
                        placeholder="Enter Your address2"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Nationality</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="nationality"
                        value={
                          props.detail &&
                          props.detail?.addresses?.[0]?.nationality
                        }
                        placeholder="Enter Your nationality"
                        onChange={props.handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 3 && (
                <div>
                  <div className={styles.second_flex}>
                    <div className="form-group">
                      <label>Bank Name</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="bank_name"
                        value={props.detail.bank_name}
                        placeholder="Enter bank name"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Bank Address</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="bank_address"
                        value={props.detail && props.detail.bank_address}
                        placeholder="Enter bank address"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Bank Location</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="bank_location"
                        value={props.detail && props.detail.bank_location}
                        placeholder="Enter bank location"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Account Holder name</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="account_holder_name"
                        value={props.detail && props.detail.account_holder_name}
                        placeholder="Enter account holder name"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Account Type</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="account_type"
                        value={props.detail && props.detail.account_type}
                        placeholder="Enter account type"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Account Number</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="number"
                        name="account_number"
                        value={props.detail && props.detail.account_number}
                        placeholder="Enter account number"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Routing/ABA Number</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="routing_ABA_number"
                        value={props.detail && props.detail.routing_ABA_number}
                        placeholder="Enter routing/ABA number"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Swift Code</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="number"
                        name="swift_code"
                        value={props.detail && props.detail.swift_code}
                        placeholder="Enter swift code"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Paypal Account</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="paypal_id"
                        value={props.detail && props.detail.paypal_id}
                        placeholder="Enter Paypal Account"
                        onChange={props.handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

        </>
      ) : props.rendertype && props.rendertype === "Affiliate" ? (
        <>
          <div className={styles.mt_2}>
            <div className="tab-headers">
              <ul className={styles.tablist}>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 0 ? "active" : ""}`}
                    onClick={() => setActiveTab(0)}
                    style={{
                      background: activeTab === 0 ? "#1c1c1c" : "#fff",
                      color: activeTab === 0 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Personal Details
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 1 ? "active" : ""}`}
                    onClick={() => setActiveTab(1)}
                    style={{
                      background: activeTab === 1 ? "#1c1c1c" : "#fff",
                      color: activeTab === 1 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Affiliate Details
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 2 ? "active" : ""}`}
                    onClick={() => setActiveTab(2)}
                    style={{
                      background: activeTab === 2 ? "#1c1c1c" : "#fff",
                      color: activeTab === 2 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Address
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 3 ? "active" : ""}`}
                    onClick={() => setActiveTab(3)}
                    style={{
                      background: activeTab === 3 ? "#1c1c1c" : "#fff",
                      color: activeTab === 3 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Bank Details
                  </Link>
                </li>
              </ul>
            </div>

            {/* Tab Content */}
            <div className={`tab-content ${styles.tab_content}`}>
              {activeTab === 0 && (
                <>
                  <div className={styles.second_flex}>
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="first_name"
                        value={props.detail?.first_name}
                        placeholder="Enter First Name"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="last_name"
                        value={props.detail?.last_name}
                        placeholder="Enter Last Name"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Mobile Number</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="number"
                        name="phone"
                        value={props.detail && props.detail.phone}
                        placeholder="+91 82937493937"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="email"
                        name="email"
                        value={props.affiliateDetails?.email}
                        placeholder="Enter Your Email"
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        // type="password"
                        // disabled
                        value={localStorage.getItem("password")}
                        name="password"
                        placeholder="*******"
                        type="password"
                      />
                    </div>
                    <div className={styles.forgot_pass}>
                      <a onClick={() => props.setPasspop(true)}>Change Password?</a>
                    </div>
                    <div className="form-group">
                      <label>Affiliate Type</label>
                      {/* <Autocomplete
                  className="form-control"
                  variant="outlined"
                  name="affiliate_account_type_id"
                  options={
                    props.affiliateType === null
                      ? []
                      : props.affiliateType.map((vl) => vl.name)
                  }
                  type="text"
                  defaultValue={
                    props.affiliateType &&
                    props.affiliateType.filter(
                      (val) =>
                        val._id === props.detail &&
                        props.detail.affiliate_account_type_id
                    )
                  }
                  value={props.detail && props.detail.affiliate_account_type_id}
                  placeholder="Company"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="affiliate_account_type_id"
                      {...params}
                      sx={{ border: "none", color: "#636365" }}
                      placeholder="Search"
                    />
                  )}
                /> */}
                      <FormControl fullWidth sx={{}}>
                        <Select
                          fullWidthclassName="form-control"
                          type="text"
                          value={props.detail && props.detail.affiliate_account_type_id}
                          onChange={props.handleChange}
                          placeholder="Select Affiliate Type"
                          name="affiliate_account_type_id"
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiInputBase-input": {
                              padding: ".375rem .75rem",
                              height: "auto",
                              borderRadius: ".25rem",
                              fontSize: "14px",
                              fontWeight: "400",
                            },
                            border: "1px solid #ced4da",
                            borderRadius: ".25rem",
                            width: "100%",
                            background: "#fff",
                          }}
                        >
                          <MenuItem disabled sx={{ color: "#636365" }} value="">
                            None
                          </MenuItem>
                          {props.affiliateType &&
                            props.affiliateType.map((vl) => (
                              <MenuItem value={vl._id}>{vl.name}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </>
              )}
              {activeTab === 1 && (
                <>
                  <div className={styles.second_flex}>
                    <div className="form-group">
                      <label>How do you plan to promote Art Smiley services?</label>
                      <Select
                        variant="outlined"
                        type="text"
                        name="promote_art_smiley"
                        value={props.detail && props.detail.promote_art_smiley}
                        placeholder="Please Select"
                        onChange={props.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".375rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                          background: "#fff",
                        }}
                        fullWidth
                      >
                        <MenuItem value="Social media">Social media</MenuItem>
                        <MenuItem value="Website">Website</MenuItem>
                        <MenuItem value="Networking">Networking</MenuItem>
                        <MenuItem value="Influencer">Influencer</MenuItem>
                        <MenuItem value="Marketing">Marketing</MenuItem>
                        <MenuItem value="Mobile App">Mobile App</MenuItem>
                        <MenuItem value="Offline">Offline</MenuItem>
                        <MenuItem value="Direct Linking">Direct Linking</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </div>
                    <div className="form-group">
                      <label>Tell us about your audience interests</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="audience_interest"
                        value={props.detail && props.detail.audience_interest}
                        placeholder="Please Select"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Enter your website URL</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="website_url"
                        value={props.detail && props.detail.website_url}
                        placeholder="Website URL"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Enter Your Mobile App URL</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="app_url"
                        value={props.detail && props.detail.app_url}
                        placeholder="Mobile app URL"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Which of the following topics best describes your website or
                        mobile apps?
                      </label>
                      <Select
                        variant="outlined"
                        type="text"
                        name="description"
                        value={props.detail && props.detail.description}
                        placeholder="Saving Account"
                        onChange={props.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".375rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                          background: "#fff",
                        }}
                      >
                        <MenuItem value="Interior Decor">Interior Decor</MenuItem>
                        <MenuItem value="Architect">Architect</MenuItem>
                        <MenuItem value="Art blogs">Art blogs</MenuItem>
                        <MenuItem value="Art Forums">Art Forums</MenuItem>
                        <MenuItem value="B2B">B2B</MenuItem>
                        <MenuItem value="B2C">B2C</MenuItem>
                        <MenuItem value="Art & Craft Stores">
                          Art & Craft Stores
                        </MenuItem>
                        <MenuItem value="Mobile Search">Mobile Search</MenuItem>
                        <MenuItem value="Social Search">Social Search</MenuItem>
                        <MenuItem value="Content creators">Content creators</MenuItem>
                        <MenuItem value="Media">Media</MenuItem>
                        <MenuItem value="Gift">Gift</MenuItem>
                        <MenuItem value="Photos and Print Services">
                          Photos and Print Services
                        </MenuItem>
                        <MenuItem value="Editorial publications">
                          Editorial publications
                        </MenuItem>
                        <MenuItem value="Publisher">Publisher</MenuItem>
                        <MenuItem value="Coupon Code Websites">
                          Coupon Code Websites
                        </MenuItem>
                        <MenuItem value="Shopping Directory">
                          Shopping Directory
                        </MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </div>
                    <div className="form-group">
                      <label>How do you drive traffic to your website?</label>
                      <Select
                        variant="outlined"
                        type="text"
                        name="traffic_drivation"
                        value={props.detail && props.detail.traffic_drivation}
                        placeholder="Traffic"
                        onChange={props.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".375rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                          background: "#fff",
                        }}
                        fullWidth
                      >
                        <MenuItem value="Organic">Organic</MenuItem>
                        <MenuItem value="Paid ads">Paid ads</MenuItem>
                        <MenuItem value="Social media">Social media</MenuItem>
                        <MenuItem value="Email marketing">Email marketing</MenuItem>
                        <MenuItem value="Whatsapp marketing">
                          Whatsapp marketing
                        </MenuItem>
                        <MenuItem value="Retargeting">Retargeting</MenuItem>
                        <MenuItem value="Lead Generation">Lead Generation</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </div>
                    <div className="form-group">
                      <label>How do you usually build links?</label>
                      <Select
                        variant="outlined"
                        type="text"
                        name="build_links"
                        value={props.detail && props.detail.build_links}
                        placeholder="Art Management System"
                        onChange={props.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".375rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                          background: "#fff",
                        }}
                        fullWidth
                      >
                        <MenuItem value="Linking via Landing Pages">
                          Linking via Landing Pages
                        </MenuItem>
                        <MenuItem value="Direct Linking">Direct Linking</MenuItem>
                        <MenuItem value="Guest Posting">Guest Posting</MenuItem>
                        <MenuItem value="Co marketing">Co marketing</MenuItem>
                      </Select>
                    </div>
                    <div className="form-group">
                      <label>
                        How do you utilize your websites and apps to genrate income?
                      </label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="generate_income"
                        value={props.detail && props.detail.generate_income}
                        placeholder="Online Selling"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        How many total unique visitors do your websites and apps get per
                        month?
                      </label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="unique_visitor_count"
                        value={props.detail && props.detail.unique_visitor_count}
                        placeholder="From 5000 to 5,000"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        What is your primary reason for joining the Art Smiley
                        Associates?
                      </label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="primary_reason_to_join"
                        value={props.detail && props.detail.primary_reason_to_join}
                        placeholder="other"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>How did you hear about us?</label>
                      <Select
                        variant="outlined"
                        type="text"
                        name="hear_about_us"
                        value={props.detail && props.detail.hear_about_us}
                        placeholder="Ad"
                        onChange={props.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".375rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                          background: "#fff",
                        }}
                        fullWidth
                      >
                        <MenuItem value="Social media">Social media</MenuItem>
                        <MenuItem value="Google search">Google search</MenuItem>
                        <MenuItem value="Friend">Friend</MenuItem>
                        <MenuItem
                          value="Existing
                        Customer"
                        >
                          Existing Customer
                        </MenuItem>
                        <MenuItem value="Existing Artist">Existing Artist</MenuItem>
                        <MenuItem value="Existing Curator">Existing Curator</MenuItem>
                        <MenuItem value="TV">TV</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </div>
                    <div className="form-group">
                      <label>What are your websites or mobile apps about?</label>
                      <textarea
                        maxRows={8}
                        minRows={8}
                        fullWidth
                        className="form-control"
                        name="website_about"
                        value={props.detail && props.detail.website_about}
                        placeholder="Type Here....."
                        onChange={props.handleChange}
                      />
                    </div>
                  </div>
                </>
              )}
              {activeTab === 2 && (
                <>
                  <div className={styles.second_flex}>
                    <div className="form-group">
                      <label>Address Type</label>
                      <Select
                        variant="outlined"
                        type="text"
                        name="address_type"
                        value={props.detail && props.detail.address_type}
                        placeholder="Address type"
                        onChange={props.handleChange}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".375rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                          background: "#fff",
                        }}
                        fullWidth
                      >
                        <MenuItem value="Home">Home</MenuItem>
                        <MenuItem value="Office">Office</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </div>
                    <div className="form-group">
                      <label>Address Line 1</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="address_line_1"
                        value={props.detail && props.detail.address_line_1}
                        placeholder="Address"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Address Line 2</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="address_type_2"
                        value={props.detail && props.detail.address_type_2}
                        placeholder="Address"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="city"
                        value={props.detail && props.detail.city}
                        placeholder="City"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>State</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="state"
                        value={props.detail && props.detail.state}
                        placeholder="State"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Country</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="country"
                        value={props.detail && props.detail.country}
                        placeholder="country"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Zipcode</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="zipcode"
                        value={props.detail && props.detail.zipcode}
                        placeholder="Zipcode"
                        onChange={props.handleChange}
                      />
                    </div>
                  </div>
                </>
              )}
              {activeTab === 3 && (
                <>
                  <div className={styles.second_flex}>
                    <div className="form-group">
                      <label>Bank Name</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="bank_name"
                        // defaul
                        value={props.detail.bank_name}
                        placeholder={props.detail.bank_name}
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Bank Address</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="bank_address"
                        value={props.detail && props.detail.bank_address}
                        placeholder="Bank Address"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Bank Location</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="bank_location"
                        value={props.detail && props.detail.bank_location}
                        placeholder=" Bank Location"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Account Holder name</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="account_holder_name"
                        value={props.detail && props.detail.account_holder_name}
                        placeholder="Holder Name"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Account Type</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="account_type"
                        value={props.detail && props.detail.account_type}
                        placeholder="Account Type"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Account Number</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="account_number"
                        value={props.detail && props.detail.account_number}
                        placeholder="Account Number"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Routing/ABA Number</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="routing_ABA_number"
                        value={props.detail && props.detail.routing_ABA_number}
                        placeholder="Routing/ABA Number"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Swift Code</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="swift_code"
                        value={props.detail && props.detail.swift_code}
                        placeholder="Swift Code"
                        onChange={props.handleChange}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

        </>
      ) : props.rendertype && props.rendertype === "Gallery" ? (
        <>
          <div className={styles.mt_2}>
            <div className="tab-headers">
              <ul className={styles.tablist}>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 0 ? "active" : ""}`}
                    onClick={() => setActiveTab(0)}
                    style={{
                      background: activeTab === 0 ? "#1c1c1c" : "#fff",
                      color: activeTab === 0 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Personal Details
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 1 ? "active" : ""}`}
                    onClick={() => setActiveTab(1)}
                    style={{
                      background: activeTab === 1 ? "#1c1c1c" : "#fff",
                      color: activeTab === 1 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Professional Details
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 2 ? "active" : ""}`}
                    onClick={() => setActiveTab(2)}
                    style={{
                      background: activeTab === 2 ? "#1c1c1c" : "#fff",
                      color: activeTab === 2 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Shipping Address
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab-button ${activeTab === 3 ? "active" : ""}`}
                    onClick={() => setActiveTab(3)}
                    style={{
                      background: activeTab === 3 ? "#1c1c1c" : "#fff",
                      color: activeTab === 3 ? "#fff" : "#1c1c1c",
                    }}
                  >
                    Bank Details
                  </Link>
                </li>
              </ul>
            </div>
            <div className={`tab-content ${styles.tab_content}`}>
              {activeTab === 0 && (
                <>
                  <div className={styles.second_flex}>
                    <div className="form-group">
                      <label>

                        What is your first name? *</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="first_name"
                        value={props.detail?.first_name}
                        placeholder="Enter your first name"
                        onChange={props.handleChange}
                        fullWidth
                      />
                      {props.errors?.first_name && (
                        <span style={{ color: "red" }}>{props.errors?.first_name}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>

                        What is your last name? *</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="last_name"
                        value={props?.detail?.last_name}
                        placeholder="Enter your last name"
                        onChange={props.handleChange}
                        fullWidth
                      />
                      {props?.errors?.last_name && (
                        <span style={{ color: "red" }}>{props?.errors?.last_name}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <PhoneInput
                        name="phone"
                        country={"us"}
                        value={props?.detail?.phone !== undefined && props?.detail?.phone !== null ? String(props.detail.phone) : ""}
                        onChange={(value) =>
                          props.handleChange({ target: { name: "phone", value } }) // Force string
                        }
                        inputClass="form-control"
                        placeholder="Enter your phone number"
                      />
                      {props.errors?.phone && (
                        <span style={{ color: "red" }}>{props.errors?.phone}</span>
                      )}
                    </div>

                    <div className="form-group">
                      <label>
                        What is your email address? *</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="email"
                        name="email"
                        disabled
                        // onChange={props.handleChange}
                        value={props.detail && props.detail?.email}
                        placeholder="Enter Your Email"
                        sx={{
                          backgroundColor: "white",
                          height: 50,
                          borderRadius: "6px",
                          mt: 0.5,
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        }}
                        InputProps={{
                          readOnly: true, // This makes the field read-only
                        }}
                        fullWidth
                      />
                      {props.errors?.email && (
                        <span style={{ color: "red" }}>{props.errors?.email}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        What is your country of residence? *
                      </label>
                      <Autocomplete
                        id="combo-box-demo"
                        disableClearable
                        options={country.map((vl) => vl.label)}
                        name="country_residence"
                        value={props.detail && props.detail?.country_residence}// Load initial value from props
                         onChange={(_, newValue) => 
                          props.handleChange({ target: { name: "country_residence", value: newValue || "" } })
                        } 
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",
                            paddingLeft: ".75rem",
                            fontSize: "14px"// Adjust padding as needed
                          },
                          "& .MuiAutocomplete-input": {
                            paddingLeft: "0 !important"
                          },
                          width: "100%",
                          border: "none",
                        }}
                        className={styles.search_select}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ border: "none", color: "#636365" }}
                            placeholder="Search"
                          />
                        )}
                      />

                    </div>
                    <div className="form-group">
                      <label>What is your title? *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your title"
                        name="title"
                        value={props.detail && props.detail?.title}
                        onChange={props.handleChange}

                      />

                    </div>
                    <div className="form-group">
                      <label>What is your gallery name? *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your Gallery Name"
                        name="gallery_name"
                        value={props.detail && props.detail?.gallery_name}
                        onChange={props.handleChange}
                      />

                    </div>

                    <div className="form-group">
                      <label>
                        Where is your gallery based? *
                      </label>
                      <Autocomplete

                        id="combo-box-demo"
                        disableClearable
                        options={nationalities.map((vl) => vl.label)}
                        name="gallery_based"
                        value={props.detail && props.detail?.gallery_based}// Load initial value from props
                         onChange={(_, newValue) => 
                          props.handleChange({ target: { name: "gallery_based", value: newValue || "" } })
                        }                     
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",
                            paddingLeft: ".75rem",
                            fontSize: "14px" // Adjust padding as needed
                          },
                          "& .MuiAutocomplete-input": {
                            paddingLeft: "0 !important"
                          },
                          width: "100%",
                          border: "none",

                        }}
                        className={styles.search_select}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ border: "none", color: "#636365" }}
                            placeholder="Search"
                          />
                        )}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        When is your gallery established? *
                      </label>
                      <Autocomplete
                        id="combo-box-demo"
                        disableClearable
                        options={years.map((vl) => vl)}
                        name="gallery_established"
                        value={props.detail && props.detail?.gallery_established}// Load initial value from props
                        onChange={(_, newValue) => 
                          props.handleChange({ target: { name: "gallery_established", value: newValue || "" } })
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",
                            paddingLeft: ".75rem",
                            fontSize: "14px" // Adjust padding as needed
                          },
                          "& .MuiAutocomplete-input": {
                            paddingLeft: "0 !important"
                          },
                          width: "100%",
                          border: "none",

                        }}
                        className={styles.search_select}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ border: "none", color: "#636365" }}
                            placeholder="Search"
                          />
                        )}
                      />
                    </div>
                    <div className="form-group">
                      <label className={styles.input_label}>
                        What is your gallery address? *
                      </label>
                      <textarea
                        maxRows={5}
                        minRows={5}

                        className="form-control"
                        value={props.detail && props.detail?.gallery_address}
                        onChange={props.handleChange}
                        name="gallery_address"
                        fullWidth
                        placeholder="Write here"
                      /></div>
                    <div className="form-group">
                      <div className={styles.downlicon}>
                        <div>
                          <label> Upload your company registration document *{" "}
                            <small class="wrtsmall">(File format: PDF/DOC/DOCX, Max Size: 2MB)</small>
                          </label>
                        </div>
                        <div>
                          <ul className={styles.docaction}>
                            <li>
                              <Tooltip title="View Company Document">
                                <a className={styles.downbtn}
                                  href={props.detail?.registration_document}
                                  download
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                  rel="noreferrer"
                                >
                                  <IconButton sx={{
                                    padding: "0"
                                  }}>
                                    <RiEyeLine className={styles.iconwf} />
                                  </IconButton>
                                </a>
                              </Tooltip>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {props.src.registration_document === "" ? (
                        <div className={styles.img_box}>
                          <div
                            htmlFor="registration_document"
                            style={{ textAlign: "center" }}
                            onClick={props.handleChangeImage}
                          >
                            <input
                              onChange={props.handleChangeImage}
                              name="registration_document"
                              type="file"
                              id="registration_document"
                              hidden
                              accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            />
                            <label htmlFor="registration_document" className={styles.labelv}>
                              <FiUpload />
                              <p>Upload Your Document</p>
                            </label>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={styles.img_box}>
                            <div
                              htmlFor="registration_document"
                              style={{ textAlign: "center" }}
                              onClick={props.handleChangeImage}
                            >
                              <input
                                onChange={props.handleChangeImage}
                                name="registration_document"
                                type="file"
                                id="registration_document"
                                hidden
                                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              />
                              <label htmlFor="registration_document" className={styles.labelv}>
                                <FiUpload />
                                {props?.src?.registration_document?.name ? (
                                  <p>{props?.src?.registration_document?.name}</p>
                                ) : props?.src?.registration_document ? (
                                  <p>{props?.src?.registration_document}</p>
                                ) : (
                                  <p>Upload Your Document</p>
                                )}
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                    </div>
                    <div className="form-group">
                      <label>What is your Preferred Language? *</label>
                      <Autocomplete
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",
                            paddingLeft: ".75rem",
                            fontSize: "14px" // Adjust padding as needed
                          },
                          "& .MuiAutocomplete-input": {
                            paddingLeft: "0 !important"
                          },
                        }}
                        options={languages}
                        getOptionLabel={(option) => `${option.name}`}
                        name="language"
                        value={
                          props.detail.language
                            ? languages.find((lang) => lang.name === props.detail.language)
                            : null
                        }
                        disableClearable // Correcting the misspelling here
                        onChange={(_, newValue) => props.handleChange({ target: { name: "language", value: newValue?.name || "" } })}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth variant="outlined" />
                        )}
                      />

                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="password"
                        // disabled
                        value={localStorage.getItem("password")}
                        name="password"
                        placeholder="**"
                      />
                    </div>
                    <div className={styles.forgot_pass}>
                      <a onClick={() => props.setPasspop(true)}>Change Password?</a>
                    </div>
                    <h3 className="lablhdng">Please provide us the links of your gallery social media platform platform here
                    </h3>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label>Instargram</label>
                          <input
                            className="form-control"
                            variant="outlined"
                            type="text"
                            name="instagram"
                            value={
                              props.detail && props.detail.social_links
                                ? props.detail.social_links?.instagram
                                : ""
                            }
                            placeholder="www.instagram.com/example"
                            onChange={props.handleChange}
                          />
                          {props.errors?.instagram && (
                            <span style={{ color: "red" }}>{props.errors?.instagram}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label>Facebook</label>
                          <input
                            className="form-control"
                            variant="outlined"
                            type="text"
                            name="facebook"
                            value={
                              props.detail && props.detail.social_links
                                ? props.detail.social_links?.facebook
                                : ""
                            }
                            placeholder="www.facebook.com/example"
                            onChange={props.handleChange}
                          />
                          {props.errors?.facebook && (
                            <span style={{ color: "red" }}>{props.errors?.facebook}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label>X </label>
                          <input
                            className="form-control"
                            variant="outlined"
                            type="text"
                            name="twitter"
                            value={
                              props.detail && props.detail.social_links
                                ? props.detail.social_links?.twitter
                                : ""
                            }
                            placeholder="www.x.com/example"
                            onChange={props.handleChange}
                          />
                          {props.errors?.twitter && (
                            <span style={{ color: "red" }}>{props.errors?.twitter}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label>LinkedIn</label>
                          <input
                            className="form-control"
                            variant="outlined"
                            type="text"
                            name="linked_in"
                            value={
                              props.detail && props.detail.social_links
                                ? props.detail.social_links?.linked_in
                                : ""
                            }
                            placeholder="www.linkedin.com/example"
                            onChange={props.handleChange}
                          />
                          {props.errors?.linked_in && (
                            <span style={{ color: "red" }}>{props.errors?.linked_in}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <label>Youtube</label>
                          <input
                            className="form-control"
                            variant="outlined"
                            type="text"
                            name="youtube"
                            value={
                              props.detail && props.detail.social_links
                                ? props.detail.social_links?.youtube
                                : ""
                            }
                            placeholder="www.youtube.com/example"
                            onChange={props.handleChange}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <div className="form-group">
                      <div className={styles.downlicon}>
                        <div>
                          <label>Upload your ID (i.e. Emirates ID, National ID, Aadhar Card, Passport, Driving License etc) *
                            <small class="wrtsmall">(File format: PDF/PNG/JPEG, Max Size: 2MB)</small>
                          </label>
                        </div>
                        <div>
                          <ul className={styles.docaction}>
                            <li>
                              <Tooltip title="View Id Proof">
                                <a className={styles.downbtn}
                                  href={props.detail?.ID_proof}
                                  download
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                  rel="noreferrer"
                                >
                                  {props?.detail?.ID_proof ? (
                                    <IconButton sx={{
                                      padding: "0"
                                    }}>
                                      <RiEyeLine className={styles.iconwf} />
                                    </IconButton>
                                  ) : null}
                                </a>
                              </Tooltip>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {props.src.id === "" ? (
                        <div className={styles.img_box}>
                          <div
                            htmlFor="ID_proof"
                            style={{ textAlign: "center" }}
                            onClick={props.handleChangeImage}
                          >
                            <input
                              onChange={props.handleChangeImage}
                              name="ID_proof"
                              type="file"
                              id="ID_proof"
                              hidden
                            />
                            <label htmlFor="ID_proof" className={styles.labelv}>
                              <FiUpload />
                              <p>Upload ID Proof</p>
                            </label>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={styles.img_box}>
                            <div
                              htmlFor="ID_proof"
                              style={{ textAlign: "center" }}
                              onClick={props.handleChangeImage}
                            >
                              <input
                                onChange={props.handleChangeImage}
                                name="ID_proof"
                                type="file"
                                id="ID_proof"
                                hidden
                              />
                              <label htmlFor="ID_proof" className={styles.labelv}>
                                <FiUpload />
                                {props?.src?.id?.name ? (
                                  <p>{props?.src?.id?.name}</p>
                                ) : props?.src?.id ? (
                                  <p>{props?.src?.id}</p>
                                ) : (
                                  <p>Upload your ID Proof</p>
                                )}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <h3 className="lablhdng">Education</h3>
                    <div className="form-group">
                      <label>Arts qualification university</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="qualification"
                        value={props.detail && props.detail?.qualification}
                        placeholder="Enter your qualification"
                        onChange={props.handleChange}
                      />
                    </div>
                    {/* <h3 className="lablhdng">Active Plan</h3>
            <div className="form-group">
              <label>Subscription Plans</label>
              <input
                className="form-control"
                type="text"
                value={lastArray?.subscriptionDetails?.plan_name}
                name="subsciption_plan_id"
                placeholder="Select a plan"
              />
            </div>


            <div className="form-group">
              <label>
                What is your main category? *
              </label>
              <Grid container>
                {props?.maincategory?.map((item) => {
                  const array1 = props?.detail?.category_id || [];

                  const isChecked = array1.some(
                    (ele) => ele?._id === item?._id
                  );

                  return (
                    <Grid item xs={12} sm={6} md={4} xl={3} key={item._id}>
                      <FormGroup>
                        <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label ": {
                            fontSize: "14px"
                          }
                        }}
                          control={
                            <Checkbox
                              name="category_id"
                              value={item._id}
                              onClick={props.handleChange}
                              checked={isChecked}
                            />
                          }
                          label={item?.name}
                        />
                      </FormGroup>
                    </Grid>
                  );
                })}
              </Grid>
            </div>

            <label>Please select your artwork selling price range*</label>
            <div className="form-group">
              <Grid container>
                {props?.pricerange?.map((item) => {
                  const isChecked = props?.detail?.art_price_range?.includes(item?.label) || false;
                  return (
                    <Grid item xs={12} sm={6} md={4} xl={3} key={item?.label}>
                      <FormGroup>
                        <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label ": {
                            fontSize: "14px"
                          }
                        }}
                          control={
                            <Checkbox
                              onClick={props.handleChange}
                              checked={isChecked}
                              value={item.label}
                              name="art_price_range"
                            />
                          }
                          label={item?.label}
                        />
                      </FormGroup>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <div className="form-group">
              <label>
                How many artworks have you sold in the past 12 months? *
              </label>
              <input
                className="form-control"
                type="number"
                name="arts_solds_in_past_12_months" // Match this with value key
                value={props?.detail?.arts_solds_in_past_12_months || ''}
                placeholder="Enter your total artworks sold in the past 12 months"
                onChange={props.handleChange}
                min="0"
              />
            </div>

            {/* <div className="form-group">
              <label>How many artworks do you produce each month?*</label>
              <input
                className="form-control"
                variant="outlined"
                type="number"
                name="produce_art_each_month"
                value={props?.detail?.produce_art_each_month}
                placeholder="Enter Artwork"
                onChange={props.handleChange}
                minLength={0}
              />
            </div> */}

                  </div>
                </>
              )}
              {activeTab === 1 && (
                <div>
                  <div className={styles.second_flex}>

                    <div className="form-group">
                      <div className={styles.downlicon}>
                        <div>
                          <label>Please upload portfolio of your gallery in word document or pdf *
                            <small className="wrtsmall">(Max Size 10MB)</small>
                          </label> </div>
                        <div>
                          <ul className={styles.docaction}>
                            <li>
                              <Tooltip title="View Portfolio">
                                <a className={styles.downbtn}
                                  href={props.detail?.CV}
                                  download
                                  target="_blank"
                                  style={{ textDecoration: "none" }}
                                  rel="noreferrer"
                                >
                                  {props?.detail?.CV ? (
                                    <IconButton sx={{
                                      padding: "0"
                                    }}>
                                      <RiEyeLine className={styles.iconwf} />
                                    </IconButton>
                                  ) : null}
                                </a>
                              </Tooltip>
                            </li>
                          </ul>
                        </div>
                      </div>


                      {props.src?.cv === "" ? (
                        <div className={styles.img_box}>
                          <div
                            htmlFor="CV"
                            style={{ textAlign: "center" }}
                            onClick={props.handleChangeImage}
                          >
                            <input
                              onChange={props.handleChangeImage}
                              name="CV"
                              type="file"
                              id="CV"
                              hidden
                            />
                            <label htmlFor="CV" className={styles.labelv}>
                              <FiUpload />
                              <p>Upload CV</p>
                            </label>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={styles.img_box}>
                            <div
                              htmlFor="CV"
                              style={{ textAlign: "center" }}
                              onClick={props.handleChangeImage}
                            >
                              <input
                                onChange={props.handleChangeImage}
                                name="CV"
                                type="file"
                                id="CV"
                                hidden
                              />
                              <label htmlFor="CV" className={styles.labelv}>
                                <FiUpload />
                                {props?.src?.cv?.name ? (
                                  <p>{props?.src?.cv?.name}</p>
                                ) : props?.src?.cv ? (
                                  <p>{props?.src?.cv}</p>
                                ) : (
                                  <p>Upload your CV</p>
                                )}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      <div>

                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        About gallery *
                      </label>
                      <ReactQuill
                        name="description"
                        value={props.detail?.description || ""}
                        placeholder="Write about yourself"
                        onChange={(value) =>
                          props.setApply({ ...props.detail, description: value })
                        }
                        theme="snow"
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        <span className="lablhdng">Professional Details:</span>
                        Please provide details of prestigious exhibitions, awards/prizes, commissions, grants, recognitions etc? *
                        <small className="wrtsmall">Please provide us with the name, the year and the place of each experience.</small>
                      </label>
                      <ReactQuill
                        name="award"
                        value={props.detail && props.detail.award}
                        placeholder="Enter Your professional details"
                        onChange={(value) =>
                          props.setApply({
                            ...props.detail,
                            award: value,
                          })
                        }
                        theme="snow"
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                    <div className="form-group">
                      <label>Upload your best artworks images *
                        <small className="wrtsmall">(Upload: Max-10 and Min-2 Artworks, File format: PNG/JPG, Max Size: 2MB)</small>
                      </label>
                      <div className={styles.imguplod}>
                        <div htmlFor="input" style={{ textAlign: "center" }}>
                          <input
                            name="art_work"
                            onChange={props.handleImageChangeMultipleGallery}
                            type="file"
                            id="art_work"
                            hidden
                            accept="image/*"
                          />
                          <label htmlFor="art_work" className={styles.labelv}>
                            <FiUpload />
                            <p>Upload Your Art Works</p>
                          </label>
                        </div>
                      </div>
                      <div style={{ display: "flex", gap: "2px" }} className={styles.result}>
                        {renderPhotosGallery(props?.selectedFilesGallery)}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>
                        If your gallery is represented by another online platform, Please provide URL links
                      </label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="other_URL"
                        value={props?.detail?.other_URL}
                        placeholder="www.example.com"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Please provide the link to your gallery online portfolio (Gallery Website)</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="portfolio"
                        value={props.detail && props.detail.portfolio}
                        placeholder="www.example.com"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        What is your main category? *
                      </label>
                      <Grid container>
                        {props?.maincategory?.map((item) => {
                          const array1 = props?.detail?.category_id || [];
                          const isChecked = array1.some(
                            (ele) => ele?._id === item?._id
                          );
                          return (
                            <Grid item xs={12} sm={6} md={4} xl={3} key={item._id}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{
                                    "& .MuiFormControlLabel-label ": {
                                      fontSize: "14px"
                                    }
                                  }}
                                  control={
                                    <Checkbox
                                      name="category_id"
                                      value={item._id}
                                      onClick={props.handleChange}
                                      checked={isChecked}
                                    />
                                  }
                                  label={item?.name}
                                />
                              </FormGroup>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                    <div className="form-group">
                      <label>Please select your artwork selling price range *</label>
                      <Grid container>
                        {props?.pricerange?.map((item) => {
                          // Check if the current item exists in art_price_range
                          const isChecked = props?.detail?.art_price_range?.includes(item?.label) || false;


                          return (
                            <Grid item xs={12} sm={6} md={4} xl={3} key={item?.label}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{
                                    "& .MuiFormControlLabel-label ": {
                                      fontSize: "14px"
                                    }
                                  }}
                                  control={
                                    <Checkbox
                                      onClick={props.handleChange}
                                      checked={isChecked}
                                      value={item.label}
                                      name="art_price_range"
                                    />
                                  }
                                  label={item?.label}
                                />
                              </FormGroup>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                    <div className="form-group">
                      <label>
                        How many artworks have you sold in the past 12 months? *
                      </label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="number"
                        name="arts_solds_in_past_12_months"
                        value={props?.detail?.arts_solds_in_past_12_months}
                        placeholder="Enter your Artworks"
                        onChange={props.handleChange}
                        minLength={0}
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 2 && (
                <>
                  <div className={styles.second_flex}>
                    <div className="form-group">
                      <label>City</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="city"
                        value={props.detail && props.detail?.addresses?.[0]?.city}
                        placeholder="Enter your city"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>State</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="state"
                        value={props.detail && props.detail?.addresses?.[0]?.state}
                        placeholder="Enter Your State"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Country</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="country"
                        value={
                          props.detail && props.detail?.addresses?.[0]?.country
                        }
                        placeholder="Enter Your Country"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Zipcode</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="zipcode"
                        value={
                          props.detail && props.detail?.addresses?.[0]?.zipcode
                        }
                        placeholder="Enter Your zipcode"
                        onChange={props.handleChange}
                        fullWidth
                      />
                    </div>
                    <div className="form-group">
                      <label>Address 1</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="address_line1"
                        value={
                          props.detail &&
                          props.detail?.addresses?.[0]?.address_line1
                        }
                        placeholder="Enter Your address1"
                        onChange={props.handleChange}
                        fullWidth
                      />
                    </div>
                    <div className="form-group">
                      <label>Address 2</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="address_line2"
                        value={
                          props.detail &&
                          props.detail?.addresses?.[0]?.address_line2
                        }
                        placeholder="Enter Your address2"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Nationality</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        rows={5}
                        name="nationality"
                        value={
                          props.detail &&
                          props.detail?.addresses?.[0]?.nationality
                        }
                        placeholder="Enter Your nationality"
                        onChange={props.handleChange}
                      />
                    </div>

                  </div>
                </>
              )}
              {activeTab === 3 && (
                <>
                  <div className={styles.second_flex}>
                    <div className="form-group">
                      <label>Bank Name</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="bank_name"
                        value={props.detail.bank_name}
                        placeholder="Enter bank name"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Bank Address</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="bank_address"
                        value={props.detail && props.detail.bank_address}
                        placeholder="Enter bank address"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Bank Location</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="bank_location"
                        value={props.detail && props.detail.bank_location}
                        placeholder="Enter bank location"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Account Holder name</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="account_holder_name"
                        value={props.detail && props.detail.account_holder_name}
                        placeholder="Enter account holder name"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Account Type</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="account_type"
                        value={props.detail && props.detail.account_type}
                        placeholder="Enter account type"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Account Number</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="number"
                        name="account_number"
                        value={props.detail && props.detail.account_number}
                        placeholder="Enter account number"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Routing/ABA Number</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="routing_ABA_number"
                        value={props.detail && props.detail.routing_ABA_number}
                        placeholder="Enter routing/ABA number"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Swift Code</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="number"
                        name="swift_code"
                        value={props.detail && props.detail.swift_code}
                        placeholder="Enter swift code"
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Paypal Account</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="paypal_id"
                        value={props.detail && props.detail.paypal_id}
                        placeholder="Enter Paypal Account"
                        onChange={props.handleChange}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.second_flex}>


            {/* <div className="form-group">
              <label>
                Please provide us with the name, the year, and the place of each experience
              </label>
              <ReactQuill
                name="professional_details"
                value={props.detail?.professional_details}
                onChange={(value) =>
                  props.setApply({ ...props.detail, professional_details: value })
                }
                placeholder="Enter your professional details"
                theme="snow"
                modules={modules}
                formats={formats}
              />
            </div>
            
            
            <div className="form-group">
              <label>Preview ArtWork Sample</label>
              {props.src.art === "" ? (
                <div className={styles.img_box}>
                  <div
                    htmlFor="art_work"
                    style={{ textAlign: "center" }}
                    onClick={props.handleChangeImage}
                  >
                    <input
                      onChange={props.handleChangeImage}
                      name="art_work"
                      type="file"
                      id="art_work"
                      hidden
                    />
                    <label htmlFor="art_work" className={styles.labelv}>
                      <FiUpload />
                      <p>Upload Artwork</p>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <label htmlFor="art_work" className={styles.labelv}>
                    <img
                      src={props.src.art}
                      className={styles.img_box_img}
                      alt="images"
                    />

                    <input
                      onChange={props.handleChangeImage}
                      name="art_work"
                      type="file"
                      id="art_work"
                      hidden
                    />
                  </label>
                </>
              )}
            </div> */}
          </div>
        </>
      ) : (
        <>
          <Grid
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            {props.input &&
              props.input.map((val) => (
                // <div key={val.name} className={styles.inputs_div}>
                <>
                  {val.type === "password" ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={styles.password_label}>
                        <label>Password</label>
                        <label
                          className={styles.forgot}
                          onClick={() => props.setPasspop(true)}
                        >
                          Change?
                        </label>
                      </div>
                      <TextField
                        className={styles.input_pass}
                        variant="outlined"
                        // type="password"
                        value={localStorage.getItem("password")}
                        name="password"
                        placeholder="************"
                        sx={{
                          backgroundColor: "white",
                          height: 50,
                          borderRadius: "6px",
                          mt: 0.5,
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                              // opacity: 0.5
                            },
                            // '&:hover fieldset': {
                            //     borderColor: 'yellow',
                            // },
                            // '&.Mui-focused fieldset': {
                            //     borderColor: 'green',
                            // },
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                  ) : val.type === "file" ? (
                    <Grid item xs={12} sm={6} md={6}>
                      <label>{val.label}</label>
                      <br />
                      <input type="file" />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={styles.inputs_div_margin}>
                        <label>{val.label}</label>
                        <TextField
                          className="form-control"
                          variant="outlined"
                          type={val.type}
                          name={val.name}
                          placeholder={val.placeholder}
                          // value={props.affiliateDetails.+val.name}
                          onChange={props.handleChange}
                          sx={{
                            backgroundColor: "white",
                            height: 50,
                            borderRadius: "6px",
                            mt: 0.5,
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                                // opacity: 0.5
                              },
                              // '&:hover fieldset': {
                              //     borderColor: 'yellow',
                              // },
                              // '&.Mui-focused fieldset': {
                              //     borderColor: 'green',
                              // },
                            },
                          }}
                          fullWidth
                        />
                      </div>
                    </Grid>
                  )}
                </>
              ))}
          </Grid>
          {props.moreinputs &&
            props.moreinputs.map((val) => (
              <div>
                <div className={styles.new_div_inputs}>
                  <h2>{val.heading}</h2>
                  <hr className={styles.hrs} />
                </div>
                {val.exhibition_inputs.map((val) => (
                  <div key={val.name} className={styles.inputs_div_second}>
                    <div className={styles.edit_div}>
                      <label className={styles.input_edit_label}>
                        {val.label}
                      </label>
                      <label onClick={handlePopup} className={styles.forgot}>
                        {val.edit && val.edit}
                      </label>
                    </div>
                    {val.type === "textarea" ? (
                      <TextareaAutosize
                        maxRows={8}
                        minRows={8}
                        fullWidth
                        sx={{
                          mt: 1,
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&&:after": {
                            border: "none",
                          },
                        }}
                        className={styles.textarea}
                        placeholder={val.placeholder}
                      />
                    ) : (
                      <TextField
                        className={styles.inpu}
                        variant="outlined"
                        type={val.type}
                        name={val.name}
                        placeholder={val.placeholder}
                        sx={{
                          backgroundColor: "white",
                          height: 50,
                          borderRadius: "6px",
                          mt: 1,
                          mb: 0.5,
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                              // opacity: 0.5
                            },
                          },
                        }}
                        fullWidth
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          <ToastContainer />
        </>
      )}
    </>
  );
}
