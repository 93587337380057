import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess, Warnnoti } from "../../../../common/alert/Alert"
import {
  deletePrivacyPolicy,
  deleteRentalAgreement,
  deleteReturnPolicy,
  deleteShippingPolicy,
  deleteTermsCondition,
  editPrivacyPolicy,
  editRentalAgreement,
  editReturnPolicy,
  editShippingPolicy,
  editTermsCondition,
  fetchPrivacyPolicyData,
  handleAddPrivacyPolicy,
  handleAddRentAlagreement,
  handleAddReturnPolicy,
  handleAddShippingPolicy,
  handleAddTermsCondition,
} from "../../../../State/api/superadminApi"
import { setPolicydata } from "../../../../State/Features/superadmin/policySlice"
import Pagination from "../Pagination/Pagination"
import Enterdetails from "../Popupdelete/enterDetails/Enterdetails"
import Moreoption from "../Table/Moreoption/Moreoption"
import Tableheader from "../Table/Tableheader/Tableheader"
import Tabletwocol from "../Table/Twocol/Tabletwocol"
import styles from "./Policy.module.css"

const moreOptions = [
  {
    id: "request",
    label: "Terms & Conditon",
    value: "term condition",
  },
  {
    id: "shitpping policy",
    label: "Shipping Policy",
    value: "shipping policy",
  },
  {
    id: "return policy",
    label: "Return Policy",
    value: "return policy",
  },
  {
    id: "privacy policy",
    label: "Privacy Policy",
    value: "privacy policy",
  },
  {
    id: "rental agreement",
    label: "Rental Agreement",
    value: "rental agreement",
  },
]
const head = [
  {
    id: 1,
    label: "Title",
  },
  {
    id: 2,
    label: "Description",
  },
]
const sortdata = [
  { label: "By Title ↓", value: "low" },
  { label: "By Title ↑", value: "high" },
]
const filterdata = [
  { label: "Free", value: "free" },
  { label: "Paid", value: "paid" },
  //  { label: "Rejected", value: "rejected" },
]
export default function Policy() {
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [button, setButton] = useState({ type: "add", text: "Create" })
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const [activetab, setAcitvetab] = useState("term condition")
  const [data, setData] = useState({ offset: 1 })
  const [editdata, setEditdata] = useState()
  const policydata = useSelector((state) => state.policyData.data)
  console.log(editdata)

  console.log("polciyData",policydata)
  const handleMorecategories = (e) => {
    setAcitvetab(e)
  }
  const dispatch = useDispatch()
  const fetchData = () => {
    if (activetab === "term condition") {
      const url = "api/termsCondition/fetchTermsConditions"
      dispatch(fetchPrivacyPolicyData(url, data))
    } else if (activetab === "shipping policy") {
      const url = "api/shippingPolicy/fetchShippingPolicies"
      dispatch(fetchPrivacyPolicyData(url, data))
    } else if (activetab === "return policy") {
      const url = "api/returnPolicy/fetchReturnPolicies"
      dispatch(fetchPrivacyPolicyData(url, data))
    } else if (activetab === "privacy policy") {
      const url = "api/privacyPolicy/fetchPrivacyPolicies"
      dispatch(fetchPrivacyPolicyData(url, data))
    } else {
      const url = "api/rentalAgreement/fetchRentalAgreements"
      dispatch(fetchPrivacyPolicyData(url, data))
    }
  }
  const handleEditdata = (dt) => {
    setEdit(true)
    setEditdata(dt)
  }
  useEffect(() => {
    fetchData()
  }, [activetab])
  const handleAdd = (dt) => {
    if (activetab === "term condition") {
      dispatch(handleAddTermsCondition(dt, setOpen, fetchData))
    } else if (activetab === "shipping policy") {
      dispatch(handleAddShippingPolicy(dt, setOpen, fetchData))
    } else if (activetab === "return policy") {
      dispatch(handleAddReturnPolicy(dt, setOpen, fetchData))
    } else if (activetab === "privacy policy") {
      dispatch(handleAddPrivacyPolicy(dt, setOpen, fetchData))
    } else {
      dispatch(handleAddRentAlagreement(dt, setOpen, fetchData))
    }
  }
  const handleEdit = (dt) => {
    if (activetab === "term condition") {
      dispatch(editTermsCondition(dt._id, dt, setEdit, fetchData))
    } else if (activetab === "shipping policy") {
      dispatch(editShippingPolicy(dt._id, dt, setEdit, fetchData))
    } else if (activetab === "return policy") {
      dispatch(editReturnPolicy(dt._id, dt, setEdit, fetchData))
    } else if (activetab === "privacy policy") {
      dispatch(editPrivacyPolicy(dt._id, dt, setEdit, fetchData))
    } else {
      dispatch(editRentalAgreement(dt._id, dt, setEdit, fetchData))
    }
  }
  const handleDelete = (dt) => {
    Warnnoti("Are you sure?", () => {
      if (activetab === "term condition") {
        dispatch(deleteTermsCondition(dt?._id, fetchData))
      } else if (activetab === "shipping policy") {
        dispatch(deleteShippingPolicy(dt?._id, fetchData))
      } else if (activetab === "return policy") {
        dispatch(deleteReturnPolicy(dt?._id, fetchData))
      } else if (activetab === "privacy policy") {
        dispatch(deletePrivacyPolicy(dt?._id, fetchData))
      } else {
        dispatch(deleteRentalAgreement(dt?._id, fetchData))
      }
    });
  
  }
  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })
  }
  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }
  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setPolicydata({
          data:
            policydata.data &&
            [...policydata.data].sort((a, b) => {
              if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
            }),
          totalCount: policydata.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setPolicydata({
          data:
            policydata.data &&
            [...policydata.data].sort((a, b) => {
              if (a.title.toLowerCase() > b.title.toLowerCase()) return -1
            }),
          totalCount: policydata.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }
  return (
    <div className={styles.mains}>
      {open ? (
        <Enterdetails
          handleAdd={handleAdd}
          poptype="policy"
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Enterdetails
          handleAdd={handleEdit}
          poptype="policy"
          open={edit}
          editdata={editdata}
          setOpen={setEdit}
        />
      ) : (
        ""
      )}
      <AlertsSuccess />
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
          policydata={policydata}
        />
        <Moreoption
          activetab={activetab}
          moreOptions={moreOptions}
          handleMorecategories={handleMorecategories}
          setData={setData}
          data={data}
        />
        <Tabletwocol
          activetab={activetab}
          handleEditdata={handleEditdata}
          handleDelete={handleDelete}
          head={head}
          data={
            policydata &&
            policydata.data.filter((val) =>
              val.title.toLowerCase().includes(condition.search.toLowerCase())
            )
          }
        />
        <Pagination
          totaldata={policydata && policydata.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  )
}
