import * as React from "react"
import styles from "./Buyertable.module.css"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Box, Button, IconButton, Pagination, Link, PaginationItem } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"
import { setLoader } from "../../../../../State/Features/common/loaderSlice"
import { FiTrash2 } from "react-icons/fi"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dayjs from "dayjs"
const headData = [
  {
    id: "customer_id",
    label: "Customer ID",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Buyer Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "email",
    label: "Email",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "country",
    label: "Country",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "item",
    label: "No. of items",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "newsletter",
    label: "Newsletter",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "contact",
    label: "Contact No.",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
]
const rows = [
  {
    customer_id: "#hd3444h",
    buyer_name: "smiley",
    email: "smiley@gmail.com",
    country: "India",
    items: "43",
    newsletter: "yes",
    contact: "87348798384",
    action: "Block",
    status: 1,
  },
  {
    customer_id: "#hd344h",
    buyer_name: "art smiley",
    email: "ARTsmiley@gmail.com",
    country: "India",
    items: "473",
    newsletter: "yes",
    contact: "87348798384",
    action: "Unblock",
    status: 0,
  },
  {
    customer_id: "#hd3444",
    buyer_name: "smiley",
    email: "smiley@gmail.com",
    country: "India",
    items: "43",
    newsletter: "yes",
    contact: "87348798384",
    action: "Block",
    status: 1,
  },
  {
    customer_id: "#hd444h",
    buyer_name: "art smiley",
    email: "ARTsmiley@gmail.com",
    country: "India",
    items: "473",
    newsletter: "yes",
    contact: "87348798384",
    action: "Unblock",
    status: 0,
  },
  {
    customer_id: "hd3444h",
    buyer_name: "smiley",
    email: "smiley@gmail.com",
    country: "India",
    items: "43",
    newsletter: "yes",
    contact: "87348798384",
    action: "Block",
    status: 1,
  },
  {
    customer_id: "#d3444h",
    buyer_name: "art smiley",
    email: "ARTsmiley@gmail.com",
    country: "India",
    items: "473",
    newsletter: "yes",
    contact: "87348798384",
    action: "Unblock",
    status: 0,
  },
]

export default function Buyertable(props) {
  const [age, setAge] = React.useState("")
  const loader = useSelector((state) => state.loader)
  const dispatch = useDispatch()
  const handleClick = (event) => {
    dispatch(setLoader(false))
  }

  const handleDelete = (data) => {
    props.handlePopup(data)
    props.setOpen && props.setOpen(true)
  }



    const [currentPage, setCurrentPage] = React.useState(1);
      const itemsPerPage = 10; 
    
      
      const handlePageChange = (event, page) => {
        setCurrentPage(page);
      };
    
      
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentItems = props?.data?.slice(startIndex, endIndex);

  return (
    <>
      {/* <div className={styles.buyer_mai}> */}
      {/* <div className={styles.buyer_header}>
                    <div className={styles.input_div}>
                        <input className={styles.search_input} type='text' placeholder='Search by...' />
                    <img className={styles.search_icon} src={ /img/Search Symbol.png'} height='20px' width='20px' />
                    </div>
                    <div className={styles.header_action}>
                        <FormControl sx={{ minWidth: 100 }}>
                            <Select
                                value={age}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ height: '44px', minWidth: '100px', color: '#636365' }}
                            // IconComponent={() => <img src={ /img/dropdownicon.png'} />}
                            // IconComponent={() => <KeyboardArrowDownIcon />}
                            >
                                <MenuItem value="">
                                    Sort by
                                </MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 100 }}
                            className={styles.header_action_filter}>
                            <Select
                                value={age}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ height: '44px', minWidth: '100px', color: '#636365' }}
                                className={styles.header_action_filter}
                            >
                                <MenuItem value="">
                                    Filter
                                </MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <Button variant='contained' className={styles.header_action_btn} sx={{ width: '100px', minWidth: 100, height: '44px', mr: 0, ml: 0 }} >Add</Button>
                    </div>
                </div> */}
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.headData.map((column) => (
                <TableCell
                  className={styles.table_head}
                  align="start"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {currentItems &&
                currentItems.map((row) => (
                  <TableRow
                    onClick={() => handleDelete(row)}
                    style={{ color: "#636365", cursor: "pointer" }}
                    key={row._id}
                  >
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        textTransform:"capitalize"
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row._id}
                    </TableCell>
                    <TableCell
                      className={styles.table_column}
                      component="th"
                      scope="row"
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        textTransform:"capitalize"
                      }}
                    >
                      {row.first_name ? (
                        <>
                          {row.first_name} {row.last_name}
                        </>
                      ) : (
                        "----"
                      )}

                      {/* <img src={ /img/Image 1.png'} width='50px' height='40px' /> */}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {
                        row.createdAt
                          ? dayjs(row.createdAt).format("DD-MM-YYYY").toString()
                          : "----"
                      }
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.email ? row.email : "----"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                     {row?.countries?.length > 0 ? row.countries[0] : "----"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.orders_count ? row.orders_count : "0"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.usernews[0]?.newsletter===true ? "Yes" : "No"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.phone ? row.phone : "----"}
                    </TableCell>
                    {/* {props.activetab === 'galleryrecrete' ?
                                    <TableCell sx={{
                                        fontFamily: 'DM Sans',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        lineHeight: '21px',
                                        color: '#35BE43'
                                    }} className={styles.table_column} align="left">
                                        <div className={styles.quoterequest}>
                                            {row.action && row.action === 'accept' ? <span > Pending</span> : row.action === 'rejected' ? <span className={styles.rejected}> Rejected</span>
                                                :
                                                <>
                                                    <Button variant='contained' sx={{ width: '80px', height: '34px', borderRadius: '4px' }}>Accept</Button>
                                                    <Button variant='outlined' sx={{ width: '80px', height: '34px', borderRadius: '4px' }} >Decline</Button>
                                                </>
                                            }
                                        </div>
                                    </TableCell>
                                    : */}
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                      className={styles.table_column}
                    >
                      <Button
                        color={row.is_blocked ? "secondary" : "primary"}
                        variant="contained"
                        sx={{ width: "100px", mr: 2 }}
                      >
                        {row.is_blocked ? "Unblock" : "Block"}
                      </Button>
                      <IconButton
           sx={{
            "&.MuiIconButton-root":{
              padding:"0",
              fontSize:"initial"
            }
           }}
           > <Link><FiTrash2 className={styles.delicon} /></Link></IconButton>
                    </TableCell>
                    {/* } */}
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

       <>
          <Box className={styles.pagination_box} sx={{}}>
          <Pagination   count={Math.ceil(props?.data?.length / itemsPerPage)} 
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
          sx={{
            "&.Mui-selected": {
              backgroundColor: "black", 
              color: "white", 
              "&:hover": {
                backgroundColor: "black", 
              },
            },
          }}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )} />
          </Box>
            </>


      {/* {totalblogs > state.limit && ( */}
      {/* )} */}
      {/* </div> */}
    </>
  )
}
