import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess, Warnnoti } from "../../../../common/alert/Alert"
import {
  deleteBlog,
  editBlog,
  fetchBlogData,
  fetchBlogDataByslug,
  fetchItemType,
  handleAddBlog,
} from "../../../../State/api/superadminApi"
import { setBlogdata } from "../../../../State/Features/superadmin/blogSlice"
import Pagination from "../Pagination/Pagination"
import Addimgpopup from "../Popupdelete/addSubscription/addwithImage/Addimgpopup"
import Tablesfivecol from "../Table/Fivecolumn/Tablefivecol"
import Sixcolimg from "../Table/sixcolumn/Sixcolimg/Sixcolimg"
import Tableheader from "../Table/Tableheader/Tableheader"
import Addblog from "./addBlog/Addblog"
import styles from "./Blogs.module.css"

const upcominghead = [
  {
    id: "artist_name ",
    label: "Image",
  },
  {
    id: "artist_name ",
    label: "Title",
  },
  {
    id: "location",
    label: "Date",
  },
  {
    id: "like",
    label: "Assign by",
  },
  {
    id: "like",
    label: "Type",
  },
  {
    id: "followe",
    label: "Description",
  },
  {
    id:'action',
    label:'Action'
  }
]
const printData = [
  {
    id: 1,
    src: "Image 1.png",
    mobile_no: "Emily cooper",
    email: "July 21 2021",
    assign_to: "Admin",
    type: "News",
    action: "Emerging artist is Emerging artist",
  },

  {
    id: 2,
    src: "Image 2.png",
    mobile_no: "Emily cooper",
    email: "July 21 2021",
    assign_to: "Admin",
    type: "News",
    action: "Emerging artist is Emerging artist",
  },
  {
    id: 3,
    src: "Image 3.png",
    mobile_no: "Emily cooper",
    email: "July 21 2021",
    assign_to: "Admin",
    type: "News",
    action: "Emerging artist is Emerging artist",
  },
  {
    id: 4,
    src: "Image 4.png",
    mobile_no: "Emily cooper",
    email: "July 21 2021",
    assign_to: "Admin",
    type: "News",
    action: "Emerging artist is Emerging artist",
  },
  {
    id: 5,
    src: "Image 5.png",
    mobile_no: "Emily cooper",
    email: "July 21 2021",
    assign_to: "Admin",
    type: "News",
    action: "Emerging artist is Emerging artist",
  },
  {
    id: 6,
    src: "Image 1.png",
    mobile_no: "Emily cooper",
    email: "July 21 2021",
    assign_to: "Admin",
    type: "News",
    action: "Emerging artist is Emerging artist",
  },
  {
    id: 7,
    src: "Image 1.png",
    mobile_no: "Emily cooper",
    email: "July 21 2021",
    assign_to: "Admin",
    type: "News",
    action: "Emerging artist is Emerging artist",
  },
]
const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
]
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
]
export default function Blogs() {
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [data, setData] = useState({ offset: 0 })
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editdata, setEditdata] = useState()
  const dispatch = useDispatch()
  const blogdt = useSelector((state) => state.blogdata.data)
  const fetchData = () => {
    dispatch(fetchBlogData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])
 
  const fetchslugData = () => {
    dispatch(fetchBlogDataByslug(data))
  }

  const handleAddblog = (data) => {
    console.log('blogData',data)
    dispatch(handleAddBlog(data, setOpen, fetchData))
  }
  const handleDelete = (id) => {
    Warnnoti("Are you sure?", () => {
      dispatch(deleteBlog(id, fetchData))
    });
    
  }
  const handleEditPopup = (data) => {
    console.log("dataforDate",data)
    setEditdata(data)
    setEdit(true)
  }
  const handleEdit = (id, data) => {
    dispatch(editBlog(id, data, setEdit, fetchData))
  }
  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })

    if (value === "name-asc") {
      dispatch(
        setBlogdata({
          data:
            blogdt.data &&
            [...blogdt.data].sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            ),
          totalCount: blogdt.totalCount,
        })
      )
    } else if (value === "name-desc") {
      dispatch(
        setBlogdata({
          data:
            blogdt.data &&
            [...blogdt.data].sort((a, b) =>
              a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1
            ),
          totalCount: blogdt.totalCount,
        })
      )
    } else {
      fetchData()
    }
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  // const handleSorting = (e) => {
  //   let value = e.target.value
  //   if (value === "low") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setBlogdata({
  //         data:
  //           blogdt.data &&
  //           [...blogdt.data].sort((a, b) => {
  //             if (a.date.toLowerCase() < b.date.toLowerCase()) return -1
  //           }),
  //         totalCount: blogdt.totalCount,
  //       })
  //     )
  //   } else if (value === "high") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setBlogdata({
  //         data:
  //           blogdt.data &&
  //           [...blogdt.data].sort((a, b) => {
  //             if (a.date.toLowerCase() > b.date.toLowerCase()) return -1
  //           }),
  //         totalCount: blogdt.totalCount,
  //       })
  //     )
  //   } else {
  //     fetchData()
  //     setCondition({ ...condition, sorting: value })
  //   }
  // }

  const handleSorting = (e) => {
    let value = e.target.value   
    setCondition({ ...condition, sorting: value })
  }


  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {/* {open ? <Addblog open={open} setOpen={setOpen} /> : ''} */}
      {open ? (
        <Addimgpopup
          handleAddblog={handleAddblog}
          open={open}
          setOpen={setOpen}
          poptype="blogs"
        />
      ) : (
        ""
      )}
      {edit ? (
        <Addimgpopup
          open={edit}
          setOpen={setEdit}
          editdata={editdata}
          handleEdit={handleEdit}
          poptype="editblogs"
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          button={button}
          setOpen={setOpen}
        />
        <Sixcolimg
          handleDelete={handleDelete}
          handleEditPopup={handleEditPopup}
          activetab="blogs"
          head={upcominghead}
          data={
            blogdt &&
            blogdt.data

              .filter((val) => {
                const search = condition.search.toLowerCase().trim()
                const nameMatch =
                  val.title && val.title.toLowerCase().includes(search)

                const assign_by =
                  val.assign_by && val.assign_by.toLowerCase().includes(search)

                const blog_type =
                  val.blog_type && val.blog_type.toLowerCase().includes(search)

                return nameMatch || assign_by || blog_type
              })
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              ?.sort((a,b)=>{
                if(condition.sorting === "high"){
                  return new Date(b.createdAt) - new Date(a.createdAt);
                }else if (condition.sorting === "low"){
                  return new Date(a.createdAt) - new Date(b.createdAt); // Sort by oldest
                }
                return 0; // Default case: no sorting
              })
          }
        />
        {/* <Pagination
          totaldata={blogdt && blogdt.totalCount}
          pagination={data}
          setPagination={setData}
        /> */}
      </div>
    </div>
  )
}
