import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./AtristInquirtDetails.module.css";
import capitalizeWords from "../../../utils/capitalizeContent";
import { IoIosArrowBack } from "react-icons/io";
import dateFormat from "../../../utils/dateFormat";
import { Box, Button, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEnquiryByEnquiryId,
  fetchEnquiryReplyByEnquiryId,
} from "../../../State/api/superadminApi";
import InquiryChatpopup from "./popup/InquiryChatpopup";
import dayjs from "dayjs";
import api from "../../../State/api/Api";
import moment from "moment";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { GetInquaryReply, InquiryStatus } from "../../../State/api/commonapi";
function AtristInquirtDetails() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = location.state || {};
  console.log("details page description:", id);

  const [inquiries, setInquiries] = useState([])

  const handleFetchAllInquiries = async () => {
    try {
      const { data } = await api.applicationApi(
        "api/enquiry/fetchEnquiries",
        "POST",
      )
      setInquiries(data.data.enquiries?.filter((ele) => ele?._id === id)?.[0])
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    handleFetchAllInquiries()
  }, [])
  console.log("inquiries", inquiries);

  const InquiryStatusChange = (status, id) => {
    dispatch(InquiryStatus(status, id))
      .then(() => {
        dispatch(handleFetchAllInquiries()); // Refresh the inquiries list after status update
      })
      .catch((error) => {
        console.error("Error updating inquiry status:", error);
      });
  };

  const enquiryId = useSelector((state) => state.enquirybyenquiryid.data);
  const enquiryreply = useSelector((state) => state.enquiryreplybyiddata.data);
  const enquirchatdetails = useSelector((state) => state.inquirychatdetails.data);


  console.log("enquirchatdetails", enquirchatdetails);

  const fetchData = () => {
    dispatch(fetchEnquiryByEnquiryId(id));
    dispatch(fetchEnquiryReplyByEnquiryId(id));
    dispatch(GetInquaryReply(id));
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [Open, setOpen] = useState(false);
  const handleAddchatbtn = (id) => {
    setOpen(true);
  };
  const repliesToShow = inquiries ? enquirchatdetails : enquiryreply;
  const formatDate = (date) => {
    const today = dayjs();
    const replyDate = dayjs(date);

    if (today.isSame(replyDate, "day")) {
      // If the reply is today, show time (e.g., "Tonight at 3:30 PM")
      return `Today at ${replyDate.format("h:mm A")}`;
    } else if (today.subtract(1, "day").isSame(replyDate, "day")) {
      // If the reply is from yesterday
      return "Yesterday";
    } else {
      // For older replies, show the full date
      return replyDate.format("DD-MM-YYYY");
    }
  };
  return (
    <div className={styles.mains}>
      {Open ?
        <InquiryChatpopup
          GetInquaryReply={GetInquaryReply}
          fetchEnquiryReplyByEnquiryId={fetchEnquiryReplyByEnquiryId}
          inquiries={inquiries}
          Open={Open}
          id={id}
          setOpen={setOpen} /> : ""}
      <div>
        <div className={styles.main_artistprofile}>
          <div className={styles.back_button}>
            <Box display="flex" justifyContent="space-between">
              <Link
                onClick={() => navigate(-1)}
                className={styles.backbn}
              >
                {" "}
                <IoIosArrowBack /> <span> Go Back</span>
              </Link>
              {/* {
                 !(localStorage.getItem("usertype") === "buyer" && inquiries?.is_open === "Close") ||
                (localStorage.getItem("usertype") === "artist" || localStorage.getItem("usertype") === "gallery") && (
                  <button className="action_btn" variant="contained" onClick={handleAddchatbtn}>
                    Add Reply
                  </button>
                )
              } */}

{
 
  (localStorage.getItem("usertype") === "artist" || localStorage.getItem("usertype") === "gallery") && (
    <button className="action_btn" variant="contained" onClick={handleAddchatbtn}>
      Add Reply
    </button>
  )
}
{(localStorage.getItem("usertype") === "buyer" && inquiries?.is_open === "Open") && 
 <button className="action_btn" variant="contained" onClick={handleAddchatbtn}>
 Add Reply
</button>
}

         

            </Box>
          </div>
        </div>

        <div className={""}>
          {!inquiries && enquiryId ? <div className={styles.bdngr}>
            <h3 className={styles.sethng}>
              Subject : {capitalizeWords(enquiryId?.subject)}{" "}
            </h3>
            <p className={styles.odlist}>
              Message : <span>{capitalizeWords(enquiryId?.message)}</span>
            </p>
            <p className={styles.odlist}>
              Enquiry Type :{" "}
              <span>{capitalizeWords(enquiryId?.enquirytype)}</span>
            </p>
            <p className={styles.odlist}>
              Enquiry SubType :{" "}
              <span>{capitalizeWords(enquiryId?.enquirysubtype)}</span>
            </p>
            <p className={styles.odlist}>
              Attachment :{" "}
              <span>
                {enquiryId?.attachment === null
                  ? "----"
                  : enquiryId?.attachment}
              </span>
            </p>
            <p className={styles.odlist}>
              Created At : <span>{dateFormat(enquiryId?.createdAt)}</span>
            </p>
          </div> : null}

          {inquiries ? <div className={styles.bdngr}>
            <p className={styles.odlist}
              style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <span>
                Status :{" "}
                <span style={{ color: inquiries?.is_open === "Open" ? "green" : "red" }}>
                  {capitalizeWords(inquiries?.is_open)}
                </span>
              </span>
              {(localStorage.getItem("usertype") === "gallery" || localStorage.getItem("usertype") === "artist") && (
                <button
                  className="action_btn"
                  variant="contained"
                  onClick={() => InquiryStatusChange(inquiries?.is_open === "Open" ? "Close" : "Open", inquiries?._id)}
                >
                  {inquiries?.is_open === "Open" ? "Status Close" : "Status Open"}
                </button>
              )}

            </p>
            <p className={styles.odlist}>
              Inquiry Id : <span>{capitalizeWords(inquiries?.enquiry_id)}</span>
            </p>
            <p className={styles.odlist}>
              Customer Name : <span>{capitalizeWords(inquiries?.customer_name)}</span>
            </p>
            <p className={styles.odlist}>
              Customer Email : <span>{capitalizeWords(inquiries?.email)}</span>
            </p>
            <p className={styles.odlist}>
              Customer Phone No. : <span>{(inquiries?.phone)}</span>
            </p>
            <p className={styles.odlist}>
              Message : <span>{capitalizeWords(inquiries?.description)}</span>
            </p>

            <p className={styles.odlist}>
              Inquiry Date : <span>{moment(inquiries?.date)?.format("DD-MM-YYYY")}</span>
            </p>
          </div> : null}

        </div>
      </div>
      {/* <div className={styles.more_detail}>
        <h3 className={styles.sethng}>Enquiry Replies</h3>

        {repliesToShow && repliesToShow.length > 0 ? (
          repliesToShow.map((val) => (
            <div key={val._id}>
              <p className={styles.odlist}>
                Reply Message: <span>{val.reply}</span>
              </p>
              <p className={styles.odlist}>
                Created at:{" "}
                <span>{dayjs(val?.createdAt).format("DD-MM-YYYY")}</span>
              </p>
              <p className={styles.odlist}>
                File Name:{" "}
                <span>
                  {val.attachment ? (
                    <a
                      href={val.attachment}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                    >
                      {val.attachment}
                    </a>
                  ) : (
                    "----"
                  )}
                </span>
              </p>
              <hr />
            </div>
          ))
        ) : (
          <div className={styles.no_reply}>No Reply found</div>
        )}
      </div> */}


      <div className={styles.more_detail}>
        <h3 className={styles.sethng}>Enquiry Replies</h3>

        {repliesToShow && repliesToShow.length > 0 ? (
          repliesToShow
            .slice() // Create a shallow copy of the array
            .reverse() // Reverse the array so the latest message is at the bottom
            .map((val) => (
              <div key={val._id}
                className={`${styles.message} ${val.usertype === localStorage.getItem("usertype") ? styles.right : styles.left}`}
              >
                <div className={styles.messageContent}>
                  <p className={styles.odlist}>
                    <MailOutlineIcon /> : <span>{val.reply}</span>
                  </p>
                  <p className={styles.odlist}>
                    <DriveFileMoveIcon /> :{" "}
                    <span>
                      {val.attachment ? (
                        <a
                          href={val.attachment}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                        >
                          {val.attachment}
                        </a>
                      ) : (
                        "----"
                      )}
                    </span>
                  </p>
                  <p className={styles.odlist}>
                    <span className={styles.date}>{formatDate(val.createdAt)}</span>
                  </p>

                </div>
              </div>
            ))
        ) : (
          <div className={styles.no_reply}>No Reply found</div>
        )}
      </div>


    </div>
  );
}

export default AtristInquirtDetails;
