import {
  Button,
  Container,
  createTheme,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./AuctionDetails.module.css";
// import "fontsource-marcellus";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ProdDetsOther from "./ProdDetsOther";
import ProdDetsqna from "./ProdDetsqna";
import PolicySidebar from "./PolicySidebar";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box } from "@mui/system";
import homeImage from "../../../constant";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../State/api/Api";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import serverimage from "../../../serverConstant";
import CircularLoader from "../../../common/loader/Loader";
import { Errornotify } from "../../../common/alert/Alert";
import { AlertsSuccess, Successnotify } from "../../../common/alert/Alert";
import { fetchAllItemData } from "../../../State/api/userApi";
import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      light: "#C1C1C1",
      main: "#000000",
      dark: "#C1C1C1",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffffff",
      main: "#C1C1C1",
      dark: "#ffffff",
      contrastText: "#000",
    },
  },
  typography: {
    prodheading: {
      fontFamily: "Marcellus",
      fontSize: "2.5rem",
    },
  },
});

const cardDetails = [
  {
    id: 1,
    imgUrl: "/img/Rectangle 368.png",
  },
  {
    id: 2,
    imgUrl: "/img/Rectangle 369.png",
  },
  {
    id: 3,
    imgUrl: "/img/Rectangle 370.png",
  },
  {
    id: 4,
    imgUrl: "/img/Rectangle 5199.png",
  },
];
function AuctionDetails() {
  //test
  const [age, setAge] = React.useState("");
  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };

  //test
  const { id } = useParams();
  const [bidamount, setBidamount] = React.useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [auctionProfile, setAuctionProfile] = useState(null);
  const [auctionsDetails, setAuctionsDetails] = useState(null);
  const [bidAmount, setBidAmount] = useState();
  const [productdetails, setProductdetails] = useState();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.loader);
  const allitemdt = useSelector((state) => state.allitemData);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setBidamount(event.target.value);
  };

  const handleFetchAuctionDetail = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(
        `api/auction/fetchAuctionArtDetails/${id}`,
        "POST"
      );
      if (result && result.status === 200) {
        setAuctionsDetails(result.data.data);
        // setAuctionProfile(result.data.response);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  const handleFetchBid = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/auctionbid`);
      if (result && result.status === 200) {
        setBidAmount(result.data.response);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  const handleSubmitBid = async function () {
    // dispatch(setLoader(true));
    const latestBid = filteredAuctions?.bids?.[filteredAuctions?.bids.length - 1];
    const base_bid = filteredAuctions?.item_price;
    if (age < base_bid) {
      Errornotify("The current bid must be greater than the base bid.");
      return; // Stop execution if validation fails
    }
    if (age <= latestBid?.amount) {
      Errornotify("The current bid must be greater than the previous bid.");
      return; // Stop execution if validation fails
    }
    try {
      const result = await api.applicationApi(`api/user/auctionbid  `, "POST", {
        auction_id: auctionsDetails?.auction?._id,
        item_id: filteredAuctions.item?.[0]._id,
        amount: age,
      });
      if (result && result.status === 200) {
        handleFetchBid();
        dispatch(setLoader(false));
        Successnotify(result.data.message);
        handleFetchAuctionDetail()
        setAge("")
      } else if (result.status === 401) {
        Errornotify("error");
        dispatch(setLoader(false));
      } else {
        Errornotify("error");
        dispatch(setLoader(false));
      }
    } catch (err) {
      dispatch(setLoader(false));
      Errornotify("error");
    }
  };
  useEffect(() => {
    handleFetchBid();
    handleFetchAuctionDetail();
    dispatch(fetchAllItemData());
  }, []);

  const handleProducts = async function (ids) {
    dispatch(setLoader(true));
    try {
      // const result = await api.applicationApi('api/user/getoffers')
      const result = await api.applicationApi(`api/user/allitems/${ids}`);
      if (result && result.status === 200) {
        setProductdetails(result.data);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
    const path = window.location.pathname;
    if (path.includes("trending")) {
      navigate(`/trending/${ids}`);
    } else if (path.includes("bestseller")) {
      navigate(`/bestseller/${ids}`);
    } else if (path.includes("mostpopular")) {
      navigate(`/mostpopular/${ids}`);
    } else if (path.includes("auction")) {
      navigate(`/auction/${ids}`);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const endDateFormat = (end_date, start_date) => {
    const duration = moment.duration(moment(end_date).diff(moment(start_date)));

    // Extract days, hours, and minutes from the duration
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    // Format the result
    const formattedDuration = `${days} day${
      days !== 1 ? "s" : ""
    }, ${hours} hour${hours !== 1 ? "s" : ""}, ${minutes} min${
      minutes !== 1 ? "s" : ""
    }`;
    return formattedDuration;
  };
  useEffect(() => {
    const storedItemId = localStorage.getItem("selectedItemId");
    if (storedItemId) {
      setSelectedItemId(storedItemId); // Retrieve itemId from localStorage
    }
  }, []);
  // Filter auction data based on selectedItemId
const filteredAuctions = auctionsDetails?.data?.find(auction =>
  auction._id === selectedItemId
);
console.log("filteredAuctions", filteredAuctions);
const artist_id = filteredAuctions?.artist_id;
    console.log("artist_id", artist_id)
  return (
    <ThemeProvider theme={theme}>
      <AlertsSuccess />
      {/* {frameModal ? (
        <ChooseFrameModal
          handleClickChooseFrame={handleClickChooseFrame}
          frameModal={frameModal}
          setFrameModal={setFrameModal}
          setFrameData={setFrameData}
          frameData={frameData}
        />
      ) : (
        ""
      )}
      {frameModalsecond ? (
        <ChooseFrameModal
          setFrameData={setFrameData}
          frameData={frameData}
          setFrameModalsecond={setFrameModalsecond}
          frameModalsecond={frameModalsecond}
          handleClickChooseFrame={handleClickChooseFrame}
          handleBackbutton={handleBackbutton}
        />
      ) : (
        ""
      )} */}
      <div className={styles.productdetails}>
        {/* test */}
        <div className={styles.proddets__top}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <div className={styles.prod__img}>
                  <div>
                    <img
                      className={`${styles.auction_main__img} img-fluid`}
                      // onLoad={handleImageLoad}
                      // src={
                      //   preview_img.length !== 0
                      //     ? preview_img
                      //     :  val.image
                      // }
                      src={
                        
                        // auctionsDetails?.data?.[0]?.item?.[0]?.image
                        filteredAuctions?.item?.[0]?.image
                      }
                      alt="img"
                    />
                  </div>
                  <br />
                  <div className={styles.previewimg__div}>
                    <img
                      className={styles.small_img}
                      src={"/img/Rectangle 5329.png"}
                    />
                    <img
                      className={styles.small_img}
                      src={"/img/Rectangle 5330.png"}
                    />
                    <img
                      className={styles.small_img}
                      src={"/img/Rectangle 5331.png"}
                    />
                    <img
                      className={styles.small_img}
                      src={"/img/Rectangle 5332.png"}
                    />
                    <img
                      className={styles.outlineimg}
                      src={"/img/interiorsofa1.png"}
                    />
                    <Typography ml={2} fontWeight="600" fontSize="0.8rem">
                      View in a room
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className={styles.prodDetails}>
                  <Typography variant="prodheading">
                    {/* {auctionsDetails?.data?.[0]?.item?.[0]?.name} */}
                    {filteredAuctions?.item?.[0]?.name}
                  </Typography>
                  <p>
                    <Typography fontSize="0.88rem" color="#636365">
                      {/* 3D multiple with glass fibre light line and PMMA mirror in
                      the acrylic display case{" "}
                      {auctionsDetails?.data?.[0]?.item?.[0]?.width} x{" "}
                      {auctionsDetails?.data?.[0]?.item?.[0]?.height} x{" "} */}
                      {/* {auctionsDetails?.data?.[0]?.item?.[0]?.category}  <br />{" "} */}
                      {filteredAuctions?.item?.[0]?.category}  <br />{" "}
                      {/* (50 x 50 x 25 cm.) Incised with signature and title;
                      signed and numbered in black marker on the Certificate of
                      Authenticity affixed to the reverse */}
                    </Typography>
                  </p>
                  <Typography fontSize="0.88rem">
                    {filteredAuctions?.item?.[0]?.width} x{" "}
                    {filteredAuctions?.item?.[0]?.height} x{" "}
                    {filteredAuctions?.item?.[0]?.depth} (
                    {filteredAuctions?.item?.[0]?.width} x{" "}
                    {filteredAuctions?.item?.[0]?.height} x{" "}
                    {filteredAuctions?.item?.[0]?.depth} cm.)
                  </Typography>
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Starting Date
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography
                        color="#636365"
                        fontWeight="400"
                        fontSize="0.85rem"
                      >
                        {moment(auctionsDetails?.auction?.state_date).format(
                          "MMMM D [at] h:mm A"
                        )}{" "}
                        (EDT)
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        End Date
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography ml={4.5} fontWeight="400" fontSize="0.85rem">
                        {endDateFormat(
                          auctionsDetails?.auction?.application_end_date,
                          auctionsDetails?.auction?.start_date
                        )}
                      </Typography>
                      <Typography
                        color="#636365"
                        fontWeight="400"
                        fontSize="0.85rem"
                      >
                        {moment(auctionsDetails?.auction?.end_date).format(
                          "MMMM D [at] h:mm A"
                        )}{" "}
                        (EDT)
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Estimate
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography
                        color="#636365"
                        fontWeight="400"
                        fontSize="0.85rem"
                      >
                        0—0 USD
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Base Bid
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography ml={5.25} fontWeight="400" fontSize="0.85rem">
                      {`$${filteredAuctions?.item_price}`}
                      </Typography>
                      <Typography
                        color="#636365"
                        fontWeight="400"
                        fontSize="0.85rem"
                      >
                        1 bid, reserve met
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <Typography
                    color="#1c1c1c"
                    fontWeight="600"
                    fontSize="0.85rem"
                  >
                    Choose your maximum bid
                  </Typography>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                    <TextField
  labelId="manual-amount-label"
  id="manual-amount"
  value={age} // Change this variable to whatever you're using for state
  label="Enter Amount"
  onChange={handleChangeAge} // Change this to your handling function for amount
  size="small"
  type="number" // Optional: specify type to ensure numeric input
  sx={{
    "& .MuiFormLabel-root": {
      marginTop: "-0.15%",
    },
    marginTop: "2%",
  }}
/>

                    </FormControl>
                  </Box>
                  <Typography fontSize="0.85rem" color="#636365" mt={1}>
                    *This amount excludes shipping fees, applicable taxes, and
                    will have a Buyer's Premium based on the hammer price of the
                    lot: Buyer's Premium
                  </Typography>
                  <Button
                  onClick={handleSubmitBid}
                    sx={{
                      textTransform: "none",
                      color: "white",
                      backgroundColor: "black",
                      width: "100%",
                      marginTop: 1,
                      "&.MuiButtonBase-root:hover": { bgcolor: "black" },
                      borderRadius: "8px",
                      height: "3rem",
                    }}
                  >
                    Place Bid
                  </Button>
                  <hr className={styles.divider} />
                  <Typography fontSize="1rem" fontWeight="500">
                    Recent Bidding
                  </Typography>
                  <div className={styles.bidders}>
                    {filteredAuctions?.bids?.slice().reverse().map((val) => (
 <div className={styles.bidders__flex}>
 <Typography fontSize="0.85rem">{val?.buyer?.[0]?.first_name + " " + val?.buyer?.[0]?.last_name}</Typography>
 <Typography fontSize="0.85rem">${val?.amount}</Typography>
</div>
                    ))}
                   
                  
                  </div>
                  <hr className={styles.divider} />
                </div>
              </Grid>
            </Grid>
          </Container>
          <Grid container>
            <Grid item xs={12} sm={7} md={7}>
              <div className={styles.description}>
                <Typography fontWeight="600">Description</Typography>
                <p>
                {filteredAuctions?.item?.[0]?.description || "No description available"}
                </p>
               
              
              </div>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <div className={styles.sidePanel}>
                {filteredAuctions?.item?.[0]?.is_hanged === "Yes" ? <div className={styles.choose__frame}>
                  <div className={styles.verticalLine}></div>
                  <Typography
                    fontSize="0.9rem"
                    fontWeight="600"
                    ml={1}
                    className={styles.choose__frameDiv}
                  >
                    Choose Frame
                  </Typography>
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "white",
                      backgroundColor: "black",
                      "&.MuiButtonBase-root:hover": { bgcolor: "black" },
                      borderRadius: "8px",
                      width: "35%",
                      marginLeft: "30%",
                      padding: "3%",
                    }}
                  >
                    <Typography fontSize="0.65rem">Choose a frame</Typography>
                  </Button>
                </div> : ""}
                <div className={styles.general__information}>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      Color:
                    </Typography>
                 

                    {filteredAuctions?.item?.[0]?.color?.map((colorItem, index) => (
          <Typography key={index} ml={25}>
            {colorItem?.name}
          </Typography>
        ))}
                   
                  </div>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      Weight:
                    </Typography>
                    <Typography ml={24}> {filteredAuctions?.item?.[0]?.sale?.sale_weight ? `${filteredAuctions?.item?.[0]?.sale?.sale_weight} Kg` : "----"}</Typography>
                  </div>
                  {filteredAuctions?.item?.[0]?.orientation?.length > 0 && 
                  <div className={styles.att__color}>
                  <div className={styles.verticalLine}></div>
                  <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                    Orientation:
                  </Typography>
                  {filteredAuctions?.item?.[0]?.orientation?.map((orientationItem, index) => (
        <Typography key={index} ml={20}>
          {orientationItem?.name}
        </Typography>
      ))}
                </div>
                  }
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      SKU Number:
                    </Typography>
                    <Typography ml={10}>{filteredAuctions?.item?.[0]?.sku_id}</Typography>
                  </div>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      Wall Mounted:
                    </Typography>
                    <Typography ml={20.5}>{filteredAuctions?.item?.[0]?.is_hanged}</Typography>
                  </div>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      Category:
                    </Typography>
                    <Typography ml={20}>{filteredAuctions?.item?.[0]?.category}</Typography>
                  </div>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      Tags:
                    </Typography>
                    <Typography ml={26}>{filteredAuctions?.item?.[0]?.keywords}</Typography>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.features}>
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4} md={4}>
                <div className={styles.safe__shipping}>
                  <img
                    className={styles.mini__truck}
                    src={"/img/Mini Truck.png"}
                    alt="img"
                  />
                  <div>
                    <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                      Safe Shipping Globally
                    </Typography>
                    <Typography fontSize="0.9rem" ml={1.75}>
                      We ensure that your artwork is shipped securely worldwide.
                    </Typography>
                    <Typography fontSize="0.9rem" ml={1.75}>
                      Our reliable shipping partners guarantee safe handling and
                      timely delivery.
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className={styles.safe__shipping}>
                  <img
                    className={styles.package}
                    src={"/img/Package.png"}
                    alt="img"
                  />
                  <div>
                    <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                      Free Return
                    </Typography>
                    <Typography fontSize="0.9rem" ml={1.75}>
                      If your purchase doesn't meet your expectations, returning
                      it is simple and free.
                    </Typography>
                    <Typography fontSize="0.9rem" ml={1.75}>
                      We offer a hassle-free return policy with no hidden fees,
                      allowing you to return the artwork within 14 days.
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className={styles.safe__shipping}>
                  <img
                    className={styles.secure}
                    src={"/img/Secure.png"}
                    alt="img"
                  />
                  <div>
                    <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                      Secure
                    </Typography>
                    <Typography fontSize="0.9rem" ml={1.75}>
                      All transactions on Art Smiley are encrypted and processed
                      securely.
                    </Typography>
                    <Typography fontSize="0.9rem" ml={1.75}>
                      We collaborate with trusted financial partners to provide
                      a safe and professional experience, ensuring your personal
                      information remains protected.
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        {/* middle section */}
        <div className={styles.proddets__middle}>
          <div className={styles.design__headingImage}>
            <img className={styles.design__image} src={"/img/Design.png"} />
          </div>
          <div className={styles.design__heading}>
            <Typography mt={1} fontSize="2rem" fontFamily="marcellus">
              More art WORKS FROM EMILY
            </Typography>
          </div>

          <div className={styles.ProductCards}>
            <Container>
              <Grid container spacing={2}>
                {allitemdt.data &&
                  allitemdt.data
                    // .filter((val) => {
                    //   return artist_id && artist_id.includes(val.artist_ids._id);
                    // })
                    .map((item, i) =>
                      i >= 4 ? (
                        ""
                      ) : (
                        <Grid item xs={12} sm={6} md={3}>
                          <div
                            className={styles.card__main}
                            onClick={() => handleProducts(item._id)}
                          >
                            <div className={styles.card__top}>
                              <img
                                className={styles.cardImage}
                                src={ item.image}
                                alt="img"
                              />
                            </div>
                            <div className={styles.pic_info}>
                              <div className={styles.card__middle}>
                                <div className={styles.card__middleLeft}>
                                  <Typography
                                    fontSize="0.95rem"
                                    fontWeight="700"
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    fontSize="0.8rem"
                                    fontWeight="700"
                                    color="#ccc"
                                  >
                                    {item.artist_id &&
                                      item.artist_id.first_name}{" "}
                                    {item.artist_id && item.artist_id.last_name}
                                  </Typography>
                                </div>
                              </div>
                              <div className={styles.card__middleRight}>
                                <FavoriteBorderIcon fontSize="small" />
                                <Typography fontWeight="400" ml={0.5}>
                                  522
                                </Typography>
                              </div>
                            </div>
                            <hr />
                            <div className={styles.card__bottom}>
                              <div className={styles.bottom__text}>
                                <Typography
                                  fontWeight="600"
                                  color="#ccc"
                                  fontSize="0.65rem"
                                >
                                  Buy
                                </Typography>
                                <Typography
                                  color="black"
                                  fontWeight="500"
                                  fontSize="0.85rem"
                                >
                                  <b>${item.sale.price}</b>
                                </Typography>
                              </div>
                              <div className={styles.verticalLine}></div>
                              <div className={styles.bottom__text}>
                                <Typography
                                  fontWeight="600"
                                  color="#ccc"
                                  fontSize="0.65rem"
                                >
                                  Rent/Month
                                </Typography>
                                <Typography
                                  color="black"
                                  fontWeight="500"
                                  fontSize="0.85rem"
                                >
                                  <b>
                                    $
                                    {item.rent
                                      ? item.rent.rent_price
                                      : "not available"}
                                  </b>
                                </Typography>
                              </div>
                              <div className={styles.verticalLine}></div>
                              <div className={styles.bottom__text}>
                                <Typography
                                  fontWeight="600"
                                  color="#ccc"
                                  fontSize="0.65rem"
                                >
                                  Print
                                </Typography>
                                <Typography
                                  color="black"
                                  fontWeight="500"
                                  fontSize="0.85rem"
                                >
                                  <b>
                                    $
                                    {item.print ? (
                                      item.print?.[0]?.print_price
                                    ) : (
                                      <>
                                        not <br /> available
                                      </>
                                    )}
                                  </b>
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      )
                    )}
              </Grid>
            </Container>
          </div>
          <PolicySidebar />
        </div>
        {/* bottom section */}
        <ProdDetsOther artist_id={artist_id} allitemdt={allitemdt} />
        {/* qna section */}
        <ProdDetsqna />
      </div>
    </ThemeProvider>
  );
}

export default AuctionDetails;
